import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarExpanded: true,
  filterActive: false,
  filterOptions: null,
  sortActive: false,
  sortOptions: null,
  sidebarLoading: false,
};

export const slice = createSlice({
  name: 'app/sidebar',
  initialState,
  reducers: {
    setSidebarExpanded: (state, action) => {
      state.sidebarExpanded = action.payload.sidebarExpanded;
    },
    setFilterActive: (state, action) => {
      state.filterActive = action.payload.filterActive;
    },
    setFilterOptions: (state, action) => {
      state.filterOptions = action.payload;
    },
    setSortActive: (state, action) => {
      state.sortActive = action.payload.sortActive;
    },
    setSortOptions: (state, action) => {
      state.sortOptions = action.payload;
    },
    setSidebarLoading: (state, action) => {
      // console.log('sidebarLoading =', action.payload);
      state.sidebarLoading = action.payload;
    },
  },
});
// export actions
export const {
  setSidebarExpanded,
  setFilterActive,
  setFilterOptions,
  setSortActive,
  setSortOptions,
  setSidebarLoading,
} = slice.actions;
// export selectors
export const selectSidebarExpanded = state => state.sidebar.sidebarExpanded;
// export slice
export default slice.reducer;
