import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { MenuButtonWideFill, ArrowBarDown } from 'react-bootstrap-icons';

import DevelopmentDetailView from './developmentDetailView/DevelopmentDetailView';
import DocumentDetailView from './documentDetailView/DocumentDetailView';
import DocumentNameDetailView from './documentNameDetailView/DocumentNameDetailView';
import PropertyDetailView from './propertyDetailView/PropertyDetailView';
import DelinquentTaxDetailView from './delinquentTaxDetailView/DelinquentTaxDetailView';
import TaxHistoryDetailView from './taxHistoryDetailView/TaxHistoryDetailView';
import TaxPaymentsDetailView from './taxPaymentsDetailView/TaxPaymentsDetailView';

import { setSearchActive } from '../app/searchSlice';
import { setSidebarExpanded } from '../sidebar/sidebarSlice';
import TaxPaymentsFilterForm from '../sidebar/filterForms/taxesFilterForms/TaxPaymentsFilterForm';

const DetailView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const router = useSelector(state => state.router);
  const pathname = router.location.pathname;
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const mainSearch = useSelector(state => state.search.mainSearch);
  const subSearch = useSelector(state => state.search.subSearch);
  const currentID = useSelector(state => state.search.currentID);
  const [detailView, setDetailView] = useState(null);

  // let detailView;

  useEffect(() => {
    switch (mainSearch) {
      case 'documents':
        switch (subSearch) {
          case 'name':
            setDetailView(<DocumentNameDetailView />);
            break;
          case 'crossReference':
            if (!currentID) return null;
            setDetailView(
              currentID.split('-').length === 1 ? (
                <PropertyDetailView />
              ) : (
                <DocumentDetailView />
              )
            );
            break;
          default:
            setDetailView(<DocumentDetailView />);
        }
        break;
      case 'properties':
        if (subSearch === 'development')
          setDetailView(<DevelopmentDetailView />);
        else setDetailView(<PropertyDetailView />);
        break;
      case 'taxes':
        switch (subSearch) {
          case 'delinquentTax':
            setDetailView(<DelinquentTaxDetailView />);
            break;
          case 'taxHistory':
            setDetailView(<TaxHistoryDetailView />);
            break;
          case 'taxPayments':
            setDetailView(<TaxPaymentsDetailView />);
            break;
          default:
            setDetailView(null);
            history.push('/');
        }
        break;
      default:
        setDetailView(null);
        history.push('/');
    }
  }, [mainSearch, subSearch, currentID]);

  return (
    <Col
      className={
        mobileView && sidebarExpanded
          ? 'd-none'
          : 'h-100 w-100 m-0 p-0 d-flex flex-column flex-grow-1 overflow-auto bg-light'
      }
    >
      {mobileView && !sidebarExpanded && (
        <Row
          noGutters
          id="sidebarMenu"
          className={`py-2 px-1 d-flex justify-content-between darkBG`}
          style={{ position: 'sticky', top: '0', zIndex: '10' }}
        >
          <Col className={`px-1`}>
            <Button
              id="sidebarSearchBtn"
              variant="outline-dark"
              className={`w-100`}
              onClick={() => {
                dispatch(setSearchActive({ searchActive: true }));
              }}
            >
              <MenuButtonWideFill width="1.5rem" height="1.5rem" />
              {mobileView && <br />}
              <span className={`pl-0`}>search</span>
            </Button>
          </Col>
          <Col className={`px-1`}>
            <Button
              id="sidebarCollapseBtn"
              variant="outline-dark"
              className={`w-100`}
              onClick={() =>
                dispatch(
                  setSidebarExpanded({ sidebarExpanded: !sidebarExpanded })
                )
              }
            >
              <ArrowBarDown width="1.5rem" height="1.5rem" />
              <br />
              <span className={`pl-0`}>results</span>
            </Button>
          </Col>
        </Row>
      )}
      {detailView}
    </Col>
  );
};

export default DetailView;
