import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { setSidebarExpanded } from '../../sidebarSlice';
import { selectDefaultMainSearch } from '../../../app/defaultSearchesSlice';
import { setCurrentID } from '../../../app/searchSlice';

import { queryToString } from '../../../../utilities/url';
import { setCurrentName } from '../../../detailView/documentNameDetailView/documentNameDetailViewSlice';
import { filterResults, sortResults } from '../../../../utilities/filterSort';
import { CheckSquareFill, Square, Search } from 'react-bootstrap-icons';

const DocumentNameSearchList = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const mainSearchInfo = useSelector(selectDefaultMainSearch(mainSearch));
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const [currentActiveKey, setCurrentActiveKey] = useState(null);
  const dispatch = useDispatch();
  const currentID = useSelector(state => state.search.currentID);
  const history = useHistory();
  const router = useSelector(state => state.router);
  const currentName = useSelector(
    state => state.documentNameDetailView.currentName
  );
  const searchResultsList = () => document.getElementById('searchResultsList');
  const [searchResultsListOverflowed, setSearchResultsListOverflowed] =
    useState(
      !mobileView &&
        searchResultsList() &&
        searchResultsList().getBoundingClientRect().height + 5 <
          searchResultsList().scrollHeight
    );
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const sortOptions = useSelector(state => state.sidebar.sortOptions);

  const searchStatus = useSelector(state => state.search.status);
  // load
  const rawResults = useSelector(state => state.search.searchResults);
  const [searchResults, setSearchResults] = useState(rawResults.slice());

  useEffect(() => {
    let tempResults = [...searchResults];
    // filter
    tempResults = [...filterResults(filterOptions, tempResults)];
    // sort
    tempResults = [...sortResults(sortOptions, tempResults)];
    // update list with filtered/sorted results
    setSearchResults([...tempResults]);
  }, [filterOptions, sortOptions]);

  useEffect(() => {
    setSearchResultsListOverflowed(
      !mobileView &&
        searchResultsList() &&
        searchResultsList().getBoundingClientRect().height + 5 <
          searchResultsList().scrollHeight
    );
  }, [searchResults, mobileView]);

  // update currentID when page loads
  useEffect(() => {
    if (searchStatus === 'succeeded' && searchResults.length > 0) {
      if (!currentID)
        dispatch(
          setCurrentID({
            currentID: `${searchResults[0].NAMEPTR}`,
          })
        );
      if (!currentName) {
        const np = currentID || `${searchResults[0].NAMEPTR}`;
        const nm = searchResults.find(n => `${n.NAMEPTR}` === `${np}`).NAME;
        dispatch(setCurrentName({ currentName: `${nm}` }));
      }
    }
  }, [searchResults]);

  return (
    <>
      {/* show search loading */}
      {searchStatus === 'loading' && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '10rem', width: '10rem' }}
          />
        </Row>
      )}
      {/* show no result message */}
      {searchStatus === 'failed' ||
        (searchStatus === 'succeeded' && searchResults.length === 0 && (
          <Row
            noGutters
            id="sidebarResults"
            className={
              sidebarExpanded
                ? 'w-100 h-100 d-flex flex-column flex-grow-1'
                : 'd-none'
            }
            style={{
              backgroundColor: 'rgba(101, 167, 219, 0.25)',
            }}
          >
            <h5
              className="mx-auto w-75 d-flex flex-column h-100 align-items-center"
              style={{
                marginTop: '25%',
              }}
            >
              <Search
                height={mobileView ? '4rem' : '8rem'}
                width={mobileView ? '4rem' : '8rem'}
                style={{ color: 'rgba(101, 167, 219, 1)' }}
              />
              <p className="mb-1 mt-4 text-center">{`No ${mainSearchInfo.titleSingle.toLowerCase()} results found.`}</p>
              <p className="mb-0 mt-2 text-center">{`Please update your filter parameters or try another search.`}</p>
            </h5>
          </Row>
        ))}
      {/* show search results list */}
      {searchStatus === 'succeeded' && searchResults.length > 0 && (
        <>
          <Row
            className={
              sidebarExpanded ? 'w-100 p-2 border-top border-bottom' : 'd-none'
            }
            style={{
              background: '#e9ecef',
              fontWeight: '600',
              fontFamily: 'Calibri, sans-serif',
            }}
            noGutters
          >
            <Col
              xs={3}
              sm={2}
              className="d-flex flex-column align-items-center"
            >
              <Row noGutters className="w-100 pl-1">
                Party 1
              </Row>
              <Row noGutters className="w-100 pl-1">
                Party 2
              </Row>
            </Col>
            <Col
              xs={6}
              sm={8}
              className="d-flex flex-column align-items-center"
            >
              <Row noGutters className="w-100">
                Name
              </Row>
              <Row noGutters className="w-100">
                KOI Group Filter
              </Row>
            </Col>
            <Col
              xs={3}
              sm={2}
              className="d-flex align-items-center text-center"
              style={{
                marginLeft: searchResultsListOverflowed ? '-1rem' : '0',
              }}
            >
              Document Count
            </Col>
            {/* <Col xs={1}></Col> */}
          </Row>
          <div
            className={
              sidebarExpanded
                ? 'w-100 overflow-auto border-bottom shadow-sm'
                : 'd-none'
            }
            style={{
              fontWeight: '100',
              fontFamily: 'Calibri, sans-serif',
            }}
            id="searchResultsList"
          >
            {searchResults.map((result, index) => {
              return (
                <Card
                  key={index}
                  className="w-100 rounded-0 border-right-0 border-left-0"
                  style={{ marginTop: '-1px' }}
                >
                  <Card.Header className="w-100 px-2 d-flex flex-row border-bottom-0">
                    <Col
                      xs={3}
                      sm={2}
                      className="px-0 d-flex flex-column align-items-center justify-content-between"
                    >
                      <Row noGutters className="w-100 pl-3 text-primary">
                        {result.PARTY1FLAG === 'X' ? (
                          <CheckSquareFill />
                        ) : (
                          <Square />
                        )}
                      </Row>
                      <Row
                        noGutters
                        className="w-100 pl-3 text-primary"
                        style={{ marginBottom: '0.15rem' }}
                      >
                        {result.PARTY2FLAG === 'X' ? (
                          <CheckSquareFill />
                        ) : (
                          <Square />
                        )}
                      </Row>
                    </Col>
                    <Col
                      xs={6}
                      sm={8}
                      className="pl-0 pr-1 d-flex flex-column align-items-center"
                    >
                      <Row
                        noGutters
                        className="w-100"
                        style={{
                          lineHeight: '1.15rem',
                          marginBottom: '0.55rem',
                        }}
                      >
                        {result.NAME ? (
                          <a
                            className={`d-flex${
                              currentActiveKey === index ? '' : ' text-truncate'
                            }`}
                            style={{
                              textUnderlineOffset: '0.125rem',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              if (mobileView)
                                dispatch(
                                  setSidebarExpanded({ sidebarExpanded: false })
                                );
                              setCurrentActiveKey(
                                index !== currentActiveKey ? index : null
                              );
                              if (currentID !== `${result.NAMEPTR}`) {
                                dispatch(
                                  setCurrentID({
                                    currentID: `${result.NAMEPTR}`,
                                  })
                                );
                                dispatch(
                                  setCurrentName({ currentName: result.NAME })
                                );
                                const query = queryToString({
                                  query: router.location.query,
                                  currentID: `${result.NAMEPTR}`,
                                });
                                history.push(
                                  `${router.location.pathname}?${query}`
                                );
                              }
                            }}
                          >
                            <span
                              className={
                                currentActiveKey === index
                                  ? ''
                                  : 'text-truncate'
                              }
                            >
                              {result.NAME}
                            </span>
                          </a>
                        ) : (
                          '-'
                        )}
                      </Row>
                      <Row noGutters className="w-100">
                        <span style={{ lineHeight: '1.15rem' }}>
                          {result.KOIGROUP ? result.KOIGROUP : `All KOI Groups`}
                        </span>
                      </Row>
                    </Col>
                    <Col
                      xs={3}
                      sm={2}
                      className="text-center d-flex align-items-center justify-content-center"
                    >
                      {result.NAMECOUNT || '-'}
                    </Col>
                    {/* <Col xs={1}></Col> */}
                  </Card.Header>
                </Card>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default DocumentNameSearchList;
