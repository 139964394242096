import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {
  ArrowCounterclockwise,
  Check2Circle,
  SortAlphaDown,
  SortAlphaDownAlt,
} from 'react-bootstrap-icons';
import { setSortActive, setSortOptions } from '../../sidebar/sidebarSlice';
import { selectCurrentSortOptions } from '../../app/defaultSearchesSlice';

export default function SortForm() {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const subSearch = useSelector(state => state.search.subSearch);
  const defaultValues = useSelector(
    selectCurrentSortOptions(mainSearch, subSearch)
  );
  const dispatch = useDispatch();
  const mobileView = useSelector(state => state.windowState.mobileView);
  const sortOptions = useSelector(state => state.sidebar.sortOptions);
  const [values, setValues] = useState(sortOptions || { ...defaultValues });

  const updateSelection = (e, key) => {
    setValues({ ...values, [key]: e.target.value });
  };

  return (
    <>
      {
        <Row
          noGutters
          className="rounded border border-primary bg-light w-100 mb-3 p-3 shadow-sm"
        >
          <Form
            className="w-100"
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              dispatch(setSortOptions(values));
              dispatch(setSortActive({ sortActive: false }));
            }}
          >
            <Form.Row>
              <Form.Group as={Col} className="d-flex justify-content-end">
                <Button
                  className="ml-auto mr-2"
                  onClick={e => {
                    dispatch(setSortOptions({ ...defaultValues }));
                    dispatch(setSortActive({ sortActive: false }));
                  }}
                >
                  <ArrowCounterclockwise /> reset
                </Button>
                <Button type="submit">
                  <Check2Circle /> apply
                </Button>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Sort Field</Form.Label>
                <Form.Control
                  as="select"
                  id="sort-field"
                  value={values.selection}
                  onChange={e => updateSelection(e, 'selection')}
                  style={{ maxHeight: '25vh', overflow: 'auto' }}
                >
                  {[
                    {
                      key: '',
                      type: 'text',
                      title: '-- Select a field --',
                      apiName: '',
                    },
                    ...Object.values(defaultValues.options),
                  ]
                    // .sort((a, b) => a.title.localeCompare(b.title))
                    .map((c, i) => (
                      <option key={i} value={c.key}>
                        {c.title}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} className="mb-0">
                <Form.Label>Sort Order</Form.Label>
                <div
                  className={`bg-white rounded d-flex${
                    mobileView
                      ? ' pl-4 py-2 pr-2 flex-column'
                      : ' p-2 justify-content-around'
                  }`}
                  style={{
                    border: '1px solid #ced4da',
                  }}
                >
                  <Form.Check
                    type="switch"
                    name="sortOrder"
                    id="sort-order-asc"
                    checked={values.order === 'ASC'}
                    label={
                      <>
                        <SortAlphaDown height="1.5rem" width="1.5rem" />{' '}
                        Ascending
                      </>
                    }
                    value="ASC"
                    onChange={e => updateSelection(e, 'order')}
                  />
                  <Form.Check
                    type="switch"
                    name="sortOrder"
                    id="sort-order-desc"
                    className={mobileView ? 'mt-2' : ''}
                    checked={values.order === 'DESC'}
                    label={
                      <>
                        <SortAlphaDownAlt height="1.5rem" width="1.5rem" />{' '}
                        Descending
                      </>
                    }
                    value="DESC"
                    onChange={e => updateSelection(e, 'order')}
                  />
                </div>
              </Form.Group>
            </Form.Row>
          </Form>
        </Row>
      }
      {}
    </>
  );
}
