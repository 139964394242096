import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

import { ChevronDown, ChevronUp, Search } from 'react-bootstrap-icons';

import { withCentsFormat } from '../../../../utilities/currency';
import {
  selectDefaultMainSearch,
  selectDefaultSubSearch,
} from '../../../app/defaultSearchesSlice';

import { queryToString } from '../../../../utilities/url';
import { setCurrentID } from '../../../app/searchSlice';
import { setSidebarExpanded } from '../../sidebarSlice';
import { sortResults } from '../../../../utilities/filterSort';

const DelinquentTaxSearchList = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const mainSearchInfo = useSelector(selectDefaultMainSearch(mainSearch));
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const [currentActiveKey, setCurrentActiveKey] = useState(null);

  const searchStatus = useSelector(state => state.search.status);
  const currentID = useSelector(state => state.search.currentID);
  const history = useHistory();
  const router = useSelector(state => state.router);
  const dispatch = useDispatch();
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const sortOptions = useSelector(state => state.sidebar.sortOptions);
  const [groupedFlag, setGroupedFlag] = useState(false);

  // load
  const rawResults = useSelector(state => state.search.searchResults);
  const [searchResults, setSearchResults] = useState(rawResults.slice());

  // group
  useEffect(() => {
    const grouped = searchResults
      .slice()
      .sort((a, b) => parseInt(b.TAX_YEAR, 10) - parseInt(a.TAX_YEAR, 10))
      .sort((a, b) => parseInt(b.SERIAL, 10) - parseInt(a.SERIAL, 10))
      .reduce((group, r) => {
        if (!(`${r.SERIAL}-${r.NAME}` in group)) {
          group[`${r.SERIAL}-${r.NAME}`] = {
            serial: r.SERIAL,
            serialFormatted: r.SERIAL_FORMATTED,
            name: r.NAME,
            taxes: [
              {
                taxYear: r.TAX_YEAR,
                taxesDue: r.TAXES_DUE,
                total: r.TOTAL,
              },
            ],
          };
        } else {
          if (
            !group[`${r.SERIAL}-${r.NAME}`].taxes
              .map(t => t.taxYear)
              .includes(r.TAX_YEAR)
          )
            group[`${r.SERIAL}-${r.NAME}`].taxes.push({
              taxYear: r.TAX_YEAR,
              taxesDue: r.TAXES_DUE,
              total: r.TOTAL,
            });
        }
        return group;
      }, {});

    setSearchResults(
      Object.values(grouped)
        .map(r => ({
          ...r,
          balanceDue: r.taxes.map(t => t.total).reduce((sum, t) => sum + t, 0),
        }))
        .sort(
          (a, b) =>
            Math.max(...b.taxes.map(y => parseInt(y.taxYear, 10))) -
            Math.max(...a.taxes.map(y => parseInt(y.taxYear, 10)))
        )
        .sort((a, b) => parseInt(b.serial, 10) - parseInt(a.serial, 10))
    );
    // trigger update in next useEffect block
    setGroupedFlag(!groupedFlag);
  }, [rawResults]);

  useEffect(() => {
    if (searchResults && searchResults.length > 0 && searchResults[0].name) {
      let tempFilteredResults = [...searchResults];
      if (filterOptions) {
        // filter
        if (filterOptions.name.value !== '')
          tempFilteredResults = tempFilteredResults.filter(
            r =>
              `${r.name}`.search(new RegExp(filterOptions.name.value, 'gi')) !==
              -1
          );
        if (filterOptions.serial.value !== '')
          tempFilteredResults = tempFilteredResults.filter(
            r =>
              `${r.serialFormatted}`.search(
                new RegExp(filterOptions.serial.value, 'gi')
              ) !== -1
          );
        if (filterOptions.taxYear.value.length > 0)
          tempFilteredResults = tempFilteredResults.filter(r => {
            let found = false;
            r.taxes
              .map(t => `${t.taxYear}`)
              .forEach(y => {
                if (filterOptions.taxYear.value.map(ty => `${ty}`).includes(y))
                  found = true;
              });
            return found;
          });
      }
      // sort
      const sortedResults = [...sortResults(sortOptions, tempFilteredResults)];
      setSearchResults([...sortedResults]);
    }
  }, [groupedFlag, filterOptions, sortOptions]);

  // update currentID when page loads
  // console.log('searchResults:', searchResults);
  useEffect(() => {
    if (searchStatus === 'succeeded' && searchResults.length && !currentID) {
      dispatch(setCurrentID({ currentID: `${searchResults[0].SERIAL}` }));
      const query = queryToString({
        query: router.location.query,
        currentID: `${searchResults[0].SERIAL}`,
      });
      history.replace(`${router.location.pathname}?${query}`);
    }
  }, [searchResults]);

  return (
    <>
      {/* show search loading */}
      {searchStatus === 'loading' && !searchResults && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '10rem', width: '10rem' }}
          />
        </Row>
      )}
      {/* show search results list */}
      {searchStatus === 'succeeded' &&
        searchResults &&
        searchResults.length > 0 && (
          <>
            <Row
              className={
                sidebarExpanded
                  ? 'w-100 p-1 border-top border-bottom'
                  : 'd-none'
              }
              style={{
                background: '#e9ecef',
                fontWeight: '600',
                fontFamily: 'Calibri, sans-serif',
                marginBottom: mobileView ? '2.25px' : '2px',
              }}
              noGutters
            >
              <Col
                xs={4}
                sm={3}
                className="p-1 d-flex flex-column align-items-center"
              >
                <Row noGutters className="w-100">
                  Serial
                </Row>
                <Row noGutters className="w-100">
                  Balance Due
                </Row>
              </Col>
              <Col xs={6} sm={8} className="p-1 d-flex align-items-center">
                <Row noGutters className="w-100">
                  Name
                </Row>
              </Col>
              <Col
                xs={2}
                sm={1}
                className="p-1 d-flex align-items-center justify-content-center"
              ></Col>
            </Row>
            <Accordion
              id="searchResultsList"
              className={
                sidebarExpanded
                  ? 'w-100 h-100 flex-grow-1 overflow-auto'
                  : 'd-none'
              }
              style={{
                fontWeight: '100',
                fontFamily: 'Calibri, sans-serif',
              }}
            >
              {searchResults.map((result, index) => {
                return (
                  <Card
                    key={index}
                    className="w-100 rounded-0 border-left-0 border-right-0 border-bottom shadow-sm"
                    style={{ marginTop: '-1px' }}
                  >
                    <Card.Header className="w-100 p-1 d-flex flex-row">
                      <Col
                        xs={4}
                        sm={3}
                        className="p-1 d-flex flex-column align-items-center"
                      >
                        <Row noGutters className="w-100">
                          {result.serialFormatted ? (
                            <Accordion.Toggle
                              as="a"
                              className="d-flex"
                              style={{
                                textUnderlineOffset: '0.125rem',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              eventKey={`${index}`}
                              onClick={() => {
                                if (mobileView)
                                  dispatch(
                                    setSidebarExpanded({
                                      sidebarExpanded: false,
                                    })
                                  );
                                setCurrentActiveKey(
                                  currentActiveKey === index ? null : index
                                );
                                if (
                                  currentID !== `${searchResults[index].serial}`
                                ) {
                                  dispatch(
                                    setCurrentID({
                                      currentID: `${searchResults[index].serial}`,
                                    })
                                  );
                                  const query = queryToString({
                                    query: router.location.query,
                                    currentID: `${searchResults[index].serial}`,
                                  });
                                  history.push(
                                    `${router.location.pathname}?${query}`
                                  );
                                }
                              }}
                            >
                              {result.serialFormatted}
                            </Accordion.Toggle>
                          ) : (
                            '-'
                          )}
                        </Row>
                        <Row noGutters className="w-100">
                          {result.taxes &&
                          result.taxes.length > 0 &&
                          !isNaN(parseFloat(`${result.balanceDue}`))
                            ? withCentsFormat(`${result.balanceDue}`)
                            : '-'}
                        </Row>
                      </Col>
                      <Col
                        xs={6}
                        sm={8}
                        className="p-1 d-flex align-items-center"
                      >
                        <Row noGutters className="w-100">
                          <span
                            className={
                              currentActiveKey === index ? '' : 'text-truncate'
                            }
                          >
                            {result.name || '-'}
                          </span>
                        </Row>
                      </Col>
                      <Col
                        xs={2}
                        sm={1}
                        className="p-1 d-flex align-items-center justify-content-center"
                      >
                        <Accordion.Toggle
                          as="a"
                          onClick={() =>
                            setCurrentActiveKey(
                              currentActiveKey === index ? null : index
                            )
                          }
                          className="py-0 px-2"
                          eventKey={`${index}`}
                          style={{ cursor: 'pointer' }}
                        >
                          {currentActiveKey === index ? (
                            <ChevronUp />
                          ) : (
                            <ChevronDown />
                          )}
                        </Accordion.Toggle>
                      </Col>
                    </Card.Header>
                    <Accordion.Collapse eventKey={`${index}`}>
                      <div className="d-flex flex-column w-100">
                        <Row
                          noGutters
                          style={{ fontWeight: '500' }}
                          className="w-100 border-bottom pt-2 pb-1 d-flex justify-content-center"
                        >
                          Tax Balances by Year
                        </Row>
                        {result.taxes &&
                          result.taxes.map((t, i) => (
                            <Row
                              key={i}
                              noGutters
                              className="w-100"
                              style={{ lineHeight: '1rem' }}
                            >
                              <Col
                                xs={4}
                                sm={3}
                                className={`${
                                  result.taxes.length === 1
                                    ? 'py-3 px-1'
                                    : i === result.taxes.length - 1
                                    ? 'pt-1 pb-3 px-1'
                                    : i === 0
                                    ? 'pt-3 pb-1 px-1'
                                    : 'p-1'
                                } border-right text-center`}
                                style={{ fontWeight: '500' }}
                              >
                                {t.taxYear}
                              </Col>
                              <Col
                                xs={8}
                                sm={9}
                                className={`${
                                  result.taxes.length === 1
                                    ? 'py-3 px-1'
                                    : i === result.taxes.length - 1
                                    ? 'pt-1 pb-3 px-1'
                                    : i === 0
                                    ? 'pt-3 pb-1 px-1'
                                    : 'p-1'
                                } d-flex justify-content-around`}
                              >
                                <div>
                                  <span style={{ fontWeight: '400' }}>
                                    Tax Balance:
                                  </span>{' '}
                                  {!isNaN(parseFloat(t.taxesDue))
                                    ? withCentsFormat(`${t.taxesDue}`)
                                    : '-'}
                                </div>
                                <div>
                                  <span style={{ fontWeight: '400' }}>
                                    Balance Due:
                                  </span>{' '}
                                  {!isNaN(parseFloat(t.total))
                                    ? withCentsFormat(`${t.total}`)
                                    : '-'}
                                </div>
                              </Col>
                            </Row>
                          ))}
                      </div>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
            </Accordion>
          </>
        )}
      {/* show no result message */}
      {searchStatus === 'succeeded' &&
        searchResults &&
        searchResults.length === 0 && (
          <Row
            noGutters
            id="sidebarResults"
            className={
              sidebarExpanded
                ? 'w-100 h-100 d-flex flex-column flex-grow-1'
                : 'd-none'
            }
            style={{
              backgroundColor: 'rgba(101, 167, 219, 0.25)',
            }}
          >
            <h5
              className="mx-auto w-75 d-flex flex-column h-100 align-items-center"
              style={{
                marginTop: '25%',
              }}
            >
              <Search
                height={mobileView ? '4rem' : '8rem'}
                width={mobileView ? '4rem' : '8rem'}
                style={{ color: 'rgba(101, 167, 219, 1)' }}
              />
              <p className="mb-1 mt-4 text-center">{`No delinquent tax results found.`}</p>
              <p className="mb-0 mt-2 text-center">{`Please update your filter parameters or try another search.`}</p>
            </h5>
          </Row>
        )}
    </>
  );
};

export default DelinquentTaxSearchList;
