import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'axios';

// if no lastSearch, defualt to properties > address search
const lastMainSearch =
  localStorage.getItem('UCLR_lastMainSearch') || 'properties';
const lastSubSearch = localStorage.getItem('UCLR_lastSubSearch') || 'address';

// const mainSearch
const initialState = {
  lastMainSearch,
  lastSubSearch,
  mainSearch: lastMainSearch,
  subSearch: lastSubSearch,
  searchInputs: null,
  searchActive: false,
  baseApiUrl: process.env.API_URL,
  searchResults: [],
  // filteredSortedResults: [],
  status: 'loading',
  error: null,
  lastSearchResult: null,
  searchDidRun: false,
  subSearchIndex: null,
  checkScrollRunning: null,
  searchResultsScrollTop: 0,
  currentID: null,
  // filterOptions: null,
  // sortOptions: null,
  // lazyCount: null,
};

// async actions
export const fetchResults = createAsyncThunk(
  'app/search/fetchResults',
  async ({ url, endpoint, queryString = '' }) => {
    // console.log('lazy load call...', queryString.search('&last=') !== -1);
    // const isLazy = queryString.search('&last=') !== -1;
    // const lazyCount = state.filteredSortedResults.length;
    // console.log('state:', state);
    // if lazy load, load until 10 + new leads available
    let response;
    const _url = `${url}/${endpoint}?${queryString}`;
    // console.log('_url:', _url);
    try {
      response = await axios({
        url: _url,
        headers: { apikey: process.env.APIKEY },
      });
    } catch (err) {
      console.error(err);
      return err;
    }
    // console.log('response.data:', response.data);
    return response.data;
  }
);

// build slice
// setting intitialState value in store.js
export const slice = createSlice({
  name: 'app/search',
  initialState: initialState,
  reducers: {
    updateSearch: (state, action) => {
      const { mainSearch, subSearch } = action.payload;
      // console.log(`updated search to ${mainSearch} > ${subSearch}`);
      state.mainSearch = mainSearch;
      state.subSearch = subSearch;
    },
    updateSavedSearch: (state, action) => {
      const { mainSearch, subSearch } = action.payload;
      state.lastMainSearch = mainSearch;
      localStorage.setItem('UCLR_lastMainSearch', mainSearch);
      state.lastSubSearch = subSearch;
      localStorage.setItem('UCLR_lastSubSearch', subSearch);
    },
    setSavedSearchInputs: (state, action) => {
      state.searchInputs = action.payload;
    },
    updateSearchInput: (state, action) => {
      const { group, key, value } = action.payload;
      state.searchInputs[group][key].value = value;
    },
    setSearchActive: (state, action) => {
      state.searchActive = action.payload.searchActive;
    },
    resetSearchResults: (state, action) => {
      state.searchResults = [];
    },
    updateSubSearchIndex: (state, action) => {
      state.subSearchIndex = action.payload.subSearchIndex;
    },
    setSearchDidRun: (state, action) => {
      state.searchDidRun = action.payload.searchDidRun;
    },
    setCheckScrollRunning: (state, action) => {
      state.checkScrollRunning = action.payload.checkScrollRunning;
    },
    setSearchResultsScrollTop: (state, action) => {
      state.searchResultsScrollTop = action.payload.searchResultsScrollTop;
    },
    setCurrentID: (state, action) => {
      // console.log(`updating currentID to: ${action.payload.currentID}`);
      state.currentID = action.payload.currentID;
    },
    setSearchStatus: (state, action) => {
      state.status = action.payload;
    },
    // setFilteredSortedResults: (state, action) => {
    //   state.filteredSortedResults = action.payload;
    // },
  },
  extraReducers: {
    [fetchResults.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchResults.fulfilled]: (state, action) => {
      state.lastSearchResult = action.payload.meta
        ? action.payload.meta.last
        : null;
      state.searchResults =
        state.searchResults.concat(action.payload.data) || [];
      state.status = 'succeeded';
    },
    [fetchResults.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

// export actions
export const {
  updateSearch,
  updateSavedSearch,
  setSavedSearchInputs,
  updateSearchInput,
  setSearchActive,
  resetSearchResults,
  updateSubSearchIndex,
  setSearchDidRun,
  setSearchChanged,
  setCheckScrollRunning,
  setSearchResultsScrollTop,
  setCurrentID,
  resetCurrentID,
  setSearchStatus,
} = slice.actions;
// export selectors
export const selectMainSearch = state => state.search.mainSearch;
export const selectSubSearch = state => state.search.subSearch;
export const selectLastMainSearch = state => state.search.lastMainSearch;
export const selectLastSubSearch = state => state.search.lastSubSearch;
export const selectSearchInputs = state => state.search.searchInputs;
// export slice
export default slice.reducer;
