import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, Redirect, useHistory } from 'react-router-dom';

import Row from 'react-bootstrap/Row';

import Sidebar from '../sidebar/Sidebar';
import DetailView from '../detailView/DetailView';
import SiteFooter from './SiteFooter';

import {
  setCurrentID,
  fetchResults,
  setSearchDidRun,
  resetSearchResults,
  setSearchActive,
  setSearchResultsScrollTop,
  updateSearch,
  // updateSubSearch,
  updateSubSearchIndex,
  setSavedSearchInputs,
} from '../app/searchSlice';

import { emptyPropertyImages } from '../app/propertyImages/propertyImagesSlice';

import {
  setSidebarExpanded,
  setFilterOptions,
  setSortOptions,
  setFilterActive,
  setSortActive,
  setSidebarLoading,
} from '../sidebar/sidebarSlice';
import { toApiQuery } from '../../utilities/url';
import ErrorBoundary from './ErrorBoundary';
import { setDetailViewLoading } from '../../components/detailView/detailViewSlice';

// Search component returns a react fragment composed of the sidebar and detail view components
const SearchPage = () => {
  const router = useSelector(state => state.router);
  const history = useHistory();
  const dispatch = useDispatch();
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const defaultSearches = useSelector(state => state.defaultSearches);
  const searchDidRun = useSelector(state => state.search.searchDidRun);
  const subSearchIndex = useSelector(state => state.search.subSearchIndex);
  const currentID = useSelector(state => state.search.currentID);
  const searchInputs = useSelector(state => state.search.searchInputs);
  const mainSearch = useSelector(state => state.search.mainSearch);
  const subSearch = useSelector(state => state.search.subSearch);

  useEffect(() => {
    // return null if home
    if (router.location.pathname === '/') return null;

    const pathnameItems = router.location.pathname
      .split('/')
      .filter(x => x !== '');

    // malformed url, push home
    if (pathnameItems.length !== 3) {
      history.replace('/');
      return null;
    }

    if (!searchDidRun && !Object.keys(router.location.query).length > 0) {
      history.replace('/');
      return null;
    }

    if (!Object.keys(defaultSearches).includes(pathnameItems[0])) {
      history.replace('/');
      return null;
    }

    if (
      !Object.keys(defaultSearches[pathnameItems[0]].subSearches).includes(
        pathnameItems[1]
      )
    ) {
      history.replace('/');
      return null;
    }
    if (
      parseInt(pathnameItems[2], 10) < 0 &&
      parseInt(pathnameItems[2], 10) >=
        defaultSearches[pathnameItems[0]].subSearches[pathnameItems[1]]
          .inputFields.length
    ) {
      history.replace('/');
      return null;
    }

    // update mainSearch and subSearch
    if (mainSearch !== pathnameItems[0] || subSearch !== pathnameItems[1])
      dispatch(
        updateSearch({
          mainSearch: pathnameItems[0],
          subSearch: pathnameItems[1],
        })
      );
    // update subSearchIndex for searches with multiple versions like document entry-year/book-page
    if (subSearchIndex !== parseInt(pathnameItems[2], 10))
      dispatch(
        updateSubSearchIndex({ subSearchIndex: parseInt(pathnameItems[2], 10) })
      );
    // this is to help prevent the error where the page crashes when you open
    // the search after loading from a URL... still needs further investigation.
    // I think I might need to perform this check from the SearchForm component itself
    if (!searchInputs)
      dispatch(
        setSavedSearchInputs(
          defaultSearches[pathnameItems[0]].subSearches[pathnameItems[1]]
            .inputFields
        )
      );
    // if there is a current ID in the url, use this one instead of an existing current ID
    if (
      router.location.query.currentID &&
      currentID !== router.location.query.currentID
    ) {
      dispatch(setCurrentID({ currentID: router.location.query.currentID }));
    }
    if (
      !searchDidRun ||
      mainSearch !== pathnameItems[0] ||
      subSearch !== pathnameItems[1]
    ) {
      // initiate UI search
      dispatch(setSidebarLoading(true));
      dispatch(setDetailViewLoading(true));
      // run search
      dispatch(
        fetchResults({
          url: baseApiUrl,
          endpoint:
            defaultSearches[pathnameItems[0]].subSearches[pathnameItems[1]]
              .inputApiEndpoints[pathnameItems[2]],
          queryString: toApiQuery(router.location.query),
        })
      );
      dispatch(setSearchDidRun({ searchDidRun: true }));
      dispatch(resetSearchResults({}));
      dispatch(emptyPropertyImages({}));
      dispatch(setSearchActive({ searchActive: false }));
      dispatch(setFilterActive({ filterActive: false }));
      dispatch(setSortActive({ sortActive: false }));
      dispatch(setSidebarExpanded({ sidebarExpanded: true }));
      dispatch(setFilterOptions(null));
      dispatch(setSortOptions(null));
      dispatch(setSearchResultsScrollTop({ searchResultsScrollTop: 0 }));
    }
  }, [router]);

  return (
    <>
      {mainSearch && subSearch && (
        <>
          <Row noGutters className="h-100 w-100 mh-100 overflow-auto">
            <ErrorBoundary errorType="sidebar">
              <Sidebar />
            </ErrorBoundary>
            <ErrorBoundary errorType="detailView">
              <DetailView />
            </ErrorBoundary>
          </Row>
          <SiteFooter />
        </>
      )}
      {(!mainSearch || !subSearch) && <Redirect to="/" />}
    </>
  );
};

export default SearchPage;
