import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'axios';

const taxDetailAdapter = createEntityAdapter({
  selectId: e =>
    e && e.INFO && e.INFO.length > 0 && e.INFO[0].TAX_YEAR && e.INFO[0].SERIAL
      ? `${e.INFO[0].TAX_YEAR}${parseInt(e.INFO[0].SERIAL, 10)}`
      : null,
});

const initialState = taxDetailAdapter.getInitialState({
  serial: null,
  taxyear: null,
  status: 'loading',
  error: null,
});

export const fetchTaxDetail = createAsyncThunk(
  'app/taxDetail/fetchTaxDetail',
  async ({ url, serial, taxyear }) => {
    if (!serial || !taxyear) {
      state.status = 'failed';
      return null;
    }
    let taxDetail;
    try {
      taxDetail = (
        await axios({
          url: `${url}/taxdetail?serial=${serial}&taxyear=${taxyear}`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data[0];
    } catch (err) {
      console.error(err);
      state.status = 'failed';
      return;
    }
    if (taxDetail.INFO && taxDetail.INFO.length > 0) return taxDetail;
    else {
      state.status = 'failed';
      return null;
    }
  }
);

export const slice = createSlice({
  name: 'app/taxDetail',
  initialState: initialState,
  reducers: {
    setSerial: (state, action) => {
      state.serial = action.payload;
    },
    setTaxyear: (state, action) => {
      state.taxyear = action.payload;
    },
    setTaxDetailStatus: (state, action) => {
      state.status = action.payload;
    },
    emptyTaxDetials: taxDetailAdapter.removeAll,
  },
  extraReducers: {
    [fetchTaxDetail.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchTaxDetail.fulfilled]: (state, action) => {
      taxDetailAdapter.upsertOne(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchTaxDetail.rejected]: (state, action) => {
      state.error = action.error.message;
      state.status = 'failed';
    },
  },
});

export const { setSerial, setTaxyear, setTaxDetailStatus, emptyTaxDetials } =
  slice.actions;

export const {
  selectEntities: selectAllTaxDetails,
  selectById: selectTaxDetailById,
  selectIds: selectAllTaxDetailIDs,
} = taxDetailAdapter.getSelectors(state => state.taxDetail);

export default slice.reducer;
