import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  mobileView: window.screen.width < 768,
  // location: window.location,
};

export const slice = createSlice({
  name: 'app/windowState',
  initialState: initialState,
  reducers: {
    setMobileView: (state, action) => {
      state.mobileView = action.payload.mobileView;
    },
  },
});
// export actions
export const { setMobileView, setLocation } = slice.actions;
// export selectors
export const selectMobileView = state => state.windowState.mobileView;
// export slice
export default slice.reducer;
