import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ArrowCounterclockwise, Check2Circle } from 'react-bootstrap-icons';
import { setFilterActive, setFilterOptions } from '../../sidebarSlice';
import { updateInput } from '../../../../utilities/filterSort';
import { getFormDateFormat } from '../../../../utilities/date';

export default function DocumentKoiFilterForm() {
  const defaultValues = {
    entry: { type: 'textbox', apiName: 'ENTRY', value: '' },
    grantor: { type: 'textbox', apiName: 'PARTY1NAME', value: '' },
    grantee: { type: 'textbox', apiName: 'PARTY2NAME', value: '' },
    docYear: { type: 'checkbox', apiName: 'YEAR', value: [] },
    koi: { type: 'checkbox', apiName: 'KOI', value: [] },
    recDate: {
      type: 'date',
      apiName: 'RJ_DATE',
      value: { startDate: null, endDate: null },
    },
  };

  const dispatch = useDispatch();
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const searchResults = useSelector(state => state.search.searchResults);
  const [values, setValues] = useState(filterOptions || { ...defaultValues });

  return (
    <>
      <Row
        noGutters
        className="rounded border border-primary bg-light w-100 mb-3 p-3 shadow-sm"
      >
        <Form
          className="w-100"
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(setFilterOptions(values));
            dispatch(setFilterActive({ filterActive: false }));
          }}
        >
          <Form.Row>
            <Form.Group as={Col} className="d-flex justify-content-end">
              <Button
                className="ml-auto mr-2"
                onClick={e => {
                  dispatch(setFilterOptions({ ...defaultValues }));
                  dispatch(setFilterActive({ filterActive: false }));
                }}
              >
                <ArrowCounterclockwise /> reset
              </Button>
              <Button type="submit">
                <Check2Circle /> apply
              </Button>
            </Form.Group>
          </Form.Row>
          <Form.Row className="mt-3">
            <Form.Group as={Col}>
              <Form.Label>Start Recording Date</Form.Label>
              <Form.Control
                type="date"
                id="filter-date-recDate-startDate"
                defaultValue={getFormDateFormat(values.recDate.value.startDate)}
                onChange={e => updateInput(e, values, setValues)}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>End Recording Date</Form.Label>
              <Form.Control
                type="date"
                id="filter-date-recDate-endDate"
                defaultValue={values.recDate.value.endDate}
                onChange={e => updateInput(e, values, setValues)}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Document Year</Form.Label>
              <div
                className="w-100 d-flex flex-column bg-white rounded py-2 px-3"
                style={{
                  border: '1px solid #ced4da',
                  maxHeight: '15vh',
                  minHeight: '38px',
                  overflow: 'auto',
                }}
              >
                {searchResults &&
                  searchResults.length > 0 &&
                  [
                    ...new Set(
                      searchResults.slice().map(r => r[values.docYear.apiName])
                    ),
                  ]
                    .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
                    .map((n, i) => (
                      <Form.Check
                        id={`filter-checkbox-docYear-${i}`}
                        key={i}
                        label={`${n}`}
                        type="checkbox"
                        name={`${n}`}
                        checked={values.docYear.value.includes(`${n}`)}
                        onChange={e => updateInput(e, values, setValues)}
                      />
                    ))}
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Entry Number Contains</Form.Label>
              <Form.Control
                id="filter-textbox-entry"
                placeholder="Enter entry number"
                value={values.entry.value}
                onChange={e => updateInput(e, values, setValues)}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Grantor Name Contains</Form.Label>
              <Form.Control
                id="filter-textbox-grantor"
                placeholder="Enter grantor name"
                value={values.grantor.value}
                onChange={e => updateInput(e, values, setValues)}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="mb-0">
              <Form.Label>Grantee Name Contains</Form.Label>
              <Form.Control
                id="filter-textbox-grantee"
                placeholder="Enter grantee number"
                value={values.grantee.value}
                onChange={e => updateInput(e, values, setValues)}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </Row>
    </>
  );
}
