import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { Search } from 'react-bootstrap-icons';

import { setSidebarExpanded } from '../../sidebarSlice';
import { selectDefaultMainSearch } from '../../../app/defaultSearchesSlice';
import { setCurrentID } from '../../../app/searchSlice';

import { queryToString } from '../../../../utilities/url';
import { filterResults, sortResults } from '../../../../utilities/filterSort';

const DocumentCrossReferenceSearchList = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const mainSearchInfo = useSelector(selectDefaultMainSearch(mainSearch));
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const [currentActiveKey, setCurrentActiveKey] = useState(null);
  const [searchResultsListOverflowed, setSearchResultsListOverflowed] =
    useState(!mobileView);
  const subSearchIndex = useSelector(state => state.search.subSearchIndex);
  const currentID = useSelector(state => state.search.currentID);
  const dispatch = useDispatch();
  const history = useHistory();
  const router = useSelector(state => state.router);
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const sortOptions = useSelector(state => state.sidebar.sortOptions);

  useEffect(() => {
    const updateSearchResultsListOverflowed = () => {
      const searchResultsList = document.getElementById('searchResultsList');
      if (
        mobileView &&
        searchResultsList &&
        searchResultsList.getBoundingClientRect().height <
          searchResultsList.scrollHeight
      )
        setSearchResultsListOverflowed(true);
      else setSearchResultsListOverflowed(false);
    };
    window.addEventListener('resize', updateSearchResultsListOverflowed);
    return () =>
      window.removeEventListener('resize', updateSearchResultsListOverflowed);
  }, [searchResultsListOverflowed, searchResults]);

  const searchStatus = useSelector(state => state.search.status);
  // load
  const rawResults = useSelector(state => state.search.searchResults);
  const [searchResults, setSearchResults] = useState(rawResults.slice());

  useEffect(() => {
    let tempResults = [...searchResults];
    // filter
    tempResults = [...filterResults(filterOptions, tempResults)];
    // sort
    tempResults = [...sortResults(sortOptions, tempResults)];
    // update list with filtered/sorted results
    setSearchResults([...tempResults]);
  }, [filterOptions, sortOptions]);

  useEffect(() => {
    if (searchStatus === 'succeeded' && searchResults.length && !currentID) {
      dispatch(
        setCurrentID({
          currentID:
            subSearchIndex === 1
              ? `${searchResults[0].ENTRYNO}-${searchResults[0].ENTRYYR}`
              : `${searchResults[0].SERIAL}`,
        })
      );
    }
  }, [searchResults]);

  return (
    <>
      {/* show search loading */}
      {searchStatus === 'loading' && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '10rem', width: '10rem' }}
          />
        </Row>
      )}{' '}
      {/* show no result message */}
      {searchStatus === 'succeeded' &&
        (searchResults.length === 0 || !currentID) && (
          <Row
            noGutters
            id="sidebarResults"
            className={
              sidebarExpanded
                ? 'w-100 h-100 d-flex flex-column flex-grow-1'
                : 'd-none'
            }
            style={{
              backgroundColor: 'rgba(101, 167, 219, 0.25)',
            }}
          >
            <h5
              className="mx-auto w-75 d-flex flex-column h-100 align-items-center"
              style={{
                marginTop: '25%',
              }}
            >
              <Search
                height={mobileView ? '4rem' : '8rem'}
                width={mobileView ? '4rem' : '8rem'}
                style={{ color: 'rgba(101, 167, 219, 1)' }}
              />
              <p className="mb-1 mt-4 text-center">{`No ${mainSearchInfo.titleSingle.toLowerCase()} results found.`}</p>
              <p className="mb-0 mt-2 text-center">{`Please update your filter parameters or try another search.`}</p>
            </h5>
          </Row>
        )}
      {/* show search results list */}
      {searchStatus === 'succeeded' && searchResults.length > 0 && (
        <>
          <Row
            className={
              sidebarExpanded
                ? 'w-100 px-0 py-2 border-top border-bottom '
                : 'd-none'
            }
            style={{
              background: '#e9ecef',
              fontWeight: '600',
              fontFamily: 'Calibri, sans-serif',
            }}
            noGutters
          >
            <Col
              xs={4}
              sm={3}
              className="pl-1 pr-0 d-flex flex-column align-items-center"
            >
              <Row noGutters className="pl-2 ml-2 w-100">
                Serial No
              </Row>
              <Row noGutters className="pl-2 ml-2 w-100">
                Entry No
              </Row>
            </Col>
            <Col
              xs={6}
              sm={7}
              className="d-flex flex-column align-items-center"
            >
              <Row noGutters className="w-100">
                Grantor
              </Row>
              <Row noGutters className="w-100">
                ..Grantee
              </Row>
            </Col>
            <Col
              xs={2}
              sm={2}
              className="d-flex align-items-center"
              style={{
                marginLeft: searchResultsListOverflowed ? '-1rem' : '0',
              }}
            >
              Kind of Inst
            </Col>
          </Row>
          <div
            id="searchResultsList"
            className={
              sidebarExpanded
                ? 'w-100 overflow-auto d-flex flex-column flex-grow-1'
                : 'd-none'
            }
            style={{
              fontWeight: '100',
              fontFamily: 'Calibri, sans-serif',
            }}
          >
            {searchResults.map((result, index) => {
              return (
                <Card
                  key={index}
                  className="w-100 rounded-0 border-left-0 border-right-0"
                  style={{ marginTop: '-1px' }}
                >
                  <Card.Header className="w-100 px-1 d-flex flex-row border-bottom-0 shadow-sm">
                    <Col
                      xs={4}
                      sm={3}
                      className="px-0 d-flex flex-column align-items-center"
                    >
                      <Row noGutters className="pl-2 ml-2 w-100">
                        <a
                          className="d-flex"
                          style={{
                            textUnderlineOffset: '0.125rem',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            lineHeight: '1.15rem',
                            marginBottom: '0.55rem',
                          }}
                          onClick={() => {
                            if (mobileView)
                              dispatch(
                                setSidebarExpanded({ sidebarExpanded: false })
                              );
                            setCurrentActiveKey(
                              index !== currentActiveKey ? index : null
                            );
                            if (
                              currentID !== `${searchResults[index].SERIAL}`
                            ) {
                              dispatch(setCurrentID({ currentID: null }));
                              dispatch(
                                setCurrentID({
                                  currentID: `${searchResults[index].SERIAL}`,
                                })
                              );
                              const query = queryToString({
                                query: router.location.query,
                                currentID: `${searchResults[index].SERIAL}`,
                              });
                              history.push(
                                `${router.location.pathname}?${query}`
                              );
                            }
                          }}
                        >
                          {result.SERIAL_FORMATTED
                            ? `${result.SERIAL_FORMATTED}`
                            : `-`}
                        </a>
                      </Row>
                      <Row noGutters className="pl-2 ml-2 w-100">
                        <a
                          className="d-flex"
                          style={{
                            textUnderlineOffset: '0.125rem',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            lineHeight: '1.15rem',
                          }}
                          onClick={() => {
                            if (mobileView)
                              dispatch(
                                setSidebarExpanded({ sidebarExpanded: false })
                              );
                            setCurrentActiveKey(
                              index !== currentActiveKey ? index : null
                            );
                            if (
                              currentID !==
                              `${searchResults[index].ENTRYNO}-${searchResults[index].ENTRYYR}`
                            ) {
                              dispatch(setCurrentID({ currentID: null }));
                              dispatch(
                                setCurrentID({
                                  currentID: `${searchResults[index].ENTRYNO}-${searchResults[index].ENTRYYR}`,
                                })
                              );
                              const query = queryToString({
                                query: router.location.query,
                                currentID: `${searchResults[index].ENTRYNO}-${searchResults[index].ENTRYYR}`,
                              });
                              history.push(
                                `${router.location.pathname}?${query}`
                              );
                            }
                          }}
                        >
                          {result.ENTRYNO && result.ENTRYYR
                            ? `${result.ENTRYNO}-${result.ENTRYYR}`
                            : `-`}
                        </a>
                      </Row>
                    </Col>
                    <Col
                      xs={6}
                      sm={7}
                      className="pl-0 pr-1 d-flex flex-column align-items-center"
                    >
                      <Row noGutters className="w-100">
                        <span
                          className={
                            currentActiveKey === index ? '' : 'text-truncate'
                          }
                        >
                          {result.PARTY1NAME ? `${result.PARTY1NAME}` : `-`}
                        </span>
                      </Row>
                      <Row noGutters className="w-100">
                        <span
                          className={
                            currentActiveKey === index ? '' : 'text-truncate'
                          }
                        >
                          {result.PARTY2NAME ? `..${result.PARTY2NAME}` : `-`}
                        </span>
                      </Row>
                    </Col>
                    <Col
                      xs={2}
                      sm={2}
                      className="px-0 d-flex align-items-center"
                    >
                      {result.KOI ? `${result.KOI}` : `-`}
                    </Col>
                  </Card.Header>
                </Card>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default DocumentCrossReferenceSearchList;
