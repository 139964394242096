import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import {
  Plus,
  X,
  BoxArrowUpRight,
  FileEarmarkTextFill,
} from 'react-bootstrap-icons';

import {
  fetchDocumentInfo,
  selectAllDocuments,
  selectDocumentById,
  selectAllDocumentIDs,
} from './documentDetailViewSlice';
import DocumentImageModal from './DocumentImageModal';

import { withCentsFormat } from '../../../utilities/currency';
import { useHistory, useLocation } from 'react-router-dom';
import { setCurrentID, setSearchDidRun } from '../../app/searchSlice';
import { queryToString } from '../../../utilities/url';
import { _normalize } from '../../../utilities/searchForm';

const DocumentDetailView = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const subSearch = useSelector(state => state.search.subSearch);
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const detailViewStatus = useSelector(
    state => state.documentDetailView.status
  );
  const searchStatus = useSelector(state => state.search.status);
  const dispatch = useDispatch();
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const currentID = useSelector(state => state.search.currentID);
  const curDoc = useSelector(state => selectDocumentById(state, currentID));
  const allDocIDs = useSelector(selectAllDocumentIDs);
  const [showDocumentImageModal, setShowDocumentImageModal] = useState(false);
  const [grantorsExpanded, setGrantorsExpanded] = useState(false);
  const [granteesExpanded, setGranteesExpanded] = useState(false);
  const [serialsExpanded, setSerialsExpanded] = useState(false);
  const [tiesExpanded, setTiesExpanded] = useState(false);
  const [releasesExpanded, setReleasesExpanded] = useState(false);
  const [legalsExpanded, setLegalsExpanded] = useState(false);
  const [viewStatus, setViewStatus] = useState('loading');

  const history = useHistory();
  const router = useSelector(state => state.router);

  // update current development every time current ID changes
  useEffect(() => {
    if (
      currentID &&
      mainSearch === 'documents' &&
      subSearch !== 'name' &&
      subSearch !== 'lastAbstract' &&
      currentID.split('-').length === 2
    ) {
      if (!allDocIDs.includes(currentID))
        dispatch(
          fetchDocumentInfo({ url: baseApiUrl, entryNumber: currentID })
        );
      const query = queryToString({
        query: router.location.query,
        currentID: currentID,
      });
      if (!router.location.query.currentID)
        history.replace(`${router.location.pathname}?${query}`);
    }
    setGrantorsExpanded(false);
    setGranteesExpanded(false);
    setSerialsExpanded(false);
    setTiesExpanded(false);
    setReleasesExpanded(false);
    setLegalsExpanded(false);
  }, [currentID]);

  useEffect(() => {
    if (detailViewStatus === 'loading') setViewStatus('loading');
    if (
      detailViewStatus === 'failed' ||
      (detailViewStatus === 'succeeded' && !curDoc) ||
      (searchStatus === 'succeeded' && !currentID)
    )
      setViewStatus('failed');
    if (detailViewStatus === 'succeeded' && curDoc) setViewStatus('succeeded');
  }, [currentID, detailViewStatus, searchStatus, curDoc]);

  return (
    <>
      {viewStatus === 'loading' && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '20rem', width: '20rem' }}
          />
        </Row>
      )}
      {viewStatus === 'failed' && (
        <Row
          noGutters
          className="h-100 d-flex flex-column align-items-center justify-content-center p-2"
          style={{ backgroundColor: 'rgba(217, 190, 80, 0.1)' }}
        >
          <FileEarmarkTextFill
            height={mobileView ? '4rem' : '8rem'}
            width={mobileView ? '4rem' : '8rem'}
            style={{ color: 'rgba(78, 128, 166, 1)' }}
          />
          <h5 className="text-center mt-4" style={{ fontSize: '1.5rem' }}>
            Could not find document information.
          </h5>
          <h5 className="text-center mt-2" style={{ fontSize: '1.5rem' }}>
            Please try another search.
          </h5>
        </Row>
      )}
      {viewStatus === 'succeeded' && curDoc && (
        <>
          <Row noGutters className="pt-3 px-3 d-flex flex-column">
            <Jumbotron className="h-100 w-100 shadow-sm bg-white p-2 mb-3">
              <Container className="p-2" fluid>
                <Row noGutters className="d-flex align-items-center">
                  <h5 className="mb-0 mr-auto">Document Detail</h5>
                  {!mobileView && (
                    <Button onClick={() => setShowDocumentImageModal(true)}>
                      Document Image Viewer
                    </Button>
                  )}
                </Row>
                <hr />
                <Row
                  noGutters
                  className="h-100 w-100 d-flex align-items-center"
                >
                  <Col
                    xs={12}
                    sm={3}
                    style={{
                      lineHeight: '1.15rem',
                      fontSize: '1rem',
                      marginBottom: '0',
                    }}
                  >
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={4}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Entry Number
                      </Col>
                      <Col xs={6} sm={8} className="p-2">
                        {curDoc.INFO[0].RJ_ENTRY_NO &&
                        curDoc.INFO[0].RJ_ENTRY_YR ? (
                          <a
                            className="d-flex"
                            style={{
                              textUnderlineOffset: '0.125rem',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => setShowDocumentImageModal(true)}
                          >{`${curDoc.INFO[0].RJ_ENTRY_NO}-${curDoc.INFO[0].RJ_ENTRY_YR}`}</a>
                        ) : (
                          `-`
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={4}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Recording Date
                      </Col>
                      <Col xs={6} sm={8} className="p-2">
                        {curDoc.INFO[0].RJ_DATE &&
                        Date.parse(curDoc.INFO[0].RJ_DATE)
                          ? `${new Date(
                              curDoc.INFO[0].RJ_DATE
                            ).toLocaleDateString()} ${new Date(
                              curDoc.INFO[0].RJ_DATE
                            ).toLocaleTimeString()}`
                          : '-'}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={4}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Recorded By Party
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {curDoc.INFO[0].RECORDEDBY || '-'}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={3}
                    style={{
                      lineHeight: '1.15rem',
                      fontSize: '1rem',
                      marginBottom: '0',
                    }}
                  >
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Kind of Instrument
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {curDoc.INFO[0].KOI && (
                          <span style={{ fontWeight: '400' }}>
                            {curDoc.INFO[0].KOI}
                          </span>
                        )}
                        {curDoc.INFO[0].KOI &&
                        curDoc.INFO[0].KOI_DESCRIPTION ? (
                          <span style={{ fontWeight: '400' }}>{' — '}</span>
                        ) : (
                          '-'
                        )}
                        {curDoc.INFO[0].KOI_DESCRIPTION}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Instrument Date
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {curDoc.INFO[0].INST_DATE &&
                        Date.parse(curDoc.INFO[0].INST_DATE)
                          ? new Date(
                              curDoc.INFO[0].INST_DATE
                            ).toLocaleDateString()
                          : '-'}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={3}
                    style={{
                      lineHeight: '1.15rem',
                      fontSize: '1rem',
                      marginBottom: '0',
                    }}
                  >
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Mail Party
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {curDoc.INFO[0].MAILNAME || `-`}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Mail Address
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {curDoc.INFO[0].MAILADDR || `-`}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Tax Address
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {curDoc.INFO[0].TAXADDRESS || '-'}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={3}
                    style={{
                      lineHeight: '1.15rem',
                      fontSize: '1rem',
                      marginBottom: '0',
                    }}
                  >
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={6}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Pages
                      </Col>
                      <Col xs={6} sm={6} className="p-2">
                        {curDoc.INFO[0].RJ_PAGES || '-'}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={6}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Consideration
                      </Col>
                      <Col xs={6} sm={6} className="p-2">
                        {isNaN(parseInt(curDoc.INFO[0].CONSIDERATION, 10))
                          ? `-`
                          : withCentsFormat(`${curDoc.INFO[0].CONSIDERATION}`)}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={6}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Fees
                      </Col>
                      <Col xs={6} sm={6} className="p-2">
                        {isNaN(parseInt(curDoc.INFO[0].RJ_FEES, 10))
                          ? `-`
                          : withCentsFormat(`${curDoc.INFO[0].RJ_FEES}`)}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {mobileView && (
                  <Row
                    noGutters
                    className="h-100 w-100 d-flex align-items-center"
                  >
                    <Button
                      className={'w-100 mt-3'}
                      onClick={() =>
                        window.open(
                          `http://bmiwebh5.utahcounty.gov/BmiWeb/?page=Document&Entry_No=${curDoc.INFO[0].RJ_ENTRY_NO}&YR=${curDoc.INFO[0].RJ_ENTRY_YR}`,
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }
                      disabled={!curDoc.PDF_DATA}
                    >
                      <BoxArrowUpRight className="mr-2 mb-1" />
                      Document Image Viewer
                    </Button>
                  </Row>
                )}
              </Container>
            </Jumbotron>
          </Row>
          <Row className="px-3" noGutters>
            <Col xs={12} sm={6} className={`mb-3${mobileView ? '' : ' pr-2'}`}>
              <Jumbotron className="w-100 shadow-sm bg-white p-3 mb-0">
                <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Grantors</h5>
                  {curDoc.GRANTORS && curDoc.GRANTORS.length > 1 && (
                    <Button
                      variant="outline-dark"
                      className="py-0 pl-1 pr-0 d-flex align-items-center justify-content-center"
                      onClick={() => setGrantorsExpanded(!grantorsExpanded)}
                    >
                      {grantorsExpanded ? (
                        <>
                          Less
                          <X className="p-0" width="1.25rem" height="1.25rem" />
                        </>
                      ) : (
                        <>
                          More
                          <Plus
                            className="p-0"
                            width="1.25rem"
                            height="1.25rem"
                          />
                        </>
                      )}
                    </Button>
                  )}
                </div>
                <hr />
                {curDoc.GRANTORS && curDoc.GRANTORS.length === 0 && (
                  <span
                    style={{
                      fontWeight: '100',
                      fontSize: '1rem',
                      lineHeight: '1.15rem',
                      display: 'block',
                    }}
                  >
                    {'-'}
                  </span>
                )}
                {curDoc.GRANTORS &&
                  curDoc.GRANTORS.length > 0 &&
                  curDoc.GRANTORS.slice(
                    0,
                    grantorsExpanded ? curDoc.GRANTORS.length : 1
                  ).map((g, i) => (
                    <Row
                      key={i}
                      noGutters
                      className={
                        grantorsExpanded || i === 0
                          ? `d-flex w-100${i >= 1 && ' pt-2'}`
                          : `d-none`
                      }
                    >
                      <a
                        className="d-flex"
                        style={{
                          fontWeight: '100',
                          fontSize: '1rem',
                          lineHeight: '1.15rem',
                          textUnderlineOffset: '0.125rem',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          dispatch(setCurrentID({ currentID: null }));
                          dispatch(setSearchDidRun({ searchDidRun: false }));
                          history.push(`/documents/name/0?name=${g.GRANTOR}`);
                        }}
                      >
                        {g.GRANTOR}
                        {g.GRANTORSFX && ` ${g.GRANTORSFX}`}
                      </a>
                    </Row>
                  ))}
              </Jumbotron>
            </Col>
            <Col xs={12} sm={6} className={`mb-3${mobileView ? '' : ' pl-2'}`}>
              <Jumbotron className="w-100 shadow-sm bg-white p-3 mb-0">
                <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Grantees</h5>
                  {curDoc.GRANTEES && curDoc.GRANTEES.length > 1 && (
                    <Button
                      variant="outline-dark"
                      className="py-0 pl-1 pr-0 d-flex align-items-center justify-content-center"
                      onClick={() => setGranteesExpanded(!granteesExpanded)}
                    >
                      {granteesExpanded ? (
                        <>
                          Less
                          <X className="p-0" width="1.25rem" height="1.25rem" />
                        </>
                      ) : (
                        <>
                          More
                          <Plus
                            className="p-0"
                            width="1.25rem"
                            height="1.25rem"
                          />
                        </>
                      )}
                    </Button>
                  )}
                </div>
                <hr />
                {curDoc.GRANTEES && curDoc.GRANTEES.length === 0 && (
                  <span
                    style={{
                      fontWeight: '100',
                      fontSize: '1rem',
                      lineHeight: '1.15rem',
                      display: 'block',
                    }}
                  >
                    {'-'}
                  </span>
                )}
                {curDoc.GRANTEES &&
                  curDoc.GRANTEES.length > 0 &&
                  curDoc.GRANTEES.slice(
                    0,
                    granteesExpanded ? curDoc.GRANTEES.length : 1
                  ).map((g, i) => (
                    <Row
                      key={i}
                      noGutters
                      className={
                        granteesExpanded || i === 0
                          ? `d-flex w-100${i >= 1 && ' pt-2'}`
                          : `d-none`
                      }
                    >
                      <a
                        className="d-flex"
                        style={{
                          fontWeight: '100',
                          fontSize: '1rem',
                          lineHeight: '1.15rem',
                          textUnderlineOffset: '0.125rem',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          dispatch(setCurrentID({ currentID: null }));
                          dispatch(setSearchDidRun({ searchDidRun: false }));
                          history.push(`/documents/name/0?name=${g.GRANTEE}`);
                        }}
                      >
                        {g.GRANTEE}
                        {g.GRANTEESFX && ` ${g.GRANTEESFX}`}
                      </a>
                    </Row>
                  ))}
              </Jumbotron>
            </Col>
          </Row>
          <Row className="px-3" noGutters>
            <Col xs={12} sm={6} className={`mb-3${mobileView ? '' : ' pr-2'}`}>
              <Jumbotron className="w-100 shadow-sm bg-white p-3 mb-0 overflow-auto">
                <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Serial Numbers</h5>
                  {curDoc.SERIAL_INFO && curDoc.SERIAL_INFO.length > 1 && (
                    <Button
                      variant="outline-dark"
                      className="py-0 pl-1 pr-0 d-flex align-items-center justify-content-center"
                      onClick={() => setSerialsExpanded(!serialsExpanded)}
                    >
                      {serialsExpanded ? (
                        <>
                          Less
                          <X className="p-0" width="1.25rem" height="1.25rem" />
                        </>
                      ) : (
                        <>
                          More
                          <Plus
                            className="p-0"
                            width="1.25rem"
                            height="1.25rem"
                          />
                        </>
                      )}
                    </Button>
                  )}
                </div>
                <hr />
                {curDoc.SERIAL_INFO && curDoc.SERIAL_INFO.length === 0 && (
                  <span
                    style={{
                      fontWeight: '100',
                      fontSize: '1rem',
                      lineHeight: '1.15rem',
                      display: 'block',
                    }}
                  >
                    {'-'}
                  </span>
                )}
                {curDoc.SERIAL_INFO &&
                  curDoc.SERIAL_INFO.length > 0 &&
                  curDoc.SERIAL_INFO.slice(
                    0,
                    serialsExpanded ? curDoc.SERIAL_INFO.length : 1
                  ).map((s, i) => (
                    <Row
                      key={i}
                      noGutters
                      className={
                        serialsExpanded || i === 0
                          ? `d-flex w-100${i >= 1 && ' pt-2'}`
                          : `d-none`
                      }
                      style={{
                        fontFamily: `SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace`,
                        fontWeight: '100',
                        fontSize: '1rem',
                        lineHeight: '1.15rem',
                      }}
                    >
                      {_normalize(s.SERIAL) !== 0 ? (
                        <a
                          className="d-flex"
                          style={{
                            textUnderlineOffset: '0.125rem',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            dispatch(setCurrentID({ currentID: null }));
                            dispatch(setSearchDidRun({ searchDidRun: false }));
                            history.push(
                              `/properties/serial/0?serial=${s.SERIAL}`
                            );
                          }}
                        >
                          {s.SERIAL}
                        </a>
                      ) : (
                        <>{s.SERIAL}</>
                      )}
                      {s.TIE_SERIAL_NUMBER && (
                        <>
                          <span className="mx-1">Part to:</span>
                          {_normalize(s.TIE_SERIAL_NUMBER) !== 0 ? (
                            <a
                              className="d-flex"
                              style={{
                                textUnderlineOffset: '0.125rem',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                dispatch(setCurrentID({ currentID: null }));
                                dispatch(
                                  setSearchDidRun({ searchDidRun: false })
                                );
                                history.push(
                                  `/properties/serial/0?serial=${s.TIE_SERIAL_NUMBER}`
                                );
                              }}
                            >
                              {s.TIE_SERIAL_NUMBER}
                            </a>
                          ) : (
                            s.TIE_SERIAL_NUMBER
                          )}
                        </>
                      )}
                      {s.REMAINDER > 0 && (
                        <span className="ml-1">Remainder</span>
                      )}
                      {s.SERIAL_TYPE === 99 && (
                        <span className="ml-1">Big Abstract:</span>
                      )}
                      {s.TAX_DISTRICT > 0 && (
                        <span className="ml-1">{`Parcels in tax district ${s.TAX_DISTRICT}`}</span>
                      )}
                      {s.SERIAL_TYPE === 99 && s.TAX_DISTRICT === 0 && (
                        <span className="ml-1">
                          All parcels in all tax districts
                        </span>
                      )}
                    </Row>
                  ))}
              </Jumbotron>
            </Col>
            <Col xs={12} sm={6} className={`mb-3${mobileView ? '' : ' pl-2'}`}>
              <Jumbotron className="w-100 shadow-sm bg-white p-3 mb-0">
                <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Tie Entry Numbers</h5>
                  {curDoc.TIE_ENTRY_NUMBERS &&
                    curDoc.TIE_ENTRY_NUMBERS.length > 1 && (
                      <Button
                        variant="outline-dark"
                        className="py-0 pl-1 pr-0 d-flex align-items-center justify-content-center"
                        onClick={() => setTiesExpanded(!tiesExpanded)}
                      >
                        {tiesExpanded ? (
                          <>
                            Less
                            <X
                              className="p-0"
                              width="1.25rem"
                              height="1.25rem"
                            />
                          </>
                        ) : (
                          <>
                            More
                            <Plus
                              className="p-0"
                              width="1.25rem"
                              height="1.25rem"
                            />
                          </>
                        )}
                      </Button>
                    )}
                </div>
                <hr />
                {(!curDoc.TIE_ENTRY_NUMBERS ||
                  curDoc.TIE_ENTRY_NUMBERS.length === 0) && (
                  <span
                    style={{
                      fontWeight: '100',
                      fontSize: '1rem',
                      lineHeight: '1.15rem',
                      display: 'block',
                    }}
                  >
                    {'-'}
                  </span>
                )}
                {curDoc.TIE_ENTRY_NUMBERS &&
                  curDoc.TIE_ENTRY_NUMBERS.length > 0 &&
                  curDoc.TIE_ENTRY_NUMBERS.slice(
                    0,
                    tiesExpanded ? curDoc.TIE_ENTRY_NUMBERS.length : 1
                  ).map(
                    (t, i) =>
                      t.TIE_ENTRY &&
                      t.TIE_YEAR && (
                        <Row
                          key={i}
                          noGutters
                          className={
                            tiesExpanded || i === 0
                              ? `d-flex w-100${i >= 1 && ' pt-2'}`
                              : `d-none`
                          }
                        >
                          <a
                            className="d-flex"
                            style={{
                              fontWeight: '100',
                              fontSize: '1rem',
                              lineHeight: '1.15rem',
                              textUnderlineOffset: '0.125rem',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              dispatch(setCurrentID({ currentID: null }));
                              dispatch(
                                setSearchDidRun({ searchDidRun: false })
                              );
                              history.push(
                                `/documents/document/0?entry=${t.TIE_ENTRY}&year=${t.TIE_YEAR}`
                              );
                            }}
                          >{`${t.TIE_ENTRY}-${t.TIE_YEAR}`}</a>
                        </Row>
                      )
                  )}
              </Jumbotron>
            </Col>
          </Row>
          <Row className="px-3 pb-3" noGutters>
            <Col xs={12} sm={6} className={`${mobileView ? ' mb-3' : ' pr-2'}`}>
              <Jumbotron className="w-100 shadow-sm bg-white p-3 mb-0">
                <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Releases</h5>
                  {curDoc.RELEASES && curDoc.RELEASES.length > 1 && (
                    <Button
                      variant="outline-dark"
                      className="py-0 pl-1 pr-0 d-flex align-items-center justify-content-center"
                      onClick={() => setReleasesExpanded(!releasesExpanded)}
                    >
                      {releasesExpanded ? (
                        <>
                          Less
                          <X className="p-0" width="1.25rem" height="1.25rem" />
                        </>
                      ) : (
                        <>
                          More
                          <Plus
                            className="p-0"
                            width="1.25rem"
                            height="1.25rem"
                          />
                        </>
                      )}
                    </Button>
                  )}
                </div>
                <hr />
                {(!curDoc.RELEASES || curDoc.RELEASES.length === 0) && (
                  <span
                    style={{
                      fontWeight: '100',
                      fontSize: '1rem',
                      lineHeight: '1.15rem',
                      display: 'block',
                    }}
                  >
                    {'-'}
                  </span>
                )}
                {curDoc.RELEASES &&
                  curDoc.RELEASES.length > 0 &&
                  curDoc.RELEASES.slice(
                    0,
                    releasesExpanded ? curDoc.RELEASES.length : 1
                  ).map((r, i) => (
                    <Row
                      key={i}
                      noGutters
                      className={
                        releasesExpanded || i === 0
                          ? `d-flex w-100${i >= 1 && ' pt-2'}`
                          : `d-none`
                      }
                      style={{
                        fontWeight: '100',
                        fontSize: '1rem',
                        lineHeight: '1.15rem',
                      }}
                    >
                      <a
                        className="d-flex"
                        style={{
                          fontWeight: '100',
                          fontSize: '1rem',
                          lineHeight: '1.15rem',
                          textUnderlineOffset: '0.125rem',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          const rel = r.RELEASE.split(' ');
                          if (rel[2].toUpperCase() === 'BOOK') {
                            dispatch(setCurrentID({ currentID: null }));
                            dispatch(setSearchDidRun({ searchDidRun: false }));
                            history.push(
                              `/documents/document/1?book=${rel[3]}&page=${rel[5]}`
                            );
                          } else if (rel[2].toUpperCase() === 'ENTRY') {
                            dispatch(setCurrentID({ currentID: null }));
                            dispatch(setSearchDidRun({ searchDidRun: false }));
                            history.push(
                              `/documents/document/0?entry=${rel[3]}&year=${rel[5]}`
                            );
                          }
                        }}
                      >
                        {r.RELEASE}
                      </a>
                    </Row>
                  ))}
              </Jumbotron>
            </Col>
            <Col xs={12} sm={6} className={`${mobileView ? '' : ' pl-2'}`}>
              <Jumbotron className="w-100 shadow-sm bg-white p-3 mb-0">
                <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Abbreviated Legal Description</h5>
                  {curDoc.LEGALS && curDoc.LEGALS.length > 1 && (
                    <Button
                      variant="outline-dark"
                      className="py-0 pl-1 pr-0 d-flex align-items-center justify-content-center"
                      onClick={() => setLegalsExpanded(!legalsExpanded)}
                    >
                      {legalsExpanded ? (
                        <>
                          Less
                          <X className="p-0" width="1.25rem" height="1.25rem" />
                        </>
                      ) : (
                        <>
                          More
                          <Plus
                            className="p-0"
                            width="1.25rem"
                            height="1.25rem"
                          />
                        </>
                      )}
                    </Button>
                  )}
                </div>
                <hr />
                {(!curDoc.LEGALS || curDoc.LEGALS.length === 0) && (
                  <span
                    style={{
                      fontWeight: '100',
                      fontSize: '1rem',
                      lineHeight: '1.15rem',
                      display: 'block',
                    }}
                  >
                    {'-'}
                  </span>
                )}
                {curDoc.LEGALS &&
                  curDoc.LEGALS.length > 0 &&
                  curDoc.LEGALS.slice(
                    0,
                    legalsExpanded ? curDoc.LEGALS.length : 1
                  ).map((l, i) => (
                    <Row
                      key={i}
                      noGutters
                      className={
                        legalsExpanded || i === 0 ? `d-flex w-100` : `d-none`
                      }
                      style={{
                        fontWeight: '100',
                        fontSize: '1rem',
                        lineHeight: '1.15rem',
                      }}
                    >
                      {l.LEGALDESC}
                    </Row>
                  ))}
              </Jumbotron>
            </Col>
          </Row>
          <DocumentImageModal
            entryNumber={`${curDoc.INFO[0].RJ_ENTRY_NO}-${curDoc.INFO[0].RJ_ENTRY_YR}`}
            pdfData={curDoc.PDF_DATA}
            show={showDocumentImageModal}
            setShow={setShowDocumentImageModal}
          />
        </>
      )}
    </>
  );
};

export default DocumentDetailView;
