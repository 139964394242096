import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  detailViewLoading: false,
};

export const slice = createSlice({
  name: 'app/detailView',
  initialState,
  reducers: {
    setDetailViewLoading: (state, action) => {
      // console.log('detailViewLoading =', action.payload);
      state.detailViewLoading = action.payload;
    },
  },
});
// export actions
export const { setDetailViewLoading } = slice.actions;
// export slice
export default slice.reducer;
