import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import { FileEarmarkTextFill } from 'react-bootstrap-icons';

import DocumentModal from '../modalViews/DocumentModal';

import {
  fetchDocNameList,
  selectAllDocNameLists,
  selectDocNameListById,
  selectAllDocNameListIDs,
} from './documentNameDetailViewSlice';

import { withCentsFormat } from '../../../utilities/currency';
import { useHistory, useLocation } from 'react-router-dom';
import { setCurrentID, setSearchDidRun } from '../../app/searchSlice';
import { queryToString } from '../../../utilities/url';

const DocumentNameDetailView = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const mainSearch = useSelector(state => state.search.mainSearch);
  const subSearch = useSelector(state => state.search.subSearch);
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const detailViewStatus = useSelector(
    state => state.documentNameDetailView.status
  );
  const searchStatus = useSelector(state => state.search.status);
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const currentID = useSelector(state => state.search.currentID);
  const curDocNameList = useSelector(state =>
    selectDocNameListById(state, currentID)
  );
  const router = useSelector(state => state.router);
  const currentName = useSelector(
    state => state.documentNameDetailView.currentName
  );

  const [docEntryNumber, setDocEntryNumber] = useState(null);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [viewStatus, setViewStatus] = useState('loading');

  useEffect(() => {
    // console.log('currentID changed:', currentID);
    if (currentID && mainSearch === 'documents' && subSearch === 'name') {
      // if (!allDocNameListIDs.includes(currentID))
      dispatch(fetchDocNameList({ url: baseApiUrl, nameID: currentID }));
      const query = queryToString({
        query: router.location.query,
        currentID: currentID,
      });
      if (!router.location.query.currentID)
        history.replace(`${router.location.pathname}?${query}`);
    }
    setShowDocumentModal(false);
  }, [currentID]);

  useEffect(() => {
    if (curDocNameList)
      setDocEntryNumber(
        `${curDocNameList.docNameList[0].RJ_ENTRY_NO}-${curDocNameList.docNameList[0].RJ_ENTRY_YR}`
      );
  }, [curDocNameList]);

  useEffect(() => {
    if (detailViewStatus === 'loading') setViewStatus('loading');
    if (
      detailViewStatus === 'failed' ||
      (detailViewStatus === 'succeeded' && !curDocNameList) ||
      (searchStatus === 'succeeded' && !currentID)
    )
      setViewStatus('failed');
    if (detailViewStatus === 'succeeded' && curDocNameList)
      setViewStatus('succeeded');
  }, [currentID, detailViewStatus, searchStatus, curDocNameList]);

  return (
    <>
      {viewStatus === 'loading' && (
        <Row
          noGutters
          className={
            sidebarExpanded
              ? 'w-100 h-100 p-1 overflow-auto bg-light'
              : 'd-none'
          }
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '20rem', width: '20rem' }}
          />
        </Row>
      )}
      {viewStatus === 'failed' && (
        <Row
          noGutters
          className="h-100 d-flex flex-column align-items-center justify-content-center p-2"
          style={{ backgroundColor: 'rgba(217, 190, 80, 0.1)' }}
        >
          <FileEarmarkTextFill
            height={mobileView ? '4rem' : '8rem'}
            width={mobileView ? '4rem' : '8rem'}
            style={{ color: 'rgba(78, 128, 166, 1)' }}
          />
          <h5 className="text-center mt-4" style={{ fontSize: '1.5rem' }}>
            Could not find document name information.
          </h5>
          <h5 className="text-center mt-2" style={{ fontSize: '1.5rem' }}>
            Please try another search.
          </h5>
        </Row>
      )}
      {viewStatus === 'succeeded' && curDocNameList && (
        <>
          <Row noGutters className={`h-100${mobileView ? ' p-1' : ' p-3'}`}>
            <Jumbotron
              className={`h-100 w-100 shadow-sm bg-white p-2 mb-0 d-flex flex-column flex-grow-1`}
            >
              <h5 className="mt-2 mb-0">
                Documents Related to {mobileView && <br />}
                <span className="text-primary" style={{ fontWeight: '400' }}>
                  {currentName}
                </span>
              </h5>
              <hr className="w-100" />
              {(!curDocNameList || curDocNameList.docNameList.length === 0) && (
                <Row noGutters className="h-100">
                  <h5 className="mx-auto text-danger d-flex align-items-center justify-content-center">
                    {`No related documents found.`}
                  </h5>
                </Row>
              )}
              {curDocNameList && curDocNameList.docNameList.length > 0 && (
                <div className={`h-100 overflow-auto`}>
                  <Table
                    striped
                    hover
                    size="sm"
                    className="w-100 mb-0 shadow-sm"
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: '1rem',
                      lineHeight: '1.15rem',
                      fontFamily: 'Calibri, sans-serif',
                      maxHeight: '100%',
                    }}
                  >
                    <thead
                      className="bg-white stickyTop"
                      // style={{ position: 'sticky', top: '73' }}
                    >
                      <tr>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Entry Number
                          <br />
                          Book-Page
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Recorded Date
                          <br />
                          Instrument Date
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Party Name
                          <br />
                          Legal Description
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          KOI Description
                          <br />
                          Release
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Party Type
                          <br />
                          Suffix
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {curDocNameList.docNameList
                        .slice()
                        .sort((a, b) =>
                          `${b.RJ_DATE}`.localeCompare(`${a.RJ_DATE}`)
                        )
                        .map((d, i) => (
                          <tr key={i} style={{ lineHeight: '1.375rem' }}>
                            <td
                              style={{
                                lineHeight: '0.75rem',
                                paddingTop: '0.75rem',
                                paddingBottom: '0.25rem',
                              }}
                            >
                              {d.RJ_ENTRY_NO && d.RJ_ENTRY_YR ? (
                                <a
                                  className="d-flex"
                                  style={{
                                    textUnderlineOffset: '0.125rem',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setDocEntryNumber(
                                      `${d.RJ_ENTRY_NO}-${d.RJ_ENTRY_YR}`
                                    );
                                    setShowDocumentModal(true);
                                  }}
                                >
                                  {`${d.RJ_ENTRY_NO}-${d.RJ_ENTRY_YR}`}
                                </a>
                              ) : (
                                '-'
                              )}
                              <br />
                              {d.RJ_BOOK && d.RJ_PAGE
                                ? `${d.RJ_BOOK}-${d.RJ_PAGE}`
                                : '-'}
                            </td>
                            <td className="py-2">
                              {d.RJ_DATE && Date.parse(d.RJ_DATE)
                                ? new Date(d.RJ_DATE).toLocaleDateString()
                                : '-'}
                              <br />
                              {d.INST_DATE && Date.parse(d.INST_DATE)
                                ? new Date(d.INST_DATE).toLocaleDateString()
                                : '-'}
                            </td>
                            <td className="py-2">
                              {d.PARTY_NAME ? `${d.PARTY_NAME}`.trim() : '-'}
                              <br />
                              {d.LEGALDESC ? `${d.LEGALDESC}`.trim() : '-'}
                            </td>
                            <td className="py-2">
                              {d.KOI || '-'}
                              <br />
                              {d.RELEASE || '-'}
                            </td>
                            <td className="py-2">
                              {d.PTYPE === '1'
                                ? 'Party 1 (Grantor)'
                                : d.PTYPE === '2'
                                ? 'Party 2 (Grantee)'
                                : '-'}
                              <br />
                              {d.SUFFIX || '-'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </Jumbotron>
          </Row>
          <DocumentModal
            show={showDocumentModal}
            setShow={setShowDocumentModal}
            entryNumber={docEntryNumber}
          />
        </>
      )}
    </>
  );
};

export default DocumentNameDetailView;
