import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import { Plus, X, Search } from 'react-bootstrap-icons';

import DefaultPropertyImage from '../../../app/propertyImages/DefaultPropertyImage';
import PropertyImage from '../../../app/propertyImages/PropertyImage';
import { setCurrentID, setSearchStatus } from '../../../app/searchSlice';

import {
  selectAllPropertyImageEntities,
  selectAllPropertyImageIds,
  fetchPropertyImages,
} from '../../../app/propertyImages/propertyImagesSlice';

import {
  fetchResults,
  setSearchResultsScrollTop,
  // setFilteredSortedResults,
} from '../../../app/searchSlice';
import {
  selectDefaultMainSearch,
  selectDefaultSubSearch,
  selectCurrentSortOptions,
} from '../../../app/defaultSearchesSlice';

import {
  setSidebarExpanded,
  setSidebarLoading,
  setSortOptions,
} from '../../sidebarSlice';
import { setDetailViewLoading } from '../../../detailView/detailViewSlice';

import { setTaxyear } from '../../../detailView/propertyDetailView/propertyDetailViewSlice';
import { toApiQuery, queryToString } from '../../../../utilities/url';
import { filterResults, sortResults } from '../../../../utilities/filterSort';

const PropertyNameSearchList = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const subSearch = useSelector(state => state.search.subSearch);
  const mainSearchInfo = useSelector(selectDefaultMainSearch(mainSearch));
  const subSearchInfo = useSelector(
    selectDefaultSubSearch(mainSearch, subSearch)
  );
  const lastSearchResult = useSelector(state => state.search.lastSearchResult);
  const subSearchIndex = useSelector(state => state.search.subSearchIndex);
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const searchResultsScrollTop = useSelector(
    state => state.search.searchResultsScrollTop
  );
  const searchStatus = useSelector(state => state.search.status);
  const allPropertyImageEntities = useSelector(selectAllPropertyImageEntities);
  const allPropertyImageIds = useSelector(selectAllPropertyImageIds);
  const dispatch = useDispatch();
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const [namesListExpanded, setNamesListExpanded] = useState(null);
  const currentID = useSelector(state => state.search.currentID);
  const router = useSelector(state => state.router);
  const history = useHistory();
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const sortOptions = useSelector(state => state.sidebar.sortOptions);
  const sidebarLoading = useSelector(state => state.sidebar.sidebarLoading);
  const detailViewLoading = useSelector(
    state => state.detailView.detailViewLoading
  );
  const [lastFilteredCount, setLastFilteredCount] = useState(0);
  const [sortedFilteredFlag, setSortedFilteredFlag] = useState(false);
  const defaultSortValues = useSelector(
    selectCurrentSortOptions(mainSearch, subSearch)
  );

  const getSearchResultList = () =>
    document.getElementById('searchResultsList');
  const loadMoreSearchResults = e => {
    // save scroll top
    if (getSearchResultList())
      dispatch(
        setSearchResultsScrollTop({
          searchResultsScrollTop: getSearchResultList().scrollTop,
        })
      );
    // dispatch search with last
    dispatch(
      fetchResults({
        url: baseApiUrl,
        endpoint: subSearchInfo.inputApiEndpoints[subSearchIndex],
        queryString: `${toApiQuery(
          router.location.query
        )}&last=${lastSearchResult}`,
      })
    );
  };
  const checkScroll = () =>
    lastSearchResult &&
    getSearchResultList() &&
    getSearchResultList().scrollTop > 0 &&
    getSearchResultList().scrollHeight - getSearchResultList().scrollTop <=
      getSearchResultList().getBoundingClientRect().height;
  const checkScrollHandler = e => {
    if (checkScroll()) loadMoreSearchResults();
  };
  // check for resultList height === scrollHeight && lastResult !== null
  const checkResultsHeight = () =>
    lastSearchResult &&
    getSearchResultList() &&
    getSearchResultList().getBoundingClientRect().height >=
      getSearchResultList().scrollHeight;
  const checkResultsHeightHandler = e => {
    if (checkResultsHeight()) loadMoreSearchResults();
  };

  // load
  const rawResults = useSelector(state => state.search.searchResults);
  const [searchResults, setSearchResults] = useState(null);
  const [newSearchResultsLoaded, setNewSearchResultsLoaded] = useState(false);

  // set default sort options
  useEffect(() => {
    dispatch(setSortOptions({ ...defaultSortValues }));
  }, []);

  useEffect(() => {
    setSearchResults([...rawResults]);
    setNewSearchResultsLoaded(true);
  }, [rawResults]);

  useEffect(() => {
    if (newSearchResultsLoaded && searchResults) {
      // filter
      const filteredSearchResults = filterResults(filterOptions, searchResults);
      // group
      const groupedSearchResults = [...filteredSearchResults].reduce(
        (tempGrouped, r) => {
          // if a key doesn't exist, create it and add the record to final results
          if (!(r.SERIAL in tempGrouped)) {
            tempGrouped[r.SERIAL] = { ...r, OTHER_OWNERS: [] };
          }
          // update item's OTHER_OWNERS list with corresponding record
          else
            tempGrouped[r.SERIAL].OTHER_OWNERS.push({
              OWNERNAME: r.OWNERNAME,
              YEARSVALID: r.YEARSVALID,
            });
          return tempGrouped;
        },
        {}
      );
      // sort
      const sortedSearchResults = sortResults(sortOptions, [
        ...Object.values(groupedSearchResults),
      ]);

      // update searchResults
      setSearchResults([...sortedSearchResults]);
      // toggle sortedFilteredFlag
      // if (sortedSearchResults.length !== lastFilteredCount)
      //   setLastFilteredCount(sortedSearchResults.length);
      setNewSearchResultsLoaded(false);
      setSortedFilteredFlag(!sortedFilteredFlag);
    }
  }, [newSearchResultsLoaded, filterOptions, sortOptions]);

  // useEffect(() => {
  //   console.log('newSearchResultsLoaded:', newSearchResultsLoaded);
  // }, [newSearchResultsLoaded]);

  // useEffect(() => {
  //   console.log('sortedFilteredFlag:', sortedFilteredFlag);
  // }, [sortedFilteredFlag]);

  // useEffect(() => {
  //   if (!!searchResults) {
  //     console.log('lastFilteredCount:', lastFilteredCount);
  //     console.log('searchResults.length:', searchResults.length);
  //     if (searchResults.length === 0 || !lastSearchResult) {
  //       dispatch(setSidebarLoading(false));
  //       return;
  //     }
  //     if (searchResults.length !== lastFilteredCount && !!lastSearchResult) {
  //       setLastFilteredCount(searchResults.length);
  //       dispatch(setSidebarLoading(false));
  //     }
  //   }
  // }, [sortedFilteredFlag]);

  // search for additional results when space left in search results
  // window or user reaches end of currently available search results
  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      // every time searchResults are updated, also check the results height
      checkResultsHeightHandler();
      // subscribe window scroll event
      if (getSearchResultList())
        getSearchResultList().addEventListener('scroll', checkScrollHandler);
      // set checked flag
    }
    return () => {
      if (getSearchResultList())
        getSearchResultList().removeEventListener('scroll', checkScrollHandler);
    };
  }, [sortedFilteredFlag]);

  // update currentID when page loads
  useEffect(() => {
    if (
      searchStatus === 'succeeded' &&
      searchResults &&
      searchResults.length > 0 &&
      !currentID
    ) {
      const yv =
        searchResults[0].YEARSVALID.slice(-3) === '...' ||
        !searchResults[0].YEARSVALID
          ? [`${new Date().getFullYear()}`]
          : searchResults[0].YEARSVALID.split('-');
      let yearsvalid;
      if (yv.length === 2) yearsvalid = yv[1];
      else yearsvalid = yv[0].slice(0, 4);
      dispatch(setTaxyear({ taxyear: yearsvalid }));
      dispatch(
        setCurrentID({
          currentID: `${searchResults[0].SERIAL}`,
        })
      );
    }
  }, [sortedFilteredFlag]);

  // load images for search results
  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      searchResults.forEach(r => {
        if (allPropertyImageIds.indexOf(r.SERIAL) === -1)
          dispatch(fetchPropertyImages({ url: baseApiUrl, serial: r.SERIAL }));
      });
    }
  }, [sortedFilteredFlag]);

  // update search results scroll top after lazy load for better UX
  useEffect(() => {
    if (
      searchStatus === 'succeeded' &&
      searchResults &&
      searchResults.length > 0 &&
      getSearchResultList()
    ) {
      getSearchResultList().scrollTop = searchResultsScrollTop;
    }
  }, [sortedFilteredFlag]);

  return (
    <>
      {/* show search loading */}
      {(searchStatus === 'loading' || !searchResults) && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '10rem', width: '10rem' }}
          />
        </Row>
      )}
      {/* show search results list */}
      {searchStatus === 'succeeded' &&
        searchResults &&
        searchResults.length > 0 && (
          <>
            <div
              id="searchResultsList"
              className={
                sidebarExpanded
                  ? 'w-100 d-flex flex-column overflow-auto border-bottom shadow-sm'
                  : 'd-none'
              }
              style={{
                fontWeight: '100',
                fontFamily: 'Calibri, sans-serif',
                background: '#FFF',
                position: 'relative',
              }}
            >
              {searchResults.map((result, index) => {
                return (
                  <Row
                    noGutters
                    key={index}
                    className={`w-100 rounded-0 border-top ${
                      mobileView ? 'pl-0 py-2 pr-2' : 'py-3 px-2'
                    }`}
                    style={{
                      background: 'rgba(0, 0, 0, 0.03)',
                    }}
                  >
                    <Col
                      xs={4}
                      sm={2}
                      className="d-flex flex-column align-items-center"
                    >
                      {!allPropertyImageEntities[result.SERIAL] && (
                        <DefaultPropertyImage loading={true} />
                      )}
                      {allPropertyImageEntities[result.SERIAL] &&
                        allPropertyImageEntities[result.SERIAL].photos
                          .length === 0 && (
                          <DefaultPropertyImage loading={false} />
                        )}
                      {allPropertyImageEntities[result.SERIAL] &&
                        allPropertyImageEntities[result.SERIAL].photos.length >
                          0 && (
                          <PropertyImage
                            photoData={
                              allPropertyImageEntities[result.SERIAL].photos[0]
                            }
                          />
                        )}
                    </Col>
                    <Col xs={8} sm={10}>
                      <Row
                        noGutters
                        className={`w-100 pl-1 mb-1${
                          mobileView ? ' d-flex' : ' d-none'
                        }`}
                      >
                        <Col
                          xs={
                            result.OTHER_OWNERS &&
                            result.OTHER_OWNERS.length > 0
                              ? 10
                              : 12
                          }
                          className="d-flex align-self-center"
                        >
                          <a
                            className="text-truncate"
                            style={{
                              lineHeight: '1.25rem',
                              textUnderlineOffset: '0.125rem',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              if (mobileView)
                                dispatch(
                                  setSidebarExpanded({ sidebarExpanded: false })
                                );
                              if (currentID !== `${result.SERIAL}`) {
                                const yv =
                                  result.YEARSVALID.slice(-3) === '...' ||
                                  !result.YEARSVALID
                                    ? [`${new Date().getFullYear()}`]
                                    : result.YEARSVALID.split('-');
                                let yearsvalid;
                                if (yv.length === 2) yearsvalid = yv[1];
                                else yearsvalid = yv[0].slice(0, 4);
                                dispatch(setTaxyear({ taxyear: yearsvalid }));
                                dispatch(
                                  setCurrentID({
                                    currentID: `${result.SERIAL}`,
                                  })
                                );
                                const query = queryToString({
                                  query: router.location.query,
                                  currentID: `${result.SERIAL}`,
                                });
                                history.push(
                                  `${router.location.pathname}?${query}`
                                );
                              }
                            }}
                          >
                            {result.OWNERNAME ? result.OWNERNAME : '-'}
                          </a>
                        </Col>

                        {result.OTHER_OWNERS && result.OTHER_OWNERS.length > 0 && (
                          <Col className="d-flex justify-content-end">
                            <Button
                              variant="outline-dark"
                              className="rounded-circle p-0 d-flex align-items-center justify-content-center"
                              style={{
                                width: '24px',
                                height: '24px',
                                margin: '3px',
                              }}
                              onClick={() => {
                                if (namesListExpanded === index)
                                  setNamesListExpanded(null);
                                else setNamesListExpanded(index);
                              }}
                            >
                              {namesListExpanded === index ? (
                                <X
                                  className="m-0 p-0"
                                  width="1.25rem"
                                  height="1.25rem"
                                />
                              ) : (
                                <Plus
                                  className="m-0 p-0"
                                  width="1.25rem"
                                  height="1.25rem"
                                />
                              )}
                            </Button>
                          </Col>
                        )}
                      </Row>
                      {namesListExpanded === index && mobileView && (
                        <div className="d-flex flex-column w-100 px-2 py-1 mb-2 border rounded">
                          <div
                            className="w-100 d-flex justify-content-between"
                            style={{
                              fontSize: '0.85rem',
                              fontWeight: '400',
                              textDecoration: 'underline',
                              textUnderlineOffset: '0.1rem',
                            }}
                          >
                            Other Matching Names
                          </div>
                          {result.OTHER_OWNERS &&
                            result.OTHER_OWNERS.map((o, i) => (
                              <div
                                key={i}
                                className="w-100 d-flex justify-content-between"
                                style={{ fontSize: '0.8rem' }}
                              >
                                <div className="text-truncate">
                                  {o.OWNERNAME}
                                </div>
                                <div className="flex-shrink-1">
                                  ({o.YEARSVALID})
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                      <Row
                        noGutters
                        className={`w-100${mobileView ? ' pl-1' : ' pl-2'}`}
                      >
                        <Col
                          xs={4}
                          sm={3}
                          className="pl-0 pr-1 d-flex flex-column align-items-center text-truncate"
                        >
                          <Row
                            noGutters
                            className={`w-100 border-right ${
                              mobileView ? 'd-none' : 'd-flex'
                            }`}
                            style={{
                              fontWeight: '400',
                              fontSize: '1.15rem',
                              lineHeight: '1.5rem',
                              paddingTop:
                                result.OTHER_OWNERS &&
                                result.OTHER_OWNERS.length > 0
                                  ? '3px'
                                  : '0px',
                              paddingBottom:
                                result.OTHER_OWNERS &&
                                result.OTHER_OWNERS.length > 0
                                  ? 'calc(3px + 0.25rem)'
                                  : '0.25rem',
                            }}
                          >
                            <span className="text-truncate">Owner Name</span>
                          </Row>
                          {/* added the following div to manage spacing */}
                          {result.OTHER_OWNERS &&
                            result.OTHER_OWNERS.length > 0 &&
                            !mobileView && (
                              <div
                                className={
                                  namesListExpanded === index
                                    ? `d-flex flex-grow-1 w-100 border-right`
                                    : `d-none`
                                }
                              ></div>
                            )}
                          <Row
                            noGutters
                            className="w-100 border-right"
                            style={{
                              fontWeight: '400',
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">Serial/Parcel</span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100 border-right"
                            style={{
                              fontWeight: '400',
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">Address</span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100 border-right"
                            style={{
                              fontWeight: '400',
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              Legal Description
                            </span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100 border-right"
                            style={{
                              fontWeight: '400',
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">Years Valid</span>
                          </Row>
                        </Col>
                        <Col className="px-1 d-flex flex-column align-items-center text-truncate">
                          <Row
                            noGutters
                            className={`w-100 pb-1 ${
                              mobileView
                                ? 'd-none'
                                : 'd-flex justify-content-between'
                            }`}
                          >
                            <a
                              className="text-truncate"
                              style={{
                                fontWeight: '400',
                                fontSize: '1.15rem',
                                lineHeight: '1.5rem',
                                textUnderlineOffset: '0.125rem',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                maxWidth: 'calc(100% - 30px)',
                              }}
                              onClick={() => {
                                if (mobileView)
                                  dispatch(
                                    setSidebarExpanded({
                                      sidebarExpanded: false,
                                    })
                                  );
                                if (currentID !== `${result.SERIAL}`) {
                                  const yv =
                                    result.YEARSVALID.slice(-3) === '...' ||
                                    !result.YEARSVALID
                                      ? [`${new Date().getFullYear()}`]
                                      : result.YEARSVALID.split('-');
                                  let yearsvalid;
                                  if (yv.length === 2) yearsvalid = yv[1];
                                  else yearsvalid = yv[0].slice(0, 4);
                                  dispatch(setTaxyear({ taxyear: yearsvalid }));
                                  dispatch(
                                    setCurrentID({
                                      currentID: `${result.SERIAL}`,
                                    })
                                  );
                                  const query = queryToString({
                                    query: router.location.query,
                                    currentID: `${result.SERIAL}`,
                                  });
                                  history.push(
                                    `${router.location.pathname}?${query}`
                                  );
                                }
                              }}
                            >
                              {result.OWNERNAME ? result.OWNERNAME : '-'}
                            </a>

                            {result.OTHER_OWNERS &&
                              result.OTHER_OWNERS.length > 0 && (
                                <Button
                                  variant="outline-dark"
                                  className="rounded-circle p-0 d-flex align-items-center justify-content-center"
                                  style={{
                                    width: '24px',
                                    height: '24px',
                                    margin: '3px',
                                  }}
                                  onClick={() => {
                                    if (namesListExpanded === index)
                                      setNamesListExpanded(null);
                                    else setNamesListExpanded(index);
                                  }}
                                >
                                  {namesListExpanded === index ? (
                                    <X
                                      className="m-0 p-0"
                                      width="1.25rem"
                                      height="1.25rem"
                                    />
                                  ) : (
                                    <Plus
                                      className="m-0 p-0"
                                      width="1.25rem"
                                      height="1.25rem"
                                    />
                                  )}
                                </Button>
                              )}
                          </Row>
                          {namesListExpanded === index && !mobileView && (
                            <div className="d-flex flex-column w-100 px-2 py-1 border rounded">
                              <div
                                className="w-100 d-flex justify-content-between"
                                style={{
                                  fontSize: '0.85rem',
                                  fontWeight: '400',
                                  textDecoration: 'underline',
                                  textUnderlineOffset: '0.1rem',
                                }}
                              >
                                Other Matching Names
                              </div>
                              {result.OTHER_OWNERS &&
                                result.OTHER_OWNERS.map((o, i) => (
                                  <div
                                    key={i}
                                    className="w-100 d-flex justify-content-between"
                                    style={{ fontSize: '0.8rem' }}
                                  >
                                    <div className="text-truncate">
                                      {o.OWNERNAME}
                                    </div>
                                    <div className="flex-shrink-1">
                                      ({o.YEARSVALID})
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                          <Row
                            noGutters
                            className="w-100"
                            style={{
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              {result.SERIAL_FORMATTED
                                ? `${result.SERIAL_FORMATTED}`
                                : '-'}
                            </span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100"
                            style={{
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              {result.ADDRESS ? `${result.ADDRESS}` : `-`}
                            </span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100"
                            style={{
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              {result.LEGAL_DESC ? `${result.LEGAL_DESC}` : `-`}
                            </span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100"
                            style={{
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              {result.YEARSVALID ? `${result.YEARSVALID}` : `-`}
                            </span>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </>
        )}
      {/* show no result message */}
      {((searchStatus === 'succeeded' &&
        searchResults &&
        searchResults.length === 0) ||
        searchStatus === 'failed') && (
        <Row
          noGutters
          id="sidebarResults"
          className={
            sidebarExpanded
              ? 'w-100 h-100 d-flex flex-column flex-grow-1'
              : 'd-none'
          }
          style={{
            backgroundColor: 'rgba(101, 167, 219, 0.25)',
          }}
        >
          <h5
            className="mx-auto w-75 d-flex flex-column h-100 align-items-center"
            style={{
              marginTop: '25%',
            }}
          >
            <Search
              height={mobileView ? '4rem' : '8rem'}
              width={mobileView ? '4rem' : '8rem'}
              style={{ color: 'rgba(101, 167, 219, 1)' }}
            />
            <p className="mb-1 mt-4 text-center">{`No ${mainSearchInfo.titleSingle.toLowerCase()} results found.`}</p>
            <p className="mb-0 mt-2 text-center">{`Please update your filter parameters or try another search.`}</p>
          </h5>
        </Row>
      )}
    </>
  );
};

export default PropertyNameSearchList;
