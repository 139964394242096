import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from 'react-bootstrap/Spinner';
import { setStatus } from './propertyDetailViewMapSlice';

// note: come back and implement book page with esri map and UC layers API using bookPage param

const PropertyDetailViewMap = ({ serial = 50220004 }) => {
  const mapStatus = useSelector(state => state.propertyDetailView.status);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const disptach = useDispatch();
  const [mapType, setMapType] = useState('standard');

  useEffect(() => {
    setTimeout(() => {
      disptach(setStatus({ status: 'succeeded' }));
    }, 200);
  });

  return (
    <>
      {mapStatus === 'loading' && (
        <div className="h-100 w-100">
          <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
            <Spinner
              animation="border"
              variant="info"
              className="d-flex align-self-center m-auto"
              style={{ height: '10rem', width: '10rem' }}
            />
          </div>
        </div>
      )}
      {mapStatus === 'succeeded' && (
        <div className={`h-100 w-100`}>
          <>
            {mapType === 'standard' && (
              <div
                id="mapView"
                className="w-100 h-100 border rounded shadow-sm d-flex align-items-center justify-content-center"
                dangerouslySetInnerHTML={{
                  __html: `<iframe class="h-100 w-100" src="https://maps.utahcounty.gov/ParcelLocationMap/ParcelLocationMap.html?mParcelToSearch=${serial}" />`,
                }}
              ></div>
            )}
            {mapType === 'aerial' && (
              <div
                id="mapView"
                className="w-100 h-100 border rounded shadow-sm d-flex align-items-center justify-content-center"
                dangerouslySetInnerHTML={{
                  __html: `<iframe class="h-100 w-100" src="http://maps.utahcounty.gov/PictometryIPA/PictometryIPA.html?mParcelToSearch=${serial}" />`,
                }}
              ></div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default PropertyDetailViewMap;
