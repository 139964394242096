import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { BoxArrowUpRight } from 'react-bootstrap-icons';

const DocumentImageModal = ({ entryNumber, pdfData, show, setShow }) => {
  const mobileView = useSelector(state => state.windowState.mobileView);
  return (
    <Modal
      style={{ minHeight: '100%' }}
      size={mobileView ? 'sm' : 'lg'}
      show={show}
      onHide={() => setShow(false)}
      className="h-100"
    >
      <Modal.Header className="pt-3 px-3 pb-2" closeButton>
        <Modal.Title style={{ fontSize: '1rem' }}>
          <Button
            className="mr-3 p-2 mb-2"
            aria-label="Open Image Viewer Application"
            onClick={() =>
              window.open(
                `http://bmiwebh5.utahcounty.gov/BmiWeb/?page=Document&Entry_No=${
                  entryNumber.split('-')[0]
                }&YR=${entryNumber.split('-')[1]}`,
                '_blank',
                'noopener,noreferrer'
              )
            }
            style={{ fontSize: '0.85rem', fontWeight: '100' }}
            disabled={!entryNumber || !pdfData}
          >
            <BoxArrowUpRight className="ml-1 mr-2" />
            Open Image Viewer Application
          </Button>
          Document Image for {entryNumber || '-'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          lineHeight: '1.15rem',
          fontSize: '1rem',
          marginBottom: '0',
          minHeight: '100%',
        }}
      >
        {pdfData && (
          <iframe
            width="100%"
            style={{
              minHeight: '75vh',
              height: '75vh',
              minWidth: '100%',
              width: '100%',
            }}
            src={`data:application/pdf;base64,${pdfData}`}
            alt="Document Image"
          />
        )}
        {!pdfData && (
          <Row>
            <h5 className="mx-auto my-5 text-danger">
              <p className="text-center">{`Could not find document image for entry number: ${entryNumber}.`}</p>
            </h5>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DocumentImageModal;
