import React from 'react';
import { useSelector } from 'react-redux';

import Row from 'react-bootstrap/Row';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
} from 'recharts';

import { withCentsFormat } from '../../../utilities/currency';

const PropertyTaxDistributionChart = ({ valTaxDist }) => {
  const mobileView = useSelector(state => state.windowState.mobileView);
  const propTaxyear = useSelector(state => state.propertyDetailView.taxyear);
  if (!!valTaxDist)
    return (
      <Row
        noGutters
        className={mobileView ? 'pb-3' : ''}
        style={{
          minHeight: '250px',
          height: mobileView ? '30vh' : `${60 + 60 * valTaxDist.length}px`,
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout={mobileView ? 'horizontal' : 'vertical'}
            data={valTaxDist}
            width={mobileView ? `${30 + 30 * valTaxDist.length}` : `1200`}
            height={mobileView ? '250' : `${50 + 50 * valTaxDist.length}`}
            minWidth="250"
            minHeight="250"
          >
            <CartesianGrid strokeDasharray="2 2" />
            {mobileView ? (
              <XAxis
                width={mobileView ? 0 : 265}
                height={mobileView ? 0 : 30}
                type="category"
                scale="band"
                dataKey="DESCR_NAME"
              />
            ) : (
              <XAxis
                type="number"
                tickFormatter={v => withCentsFormat(`${v}`)}
              />
            )}
            {!mobileView && (
              <YAxis
                width={mobileView ? 0 : 265}
                type="category"
                scale="band"
                dataKey="DESCR_NAME"
              />
            )}
            <Tooltip
              wrapperStyle={{
                top: mobileView ? '97.5%' : '0',
                zIndex: '50',
              }}
              formatter={(v, n) => [withCentsFormat(`${v}`), `${n}`]}
            />

            {!mobileView && <Legend align="center" />}
            <Bar
              dataKey="PREV_TAX"
              barSize={2}
              fill="rgb(13, 202, 240)"
              name={`${propTaxyear - 1} Taxes`}
            />
            <Bar
              dataKey="CUR_CERT_TAX"
              barSize={mobileView ? 12 : 15}
              fill="rgb(183, 169, 0)"
              name={`${propTaxyear} Taxes (Before Budget Changes)`}
            />
            <Bar
              dataKey="CUR_TAX"
              barSize={mobileView ? 12 : 15}
              stroke="rgba(25, 135, 84, 1)"
              fill="rgba(25, 135, 84, 1)"
              name={`${propTaxyear} Taxes (After Proposed Budget Changes)`}
            />
          </BarChart>
        </ResponsiveContainer>
        {mobileView && (
          <p className="w-100 d-flex justify-content-center my-1 text-secondary">
            Tap Bars for Info
          </p>
        )}
      </Row>
    );
  return null;
};

const areEqual = (prevProps, nextProps) =>
  prevProps.valTaxDist === nextProps.valTaxDist;

export default React.memo(PropertyTaxDistributionChart, areEqual);
