const replaceall = require('replaceall');
const formatWithCents = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
const withCentsFormat = (amount = '0') => {
  let noCommas = parseFloat(replaceall(',', '', amount));
  return formatWithCents.format(noCommas);
};
const formatNoCents = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const noCentsFormat = (amount = '0') => {
  let noCommas = parseInt(replaceall(',', '', amount));
  return formatNoCents.format(noCommas);
};

export { withCentsFormat, noCentsFormat };
