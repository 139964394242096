import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { Search } from 'react-bootstrap-icons';

import { withCentsFormat } from '../../../../utilities/currency';
import { queryToString } from '../../../../utilities/url';
import { filterResults, sortResults } from '../../../../utilities/filterSort';
import { setCurrentID } from '../../../app/searchSlice';
import { setSidebarExpanded } from '../../sidebarSlice';
import { setSerial, setTaxyear } from '../../../app/taxDetail/taxDetailSlice';

const TaxHistorySearchList = () => {
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);

  const searchStatus = useSelector(state => state.search.status);
  const currentID = useSelector(state => state.search.currentID);
  const serial = useSelector(state => state.taxDetail.serial);
  const taxyear = useSelector(state => state.taxDetail.taxyear);
  const history = useHistory();
  const router = useSelector(state => state.router);
  const dispatch = useDispatch();
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const sortOptions = useSelector(state => state.sidebar.sortOptions);

  // load
  const rawResults = useSelector(state => state.search.searchResults);
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    let tempResults = [...rawResults];
    // filter
    tempResults = [...filterResults(filterOptions, tempResults)];
    // sort
    tempResults = [...sortResults(sortOptions, tempResults)];
    // update list with filtered/sorted results
    setSearchResults([...tempResults]);
  }, [rawResults, filterOptions, sortOptions]);

  // update currentID when rawResults changes
  useEffect(() => {
    if (searchStatus === 'succeeded' && searchResults && searchResults.length) {
      // console.log('searchResults:', searchResults);
      if (!currentID) {
        dispatch(setCurrentID({ currentID: `${searchResults[0].SERIAL}` }));
        const query = queryToString({
          query: router.location.query,
          currentID: `${searchResults[0].SERIAL}`,
        });
        history.replace(`${router.location.pathname}?${query}`);
      }
      if (!serial) dispatch(setSerial(`${searchResults[0].SERIAL}`));
      if (!taxyear) dispatch(setTaxyear(searchResults[0].TAX_YEAR));
    }
  }, [searchResults]);

  return (
    <>
      {/* show search loading */}
      {searchStatus === 'loading' && !searchResults && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '10rem', width: '10rem' }}
          />
        </Row>
      )}
      {/* show search results list */}
      {searchStatus === 'succeeded' &&
        searchResults &&
        searchResults.length > 0 && (
          <div className="position-relative overflow-auto d-flex flex-column flex-grow-1">
            <div className="stickyTop" style={{ zIndex: '110' }}>
              <Row
                className={
                  sidebarExpanded
                    ? `w-100 p-2 border-bottom border-primary text-primary d-flex justify-content-center align-items-center${
                        mobileView ? ' flex-column' : ''
                      }`
                    : 'd-none'
                }
                style={{
                  background: '#e0dff8',
                  fontWeight: '600',
                  fontFamily: 'Calibri, sans-serif',
                  lineHeight: '1.15rem',
                }}
                noGutters
              >
                <span
                  className={mobileView ? '' : 'mr-2'}
                  style={{ fontWeight: '400' }}
                >
                  Tax History for Serial/Parcel:
                </span>
                <span>
                  {searchResults[0].SERIAL_FORMATTED
                    ? searchResults[0].SERIAL_FORMATTED
                    : '-'}
                </span>
              </Row>
              <Row
                className={
                  sidebarExpanded
                    ? `w-100 border-top border-bottom${
                        mobileView ? ' p-1' : ' py-1 px-2'
                      }`
                    : 'd-none'
                }
                style={{
                  background: '#e9ecef',
                  fontWeight: '600',
                  fontFamily: 'Calibri, sans-serif',
                  marginBottom: mobileView ? '2.25px' : '2px',
                }}
                noGutters
              >
                <Col xs={2} sm={2} className="p-1 d-flex align-items-center">
                  <Row noGutters className="w-100">
                    Year
                  </Row>
                </Col>
                <Col xs={5} sm={5} className="p-1 d-flex align-items-center">
                  <Row noGutters className="w-100 d-flex justify-content-end">
                    General Taxes
                  </Row>
                </Col>
                <Col xs={5} sm={5} className="p-1 d-flex align-items-center">
                  <Row noGutters className="w-100 d-flex justify-content-end">
                    Taxes Due
                  </Row>
                </Col>
              </Row>
            </div>
            <div
              id="searchResultsList"
              className={sidebarExpanded ? 'w-100 h-100 flex-grow-1' : 'd-none'}
              style={{
                fontWeight: '100',
                fontFamily: 'Calibri, sans-serif',
                marginTop: '-2px',
              }}
            >
              {searchResults.map((result, index) => {
                return (
                  <Card
                    key={index}
                    className="w-100 rounded-0 border-0 border-top"
                    // style={{ marginTop: '-1px' }}
                  >
                    <Card.Header className="w-100 p-1 d-flex flex-row">
                      <Col
                        xs={2}
                        sm={2}
                        className="p-1 d-flex flex-column align-items-center"
                      >
                        <Row noGutters className="w-100 p-1">
                          {result.TAX_YEAR ? (
                            <a
                              className="d-flex"
                              style={{
                                textUnderlineOffset: '0.125rem',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              // eventKey={`${index}`}
                              onClick={() => {
                                if (mobileView)
                                  dispatch(
                                    setSidebarExpanded({
                                      sidebarExpanded: false,
                                    })
                                  );
                                // setCurrentActiveKey(
                                //   currentActiveKey === index ? null : index
                                // );
                                if (
                                  currentID !== `${searchResults[index].SERIAL}`
                                ) {
                                  dispatch(
                                    setCurrentID({
                                      currentID: `${searchResults[index].SERIAL}`,
                                    })
                                  );
                                  const query = queryToString({
                                    query: router.location.query,
                                    currentID: `${searchResults[index].SERIAL}`,
                                  });
                                  history.push(
                                    `${router.location.pathname}?${query}`
                                  );
                                }

                                if (serial !== `${searchResults[index].SERIAL}`)
                                  dispatch(
                                    setSerial(`${searchResults[index].SERIAL}`)
                                  );

                                if (taxyear !== searchResults[index].TAX_YEAR)
                                  dispatch(
                                    setTaxyear(searchResults[index].TAX_YEAR)
                                  );
                              }}
                            >
                              {result.TAX_YEAR}
                            </a>
                          ) : (
                            '-'
                          )}
                        </Row>
                      </Col>
                      <Col
                        xs={5}
                        sm={5}
                        className="p-1 d-flex align-items-center"
                      >
                        <Row
                          noGutters
                          className="w-100 d-flex justify-content-end"
                        >
                          {!isNaN(parseFloat(`${result.ASMT_TAXES}`))
                            ? withCentsFormat(`${result.ASMT_TAXES}`)
                            : '-'}
                        </Row>
                      </Col>
                      <Col
                        xs={5}
                        sm={5}
                        className="p-1 d-flex align-items-center"
                      >
                        <Row
                          noGutters
                          className="w-100 d-flex justify-content-end"
                        >
                          {!isNaN(parseFloat(`${result.TAX_BALANCE}`))
                            ? withCentsFormat(`${result.TAX_BALANCE}`)
                            : '-'}
                        </Row>
                      </Col>
                    </Card.Header>
                  </Card>
                );
              })}
            </div>
          </div>
        )}
      {/* show no result message */}
      {searchStatus === 'succeeded' &&
        searchResults &&
        searchResults.length === 0 && (
          <Row
            noGutters
            id="sidebarResults"
            className={
              sidebarExpanded
                ? 'w-100 h-100 d-flex flex-column flex-grow-1'
                : 'd-none'
            }
            style={{
              backgroundColor: 'rgba(101, 167, 219, 0.25)',
            }}
          >
            <h5
              className="mx-auto w-75 d-flex flex-column h-100 align-items-center"
              style={{
                marginTop: '25%',
              }}
            >
              <Search
                height={mobileView ? '4rem' : '8rem'}
                width={mobileView ? '4rem' : '8rem'}
                style={{ color: 'rgba(101, 167, 219, 1)' }}
              />
              <p className="mb-1 mt-4 text-center">{`No tax history results found.`}</p>
              <p className="mb-0 mt-2 text-center">{`Please update your filter parameters or try another search.`}</p>
            </h5>
          </Row>
        )}
    </>
  );
};

export default TaxHistorySearchList;
