import { getOffsetDate } from './date';
const replaceall = require('replaceall');

// name parser
const parseName = name => {
  let namePieces = name.split(',').map(x => x.trim());
  if (namePieces.length > 1) {
    return name;
  } else if (namePieces.length === 1) {
    let np = namePieces[0].split(' ');
    return `${np.slice(-1)}, ${np.slice(0, -1).join(' ')}`;
  }
};

// serial number check
// _normalize takes an input string and returns a valid serial number that can
// be used in our oracle database for functions.
// It replaces the old normalize function to avoid unnecessary database calls.
const _normalize = input => {
  // convert input to a string and store in 'inStr'
  let inStr = input.toString();
  // convert input to a base-10 integer in 'inInt'
  let inInt = parseInt(input, 10);
  // convert the 'inInt' variable back into a formatted
  // 9-digit string with front padding of zeros
  let strInInt = inInt.toString().padStart(9, '0');

  // First Case: Check if a number was passed in and return it if it's valid
  // if ( the input converted to a string exactly equals the padded string OR
  //		( the padded string starts with a zero AND
  //			the input string exactly equals the padded string without its first 0
  // 		)
  // 	  ) AND the integer value of the input is within [10010001, 999999999]
  if (
    (inStr === strInInt ||
      (strInInt[0] === '0' && inStr === strInInt.slice(1))) &&
    inInt >= 10010001 &&
    inInt <= 999999999
  ) {
    // then return the valid serial number
    return inInt;
  }

  // Second Case: Check for valid serial numbers of alternate formats
  // create a match object that checks for a regular expression where
  // periods, commas, colons, semicolons, and spaces can exist between
  // the book, page, and id
  let m = inStr.match(
    /^(\d{1,2})[\.:,; ]{1}(?!\D)(\d{1,3})[\.:,; ]{1}(?!\D)(\d{1,4})$/
  );
  // if there is a match and each subsection is valid (!== 0)
  // and that there are 3 subsections (m.length === 4)
  if (
    m &&
    m.length === 4 &&
    parseInt(m[1], 10) % 100 > 0 &&
    parseInt(m[2], 10) % 1000 > 0 &&
    parseInt(m[3], 10) % 10000 > 0
  ) {
    // return a valid serial number
    return parseInt(
      `${m[1]}${m[2].padStart(3, '0')}${m[3].padStart(4, '0')}`,
      10
    );
  }

  // Case 3: invalid serial number
  return 0;
};

// getURL
const getUrlFromFormValues = formVals => {
  let name;
  return Object.entries(formVals)
    .map(([inputKey, input]) => {
      if (input.value === null || input.value === '') return '';
      else if (input.type === 'date')
        return `${inputKey}=${getOffsetDate(input.value).toLocaleDateString(
          'en-US',
          {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }
        )}`;
      else if (input.namePart) {
        if (!name) name = [];
        if (inputKey === 'lastName') name[0] = input.value.trim();
        if (inputKey === 'firstName') name[1] = input.value.trim();
      } else if (
        input.validators &&
        input.validators.find(v => v.type === 'number')
      ) {
        const inputValue = parseFloat(
          replaceall(',', '', `${input.value.trim()}`)
        );
        return `${inputKey}=${inputValue}`;
      } else return `${inputKey}=${input.value.trim()}`;
    })
    .concat([''])
    .filter(i => !!i)
    .concat([name ? `name=${name.join(', ')}` : ''])
    .filter(i => !!i)
    .join('&');
};

// validate
const validateFormInput = formVals => {
  const formEmpty =
    !!formVals &&
    Object.values(formVals).filter(v => !!v.value && v.value !== '').length ===
      0;
  if (!!formVals && Object.values(formVals)[0].emptyCheck && formEmpty)
    return Object.keys(formVals).reduce((errors, inputKey) => {
      errors[inputKey] = {
        message: 'Please add a value for at least one field.',
      };
      return errors;
    }, {});
  if (!!formVals)
    return Object.entries(formVals).reduce((errors, [inputKey, input]) => {
      if (input.validators) {
        for (const {
          type,
          message,
          min,
          max,
          dependentKey,
        } of input.validators) {
          const noValueSet = !input.value || input.value === '';
          switch (type) {
            case 'empty':
              if (!errors[inputKey] && formEmpty)
                errors[inputKey] = { message: message };
              break;
            case 'required':
              if (!errors[inputKey] && noValueSet)
                errors[inputKey] = { message: message };
              break;
            case 'dependentRequired':
              if (
                !errors[dependentKey] &&
                !noValueSet &&
                (formVals[dependentKey].value === '' ||
                  formVals[dependentKey].value === null)
              )
                errors[dependentKey] = { message: message };
              break;
            case 'length':
              if (!errors[inputKey] && !noValueSet && input.value.length < min)
                errors[inputKey] = { message: message };
              break;
            case 'number':
              if (
                !errors[inputKey] &&
                !noValueSet &&
                (isNaN(parseInt(input.value, 10)) ||
                  (!isNaN(parseInt(min, 10)) &&
                    parseInt(input.value, 10) < min) ||
                  (!isNaN(parseInt(max, 10)) &&
                    parseInt(input.value, 10) > max))
              )
                errors[inputKey] = { message: message };
              break;
            case 'serial':
              if (
                !errors[inputKey] &&
                !noValueSet &&
                _normalize(input.value) === 0
              )
                errors[inputKey] = { message: message };
              break;
            case 'dateValid':
              if (
                !errors[inputKey] &&
                !noValueSet &&
                (isNaN(Date.parse(input.value)) ||
                  new Date(input.value).getTime() > new Date().getTime())
              )
                errors[inputKey] = { message: message };
              break;
            case 'dateCompare':
              if (
                !errors[inputKey] &&
                !noValueSet &&
                !isNaN(Date.parse(formVals[min].value)) &&
                new Date(input.value).getTime() <
                  new Date(formVals[min].value).getTime()
              )
                errors[inputKey] = { message: message };
              break;
            default:
              console.log('invalid validator option');
          }
        }
      }

      return errors;
    }, {});
};

/* 
  valid: true | false,
  formFields: {
    name: 'error message',
  }
*/

export { getUrlFromFormValues, validateFormInput, _normalize };
