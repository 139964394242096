import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

import { ChevronDown, ChevronUp, Search } from 'react-bootstrap-icons';

import { setSidebarExpanded } from '../../sidebarSlice';
import { selectDefaultMainSearch } from '../../../app/defaultSearchesSlice';
import { setCurrentID } from '../../../app/searchSlice';

import { queryToString } from '../../../../utilities/url';
import { filterResults, sortResults } from '../../../../utilities/filterSort';

const DocumentWaterRightsSearchList = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const mainSearchInfo = useSelector(selectDefaultMainSearch(mainSearch));
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const [currentActiveKey, setCurrentActiveKey] = useState(null);
  const dispatch = useDispatch();
  const currentID = useSelector(state => state.search.currentID);
  const history = useHistory();
  const router = useSelector(state => state.router);
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const sortOptions = useSelector(state => state.sidebar.sortOptions);

  const searchStatus = useSelector(state => state.search.status);
  // load
  const rawResults = useSelector(state => state.search.searchResults);
  const [searchResults, setSearchResults] = useState(rawResults.slice());

  useEffect(() => {
    let tempResults = [...searchResults];
    // filter
    tempResults = [...filterResults(filterOptions, tempResults)];
    // sort
    tempResults = [...sortResults(sortOptions, tempResults)];
    // update list with filtered/sorted results
    setSearchResults([...tempResults]);
  }, [filterOptions, sortOptions]);

  // update currentID when page loads
  useEffect(() => {
    if (
      searchStatus === 'succeeded' &&
      searchResults.length > 0 &&
      !currentID
    ) {
      dispatch(
        setCurrentID({
          currentID: `${searchResults[0].RJ_ENTRY_NO}-${searchResults[0].RJ_ENTRY_YR}`,
        })
      );
    }
  }, [searchResults]);
  return (
    <>
      {/* show search loading */}
      {searchStatus === 'loading' && !searchResults && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '10rem', width: '10rem' }}
          />
        </Row>
      )}
      {/* show search results list */}
      {searchStatus === 'succeeded' &&
        searchResults &&
        searchResults.length > 0 && (
          <>
            <Row
              className={
                sidebarExpanded
                  ? 'w-100 px-0 py-2 border-top border-bottom'
                  : 'd-none'
              }
              style={{
                background: '#e9ecef',
                fontWeight: '600',
                fontFamily: 'Calibri, sans-serif',
                marginBottom: '2px',
              }}
              noGutters
            >
              <Col xs={4} sm={3} className="pl-3 pr-0">
                Entry No
              </Col>
              <Col xs={8} sm={9} className="px-0">
                Legal Description
              </Col>
              <Col xs={4} sm={3} className="pl-3 pr-0">
                Kind of Inst
              </Col>
              <Col xs={8} sm={9} className="px-0">
                Recorded Date
              </Col>
            </Row>
            <Accordion
              className={
                sidebarExpanded
                  ? 'w-100 overflow-auto border-bottom shadow-sm'
                  : 'd-none'
              }
              style={{
                fontWeight: '100',
                fontFamily: 'Calibri, sans-serif',
              }}
            >
              {searchResults.map((result, index) => {
                return (
                  <Card
                    key={index}
                    className="w-100 rounded-0 border-right-0 border-left-0"
                    style={{ marginTop: '-1px' }}
                  >
                    <Card.Header className="w-100 px-1 d-flex flex-row border-bottom-0">
                      <Col
                        xs={4}
                        sm={3}
                        className="px-0 d-flex flex-column align-items-center"
                      >
                        <Row noGutters className="w-100 text-left">
                          {result.RJ_ENTRY_NO && result.RJ_ENTRY_YR ? (
                            <Accordion.Toggle
                              as="a"
                              eventKey={`${index}`}
                              className="py-0 px-2 ml-1 border-0 d-flex text-left"
                              style={{
                                textUnderlineOffset: '0.125rem',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                if (mobileView)
                                  dispatch(
                                    setSidebarExpanded({
                                      sidebarExpanded: false,
                                    })
                                  );
                                setCurrentActiveKey(
                                  index !== currentActiveKey ? index : null
                                );
                                if (
                                  currentID !==
                                  `${result.RJ_ENTRY_NO}-${result.RJ_ENTRY_YR}`
                                ) {
                                  dispatch(
                                    setCurrentID({
                                      currentID: `${result.RJ_ENTRY_NO}-${result.RJ_ENTRY_YR}`,
                                    })
                                  );
                                  const query = queryToString({
                                    query: router.location.query,
                                    currentID: `${result.RJ_ENTRY_NO}-${result.RJ_ENTRY_YR}`,
                                  });
                                  history.push(
                                    `${router.location.pathname}?${query}`
                                  );
                                }
                              }}
                            >
                              {`${result.RJ_ENTRY_NO}-${result.RJ_ENTRY_YR}`}
                            </Accordion.Toggle>
                          ) : (
                            '-'
                          )}
                        </Row>
                        <Row noGutters className="pl-2 ml-2 w-100">
                          {result.KOI}
                        </Row>
                      </Col>
                      <Col
                        xs={7}
                        sm={8}
                        className="pl-0 pr-1 d-flex flex-column align-items-center"
                      >
                        <Row noGutters className="w-100">
                          <span
                            className={`py-1 ${
                              currentActiveKey === index ? '' : 'text-truncate'
                            }`}
                            style={{
                              lineHeight: '1.15rem',
                              // marginBottom: '0.5rem',
                            }}
                          >
                            {result.DESCR ? result.DESCR : '-'}
                          </span>
                        </Row>
                        <Row noGutters className="w-100">
                          <span
                            className={
                              currentActiveKey === index ? '' : 'text-truncate'
                            }
                            style={{ lineHeight: '1.15rem' }}
                          >
                            {result.RJ_DATE
                              ? `${new Date(
                                  result.RJ_DATE
                                ).toLocaleDateString()} ${new Date(
                                  result.RJ_DATE
                                ).toLocaleTimeString('en-US', {
                                  hour: 'numeric',
                                  minute: '2-digit',
                                })}`
                              : '-'}
                          </span>
                        </Row>
                      </Col>
                      <Col
                        xs={1}
                        sm={1}
                        className="px-0 d-flex align-items-center justify-content-center"
                      >
                        <Accordion.Toggle
                          as="a"
                          onClick={() =>
                            setCurrentActiveKey(
                              index !== currentActiveKey ? index : null
                            )
                          }
                          className="py-0 px-1"
                          eventKey={`${index}`}
                          style={{ cursor: 'pointer' }}
                          // disabled={currentActiveKey === index}
                        >
                          {currentActiveKey === index ? (
                            <ChevronUp />
                          ) : (
                            <ChevronDown />
                          )}
                        </Accordion.Toggle>
                      </Col>
                    </Card.Header>
                    <Accordion.Collapse className="px-3" eventKey={`${index}`}>
                      <div className="d-flex flex-column w-100">
                        <Row noGutters className="w-100">
                          <Table
                            responsive
                            size="sm"
                            className="w-100 my-2"
                            style={{ lineHeight: '1.15rem' }}
                          >
                            <tbody>
                              <tr>
                                <th className="border-top-0 mt-1 py-1 pl-0 pr-2 border-right">
                                  Grantor
                                </th>
                                <td className="border-top-0 py-1 pr-0 pl-3">
                                  {result.PARTY1NAME
                                    ? result.PARTY1NAME
                                    : result.P1
                                    ? result.P1
                                    : '-'}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-top-0 py-1 pl-0 pr-2 border-right">
                                  ..Grantee
                                </th>
                                <td className="border-top-0 py-1 pr-0 pl-3">
                                  {result.PARTY2NAME
                                    ? `..${result.PARTY2NAME}`
                                    : result.P2
                                    ? `..${result.P2}`
                                    : '-'}
                                </td>
                              </tr>
                              <tr>
                                <th className="border-top-0 py-1 pl-0 pr-2 border-right">
                                  Book/Page
                                </th>
                                <td className="border-top-0 py-1 pr-0 pl-3">
                                  {result.RJ_BOOK && result.RJ_PAGE
                                    ? `${result.RJ_BOOK}-${result.RJ_PAGE}`
                                    : `-`}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Row>
                      </div>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
            </Accordion>
          </>
        )}
      {/* show no result message */}
      {searchStatus === 'succeeded' &&
        searchResults &&
        searchResults.length === 0 && (
          <Row
            noGutters
            id="sidebarResults"
            className={
              sidebarExpanded
                ? 'w-100 h-100 d-flex flex-column flex-grow-1'
                : 'd-none'
            }
            style={{
              backgroundColor: 'rgba(101, 167, 219, 0.25)',
            }}
          >
            <h5
              className="mx-auto w-75 d-flex flex-column h-100 align-items-center"
              style={{
                marginTop: '25%',
              }}
            >
              <Search
                height={mobileView ? '4rem' : '8rem'}
                width={mobileView ? '4rem' : '8rem'}
                style={{ color: 'rgba(101, 167, 219, 1)' }}
              />
              <p className="mb-1 mt-4 text-center">{`No ${mainSearchInfo.titleSingle.toLowerCase()} results found.`}</p>
              <p className="mb-0 mt-2 text-center">{`Please update your filter parameters or try another search.`}</p>
            </h5>
          </Row>
        )}
    </>
  );
};

export default DocumentWaterRightsSearchList;
