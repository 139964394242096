import React from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavLink from 'react-bootstrap/NavLink';

import FeedbackModal from './feedbackModal/FeedbackModal';

const SiteFooter = () => (
  <Navbar
    id="site-footer"
    bg="dark"
    variant="dark"
    expand={false}
    // className="position-static"
  >
    <Row
      className="w-100 m-0 px-0 px-md-2 py-1 d-flex justify-content-between"
      noGutters
    >
      <Col
        md={3}
        xs={3}
        className="w-100 p-0 d-flex justify-content-start align-items-center"
      >
        <NavLink
          className="px-0"
          href="https://www.utahcounty.gov/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {new Date().getFullYear()} © Utah County
        </NavLink>
      </Col>
      <Col
        md={3}
        xs={2}
        className="w-50 p-0 d-flex flex-column justify-content-center align-items-center"
      >
        <NavLink
          className="px-0"
          href="https://www.utahcounty.gov/LandRecords/Index.asp"
          target="_blank"
          rel="noopener noreferrer"
        > Legacy Site</NavLink>
      </Col>
      <Col
        md={3}
        xs={6}
        className="w-100 p-0 d-flex flex-column justify-content-center align-items-end"
      >
        <FeedbackModal />
        {/* <NavLink className="py-1 px-0" href="tel:8018518000">
          (801) 851-8000
        </NavLink> */}
      </Col>
    </Row>
  </Navbar>
);

export default SiteFooter;
