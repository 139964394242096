// external imports
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// external components
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import {
  BoxArrowUpRight,
  CaretDownFill,
  CaretUpFill,
  Search,
} from 'react-bootstrap-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAppStoreIos,
  faGooglePlay,
} from '@fortawesome/free-brands-svg-icons';
// app state store imports
import {
  updateSearch,
  setSearchActive,
  resetSearchResults,
  fetchResults,
  updateSubSearchIndex,
  setSearchDidRun,
  updateSavedSearch,
  setCurrentID,
  setSearchResultsScrollTop,
} from '../searchSlice';
import {
  setSidebarExpanded,
  setFilterOptions,
  setSortOptions,
  setFilterActive,
  setSortActive,
  setSidebarLoading,
} from '../../sidebar/sidebarSlice';
import { emptyPropertyImages } from '../propertyImages/propertyImagesSlice';
import {
  getUrlFromFormValues,
  validateFormInput,
} from '../../../utilities/searchForm';
import {
  setSearchFormValues,
  setActiveSearchFormGroup,
} from './searchFormSlice';
import LastAbstractInfo from './lastAbstractInfo/LastAbstractInfo';
import { emptyPropVals } from '../../detailView/propertyDetailView/propertyValuationModal/propertyValuationModalSlice';
import { setDetailViewLoading } from '../../detailView/detailViewSlice';

const SearchForm = () => {
  // imported utilities
  const dispatch = useDispatch();
  const history = useHistory();
  // app state variables
  const mainSearch = useSelector(state => state.search.mainSearch);
  const subSearch = useSelector(state => state.search.subSearch);
  const allSearches = useSelector(state => state.defaultSearches);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const router = useSelector(state => state.router);
  const pathname = router.location.pathname;
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const allCities = useSelector(state => state.searchForm.allCities);
  const allKoiCodeGroups = useSelector(
    state => state.searchForm.allKoiCodeGroups
  );
  const searchFormValues = useSelector(
    state => state.searchForm.searchFormValues
  );
  const activeSearchFormGroup = useSelector(
    state => state.searchForm.activeSearchFormGroup
  );
  // local state variables
  const [_mainSearch, set_mainSearch] = useState(mainSearch);
  const [_subSearch, set_subSearch] = useState(subSearch);
  const [mainSearchExpaned, setMainSearchExpanded] = useState(false);
  const [validated, setValidated] = useState([]);
  const [formErrors, setFormErrors] = useState(null);
  const [activeGroup, setActiveGroup] = useState(activeSearchFormGroup);
  const [activeKoiCodeGroup, setActiveKoiCodeGroup] = useState('');
  const [firstInputId, setFirstInputId] = useState('');

  const getOptions = dropdownListReference => {
    switch (dropdownListReference) {
      case 'allCities':
        return allCities;
      case 'allKoiCodeGroups':
        return allKoiCodeGroups;
      case 'koiCodes':
        const koiCodeList = allKoiCodeGroups.find(
          k => k.value === activeKoiCodeGroup
        );
        return koiCodeList
          ? koiCodeList.kois
          : [{ title: '-- All --', value: '', group: '' }];
      default:
        return { title: 'List Load Error', value: '' };
    }
  };

  const getSubSearchesFromMain = __mainSearch =>
    allSearches[__mainSearch].subSearches;
  const getSubSearchInfo = (__mainSearch, __subSearch) =>
    getSubSearchesFromMain(__mainSearch)[__subSearch];
  const [activeSubSearch, setActiveSubSearch] = useState(
    pathname === '/' &&
      Object.keys(getSubSearchesFromMain(_mainSearch)).length > 1
      ? null
      : _subSearch
  );
  // check search inputs
  const [formValues, setFormValues] = useState(
    _mainSearch === 'maps' ||
      (_mainSearch === 'documents' && _subSearch === 'lastAbstract')
      ? null
      : searchFormValues
      ? [...searchFormValues]
      : getSubSearchInfo(_mainSearch, _subSearch)
      ? [...getSubSearchInfo(_mainSearch, _subSearch).inputFields]
      : null
  );
  // update input
  const updateFormValue = e => {
    const [type, search, group, key] = e.target.id.split('-');
    let _formValues = [...formValues];
    // special case for koigroup input
    if (key === 'koigroup') {
      if (Object.keys(_formValues[group]).includes('koi')) {
        _formValues[group] = {
          ..._formValues[group],
          ['koi']: {
            ..._formValues[group]['koi'],
            value: '',
          },
        };
      }
      setActiveKoiCodeGroup(e.target.value);
    }
    // special case for koi input
    if (key === 'koi') {
      const groupReference = allKoiCodeGroups
        .find(g => g.value === '')
        .kois.find(k => k.value === e.target.value).groupRef;
      _formValues[group] = {
        ..._formValues[group],
        ['koigroup']: {
          ..._formValues[group]['koigroup'],
          value: groupReference,
        },
      };
      setActiveKoiCodeGroup(groupReference);
    }
    _formValues[group] = {
      ..._formValues[group],
      [key]: {
        ..._formValues[group][key],
        value: e.target.value,
      },
    };
    setFormValues([..._formValues]);
    setFormErrors(validateFormInput(_formValues[activeGroup]));
  };

  // used to control whether the arrow for the main search menu
  // points up or down in mobile view
  const mainNavbarToggle = e => {
    if (mobileView) setMainSearchExpanded(!mainSearchExpaned);
  };
  // updates main & sub searches & resets search input
  const handleMainSearchSelect = mainSearchKey => {
    // toggle arrow if mobile view
    mainNavbarToggle();
    if (mainSearchKey !== _mainSearch) {
      // reset mainSearch
      set_mainSearch(mainSearchKey);
      // reset subSearch
      const subSearches = Object.keys(getSubSearchesFromMain(mainSearchKey));
      const __subSearch = subSearches[0];
      set_subSearch(__subSearch);
      setActiveSubSearch(subSearches.length === 1 ? __subSearch : '');
      if (mainSearchKey === 'maps') return;
      // reset form
      const _inputFields = [
        ...getSubSearchInfo(mainSearchKey, __subSearch).inputFields,
      ];
      setFormValues(_inputFields);
      const [firstSubInputKey, firstSubInput] = Object.entries(
        _inputFields[0]
      )[0];
      setFirstInputId(
        `${firstSubInput.type}-${__subSearch}-0-${firstSubInputKey}`
      );
      // firstInput.current.focus();
      // console.log('firstInput.current:', firstInput.current);
      // reset validation
      let _validated = validated.map(() => false);
      _validated[activeGroup] = true;
      setValidated(_validated);
      setValidated(_inputFields.map(() => false));
    }
  };
  // updates sub search & resets search input
  const handleSubSearchSelect = subSearchKey => {
    if (subSearchKey !== _subSearch) {
      // reset subSearch
      set_subSearch(subSearchKey);
      setActiveSubSearch(subSearchKey);
      if (_mainSearch === 'maps') return;
      // reset form
      const _inputFields = [
        ...getSubSearchInfo(_mainSearch, subSearchKey).inputFields,
      ];
      setFormValues(_inputFields);
      const [firstSubInputKey, firstSubInput] = Object.entries(
        _inputFields[0]
      )[0];
      setFirstInputId(
        `${firstSubInput.type}-${subSearchKey}-0-${firstSubInputKey}`
      );
      // reset validation
      let _validated = validated.map(() => false);
      _validated[activeGroup] = true;
      setValidated(_validated);
      setValidated(_inputFields.map(() => false));
    } else setActiveSubSearch(!activeSubSearch ? subSearchKey : null);
  };
  // handle form submission
  const handleFormSubmit = e => {
    // console.log('submit clicked...');
    e.preventDefault();
    e.stopPropagation();
    // validate input
    const _formErrors = validateFormInput({ ...formValues[activeGroup] });
    if (Object.values(_formErrors).some(() => true)) {
      setFormErrors(_formErrors);
      let _validated = validated.map(() => false);
      _validated[activeGroup] = true;
      setValidated(_validated);
      return;
    } else setFormErrors(null);
    // build query string
    const _queryString = getUrlFromFormValues({ ...formValues[activeGroup] });
    // console.log('_queryString:', _queryString);
    // initiate UI search
    dispatch(setSidebarLoading(true));
    dispatch(setDetailViewLoading(true));
    // reset currentID
    dispatch(setCurrentID({ currentID: null }));
    // run search
    dispatch(updateSearch({ mainSearch: _mainSearch, subSearch: _subSearch }));
    dispatch(
      fetchResults({
        url: baseApiUrl,
        endpoint: getSubSearchInfo(_mainSearch, _subSearch).inputApiEndpoints[
          activeGroup
        ],
        queryString: _queryString,
      })
    );
    dispatch(setSearchDidRun({ searchDidRun: true }));
    dispatch(resetSearchResults({}));
    dispatch(emptyPropertyImages({}));
    dispatch(emptyPropVals());
    dispatch(setSearchActive({ searchActive: false }));
    dispatch(setFilterActive({ filterActive: false }));
    dispatch(setSortActive({ sortActive: false }));
    dispatch(setSidebarExpanded({ sidebarExpanded: true }));
    dispatch(setFilterOptions(null));
    dispatch(setSortOptions(null));
    dispatch(updateSubSearchIndex({ subSearchIndex: activeGroup }));
    dispatch(setSearchResultsScrollTop({ searchResultsScrollTop: 0 }));
    dispatch(setSearchFormValues([...formValues]));
    dispatch(setActiveSearchFormGroup(activeGroup));
    dispatch(
      updateSavedSearch({ mainSearch: _mainSearch, subSearch: _subSearch })
    );
    history.push(
      `/${_mainSearch}/${_subSearch}/${activeGroup}?${_queryString}`
    );
  };

  useEffect(() => {
    if (formValues) {
      const [firstSubInputKey, firstSubInput] = Object.entries(
        formValues[activeGroup]
      )[0];
      setFirstInputId(
        `${firstSubInput.type}-${_subSearch}-${activeGroup}-${firstSubInputKey}`
      );
    }
  }, []);

  useEffect(() => {
    if (firstInputId !== '' && activeSubSearch !== null) {
      // defer update till next pass of javascript event loop
      setTimeout(() => {
        const firstInputElement = document.getElementById(firstInputId);
        if (firstInputElement) firstInputElement.focus();
      }, 1);
    }
  }, [firstInputId, activeSubSearch]);

  return (
    <>
      <Row
        className={
          mobileView
            ? 'searchMenuNavbarContainerMobile'
            : 'searchMenuNavbarContainerDesktop'
        }
        noGutters
      >
        <Navbar
          collapseOnSelect
          expand={mobileView ? false : 'md'}
          id="homeMenuNavbar"
          variant="light"
          bg={mobileView ? 'transparent' : 'white'}
          className={
            mobileView ? 'p-0 mx-0 w-100' : 'rounded-top p-0 mx-0 w-100'
          }
        >
          <Navbar.Toggle
            id="homeMobileToggle"
            className="text-dark btn btn-white bg-white shadow-sm py-2 w-100"
            onClick={mainNavbarToggle}
            label={allSearches[_mainSearch].title}
          >
            <span>{allSearches[_mainSearch].title + ' '}</span>
            {mainSearchExpaned ? <CaretUpFill /> : <CaretDownFill />}
          </Navbar.Toggle>
          <Navbar.Collapse
            // id="homeMenuCollapse"
            className={
              mobileView
                ? 'mt-0 py-1 w-100 px-2 px-md-0 shadow-sm homeMenuCollapse homeMenuCollapseMobile'
                : 'mt-0 w-100 px-2 px-md-0 homeMenuCollapse homeMenuCollapseDesktop'
            }
          >
            <Nav
              id="homeMenu"
              justify
              variant={mobileView ? 'pills' : 'tabs'}
              activeKey={_mainSearch}
              onSelect={handleMainSearchSelect}
              className={
                mobileView
                  ? 'px-0 mx-0 homeMenu homeMenuMobile'
                  : `px-0 mx-0 homeMenu homeMenuDesktop${
                      pathname === '/' ? ' homeMenuDesktopHome' : ''
                    }`
              }
            >
              {Object.entries(allSearches).map(
                ([__mainSearchKey, __mainSearch]) => (
                  <Nav.Item key={__mainSearchKey}>
                    <Nav.Link
                      eventKey={__mainSearchKey}
                      className={
                        mobileView
                          ? 'text-dark btn btn-light shadow-sm mx-md-2 my-1'
                          : 'text-dark mt-0'
                      }
                    >
                      {__mainSearch.title}
                    </Nav.Link>
                  </Nav.Item>
                )
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Row>
      <Row className="text-left mt-0 mx-0 mb-3 w-100" noGutters>
        <div className="w-100 rounded-bottom shadow">
          <Accordion
            className="w-100 rounded-bottom"
            activeKey={activeSubSearch}
          >
            {Object.entries(getSubSearchesFromMain(_mainSearch)).map(
              ([__subSearchKey, __subSearch], index) => {
                return (
                  <Card
                    key={index}
                    style={{ background: 'rgba(255,255,255,0.5)' }}
                  >
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={__subSearchKey}
                      id={__subSearchKey}
                      onClick={
                        _mainSearch === 'maps'
                          ? e => {
                              if (__subSearchKey === 'appLinks') return;
                              const linkUrl =
                                __subSearchKey === 'propNotification'
                                  ? 'https://maps.utahcounty.gov/PropertyNotification/PropertyNotification.htm'
                                  : __subSearchKey === 'parcel'
                                  ? 'https://maps.utahcounty.gov/ParcelMap/ParcelMap.html'
                                  : 'https://utah-county-gis-maps-and-data-utahcounty.hub.arcgis.com/';
                              dispatch(
                                updateSavedSearch({
                                  mainSearch: 'maps',
                                  subSearch: __subSearchKey,
                                })
                              );
                              window.open(
                                linkUrl,
                                '_blank',
                                'noopener,noreferrer'
                              );
                            }
                          : _mainSearch === 'documents' &&
                            __subSearchKey === 'lastAbstract'
                          ? e => {
                              // set_subSearch(__subSearchKey);
                              if (activeSubSearch !== 'lastAbstract') {
                                setActiveSubSearch('lastAbstract');
                                dispatch(
                                  updateSavedSearch({
                                    mainSearch: 'documents',
                                    subSearch: 'lastAbstract',
                                  })
                                );
                              } else
                                setActiveSubSearch(
                                  !activeSubSearch ? __subSearchKey : null
                                );
                            }
                          : e => handleSubSearchSelect(__subSearchKey)
                      }
                      className={`pb-1 px-3${
                        __subSearchKey === 'appLinks'
                          ? ' bg-light'
                          : ' subSearchHeader'
                      }`}
                    >
                      <h5
                        className="mb-1"
                        style={{ fontSize: '1rem', lineHeight: '1.33rem' }}
                      >
                        {' '}
                        {_mainSearch === 'maps' &&
                          __subSearchKey !== 'appLinks' && (
                            <BoxArrowUpRight className="mr-2 mb-1" />
                          )}
                        {__subSearch.title}
                      </h5>
                      <p
                        className="mb-2 "
                        style={{
                          fontSize: '1rem',
                          fontFamily: 'Calibri, sans-serif',
                          lineHeight: '1rem',
                        }}
                      >
                        {__subSearch.description}
                      </p>
                      {_mainSearch === 'maps' && __subSearchKey === 'appLinks' && (
                        <>
                          <hr />
                          <Row
                            noGutters
                            className="w-100 px-0 pb-3 pt-1 d-flex justify-content-around"
                          >
                            {/* <Col
                              xs={12}
                              md={{ span: 3, offset: 2 }}
                              lg={{ span: 2, offset: 2 }}
                            > */}
                            <Button
                              className={`${
                                mobileView ? 'w-100 ' : ''
                              }d-flex align-items-center justify-content-center`}
                              style={{ maxHeight: '38px' }}
                              onClick={() =>
                                window.open(
                                  'http://itunes.apple.com/us/app/utah-co-maps/id533104189?mt=8',
                                  '_blank',
                                  'noopener,noreferrer'
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faAppStoreIos}
                                size="2x"
                                style={{
                                  maxHeight: '1.5rem',
                                  maxWidth: '1.5rem',
                                }}
                                className="mr-2"
                              />
                              iOS
                            </Button>
                            <Button
                              className={`${
                                mobileView ? 'w-100 ' : ''
                              }d-flex align-items-center justify-content-center`}
                              style={{
                                marginTop: mobileView ? '1rem' : '0',
                                maxHeight: '38px',
                              }}
                              onClick={() =>
                                window.open(
                                  'https://play.google.com/store/apps/details?id=gov.utahcounty.arcgis.ucparcelmap&feature=search_result#?t=W10',
                                  '_blank',
                                  'noopener,noreferrer'
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faGooglePlay}
                                size="2x"
                                style={{
                                  maxHeight: '1.5rem',
                                  maxWidth: '1.5rem',
                                }}
                                className="mr-2"
                              />
                              Android
                            </Button>
                            {/* </Col> */}
                          </Row>
                        </>
                      )}
                    </Accordion.Toggle>
                    {_mainSearch !== 'maps' && (
                      <Accordion.Collapse eventKey={__subSearchKey}>
                        <Card.Body className="p-0">
                          {__subSearchKey === 'lastAbstract' && (
                            <Row
                              noGutters
                              className="w-100 p-3 d-flex justify-content-center align-items-center"
                              // style={{backgroundColor: pathname !== }}
                            >
                              <LastAbstractInfo
                                loadNew={activeSubSearch === __subSearchKey}
                              />
                            </Row>
                          )}
                          {__subSearchKey !== 'lastAbstract' &&
                            __subSearch.inputFields.map(
                              (inputGroup, _index) => (
                                <Form
                                  noValidate
                                  onSubmit={handleFormSubmit}
                                  className="w-100 mx-0 px-3 pt-3 border-top border-primary"
                                  key={_index}
                                >
                                  <Form.Row className="inputsRow">
                                    <p className="inputsParagraph">
                                      {__subSearch.inputDescriptions[_index]}
                                    </p>
                                  </Form.Row>
                                  <Form.Row className="inputsRow">
                                    {Object.entries(inputGroup).map(
                                      ([inputKey, input], __index) => {
                                        const formLength = Object.keys(
                                          __subSearch.inputFields
                                        ).length;
                                        let inputField;
                                        switch (input.type) {
                                          case 'textbox':
                                            inputField = (
                                              <Form.Control
                                                type="text"
                                                className="border border-primary"
                                                id={`textbox-${__subSearchKey}-${_index}-${inputKey}`}
                                                autoComplete="off"
                                                isValid={
                                                  validated.length > 0 &&
                                                  _index === activeGroup &&
                                                  validated[activeGroup] &&
                                                  (!formErrors ||
                                                    !formErrors[inputKey])
                                                }
                                                isInvalid={
                                                  validated.length > 0 &&
                                                  _index === activeGroup &&
                                                  validated[activeGroup] &&
                                                  formErrors &&
                                                  formErrors[inputKey]
                                                }
                                                style={{ height: '2.25rem' }}
                                                onChange={updateFormValue}
                                                value={
                                                  formValues &&
                                                  formValues[_index] &&
                                                  formValues[_index][inputKey]
                                                    ? formValues[_index][
                                                        inputKey
                                                      ].value
                                                    : ''
                                                }
                                              />
                                            );
                                            break;
                                          case 'dropdown':
                                            const getCurrentOptions = () =>
                                              !input.dynamicOptions
                                                ? [...input.options]
                                                : [
                                                    ...getOptions(
                                                      input.dynamicOptions
                                                    ),
                                                  ];
                                            inputField = (
                                              <Form.Control
                                                as="select"
                                                className="border border-primary"
                                                id={`dropdown-${__subSearchKey}-${_index}-${inputKey}`}
                                                isInvalid={
                                                  validated.length > 0 &&
                                                  _index === activeGroup &&
                                                  validated[activeGroup] &&
                                                  formErrors &&
                                                  formErrors[inputKey]
                                                }
                                                isValid={
                                                  validated.length > 0 &&
                                                  _index === activeGroup &&
                                                  validated[activeGroup] &&
                                                  (!formErrors ||
                                                    !formErrors[inputKey])
                                                }
                                                style={{ height: '2.25rem' }}
                                                onChange={updateFormValue}
                                                value={
                                                  formValues &&
                                                  formValues[_index] &&
                                                  formValues[_index][inputKey]
                                                    ? formValues[_index][
                                                        inputKey
                                                      ].value
                                                    : ''
                                                }
                                              >
                                                {getCurrentOptions().map(
                                                  (o, i) => (
                                                    <option
                                                      key={`${i}`}
                                                      value={o.value}
                                                    >
                                                      {o.title}
                                                    </option>
                                                  )
                                                )}
                                              </Form.Control>
                                            );
                                            break;
                                          case 'date':
                                            inputField = (
                                              <Form.Control
                                                type="date"
                                                className="border border-primary"
                                                id={`date-${__subSearchKey}-${_index}-${inputKey}`}
                                                isInvalid={
                                                  validated.length > 0 &&
                                                  _index === activeGroup &&
                                                  validated[activeGroup] &&
                                                  formErrors &&
                                                  formErrors[inputKey]
                                                }
                                                isValid={
                                                  validated.length > 0 &&
                                                  _index === activeGroup &&
                                                  validated[activeGroup] &&
                                                  (!formErrors ||
                                                    !formErrors[inputKey])
                                                }
                                                style={{ height: '2.25rem' }}
                                                onChange={updateFormValue}
                                                value={
                                                  formValues &&
                                                  formValues[_index] &&
                                                  formValues[_index][inputKey]
                                                    ? formValues[_index][
                                                        inputKey
                                                      ].value
                                                    : ''
                                                }
                                              />
                                            );
                                            break;
                                          default:
                                            inputField = (
                                              <div>
                                                input field type: {input.type}{' '}
                                                is not implemented
                                              </div>
                                            );
                                        }
                                        return (
                                          <Form.Group
                                            as={Col}
                                            xs={
                                              input.mobileWidth
                                                ? input.mobileWidth
                                                : 12
                                            }
                                            md={
                                              input.desktopWidth
                                                ? pathname !== '/'
                                                  ? input.mobileWidth
                                                  : input.desktopWidth
                                                : formLength === 1
                                                ? 12
                                                : 6
                                            }
                                            className="px-2 mx-0"
                                            key={`${__index}`}
                                          >
                                            <Form.Label className="mb-0 mb-md-2">
                                              {input.title}
                                            </Form.Label>
                                            {inputField}
                                            {(!validated[activeGroup] ||
                                              !formErrors ||
                                              !formErrors[inputKey]) && (
                                              <Form.Text muted>
                                                {input.hint}
                                              </Form.Text>
                                            )}
                                            {validated[activeGroup] &&
                                              formErrors &&
                                              formErrors[inputKey] && (
                                                <Form.Control.Feedback type="invalid">
                                                  {formErrors[inputKey].message}{' '}
                                                  {input.hint}
                                                </Form.Control.Feedback>
                                              )}
                                          </Form.Group>
                                        );
                                      }
                                    )}
                                  </Form.Row>
                                  <Form.Row className="w-100 mb-2 mx-0 px-1">
                                    <Form.Group
                                      as={Col}
                                      className="d-flex justify-content-end"
                                    >
                                      <Button
                                        id={`searchBtn-${_index}`}
                                        type="submit"
                                        variant="primary"
                                        onClick={() => setActiveGroup(_index)}
                                      >
                                        <Search />
                                        {' search'}
                                      </Button>
                                    </Form.Group>
                                  </Form.Row>
                                </Form>
                              )
                            )}
                        </Card.Body>
                      </Accordion.Collapse>
                    )}
                  </Card>
                );
              }
            )}
          </Accordion>
        </div>
      </Row>
    </>
  );
};

export default SearchForm;
