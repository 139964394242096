import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';

import DefaultPropertyImage from '../../app/propertyImages/DefaultPropertyImage';
import PropertyImage from '../../app/propertyImages/PropertyImage';
import {
  selectAllPropertyImageEntities,
  selectAllPropertyImageIds,
  fetchPropertyImages,
  selectPropertyImagesBySerial,
} from '../../app/propertyImages/propertyImagesSlice';

const PropertyImagesCarousel = ({
  curPropImageIndex,
  setCurPropImageIndex,
  setShowCurPropImages,
}) => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const subSearch = useSelector(state => state.search.subSearch);
  const dispatch = useDispatch();
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const currentID = useSelector(state => state.search.currentID);
  const allPropertyImageEntities = useSelector(selectAllPropertyImageEntities);
  const allPropertyImageIds = useSelector(selectAllPropertyImageIds);
  // const [showCurPropImages, setShowCurPropImages] = useState(false);
  const propImageEntity = useSelector(state =>
    selectPropertyImagesBySerial(state, currentID)
  );

  useEffect(() => {
    if (
      currentID &&
      ((mainSearch === 'properties' && subSearch !== 'development') ||
        (mainSearch === 'documents' &&
          subSearch === 'crossReference' &&
          currentID.split('-').length === 1)) &&
      !allPropertyImageIds.includes(currentID)
    ) {
      dispatch(fetchPropertyImages({ url: baseApiUrl, serial: currentID }));
    }
  }, [currentID]);

  return (
    <Carousel
      activeIndex={curPropImageIndex}
      onSelect={(i, e) => setCurPropImageIndex(i)}
      className="h-100 w-100"
      interval={null}
      indicators={propImageEntity && propImageEntity.photos.length > 1}
      nextIcon={
        propImageEntity && propImageEntity.photos.length < 2 ? (
          ''
        ) : (
          <span aria-hidden="true" className="carousel-control-next-icon" />
        )
      }
      nextLabel={
        propImageEntity && propImageEntity.photos.length < 2 ? '' : 'Next'
      }
      prevIcon={
        propImageEntity && propImageEntity.photos.length < 2 ? (
          ''
        ) : (
          <span aria-hidden="true" className="carousel-control-prev-icon" />
        )
      }
      prevLabel={
        propImageEntity && propImageEntity.photos.length < 2 ? '' : 'Previous'
      }
    >
      {!propImageEntity && (
        <Carousel.Item className="h-100">
          <DefaultPropertyImage
            loading={true}
            height="100%"
            width="auto"
            spinnerSize="10rem"
          />
        </Carousel.Item>
      )}
      {propImageEntity && propImageEntity.photos.length === 0 && (
        <Carousel.Item className="h-100">
          <DefaultPropertyImage
            loading={false}
            height="100%"
            width="auto"
            fontSize="2.5rem"
            textMargin="2.5rem"
          />
        </Carousel.Item>
      )}
      {propImageEntity &&
        propImageEntity.photos.length > 0 &&
        propImageEntity.photos.map((photoData, i) => (
          <Carousel.Item
            className="h-100"
            key={i}
            onClick={() => {
              setCurPropImageIndex(i);
              setShowCurPropImages(true);
            }}
          >
            <PropertyImage photoData={photoData} height="100%" width="auto" />
          </Carousel.Item>
        ))}
    </Carousel>
  );
};

export default PropertyImagesCarousel;
