import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import { fetchLastAbstractInfo } from './lastAbstractInfoSlice';
import { setCurrentID, setSearchDidRun } from '../../searchSlice';

const LastAbstractInfo = ({ loadNew }) => {
  const lastAbstract = useSelector(
    state => state.lastAbstractModal.lastAbstract
  );
  const dispatch = useDispatch();
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const lastAbstractStatus = useSelector(
    state => state.lastAbstractModal.status
  );
  const history = useHistory();
  const mobileView = useSelector(state => state.windowState.mobileView);
  const router = useSelector(state => state.router);
  const pathname = router.location.pathname;

  const [show, setShow] = useState(false);
  const [loadedNew, setLoadedNew] = useState(false);
  const [curTime, setCurTime] = useState(new Date());

  // update current document
  useEffect(() => {
    if (loadNew) {
      setCurTime(new Date());
      dispatch(fetchLastAbstractInfo({ url: baseApiUrl }));
    }
  }, [loadNew]);

  return (
    <>
      {/* <Button
        className="sf-button"
        variant="outline-info"
        onClick={() => {
          setLoadedNew(!loadedNew);
          setShow(true);
        }}
      >
        Last Abstract
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '1.25rem', lineHeight: '1.85rem' }}>
            Document and Abstract Information
            <p className="mb-0" style={{ fontSize: '1rem', fontWeight: '400' }}>
              As of {curTime.toLocaleDateString()}{' '}
              {curTime.toLocaleTimeString()}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            lineHeight: '1.15rem',
            fontSize: '1rem',
            marginBottom: '0',
          }}
        > */}
      {lastAbstractStatus === 'loading' && (
        <Row noGutters className="m-3">
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '10rem', width: '10rem' }}
          />
        </Row>
      )}
      {lastAbstractStatus === 'succeeded' && lastAbstract && (
        <>
          <Col xs={mobileView || pathname !== '/' ? 12 : 6}>
            <Row noGutters className="w-100 mb-2">
              <p
                className="mb-0"
                style={{ fontSize: '1rem', fontWeight: '400' }}
              >
                As of {curTime.toLocaleDateString()}{' '}
                {curTime.toLocaleTimeString()}
              </p>
            </Row>
            <Row
              noGutters
              className="w-100 mb-2"
              style={{
                fontSize: '1.15rem',
                lineHeight: '1.5rem',
                fontWeight: '500',
              }}
            >
              Last Document Recorded:
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={4}
                className="border-right px-2 pt-1 pb-1 w-100"
                style={{ fontWeight: '400' }}
              >
                Entry Number
              </Col>
              <Col xs={6} sm={8} className="px-2 pt-1 pb-1">
                <a
                  className="d-flex"
                  style={{
                    textUnderlineOffset: '0.125rem',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    // setShow(false);
                    dispatch(setCurrentID({ currentID: null }));
                    dispatch(setSearchDidRun({ searchDidRun: false }));
                    history.push(
                      `/documents/document/0?entry=${
                        lastAbstract.ID_FORM.split('-')[0]
                      }&year=${lastAbstract.ID_FORM.split('-')[1]}`
                    );
                  }}
                >
                  {lastAbstract.ID_FORM || '-'}
                </a>
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={4}
                className="border-right px-2 pt-1 pb-1"
                style={{ fontWeight: '400' }}
              >
                Recorded Date
              </Col>
              <Col xs={6} sm={8} className="px-2 pt-1 pb-1">
                {Date.parse(lastAbstract.RJ_DATE)
                  ? `${new Date(
                      lastAbstract.RJ_DATE
                    ).toLocaleDateString()} ${new Date(
                      lastAbstract.RJ_DATE
                    ).toLocaleTimeString()}`
                  : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={4}
                className="border-right px-2 pt-1 pb-1"
                style={{ fontWeight: '400' }}
              >
                Recorded By
              </Col>
              <Col xs={6} sm={7} className="px-2 pt-1 pb-1">
                {lastAbstract.RECORDED_BY || '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100 mb-2">
              <Col
                xs={6}
                sm={4}
                className="border-right px-2 pt-1 pb-1"
                style={{ fontWeight: '400' }}
              >
                Pages
              </Col>
              <Col xs={6} sm={7} className="px-2 pt-1 pb-1">
                {lastAbstract.RJ_PAGES || '-'}
              </Col>
            </Row>
            <Row
              noGutters
              className="w-100 mb-2"
              style={{
                fontSize: '1.15rem',
                lineHeight: '1.5rem',
                fontWeight: '500',
              }}
            >
              Abstract Reconcile Date:{' '}
              <span
                style={{
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  fontWeight: '400',
                  marginLeft: '0.5rem',
                }}
              >
                {Date.parse(lastAbstract.LOCKED_ABSTRACT)
                  ? `${new Date(
                      lastAbstract.LOCKED_ABSTRACT
                    ).toLocaleDateString()}`
                  : '-'}
              </span>
            </Row>
            <Row
              noGutters
              className="w-100 mb-2"
              style={{
                fontSize: '1.15rem',
                lineHeight: '1.5rem',
                fontWeight: '500',
              }}
            >
              Last Document Abstracted (No Gaps):{' '}
              <a
                className="d-flex"
                style={{
                  textUnderlineOffset: '0.125rem',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontWeight: '400',
                  marginLeft: '0.5rem',
                  lineHeight: '1.5rem',
                  fontSize: '1rem',
                }}
                onClick={() => {
                  // setShow(false);
                  dispatch(setCurrentID({ currentID: null }));
                  dispatch(setSearchDidRun({ searchDidRun: false }));
                  history.push(
                    `/documents/document/0?entry=${
                      lastAbstract.LAST_ABSTRACTED_FORM.split('-')[0]
                    }&year=${lastAbstract.LAST_ABSTRACTED_FORM.split('-')[1]}`
                  );
                }}
              >
                {lastAbstract.LAST_ABSTRACTED_FORM || '-'}
              </a>
            </Row>
            <Row
              noGutters
              className="w-100"
              style={{
                fontSize: '1.15rem',
                lineHeight: '1.5rem',
                fontWeight: '500',
              }}
            >
              Entry Completed Through:{' '}
              <span
                style={{
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  fontWeight: '400',
                  marginLeft: '0.5rem',
                }}
              >
                {Date.parse(lastAbstract.ENTRY_COMPLETED_THROUGH)
                  ? `${new Date(
                      lastAbstract.ENTRY_COMPLETED_THROUGH
                    ).toLocaleDateString()}`
                  : '-'}
              </span>
            </Row>
          </Col>
        </>
      )}
      {lastAbstractStatus === 'failed' ||
        (lastAbstractStatus === 'succeeded' && !lastAbstract && (
          <Row noGutters>
            <h5 className="mx-auto my-5 text-danger">
              <p className="text-center">{`Could not find document information for entry number: ${entryNumber}.`}</p>
            </h5>
          </Row>
        ))}
      {/* </Modal.Body>
      </Modal> */}
    </>
  );
};

export default LastAbstractInfo;
