import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';

import {
  fetchDocumentInfo,
  selectAllDocumentIDs,
  selectDocumentById,
} from '../documentDetailView/documentDetailViewSlice';

import { withCentsFormat } from '../../../utilities/currency';

import { Plus, X, BoxArrowUpRight } from 'react-bootstrap-icons';
import { setCurrentID, setSearchDidRun } from '../../app/searchSlice';
import { Router } from 'react-router-dom';
import { _normalize } from '../../../utilities/searchForm';

const DocumentModal = ({ entryNumber, show, setShow }) => {
  const allDocIds = useSelector(selectAllDocumentIDs);
  const history = useHistory();
  const dispatch = useDispatch();
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const curDoc = useSelector(state => selectDocumentById(state, entryNumber));
  const docStatus = useSelector(state => state.documentDetailView.status);
  const [grantorsExpanded, setGrantorsExpanded] = useState(false);
  const [granteesExpanded, setGranteesExpanded] = useState(false);
  const [serialsExpanded, setSerialsExpanded] = useState(false);
  const [tiesExpanded, setTiesExpanded] = useState(false);
  const [releasesExpanded, setReleasesExpanded] = useState(false);
  const [legalsExpanded, setLegalsExpanded] = useState(false);
  const router = useSelector(state => state.router);

  // update current document
  useEffect(() => {
    // console.log('entryNumber:', entryNumber);
    if (!!entryNumber && !allDocIds.includes(entryNumber))
      dispatch(
        fetchDocumentInfo({ url: baseApiUrl, entryNumber: entryNumber })
      );

    setGrantorsExpanded(false);
    setGranteesExpanded(false);
    setSerialsExpanded(false);
    setTiesExpanded(false);
    setReleasesExpanded(false);
    setLegalsExpanded(false);
  }, [entryNumber]);

  return (
    <Modal show={show} onHide={() => setShow(false)} className="w-100">
      <Modal.Header closeButton>
        <Modal.Title>Document Information</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          lineHeight: '1.15rem',
          fontSize: '1rem',
          marginBottom: '0',
        }}
        className="bg-light rounded-bottom"
      >
        {docStatus === 'loading' && (
          <Row noGutters>
            <Spinner
              animation="border"
              variant="info"
              className="d-flex align-self-center m-auto"
              style={{ height: '10rem', width: '10rem' }}
            />
          </Row>
        )}
        {docStatus === 'failed' ||
          (docStatus === 'succeeded' && !curDoc && (
            <Row noGutters>
              <h5 className="mx-auto my-5 text-danger">
                <p className="text-center">{`Could not find document information for entry number: ${entryNumber}.`}</p>
              </h5>
            </Row>
          ))}
        {docStatus === 'succeeded' && curDoc && (
          <>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2 w-100"
                style={{ fontWeight: '500' }}
              >
                Entry Number
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curDoc.INFO &&
                curDoc.INFO[0].RJ_ENTRY_NO &&
                curDoc.INFO[0].RJ_ENTRY_YR ? (
                  <a
                    className="d-flex"
                    style={{
                      textUnderlineOffset: '0.125rem',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      dispatch(
                        setCurrentID({
                          currentID: `${curDoc.INFO[0].RJ_ENTRY_NO}-${curDoc.INFO[0].RJ_ENTRY_YR}`,
                        })
                      );
                      // dispatch(setCurrentID({ currentID: null }));
                      dispatch(setSearchDidRun({ searchDidRun: false }));
                      setShow(false);
                      history.push(
                        `/documents/document/0?entry=${curDoc.INFO[0].RJ_ENTRY_NO}&year=${curDoc.INFO[0].RJ_ENTRY_YR}`
                      );
                    }}
                  >{`${curDoc.INFO[0].RJ_ENTRY_NO}-${curDoc.INFO[0].RJ_ENTRY_YR}`}</a>
                ) : (
                  `-`
                )}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Recording Date
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curDoc.INFO &&
                curDoc.INFO[0].RJ_DATE &&
                Date.parse(curDoc.INFO[0].RJ_DATE)
                  ? `${new Date(
                      curDoc.INFO[0].RJ_DATE
                    ).toLocaleDateString()} ${new Date(
                      curDoc.INFO[0].RJ_DATE
                    ).toLocaleTimeString()}`
                  : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Recorded By Party
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curDoc && curDoc.INFO && curDoc.INFO[0].RECORDEDBY
                  ? curDoc.INFO[0].RECORDEDBY
                  : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Kind of Instrument
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curDoc.INFO && curDoc.INFO[0].KOI && (
                  <span style={{ fontWeight: '400' }}>
                    {curDoc.INFO[0].KOI}
                  </span>
                )}
                {curDoc.INFO &&
                curDoc.INFO[0].KOI &&
                curDoc.INFO[0].KOI_DESCRIPTION ? (
                  <span style={{ fontWeight: '400' }}>{' — '}</span>
                ) : (
                  '-'
                )}
                {curDoc.INFO[0].KOI_DESCRIPTION}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Instrument Date
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curDoc.INFO &&
                curDoc.INFO[0].INST_DATE &&
                Date.parse(curDoc.INFO[0].INST_DATE)
                  ? new Date(curDoc.INFO[0].INST_DATE).toLocaleDateString()
                  : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Mail Party
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curDoc.INFO && curDoc.INFO[0].MAILNAME
                  ? curDoc.INFO[0].MAILNAME
                  : `-`}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Mail Address
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curDoc.INFO && curDoc.INFO[0].MAILADDR
                  ? curDoc.INFO[0].MAILADDR
                  : `-`}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Tax Address
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curDoc.INFO && curDoc.INFO[0].TAXADDRESS
                  ? curDoc.INFO[0].TAXADDRESS
                  : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Pages
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curDoc.INFO && curDoc.INFO[0].RJ_PAGES
                  ? curDoc.INFO[0].RJ_PAGES
                  : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Consideration
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curDoc.INFO &&
                isNaN(parseInt(curDoc.INFO[0].CONSIDERATION, 10))
                  ? `-`
                  : withCentsFormat(`${curDoc.INFO[0].CONSIDERATION}`)}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Fees
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {isNaN(parseInt(curDoc.INFO[0].RJ_FEES, 10))
                  ? `-`
                  : withCentsFormat(`${curDoc.INFO[0].RJ_FEES}`)}
              </Col>
            </Row>
            <Row noGutters className="h-100 w-100 d-flex align-items-center">
              <Button
                className={'w-100 mt-3'}
                onClick={() =>
                  window.open(
                    `http://bmiwebh5.utahcounty.gov/BmiWeb/?page=Document&Entry_No=${
                      entryNumber.split('-')[0]
                    }&YR=${entryNumber.split('-')[1]}`,
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
                disabled={!curDoc.PDF_DATA}
              >
                <BoxArrowUpRight className="mr-2 mb-1" />
                Document Image Viewer
              </Button>
            </Row>
            <Jumbotron className="w-100 shadow-sm bg-white p-3 mt-3 mb-0">
              <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Grantors</h5>
                {curDoc.GRANTORS && curDoc.GRANTORS.length > 1 && (
                  <Button
                    variant="outline-dark"
                    className="py-0 pl-1 pr-0 d-flex align-items-center justify-content-center"
                    onClick={() => setGrantorsExpanded(!grantorsExpanded)}
                  >
                    {grantorsExpanded ? (
                      <>
                        Less
                        <X className="p-0" width="1.25rem" height="1.25rem" />
                      </>
                    ) : (
                      <>
                        More
                        <Plus
                          className="p-0"
                          width="1.25rem"
                          height="1.25rem"
                        />
                      </>
                    )}
                  </Button>
                )}
              </div>
              <hr />
              {curDoc.GRANTORS && curDoc.GRANTORS.length === 0 && '-'}
              {curDoc.GRANTORS &&
                curDoc.GRANTORS.length > 0 &&
                curDoc.GRANTORS.slice(
                  0,
                  grantorsExpanded ? curDoc.GRANTORS.length : 1
                ).map((g, i) => (
                  <Row
                    key={i}
                    noGutters
                    className={
                      grantorsExpanded || i === 0
                        ? `d-flex w-100${i >= 1 && ' pt-2'}`
                        : `d-none`
                    }
                  >
                    <a
                      className="d-flex"
                      style={{
                        fontWeight: '100',
                        fontSize: '1rem',
                        lineHeight: '1.15rem',
                        textUnderlineOffset: '0.125rem',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        dispatch(setCurrentID({ currentID: null }));
                        dispatch(setSearchDidRun({ searchDidRun: false }));
                        setShow(false);
                        history.push(`/documents/name/0?name=${g.GRANTOR}`);
                      }}
                    >
                      {g.GRANTOR}
                      {g.GRANTORSFX && ` ${g.GRANTORSFX}`}
                    </a>
                  </Row>
                ))}
            </Jumbotron>
            <Jumbotron className="w-100 shadow-sm bg-white p-3 mt-3 mb-0">
              <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Grantees</h5>
                {curDoc.GRANTEES && curDoc.GRANTEES.length > 1 && (
                  <Button
                    variant="outline-dark"
                    className="py-0 pl-1 pr-0 d-flex align-items-center justify-content-center"
                    onClick={() => setGranteesExpanded(!granteesExpanded)}
                  >
                    {granteesExpanded ? (
                      <>
                        Less
                        <X className="p-0" width="1.25rem" height="1.25rem" />
                      </>
                    ) : (
                      <>
                        More
                        <Plus
                          className="p-0"
                          width="1.25rem"
                          height="1.25rem"
                        />
                      </>
                    )}
                  </Button>
                )}
              </div>
              <hr />
              {curDoc.GRANTEES && curDoc.GRANTEES.length === 0 && '-'}
              {curDoc.GRANTEES &&
                curDoc.GRANTEES.length > 0 &&
                curDoc.GRANTEES.slice(
                  0,
                  granteesExpanded ? curDoc.GRANTEES.length : 1
                ).map((g, i) => (
                  <Row
                    key={i}
                    noGutters
                    className={
                      granteesExpanded || i === 0
                        ? `d-flex w-100${i >= 1 && ' pt-2'}`
                        : `d-none`
                    }
                  >
                    <a
                      className="d-flex"
                      style={{
                        fontWeight: '100',
                        fontSize: '1rem',
                        lineHeight: '1.15rem',
                        textUnderlineOffset: '0.125rem',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        dispatch(setCurrentID({ currentID: null }));
                        dispatch(setSearchDidRun({ searchDidRun: false }));
                        setShow(false);
                        history.push(`/documents/name/0?name=${g.GRANTEE}`);
                      }}
                    >
                      {g.GRANTEE}
                      {g.GRANTEESFX && ` ${g.GRANTEESFX}`}
                    </a>
                  </Row>
                ))}
            </Jumbotron>
            <Jumbotron className="w-100 shadow-sm bg-white p-3 mt-3 mb-0">
              <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Serial Numbers</h5>
                {curDoc.SERIAL_INFO && curDoc.SERIAL_INFO.length > 1 && (
                  <Button
                    variant="outline-dark"
                    className="py-0 pl-1 pr-0 d-flex align-items-center justify-content-center"
                    onClick={() => setSerialsExpanded(!serialsExpanded)}
                  >
                    {serialsExpanded ? (
                      <>
                        Less
                        <X className="p-0" width="1.25rem" height="1.25rem" />
                      </>
                    ) : (
                      <>
                        More
                        <Plus
                          className="p-0"
                          width="1.25rem"
                          height="1.25rem"
                        />
                      </>
                    )}
                  </Button>
                )}
              </div>
              <hr />
              {curDoc.SERIAL_INFO && curDoc.SERIAL_INFO.length === 0 && '-'}
              {curDoc.SERIAL_INFO &&
                curDoc.SERIAL_INFO.length > 0 &&
                curDoc.SERIAL_INFO.slice(
                  0,
                  serialsExpanded ? curDoc.SERIAL_INFO.length : 1
                ).map((s, i) => (
                  <Row
                    key={i}
                    noGutters
                    className={
                      serialsExpanded || i === 0
                        ? `d-flex w-100${i >= 1 && ' pt-2'}`
                        : `d-none`
                    }
                    style={{
                      fontFamily: `SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace`,
                      fontWeight: '100',
                      fontSize: '1rem',
                      lineHeight: '1.15rem',
                    }}
                  >
                    {_normalize(s.SERIAL) !== 0 ? (
                      <a
                        className="d-flex"
                        style={{
                          textUnderlineOffset: '0.125rem',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          dispatch(setCurrentID({ currentID: null }));
                          dispatch(setSearchDidRun({ searchDidRun: false }));
                          setShow(false);
                          history.push(
                            `/properties/serial/0?serial=${s.SERIAL}`
                          );
                        }}
                      >
                        {s.SERIAL}
                      </a>
                    ) : (
                      <>{s.SERIAL}</>
                    )}
                    {_normalize(s.TIE_SERIAL_NUMBER) !== 0 && (
                      <>
                        <span className="mx-1">Part to:</span>
                        <a
                          className="d-flex"
                          style={{
                            textUnderlineOffset: '0.125rem',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            dispatch(setCurrentID({ currentID: null }));
                            dispatch(setSearchDidRun({ searchDidRun: false }));
                            setShow(false);
                            history.push(
                              `/properties/serial/0?serial=${s.TIE_SERIAL_NUMBER}`
                            );
                          }}
                        >
                          {s.TIE_SERIAL_NUMBER}
                        </a>
                      </>
                    )}
                    {s.REMAINDER > 0 && <span className="ml-1">Remainder</span>}
                    {s.SERIAL_TYPE === 99 && (
                      <span className="ml-1">Big Abstract:</span>
                    )}
                    {s.TAX_DISTRICT > 0 && (
                      <span className="ml-1">{`Parcels in tax district ${s.TAX_DISTRICT}`}</span>
                    )}
                    {s.SERIAL_TYPE === 99 && s.TAX_DISTRICT === 0 && (
                      <span className="ml-1">
                        All parcels in all tax districts
                      </span>
                    )}
                  </Row>
                ))}
            </Jumbotron>
            <Jumbotron className="w-100 shadow-sm bg-white p-3 mt-3 mb-0">
              <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Tie Entry Numbers</h5>
                {curDoc.TIE_ENTRY_NUMBERS &&
                  curDoc.TIE_ENTRY_NUMBERS.length > 1 && (
                    <Button
                      variant="outline-dark"
                      className="py-0 pl-1 pr-0 d-flex align-items-center justify-content-center"
                      onClick={() => setTiesExpanded(!tiesExpanded)}
                    >
                      {tiesExpanded ? (
                        <>
                          Less
                          <X className="p-0" width="1.25rem" height="1.25rem" />
                        </>
                      ) : (
                        <>
                          More
                          <Plus
                            className="p-0"
                            width="1.25rem"
                            height="1.25rem"
                          />
                        </>
                      )}
                    </Button>
                  )}
              </div>
              <hr />
              {curDoc.TIE_ENTRY_NUMBERS &&
                curDoc.TIE_ENTRY_NUMBERS.length === 0 &&
                '-'}
              {curDoc.TIE_ENTRY_NUMBERS &&
                curDoc.TIE_ENTRY_NUMBERS.length > 0 &&
                curDoc.TIE_ENTRY_NUMBERS.slice(
                  0,
                  tiesExpanded ? curDoc.TIE_ENTRY_NUMBERS.length : 1
                ).map(
                  (t, i) =>
                    t.TIE_ENTRY &&
                    t.TIE_YEAR && (
                      <Row
                        key={i}
                        noGutters
                        className={
                          tiesExpanded || i === 0
                            ? `d-flex w-100${i >= 1 && ' pt-2'}`
                            : `d-none`
                        }
                      >
                        <a
                          className="d-flex"
                          style={{
                            fontWeight: '100',
                            fontSize: '1rem',
                            lineHeight: '1.15rem',
                            textUnderlineOffset: '0.125rem',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            dispatch(setCurrentID({ currentID: null }));
                            dispatch(setSearchDidRun({ searchDidRun: false }));
                            setShow(false);
                            history.push(
                              `/documents/document/0?entry=${t.TIE_ENTRY}&year=${t.TIE_YEAR}`
                            );
                          }}
                        >{`${t.TIE_ENTRY}-${t.TIE_YEAR}`}</a>
                      </Row>
                    )
                )}
            </Jumbotron>
            <Jumbotron className="w-100 shadow-sm bg-white p-3 mt-3 mb-0">
              <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Releases</h5>
                {curDoc.RELEASES && curDoc.RELEASES.length > 1 && (
                  <Button
                    variant="outline-dark"
                    className="py-0 pl-1 pr-0 d-flex align-items-center justify-content-center"
                    onClick={() => setReleasesExpanded(!releasesExpanded)}
                  >
                    {releasesExpanded ? (
                      <>
                        Less
                        <X className="p-0" width="1.25rem" height="1.25rem" />
                      </>
                    ) : (
                      <>
                        More
                        <Plus
                          className="p-0"
                          width="1.25rem"
                          height="1.25rem"
                        />
                      </>
                    )}
                  </Button>
                )}
              </div>
              <hr />
              {curDoc.RELEASES && curDoc.RELEASES.length === 0 && '-'}
              {curDoc.RELEASES &&
                curDoc.RELEASES.length > 0 &&
                curDoc.RELEASES.slice(
                  0,
                  releasesExpanded ? curDoc.RELEASES.length : 1
                ).map((r, i) => (
                  <Row
                    key={i}
                    noGutters
                    className={
                      releasesExpanded || i === 0
                        ? `d-flex w-100${i >= 1 && ' pt-2'}`
                        : `d-none`
                    }
                    style={{
                      fontWeight: '100',
                      fontSize: '1rem',
                      lineHeight: '1.15rem',
                    }}
                  >
                    <a
                      className="d-flex"
                      style={{
                        fontWeight: '100',
                        fontSize: '1rem',
                        lineHeight: '1.15rem',
                        textUnderlineOffset: '0.125rem',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        const rel = r.RELEASE.split(' ');
                        if (rel[2].toUpperCase() === 'BOOK') {
                          dispatch(setCurrentID({ currentID: null }));
                          dispatch(setSearchDidRun({ searchDidRun: false }));
                          setShow(false);
                          history.push(
                            `/documents/document/1?book=${rel[3]}&page=${rel[5]}`
                          );
                        } else if (rel[2].toUpperCase() === 'ENTRY') {
                          dispatch(setCurrentID({ currentID: null }));
                          dispatch(setSearchDidRun({ searchDidRun: false }));
                          setShow(false);
                          history.push(
                            `/documents/document/0?entry=${rel[3]}&year=${rel[5]}`
                          );
                        }
                      }}
                    >
                      {r.RELEASE}
                    </a>
                  </Row>
                ))}
            </Jumbotron>
            <Jumbotron className="w-100 shadow-sm bg-white p-3 mt-3 mb-0">
              <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Abbreviated Legal Description</h5>
                {curDoc.LEGALS && curDoc.LEGALS.length > 1 && (
                  <Button
                    variant="outline-dark"
                    className="py-0 pl-1 pr-0 d-flex align-items-center justify-content-center"
                    onClick={() => setLegalsExpanded(!legalsExpanded)}
                  >
                    {legalsExpanded ? (
                      <>
                        Less
                        <X className="p-0" width="1.25rem" height="1.25rem" />
                      </>
                    ) : (
                      <>
                        More
                        <Plus
                          className="p-0"
                          width="1.25rem"
                          height="1.25rem"
                        />
                      </>
                    )}
                  </Button>
                )}
              </div>
              <hr />
              {(!curDoc.LEGALS || curDoc.LEGALS.length === 0) && '-'}
              {curDoc.LEGALS &&
                curDoc.LEGALS.length > 0 &&
                curDoc.LEGALS.slice(
                  0,
                  legalsExpanded ? curDoc.LEGALS.length : 1
                ).map((l, i) => (
                  <Row
                    key={i}
                    noGutters
                    className={
                      legalsExpanded || i === 0 ? `d-flex w-100` : `d-none`
                    }
                    style={{
                      fontWeight: '100',
                      fontSize: '1rem',
                      lineHeight: '1.15rem',
                    }}
                  >
                    {l.LEGALDESC}
                  </Row>
                ))}
            </Jumbotron>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DocumentModal;
