import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { Search } from 'react-bootstrap-icons';

import DefaultPropertyImage from '../../../app/propertyImages/DefaultPropertyImage';
import PropertyImage from '../../../app/propertyImages/PropertyImage';

import {
  selectAllPropertyImageEntities,
  selectAllPropertyImageIds,
  fetchPropertyImages,
} from '../../../app/propertyImages/propertyImagesSlice';

import {
  setCurrentID,
  fetchResults,
  setSearchResultsScrollTop,
  // setFilteredSortedResults,
} from '../../../app/searchSlice';

import {
  selectDefaultMainSearch,
  selectDefaultSubSearch,
} from '../../../app/defaultSearchesSlice';
import { setSidebarExpanded, setSidebarLoading } from '../../sidebarSlice';
import { setDetailViewLoading } from '../../../detailView/detailViewSlice';
import { setTaxyear } from '../../../detailView/propertyDetailView/propertyDetailViewSlice';
import { toApiQuery, queryToString } from '../../../../utilities/url';
import { filterResults, sortResults } from '../../../../utilities/filterSort';

const PropertyAddressSearchList = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const subSearch = useSelector(state => state.search.subSearch);
  const mainSearchInfo = useSelector(selectDefaultMainSearch(mainSearch));
  const subSearchInfo = useSelector(
    selectDefaultSubSearch(mainSearch, subSearch)
  );
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const searchResultsScrollTop = useSelector(
    state => state.search.searchResultsScrollTop
  );
  const lastSearchResult = useSelector(state => state.search.lastSearchResult);
  const subSearchIndex = useSelector(state => state.search.subSearchIndex);
  const searchStatus = useSelector(state => state.search.status);
  const allPropertyImageEntities = useSelector(selectAllPropertyImageEntities);
  const allPropertyImageIds = useSelector(selectAllPropertyImageIds);
  const dispatch = useDispatch();
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const currentID = useSelector(state => state.search.currentID);
  const router = useSelector(state => state.router);
  const history = useHistory();
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const sortOptions = useSelector(state => state.sidebar.sortOptions);

  const [sortedFilteredFlag, setSortedFilteredFlag] = useState(false);

  const getSearchResultList = () =>
    document.getElementById('searchResultsList');
  const loadMoreSearchResults = e => {
    // initiate UI search
    dispatch(setSidebarLoading(true));
    dispatch(setDetailViewLoading(true));
    // save scroll top
    if (getSearchResultList())
      dispatch(
        setSearchResultsScrollTop({
          searchResultsScrollTop: getSearchResultList().scrollTop,
        })
      );
    // dispatch search with last
    dispatch(
      fetchResults({
        url: baseApiUrl,
        endpoint: subSearchInfo.inputApiEndpoints[subSearchIndex],
        queryString: `${toApiQuery(
          router.location.query
        )}&last=${lastSearchResult}`,
      })
    );
  };
  const checkScroll = () =>
    lastSearchResult &&
    getSearchResultList() &&
    getSearchResultList().scrollTop > 0 &&
    getSearchResultList().scrollHeight - getSearchResultList().scrollTop <=
      getSearchResultList().getBoundingClientRect().height;
  const checkScrollHandler = e => {
    if (checkScroll()) loadMoreSearchResults();
  };
  // check for resultList height === scrollHeight && lastResult !== null
  const checkResultsHeight = () =>
    lastSearchResult &&
    getSearchResultList() &&
    getSearchResultList().getBoundingClientRect().height >=
      getSearchResultList().scrollHeight;
  const checkResultsHeightHandler = e => {
    if (checkResultsHeight()) loadMoreSearchResults();
  };

  // load
  const rawResults = useSelector(state => state.search.searchResults);
  const [searchResults, setSearchResults] = useState(null);
  const [newSearchResultsLoaded, setNewSearchResultsLoaded] = useState(false);

  useEffect(() => {
    setSearchResults([...rawResults]);
    setNewSearchResultsLoaded(true);
  }, [rawResults]);

  useEffect(() => {
    if (newSearchResultsLoaded && searchResults) {
      let tempResults = [...searchResults];
      // filter
      tempResults = [...filterResults(filterOptions, tempResults)];
      // sort
      tempResults = [...sortResults(sortOptions, tempResults)];
      // update list with filtered/sorted results
      setSortedFilteredFlag(!sortedFilteredFlag);
      setNewSearchResultsLoaded(false);
      setSearchResults([...tempResults]);
    }
  }, [newSearchResultsLoaded, filterOptions, sortOptions]);

  // search for additional results when space left in search results
  // window or until user reaches end of currently available search results
  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      // every time searchResults are updated, also check the results height
      checkResultsHeightHandler();
      // subscribe window scroll event
      if (getSearchResultList())
        getSearchResultList().addEventListener('scroll', checkScrollHandler);
    }
    return () => {
      if (getSearchResultList())
        getSearchResultList().removeEventListener('scroll', checkScrollHandler);
    };
  }, [sortedFilteredFlag]);

  // update currentID when page loads
  useEffect(() => {
    if (
      searchStatus === 'succeeded' &&
      searchResults &&
      searchResults.length > 0 &&
      !currentID
    ) {
      const sl =
        searchResults[0].SERIAL_LIFE.slice(-3) === '...' ||
        !searchResults[0].SERIAL_LIFE
          ? [`${new Date().getFullYear()}`]
          : searchResults[0].SERIAL_LIFE.split('-');
      let yearsvalid;
      if (sl.length === 2) yearsvalid = sl[1];
      else yearsvalid = sl[0].slice(0, 4);
      dispatch(setTaxyear({ taxyear: yearsvalid }));
      dispatch(
        setCurrentID({
          currentID: searchResults[0].SERIAL,
        })
      );
    }
  }, [sortedFilteredFlag]);

  // load images for search results
  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      searchResults.forEach(r => {
        if (allPropertyImageIds.indexOf(r.SERIAL) === -1)
          dispatch(fetchPropertyImages({ url: baseApiUrl, serial: r.SERIAL }));
      });
    }
  }, [sortedFilteredFlag]);

  // update search results scroll top after lazy load for better UX
  useEffect(() => {
    if (
      searchStatus === 'succeeded' &&
      searchResults &&
      searchResults.length > 0 &&
      getSearchResultList()
    ) {
      getSearchResultList().scrollTop = searchResultsScrollTop;
    }
  }, [sortedFilteredFlag]);

  return (
    <>
      {/* show search loading */}
      {searchStatus === 'loading' && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
          style={{ zIndex: '100' }}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '10rem', width: '10rem' }}
          />
        </Row>
      )}
      {/* show search results list */}
      {searchStatus === 'succeeded' &&
        searchResults &&
        searchResults.length > 0 && (
          <>
            <div
              id="searchResultsList"
              className={
                sidebarExpanded
                  ? 'w-100 d-flex flex-column overflow-auto border-bottom shadow-sm'
                  : 'd-none'
              }
              style={{
                fontWeight: '100',
                fontFamily: 'Calibri, sans-serif',
                background: '#FFF',
                position: 'relative',
              }}
            >
              {searchResults.map((result, index) => {
                // load images for result
                return (
                  <Row
                    noGutters
                    key={index}
                    className={`w-100 rounded-0 border-top ${
                      mobileView ? 'pl-0 py-2 pr-2' : 'py-3 px-2'
                    }`}
                    style={{
                      background: 'rgba(0, 0, 0, 0.03)',
                    }}
                  >
                    <Col
                      xs={4}
                      sm={2}
                      className="d-flex flex-column align-items-center"
                    >
                      {!allPropertyImageEntities[`${result.SERIAL}`] && (
                        <DefaultPropertyImage loading={true} />
                      )}
                      {allPropertyImageEntities[`${result.SERIAL}`] &&
                        allPropertyImageEntities[`${result.SERIAL}`].photos
                          .length === 0 && (
                          <DefaultPropertyImage loading={false} />
                        )}
                      {allPropertyImageEntities[`${result.SERIAL}`] &&
                        allPropertyImageEntities[`${result.SERIAL}`].photos
                          .length > 0 && (
                          <PropertyImage
                            photoData={
                              allPropertyImageEntities[`${result.SERIAL}`]
                                .photos[0]
                            }
                          />
                        )}
                    </Col>
                    <Col xs={8} sm={10}>
                      <Row
                        noGutters
                        className={`w-100 pl-1 mb-1 ${
                          mobileView ? 'd-flex' : 'd-none'
                        }`}
                      >
                        {result.ADDRESS ? (
                          <a
                            className="text-truncate"
                            style={{
                              lineHeight: '1.25rem',
                              textUnderlineOffset: '0.125rem',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              if (mobileView)
                                dispatch(
                                  setSidebarExpanded({ sidebarExpanded: false })
                                );
                              if (currentID !== `${result.SERIAL}`) {
                                const sl =
                                  result.SERIAL_LIFE.slice(-3) === '...' ||
                                  !result.SERIAL_LIFE
                                    ? [`${new Date().getFullYear()}`]
                                    : result.SERIAL_LIFE.split('-');
                                let yearsvalid;
                                if (sl.length === 2) yearsvalid = sl[1];
                                else yearsvalid = sl[0].slice(0, 4);
                                dispatch(setTaxyear({ taxyear: yearsvalid }));
                                dispatch(
                                  setCurrentID({
                                    currentID: `${result.SERIAL}`,
                                  })
                                );
                                const query = queryToString({
                                  query: router.location.query,
                                  currentID: `${result.SERIAL}`,
                                });
                                history.push(
                                  `${router.location.pathname}?${query}`
                                );
                              }
                            }}
                          >
                            {result.ADDRESS}
                          </a>
                        ) : (
                          '-'
                        )}
                      </Row>
                      <Row
                        noGutters
                        className={`w-100${mobileView ? ' pl-1' : ' pl-2'}`}
                      >
                        <Col
                          xs={4}
                          sm={3}
                          className="pl-0 pr-1 d-flex flex-column align-items-center text-truncate"
                        >
                          <Row
                            noGutters
                            className={`w-100 border-right pb-1 ${
                              mobileView ? 'd-none' : 'd-flex'
                            }`}
                            style={{
                              fontWeight: '400',
                              fontSize: '1.15rem',
                              lineHeight: '1.5rem',
                            }}
                          >
                            <span className="text-truncate">Address</span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100 border-right"
                            style={{
                              fontWeight: '400',
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">Serial/Parcel</span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100 border-right"
                            style={{
                              fontWeight: '400',
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">Owner Name</span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100 border-right"
                            style={{
                              fontWeight: '400',
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              Legal Description
                            </span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100 border-right"
                            style={{
                              fontWeight: '400',
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">Serial Life</span>
                          </Row>
                        </Col>
                        <Col className="px-1 d-flex flex-column align-items-center text-truncate">
                          <Row
                            noGutters
                            className={`w-100 pb-1 ${
                              mobileView ? 'd-none' : 'd-flex'
                            }`}
                          >
                            {' '}
                            {result.ADDRESS ? (
                              <a
                                className="text-truncate"
                                style={{
                                  fontWeight: '400',
                                  fontSize: '1.15rem',
                                  lineHeight: '1.5rem',
                                  textUnderlineOffset: '0.125rem',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  if (mobileView)
                                    dispatch(
                                      setSidebarExpanded({
                                        sidebarExpanded: false,
                                      })
                                    );
                                  if (currentID !== `${result.SERIAL}`) {
                                    const sl =
                                      result.SERIAL_LIFE.slice(-3) === '...' ||
                                      !result.SERIAL_LIFE
                                        ? [`${new Date().getFullYear()}`]
                                        : result.SERIAL_LIFE.split('-');
                                    let yearsvalid;
                                    if (sl.length === 2) yearsvalid = sl[1];
                                    else yearsvalid = sl[0].slice(0, 4);
                                    dispatch(
                                      setTaxyear({ taxyear: yearsvalid })
                                    );
                                    dispatch(
                                      setCurrentID({
                                        currentID: `${result.SERIAL}`,
                                      })
                                    );
                                    const query = queryToString({
                                      query: router.location.query,
                                      currentID: `${result.SERIAL}`,
                                    });
                                    history.push(
                                      `${router.location.pathname}?${query}`
                                    );
                                  }
                                }}
                              >
                                {result.ADDRESS}
                              </a>
                            ) : (
                              '-'
                            )}
                          </Row>
                          <Row
                            noGutters
                            className="w-100"
                            style={{
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              {result.SERIAL_FORMATTED
                                ? `${result.SERIAL_FORMATTED}`
                                : '-'}
                            </span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100"
                            style={{
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              {result.OWNERNAME ? `${result.OWNERNAME}` : `-`}
                            </span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100"
                            style={{
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              {result.LEGAL_DESC ? `${result.LEGAL_DESC}` : `-`}
                            </span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100"
                            style={{
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              {result.SERIAL_LIFE
                                ? `${result.SERIAL_LIFE}`
                                : `-`}
                            </span>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </>
        )}
      {/* show no result message */}
      {((searchStatus === 'succeeded' &&
        searchResults &&
        searchResults.length === 0) ||
        searchStatus === 'failed') && (
        <Row
          noGutters
          id="sidebarResults"
          className={
            sidebarExpanded
              ? 'w-100 h-100 d-flex flex-column flex-grow-1'
              : 'd-none'
          }
          style={{
            backgroundColor: 'rgba(101, 167, 219, 0.25)',
          }}
        >
          <h5
            className="mx-auto w-75 d-flex flex-column h-100 align-items-center"
            style={{
              marginTop: '25%',
            }}
          >
            <Search
              height={mobileView ? '4rem' : '8rem'}
              width={mobileView ? '4rem' : '8rem'}
              style={{ color: 'rgba(101, 167, 219, 1)' }}
            />
            <p className="mb-1 mt-4 text-center">{`No ${mainSearchInfo.titleSingle.toLowerCase()} results found.`}</p>
            <p className="mb-0 mt-2 text-center">{`Please update your filter parameters or try another search.`}</p>
          </h5>
        </Row>
      )}
    </>
  );
};

export default PropertyAddressSearchList;
