import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const DefaultPropertyImage = ({
  loading = true,
  width = '5.5rem',
  height = '5.5rem',
  spinnerSize = '4rem',
  fontSize = '0.75rem',
  textMargin = '1.5rem',
}) => (
  <div
    // id={`srPropertyImage-${serial}`}
    className={`my-auto${
      loading ? ' d-flex align-items-center border rounded shadow-sm' : ''
    }`}
    style={{
      maxWidth: width,
      maxHeight: height,
      width: width,
      height: '100%',
      position: 'relative',
      fontFamily: 'Poppins, "Gill Sans MT", Calibri, sans-serif',
    }}
  >
    {!loading && (
      <>
        <div
          className="rounded shadow-sm d-flex align-items-center"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            background: 'rgba(240,240,250,0.5)',
            width: '100%',
            maxWidth: '100%',
            maxHeight: height,
            height: '100%',
          }}
        >
          <p
            className="text-center w-100"
            style={{
              fontSize: fontSize,
              // lineHeight: `calc(1.267*${fontSize})`,
              marginTop: textMargin,
              opacity: '0.9',
              background: 'rgba(240,240,250,0.5)',
            }}
          >
            <strong>No Image</strong>
          </p>
        </div>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          className="bi bi-house-door-fill"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.5 10.995V14.5a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5V11c0-.25-.25-.5-.5-.5H7c-.25 0-.5.25-.5.495z"></path>
          <path
            fillRule="evenodd"
            d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
          ></path>
        </svg>{' '}
      </>
    )}
    {loading && (
      <Spinner
        animation="border"
        variant="info"
        className="d-flex align-self-center m-auto"
        style={{ height: spinnerSize, width: spinnerSize }}
      />
    )}
  </div>
);

export default DefaultPropertyImage;
