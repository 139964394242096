// framework dependencies
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// external components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Plus, X, BoxArrowUpRight, HouseFill } from 'react-bootstrap-icons';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
} from 'recharts';

// internal components and dependencies
import {
  fetchPropertyInfo,
  selectPropertyById,
  setTaxyear,
} from './propertyDetailViewSlice';
import { selectPropertyImagesBySerial } from '../../app/propertyImages/propertyImagesSlice';
import { setCurrentID, setSearchDidRun } from '../../app/searchSlice';
import {
  setSerial as setTaxPaymentsSerial,
  setTaxyear as setTaxPaymentsTaxyear,
} from '../taxPaymentsDetailView/taxPaymentsDetailViewSlice';
import TaxPaymentsModal from '../modalViews/TaxPaymentsModal';

import PropertyImagesCarousel from './PropertyImagesCarousel';
import PropertyDetailViewMap from './propertyDetailViewMap/PropertyDetailViewMap';
import DocumentModal from '../modalViews/DocumentModal';
import PropertyValuationModal from './propertyValuationModal/PropertyValuationModal';

import { queryToString } from '../../../utilities/url';
import { withCentsFormat, noCentsFormat } from '../../../utilities/currency';
import TaxDetailModal from '../../app/taxDetail/TaxDetailModal';
import { _normalize } from '../../../utilities/searchForm';
import PropertyTaxDistributionChart from './PropertyTaxDistributionChart';

const PropertyDetailView = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const subSearch = useSelector(state => state.search.subSearch);
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const detailViewStatus = useSelector(
    state => state.propertyDetailView.status
  );
  const searchStatus = useSelector(state => state.search.status);
  const dispatch = useDispatch();
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const currentID = useSelector(state => state.search.currentID);
  const curProp = useSelector(state => selectPropertyById(state, currentID));
  const curPropStatus = useSelector(state => state.propertyDetailView.status);
  const propTaxyear = useSelector(state => state.propertyDetailView.taxyear);
  const propImageEntity = useSelector(state =>
    selectPropertyImagesBySerial(state, currentID)
  );
  const history = useHistory();
  const router = useSelector(state => state.router);
  const [viewStatus, setViewStatus] = useState('loading');
  const [propInfo, setPropInfo] = useState(null);
  const [propOwners, setPropOwners] = useState(null);
  const [serialHistoryParents, setSerialHistoryParents] = useState(null);
  const [serialHistoryChildren, setSerialHistoryChildren] = useState(null);
  const [propTaxYearInfo, setPropTaxYearInfo] = useState(null);
  const [propTaxValues, setPropTaxValues] = useState(null);
  const [propAppraisalImprovement, setPropAppraisalImprovement] =
    useState(null);
  const [showCurPropImages, setShowCurPropImages] = useState(false);
  const [curPropImageIndex, setCurPropImageIndex] = useState(0);
  const [legalExpanded, setLegalExpanded] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [showPropValModal, setShowPropValModal] = useState(false);
  const [propValModalTaxYear, setPropValModalTaxYear] = useState(propTaxyear);
  const [showTaxDetailModal, setShowTaxDetailModal] = useState(false);
  const [taxDetailModalTaxYear, setTaxDetailModalTaxYear] =
    useState(propTaxyear);
  const [showTaxPaymentsModal, setShowTaxPaymentsModal] = useState(false);
  const [docEntryNumber, setDocEntryNumber] = useState(null);
  const [mapFiling, setMapFiling] = useState(null);
  const [legalTextOverflowed, setLegalTextOverflowed] = useState(false);
  const [impNo, setCurImprovementNubmer] = useState(null);
  const [partialReload, setPartialReload] = useState(false);
  const [propDocuments, setPropDocuments] = useState(null);
  const [propAddresses, setPropAddresses] = useState(null);
  const [propAddressesExpanded, setPropAddressesExpanded] = useState(false);
  const [propAsmtValues, setPropAsmtValues] = useState(null);
  const [valTaxDist, setValTaxDist] = useState(null);
  const [showOwnerNameModal, setShowOwnerNameModal] = useState(false);
  const [showSerialHistoryModal, setShowSerialHistoryModal] = useState(false);
  // const [reloadedCurPropInfo, setReloadedCurPropInfo] = useState(false);

  // update current property every time current ID changes
  useEffect(() => {
    if (
      currentID &&
      ((mainSearch === 'properties' && subSearch !== 'development') ||
        (mainSearch === 'documents' && subSearch === 'crossReference'))
    ) {
      dispatch(
        fetchPropertyInfo({
          url: baseApiUrl,
          serial: currentID,
          taxyear: propTaxyear,
        })
      );
      setLegalExpanded(false);
      setPropAddressesExpanded(false);
      const query = queryToString({
        query: router.location.query,
        currentID: currentID,
      });
      if (!router.location.query.currentID)
        history.replace(`${router.location.pathname}?${query}`);
      // setReloadedCurPropInfo(true);
    }
  }, [currentID]);

  useEffect(() => {
    if (
      currentID &&
      ((mainSearch === 'properties' && subSearch !== 'development') ||
        (mainSearch === 'documents' &&
          subSearch === 'crossReference' &&
          currentID.split('-').length === 1))
    ) {
      dispatch(
        fetchPropertyInfo({
          url: baseApiUrl,
          serial: currentID,
          taxyear: propTaxyear,
        })
      );
      // setReloadedCurPropInfo(true);
    }
  }, [propTaxyear]);

  // update local state shortcuts when property or tax year changes
  useEffect(() => {
    if (curPropStatus === 'succeeded') {
      // console.log('curProp:', curProp);
      setPropInfo(
        curProp &&
          curProp.property &&
          curProp.property.INFO &&
          curProp.property.INFO.length > 0
          ? curProp.property.INFO[0]
          : null
      );
      setPropOwners(
        curProp &&
          curProp.property &&
          curProp.property.OWNER_NAMES &&
          curProp.property.OWNER_NAMES.length > 1
          ? curProp.property.OWNER_NAMES.slice()
          : null
      );
      setSerialHistoryParents(
        curProp &&
          curProp.serialhistory &&
          curProp.serialhistory.PARENTS &&
          curProp.serialhistory.PARENTS.length > 0
          ? curProp.serialhistory.PARENTS.slice()
          : null
      );
      setSerialHistoryChildren(
        curProp &&
          curProp.serialhistory &&
          curProp.serialhistory.CHILDREN &&
          curProp.serialhistory.CHILDREN.length > 0
          ? curProp.serialhistory.CHILDREN.slice()
          : null
      );
      setPropDocuments(
        curProp &&
          curProp.property &&
          curProp.property.DOCUMENTS &&
          curProp.property.DOCUMENTS.length > 0
          ? curProp.property.DOCUMENTS.slice()
          : null
      );
      setPropAddresses(
        curProp &&
          curProp.property &&
          curProp.property.PARCEL_ADDRESSES &&
          curProp.property.PARCEL_ADDRESSES.length > 1
          ? curProp.property.PARCEL_ADDRESSES.map(a =>
              a.PROP_ADDR_FORM ? a.PROP_ADDR_FORM : ''
            ).filter(
              a =>
                a !== '' &&
                curProp.property.INFO &&
                curProp.property.INFO.length > 0 &&
                a !== curProp.property.INFO[0].ADDRESS
            )
          : null
      );
      setMapFiling(
        curProp &&
          curProp.property &&
          curProp.property.MAP_FILING &&
          curProp.property.INFO.length > 0 &&
          curProp.property.MAP_FILING[0].MAP_NUMBER
          ? curProp.property.MAP_FILING[0].MAP_NUMBER
          : null
      );
      setDocEntryNumber(
        curProp &&
          curProp.property &&
          curProp.property.INFO &&
          curProp.property.INFO.length > 0 &&
          curProp.property.INFO[0].LAST_DOC_ENTRY &&
          curProp.property.INFO[0].LAST_DOC_YEAR
          ? `${curProp.property.INFO[0].LAST_DOC_ENTRY}-${curProp.property.INFO[0].LAST_DOC_YEAR}`
          : null
      );
      setPropAsmtValues(
        curProp &&
          curProp.property &&
          curProp.property.ASSESSMENT_VALUES &&
          curProp.property.ASSESSMENT_VALUES.length > 0
          ? curProp.property.ASSESSMENT_VALUES.slice()
              .filter(a => parseInt(a.PV_YEAR, 10) <= new Date().getFullYear())
              .reduce((asmts, a) => {
                asmts[a.PV_YEAR] = a;
                return asmts;
              }, {})
          : null
      );
      setPropTaxValues(
        curProp &&
          curProp.property &&
          curProp.property.TAX_VALUES &&
          curProp.property.TAX_VALUES.length > 0
          ? curProp.property.TAX_VALUES.slice()
              .filter(t => parseInt(t.TAX_YEAR, 10) <= new Date().getFullYear())
              .reduce((taxVals, t) => {
                taxVals[t.TAX_YEAR] = t;
                return taxVals;
              }, {})
          : null
      );
      setPropTaxYearInfo(
        curProp &&
          curProp.propertytaxyear &&
          curProp.propertytaxyear.INFO &&
          curProp.propertytaxyear.INFO.length > 0
          ? curProp.propertytaxyear.INFO[0]
          : null
      );
      setPropAppraisalImprovement(
        curProp &&
          curProp.appraisalinfo &&
          curProp.appraisalinfo.IMPROVEMENT &&
          curProp.appraisalinfo.IMPROVEMENT.length > 0
          ? curProp.appraisalinfo.IMPROVEMENT.slice().reduce((imps, i) => {
              imps[i.IMPNO] = i;
              return imps;
            }, {})
          : null
      );
      setCurImprovementNubmer(
        curProp &&
          curProp.appraisalinfo &&
          curProp.appraisalinfo.IMPROVEMENT &&
          curProp.appraisalinfo.IMPROVEMENT.length > 0 &&
          curProp.appraisalinfo.IMPROVEMENT[0].IMPNO
          ? curProp.appraisalinfo.IMPROVEMENT[0].IMPNO
          : null
      );
      setValTaxDist(
        curProp &&
          curProp.valuation &&
          curProp.valuation.TAX_DIST &&
          curProp.valuation.TAX_DIST.length > 0 &&
          curProp.valuation.TAX_DIST[0].CUR_TAX > 0
          ? curProp.valuation.TAX_DIST.slice().sort(
              (a, b) => parseFloat(b.CUR_TAX) - parseFloat(a.CUR_TAX)
            )
          : null
      );

      setPartialReload(false);
      // setReloadedCurPropInfo(false);
    }
  }, [curProp]);

  useEffect(() => {
    setValTaxDist(
      curProp &&
        curProp.valuation &&
        curProp.valuation.TAX_DIST &&
        curProp.valuation.TAX_DIST.length > 0 &&
        curProp.valuation.TAX_DIST[0].CUR_TAX > 0
        ? curProp.valuation.TAX_DIST.slice().sort(
            (a, b) => parseFloat(b.CUR_TAX) - parseFloat(a.CUR_TAX)
          )
        : null
    );
  }, [curProp, curPropStatus]);

  // useEffect(() => {
  //   console.log('valTaxDist:', valTaxDist);
  // }, [valTaxDist]);

  useEffect(() => {
    setCurPropImageIndex(0);
  }, [propImageEntity]);

  useEffect(() => {
    setLegalTextOverflowed(
      document.getElementById('legalDescText') &&
        document.getElementById('legalDescText').scrollWidth >
          document.getElementById('legalDescText').clientWidth
    );
  }, [curProp, propTaxyear, sidebarExpanded]);

  useEffect(() => {
    if (detailViewStatus === 'loading' && !partialReload)
      setViewStatus('loading');
    if (
      detailViewStatus === 'failed' ||
      (detailViewStatus === 'succeeded' && !curProp) ||
      (searchStatus === 'succeeded' && !currentID)
    )
      setViewStatus('failed');
    if (
      (detailViewStatus === 'succeeded' && curProp) ||
      (curProp && partialReload)
    )
      setViewStatus('succeeded');
  }, [currentID, detailViewStatus, searchStatus, curProp, partialReload]);

  return (
    <>
      {viewStatus === 'loading' && (
        <Row
          noGutters
          id="sidebarResults"
          className={
            sidebarExpanded && !mobileView ? 'w-100 h-100 bg-light' : 'd-none'
          }
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '20rem', width: '20rem' }}
          />
        </Row>
      )}
      {viewStatus === 'failed' && (
        <Row
          noGutters
          className="h-100 d-flex flex-column align-items-center justify-content-center p-2"
          style={{ backgroundColor: 'rgba(217, 190, 80, 0.1)' }}
        >
          <HouseFill
            height={mobileView ? '4rem' : '8rem'}
            width={mobileView ? '4rem' : '8rem'}
            style={{ color: 'rgba(78, 128, 166, 1)' }}
          />
          <h5 className="text-center mt-4" style={{ fontSize: '1.5rem' }}>
            Could not find property information.
          </h5>
          <h5 className="text-center mt-2" style={{ fontSize: '1.5rem' }}>
            Please try another search.
          </h5>
        </Row>
      )}
      {viewStatus === 'succeeded' && curProp && (
        <>
          <Row noGutters className="w-100 pt-3 px-3">
            <Jumbotron className="h-100 w-100 shadow-sm bg-white p-2 mb-3">
              <Container className="p-2" fluid>
                <Row noGutters className="d-flex align-items-center">
                  <Col
                    xs={{ span: 12, order: 2 }}
                    sm={{ span: 3, order: 1 }}
                    className="h-100"
                  >
                    {mobileView && (
                      <Row noGutters className="w-100 mt-4">
                        <h5 className="mb-0 mr-auto">Property Images</h5>
                        <hr className="w-100 mb-0" />
                      </Row>
                    )}
                    <div
                      style={{
                        height: mobileView ? '15rem' : '19rem',
                        width: '100%',
                        backgroundColor: '#8b8b8b',
                      }}
                      className={`shadow-sm rounded${
                        mobileView ? ' mt-3' : ''
                      }`}
                    >
                      <PropertyImagesCarousel
                        curPropImageIndex={curPropImageIndex}
                        setCurPropImageIndex={setCurPropImageIndex}
                        setShowCurPropImages={setShowCurPropImages}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12, order: 1 }}
                    sm={{ span: 6, order: 2 }}
                    className={`h-100 d-flex flex-column align-items-start${
                      mobileView ? '' : ' px-3'
                    }`}
                  >
                    <Row noGutters className="w-100">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Property Information</h5>
                        {!mobileView && (
                          <div className="d-flex align-items-center">
                            <span
                              className="py-1 px-2 rounded-left border-top border-left border-bottom border-primary"
                              style={{
                                backgroundColor: '#e9ecef',
                                lineHeight: '1.15rem',
                                fontSize: '1rem',
                              }}
                            >
                              Tax Year
                            </span>
                            <Dropdown align="end">
                              <Dropdown.Toggle
                                className={`py-1 pl-3 pr-2${
                                  partialReload ? ' bg-success' : ''
                                }`}
                                style={{
                                  lineHeight: '1.15rem',
                                  fontSize: '1rem',
                                  fontWeight: '100',
                                  borderTopLeftRadius: '0',
                                  borderBottomLeftRadius: '0',
                                }}
                              >
                                {propTaxyear}
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                style={{ maxHeight: '25vh', overflow: 'auto' }}
                              >
                                {propTaxValues &&
                                  Object.values(propTaxValues)
                                    .sort(
                                      (a, b) =>
                                        parseInt(b.TAX_YEAR, 10) -
                                        parseInt(a.TAX_YEAR, 10)
                                    )
                                    .map((t, i) => (
                                      <Dropdown.Item
                                        key={i}
                                        onClick={() => {
                                          if (propTaxyear !== t.TAX_YEAR) {
                                            setPartialReload(true);
                                            dispatch(
                                              setTaxyear({
                                                taxyear: t.TAX_YEAR,
                                              })
                                            );
                                          }
                                        }}
                                      >
                                        {t.TAX_YEAR}
                                      </Dropdown.Item>
                                    ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                      </div>
                      <hr className="w-100" />
                    </Row>
                    <Row
                      noGutters
                      className="h-100 w-100 d-flex align-items-center"
                    >
                      <Col
                        xs={12}
                        sm={12}
                        style={{
                          lineHeight: '1.15rem',
                          fontSize: '1rem',
                          marginBottom: '0',
                        }}
                      >
                        {mobileView && (
                          <Row noGutters className="w-100">
                            <Col
                              xs={5}
                              sm={3}
                              className="border-right py-2 pr-2"
                              style={{ fontWeight: '500' }}
                            >
                              Tax Year
                            </Col>
                            <Col xs={7} sm={9} className="py-2 pl-2">
                              <Dropdown align="end">
                                <Dropdown.Toggle
                                  className={`py-1 px-2 w-100${
                                    partialReload ? ' bg-success' : ''
                                  }`}
                                  style={{
                                    lineHeight: '1.15rem',
                                    fontSize: '1rem',
                                    fontWeight: '100',
                                  }}
                                >
                                  {propTaxyear}
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  style={{
                                    maxHeight: '25vh',
                                    overflow: 'auto',
                                  }}
                                >
                                  {propTaxValues &&
                                    Object.values(propTaxValues)
                                      .sort(
                                        (a, b) =>
                                          parseInt(b.TAX_YEAR, 10) -
                                          parseInt(a.TAX_YEAR, 10)
                                      )
                                      .map((t, i) => (
                                        <Dropdown.Item
                                          key={i}
                                          onClick={() => {
                                            if (propTaxyear !== t.TAX_YEAR) {
                                              setPartialReload(true);
                                              dispatch(
                                                setTaxyear({
                                                  taxyear: t.TAX_YEAR,
                                                })
                                              );
                                            }
                                          }}
                                        >
                                          {t.TAX_YEAR}
                                        </Dropdown.Item>
                                      ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                        )}
                        <Row noGutters className="w-100">
                          <Col
                            xs={5}
                            sm={3}
                            className="border-right py-2 pr-2 w-100"
                            style={{ fontWeight: '500' }}
                          >
                            {serialHistoryParents || serialHistoryChildren ? (
                              <a
                                className="d-flex"
                                style={{
                                  textUnderlineOffset: '0.125rem',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => setShowSerialHistoryModal(true)}
                              >
                                Serial/{mobileView && <br />}Parcel
                              </a>
                            ) : (
                              <>Serial/{mobileView && <br />}Parcel</>
                            )}
                          </Col>
                          <Col xs={7} sm={9} className="py-2 pl-2">
                            {propInfo && propInfo.SERIAL_FORMATTED ? (
                              <span
                                className="d-flex"
                                style={{ fontWeight: '400' }}
                              >{`${propInfo.SERIAL_FORMATTED}`}</span>
                            ) : (
                              `-`
                            )}
                          </Col>
                        </Row>
                        <Row noGutters className="w-100">
                          <Col
                            xs={5}
                            sm={3}
                            className="border-right py-2 pr-2"
                            style={{ fontWeight: '500' }}
                          >
                            {propOwners ? (
                              <a
                                className="d-flex"
                                style={{
                                  textUnderlineOffset: '0.125rem',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => setShowOwnerNameModal(true)}
                              >
                                Owner Names
                              </a>
                            ) : (
                              'Owner Name'
                            )}
                          </Col>
                          <Col xs={7} sm={9} className="py-2 pl-2">
                            {propTaxYearInfo && propTaxYearInfo.OWNER_NAME ? (
                              <span>{`${propTaxYearInfo.OWNER_NAME}`}</span>
                            ) : curProp &&
                              curProp.propertyvalues &&
                              curProp.propertyvalues.OWNERNAME ? (
                              <span>{`${curProp.propertyvalues.OWNERNAME}`}</span>
                            ) : (
                              `-`
                            )}
                          </Col>
                        </Row>
                        <Row noGutters className="w-100">
                          <Col
                            xs={5}
                            sm={3}
                            className="border-right py-2 pr-2"
                            style={{ fontWeight: '500' }}
                          >
                            Property Address{propAddresses && 'es'}
                          </Col>
                          <Col
                            xs={7}
                            sm={9}
                            className="py-2 pl-2 d-flex justify-content-between align-items-start"
                          >
                            {propInfo && propInfo.ADDRESS ? (
                              <>
                                <Col className="px-0">
                                  <div>{`${propInfo.ADDRESS}`}</div>
                                  {propAddressesExpanded &&
                                    propAddresses.map(a => <div>{`${a}`}</div>)}
                                </Col>
                                {propAddresses && (
                                  <Button
                                    variant="outline-dark"
                                    className={`align-self-start py-0 ${
                                      mobileView ? 'pl-0' : 'pl-1'
                                    } pr-0 d-flex align-items-center justify-content-center`}
                                    onClick={() =>
                                      setPropAddressesExpanded(
                                        !propAddressesExpanded
                                      )
                                    }
                                  >
                                    {propAddressesExpanded ? (
                                      <>
                                        {mobileView ? '' : 'Less'}
                                        <X
                                          className="p-0"
                                          width="1.25rem"
                                          height="1.25rem"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {mobileView ? '' : 'More'}
                                        <Plus
                                          className="p-0"
                                          width="1.25rem"
                                          height="1.25rem"
                                        />
                                      </>
                                    )}
                                  </Button>
                                )}
                              </>
                            ) : (
                              `-`
                            )}
                          </Col>
                        </Row>
                        <Row noGutters className="w-100">
                          <Col
                            xs={5}
                            sm={3}
                            className="border-right py-2 pr-2"
                            style={{ fontWeight: '500' }}
                          >
                            Mailing Address
                          </Col>
                          <Col xs={7} sm={9} className="py-2 pl-2">
                            {propInfo && propInfo.ADDRESS_MAILING ? (
                              <span>{`${propInfo.ADDRESS_MAILING}`}</span>
                            ) : (
                              `-`
                            )}
                          </Col>
                        </Row>
                        <Row noGutters className="w-100">
                          <Col
                            xs={5}
                            sm={3}
                            className="border-right py-2 pr-2"
                            style={{ fontWeight: '500' }}
                          >
                            Tax District
                          </Col>
                          <Col xs={7} sm={9} className="py-2 pl-2">
                            {propTaxValues &&
                            Object.values(propTaxValues).length > 0 ? (
                              <span>
                                {Object.values(propTaxValues)[0].TAXDIST || '-'}
                              </span>
                            ) : (
                              '-'
                            )}
                          </Col>
                        </Row>
                        <Row noGutters className="w-100">
                          <Col
                            xs={5}
                            sm={3}
                            className="border-right py-2 pr-2"
                            style={{ fontWeight: '500' }}
                          >
                            Acreage
                          </Col>
                          <Col xs={7} sm={9} className="py-2 pl-2">
                            {propInfo && propInfo.ACREAGE ? (
                              <span>
                                {parseFloat(`${propInfo.ACREAGE}`).toFixed(3)}
                              </span>
                            ) : (
                              `-`
                            )}
                          </Col>
                        </Row>
                        <Row noGutters className="w-100">
                          <Col
                            xs={5}
                            sm={3}
                            className="border-right py-2 pr-2"
                            style={{ fontWeight: '500' }}
                          >
                            Serial Life
                          </Col>
                          <Col xs={7} sm={9} className="py-2 pl-2">
                            {propInfo && propInfo.SERIALLIFE ? (
                              <span>{`${propInfo.SERIALLIFE}`}</span>
                            ) : (
                              `-`
                            )}
                          </Col>
                        </Row>
                        <Row noGutters className="w-100">
                          <Col
                            xs={5}
                            sm={3}
                            className="border-right py-2 pr-2"
                            style={{ fontWeight: '500' }}
                          >
                            Legal Description
                          </Col>
                          <Col
                            xs={7}
                            sm={9}
                            className="py-2 pl-2 d-flex justify-content-between align-items-start"
                          >
                            {curProp &&
                            curProp.property &&
                            curProp.property.LEGAL_DESC &&
                            curProp.property.LEGAL_DESC.length > 0 &&
                            curProp.property.LEGAL_DESC[0] &&
                            curProp.property.LEGAL_DESC[0].LEGAL_DESC ? (
                              <>
                                <span
                                  id="legalDescText"
                                  className={`${
                                    legalExpanded && legalTextOverflowed
                                      ? ''
                                      : ' text-truncate'
                                  }${mobileView ? '' : ' mr-2'}`}
                                >
                                  {`${curProp.property.LEGAL_DESC[0].LEGAL_DESC}`}
                                </span>
                                {legalTextOverflowed && (
                                  <Button
                                    variant="outline-dark"
                                    className={`align-self-start py-0 ${
                                      mobileView ? 'pl-0' : 'pl-1'
                                    } pr-0 d-flex align-items-center justify-content-center`}
                                    onClick={() =>
                                      setLegalExpanded(!legalExpanded)
                                    }
                                  >
                                    {legalExpanded ? (
                                      <>
                                        {mobileView ? '' : 'Less'}
                                        <X
                                          className="p-0"
                                          width="1.25rem"
                                          height="1.25rem"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {mobileView ? '' : 'More'}
                                        <Plus
                                          className="p-0"
                                          width="1.25rem"
                                          height="1.25rem"
                                        />
                                      </>
                                    )}
                                  </Button>
                                )}
                              </>
                            ) : (
                              `-`
                            )}
                          </Col>
                        </Row>
                        <Row
                          noGutters
                          className="w-100 d-flex justify-content-between"
                        >
                          <Col
                            xs={12}
                            sm={6}
                            className={`w-100 d-flex mt-3 ${
                              mobileView
                                ? 'justify-content-center'
                                : 'justify-content-start'
                            }`}
                          >
                            <Button
                              style={{
                                width: mobileView ? '100%' : '10rem',
                                fontWeight: '100',
                              }}
                              disabled={
                                !propInfo ||
                                !propInfo.LAST_DOC_ENTRY ||
                                !propInfo.LAST_DOC_YEAR
                              }
                              onClick={() => {
                                if (
                                  docEntryNumber !==
                                  `${propInfo.LAST_DOC_ENTRY}-${propInfo.LAST_DOC_YEAR}`
                                )
                                  setDocEntryNumber(
                                    `${propInfo.LAST_DOC_ENTRY}-${propInfo.LAST_DOC_YEAR}`
                                  );
                                setShowDocumentModal(true);
                              }}
                            >
                              Last Document
                            </Button>
                          </Col>

                          <Col
                            xs={12}
                            sm={6}
                            className={`w-100 d-flex px-0 ${
                              mobileView
                                ? 'mt-2 justify-content-center'
                                : 'mt-3 justify-content-end'
                            }`}
                          >
                            <Button
                              style={{
                                width: mobileView ? '100%' : '10rem',
                                fontWeight: '100',
                              }}
                              onClick={() =>
                                window.open(
                                  `http://bmiweb.utahcounty.gov/BmiWeb/?database=Mapfilings&page=Document&MAP_NO=${mapFiling}`,
                                  '_blank',
                                  'noopener,noreferrer'
                                )
                              }
                              disabled={
                                !mapFiling ||
                                !propTaxYearInfo ||
                                (propTaxYearInfo &&
                                  propTaxYearInfo.PROTECTED_RECORD === 0)
                              }
                            >
                              <BoxArrowUpRight className="mr-2 mb-1" />
                              Map Filing
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={{ span: 12, order: 3 }} sm={3} className="h-100">
                    {mobileView && (
                      <Row noGutters className="w-100 mt-4">
                        <h5 className="mb-0 mr-auto">Property Location</h5>
                        <hr className="w-100 mb-0" />
                      </Row>
                    )}
                    <div
                      style={{
                        height: mobileView ? '15rem' : '19rem',
                        width: '100%',
                        backgroundColor: '#8b8b8b',
                      }}
                      className={`rounded${mobileView ? ' mt-3' : ''}`}
                    >
                      <PropertyDetailViewMap
                        serial={
                          curProp && propInfo && propInfo.ADDRESS
                            ? curProp.serial
                            : undefined
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Jumbotron>
          </Row>
          <Row noGutters className="d-flex flex-column pt-3 px-3">
            <Jumbotron className="h-100 w-100 shadow-sm bg-white p-2 mb-3">
              <Container className="p-2" fluid>
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Improvement Information</h5>
                  {!mobileView && (
                    <div className="d-flex align-items-center">
                      <span
                        className="py-1 px-2 rounded-left border-top border-left border-bottom border-primary"
                        style={{
                          backgroundColor: '#e9ecef',
                          lineHeight: '1.15rem',
                          fontSize: '1rem',
                        }}
                      >
                        Improvement Number
                      </span>
                      <Dropdown align="end">
                        <Dropdown.Toggle
                          className="py-1 pl-3 pr-2"
                          style={{
                            lineHeight: '1.15rem',
                            fontSize: '1rem',
                            fontWeight: '100',
                            borderTopLeftRadius: '0',
                            borderBottomLeftRadius: '0',
                          }}
                        >
                          {impNo}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{ maxHeight: '25vh', overflow: 'auto' }}
                        >
                          {propAppraisalImprovement &&
                            Object.values(propAppraisalImprovement).map(
                              (imp, i) => (
                                <Dropdown.Item
                                  key={i}
                                  onClick={() => {
                                    setCurImprovementNubmer(imp.IMPNO);
                                  }}
                                >
                                  {imp.IMPNO}
                                </Dropdown.Item>
                              )
                            )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                </div>
                <hr className="w-100" />
                <Row
                  noGutters
                  className="h-100 w-100 d-flex align-items-center"
                >
                  <Col
                    xs={12}
                    sm={4}
                    style={{
                      lineHeight: '1.15rem',
                      fontSize: '1rem',
                      marginBottom: '0',
                    }}
                  >
                    {mobileView && (
                      <Row noGutters className="w-100">
                        <Col
                          xs={6}
                          className="border-right p-2"
                          style={{ fontWeight: '500' }}
                        >
                          Improvement #
                        </Col>
                        <Col xs={6} className="p-2">
                          <Dropdown align="end">
                            <Dropdown.Toggle
                              className="py-1 px-2 w-100"
                              style={{
                                lineHeight: '1.15rem',
                                fontSize: '1rem',
                                fontWeight: '100',
                              }}
                            >
                              {impNo}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              style={{ maxHeight: '25vh', overflow: 'auto' }}
                            >
                              {propAppraisalImprovement &&
                                Object.values(propAppraisalImprovement).map(
                                  (imp, i) => (
                                    <Dropdown.Item
                                      key={i}
                                      onClick={() => {
                                        setCurImprovementNubmer(imp.IMPNO);
                                      }}
                                    >
                                      {imp.IMPNO}
                                    </Dropdown.Item>
                                  )
                                )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    )}
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Improvement Type
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].BLTASDESCRIPTION ? (
                          <span>{`${propAppraisalImprovement[impNo].BLTASDESCRIPTION}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Square Feet
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].TOTSF ? (
                          <span>{`${propAppraisalImprovement[impNo].TOTSF}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Basement Square Feet
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].BSMTSF ? (
                          <span>{`${propAppraisalImprovement[impNo].BSMTSF}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Basement Square Feet Finished
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].BSMTFINISHED ? (
                          <span>{`${propAppraisalImprovement[impNo].BSMTFINISHED}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Year Built
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].BLTASYEARBUILT ? (
                          <span>{`${propAppraisalImprovement[impNo].BLTASYEARBUILT}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Adjusted Year Built
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].ADJUSTEDYEARBUILT ? (
                          <span>{`${propAppraisalImprovement[impNo].ADJUSTEDYEARBUILT}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    style={{
                      lineHeight: '1.15rem',
                      fontSize: '1rem',
                      marginBottom: '0',
                    }}
                  >
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Quality
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].IMPQUALITY ? (
                          <span>{`${propAppraisalImprovement[impNo].IMPQUALITY}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Condition
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].IMPCONDITIONTYPE ? (
                          <span>{`${propAppraisalImprovement[impNo].IMPCONDITIONTYPE}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Exterior
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].IMPEXTERIOR ? (
                          <span>{`${propAppraisalImprovement[impNo].IMPEXTERIOR}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Interior
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].BSMTFINISHED ? (
                          <span>{`${propAppraisalImprovement[impNo].BSMTFINISHED}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Roof Type
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].IMPINTERIOR ? (
                          <span>{`${propAppraisalImprovement[impNo].IMPINTERIOR}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Roof Cover
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].ROOFCOVER ? (
                          <span>{`${propAppraisalImprovement[impNo].ROOFCOVER}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Fireplace
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].FIREPLACE ? (
                          <span>{`${propAppraisalImprovement[impNo].FIREPLACE}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    style={{
                      lineHeight: '1.15rem',
                      fontSize: '1rem',
                      marginBottom: '0',
                    }}
                  >
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Foundation
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].BLTASFOUNDATION ? (
                          <span>{`${propAppraisalImprovement[impNo].BLTASFOUNDATION}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Bedroom Count
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].BEDROOMCOUNT ? (
                          <span>{`${propAppraisalImprovement[impNo].BEDROOMCOUNT}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Full Bathroom Count
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].FULLBATH ? (
                          <span>{`${propAppraisalImprovement[impNo].FULLBATH}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Three-quarter Bathroom Count
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].THREEQTRBATH ? (
                          <span>{`${propAppraisalImprovement[impNo].THREEQTRBATH}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={5}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Half Bathroom Count
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {propAppraisalImprovement &&
                        propAppraisalImprovement[impNo] &&
                        propAppraisalImprovement[impNo].HALFBATH ? (
                          <span>{`${propAppraisalImprovement[impNo].HALFBATH}`}</span>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Jumbotron>
          </Row>
          <Row noGutters className="pt-3 px-3">
            <Jumbotron className="w-100 h-100 d-flex flex-column shadow-sm bg-white p-3 mb-0">
              <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Valuation History</h5>
              </div>
              <hr className="w-100" />
              {!propAsmtValues && (
                <Row noGutters className="h-100">
                  <h5 className="mx-auto text-danger d-flex align-items-center justify-content-center">
                    {`No related assessment values found.`}
                  </h5>
                </Row>
              )}
              {propAsmtValues && (
                <Row noGutters>
                  <Col
                    xs={12}
                    sm={6}
                    className={
                      Object.values(propAsmtValues).length > 1
                        ? `mb-0${mobileView ? ' px-0' : ' pr-2 pl-0'}`
                        : 'd-none'
                    }
                    style={{
                      maxHeight: '30vh',
                      height: mobileView
                        ? '30vh'
                        : `${30 * Object.values(propAsmtValues).length + 30}px`,
                    }}
                  >
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={Object.values(propAsmtValues)}
                        width="100%"
                        height="100%"
                        minWidth="250"
                        minHeight="250"
                        style={{
                          minHeight: '250px',
                          minWidth: '250px',
                        }}
                        margin={
                          mobileView
                            ? { left: 0 }
                            : Math.max(
                                ...Object.values(propAsmtValues).map(a =>
                                  parseInt(a.TOTASMT, 10)
                                )
                              ) >= 100000000
                            ? { left: 65 }
                            : Math.max(
                                ...Object.values(propAsmtValues).map(a =>
                                  parseInt(a.TOTASMT, 10)
                                )
                              ) >= 1000000
                            ? { left: 45 }
                            : { left: 25 }
                        }
                      >
                        <defs>
                          <linearGradient
                            id="valuationHistoryGradient"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="rgb(13, 202, 240)"
                              stopOpacity={0.9}
                            />
                            <stop
                              offset="95%"
                              stopColor="rgb(13, 202, 240)"
                              stopOpacity={0.1}
                            />
                          </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="2 2" />
                        <XAxis dataKey="PV_YEAR" />
                        {!mobileView && (
                          <YAxis tickFormatter={v => noCentsFormat(`${v}`)} />
                        )}
                        <Tooltip
                          formatter={(v, n) => [noCentsFormat(`${v}`), 'Value']}
                        />
                        <Area
                          type="monotone"
                          dataKey="TOTASMT"
                          stroke="rgba(13, 202, 240, 1)"
                          fill="url(#valuationHistoryGradient)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </Col>
                  <Col
                    xs={12}
                    sm={{
                      span: Object.values(propAsmtValues).length > 1 ? 6 : 8,
                      offset: Object.values(propAsmtValues).length > 1 ? 0 : 2,
                    }}
                    className={`mb-0${
                      mobileView || Object.values(propAsmtValues).length === 1
                        ? ' px-0'
                        : ' pr-0 pl-2'
                    }`}
                  >
                    <div
                      className="rounded"
                      style={{
                        maxHeight: '30vh',
                        overflow: 'auto',
                      }}
                    >
                      <Table
                        striped
                        hover
                        size="sm"
                        className="w-100 mb-0"
                        style={{
                          whiteSpace: 'nowrap',
                          fontSize: '1rem',
                          lineHeight: '1.15rem',
                          fontFamily: 'Calibri, sans-serif',
                        }}
                      >
                        <thead className="stickyTop bg-white">
                          <tr>
                            <th
                              style={{ fontWeight: '400' }}
                              className="border-top-0 border-bottom-0"
                            >
                              Year
                            </th>
                            <th
                              style={{ fontWeight: '400', textAlign: 'right' }}
                              className="border-top-0 border-bottom-0"
                            >
                              Real Estate
                            </th>
                            <th
                              style={{ fontWeight: '400', textAlign: 'right' }}
                              className="border-top-0 border-bottom-0"
                            >
                              Improvements
                            </th>
                            <th
                              style={{ fontWeight: '400', textAlign: 'right' }}
                              className="border-top-0 border-bottom-0"
                            >
                              Greenbelt
                            </th>
                            <th
                              style={{ fontWeight: '400', textAlign: 'right' }}
                              className="border-top-0 border-bottom-0"
                            >
                              Total Market Value
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.values(propAsmtValues)
                            .sort(
                              (a, b) =>
                                parseInt(b.PV_YEAR, 10) -
                                parseInt(a.PV_YEAR, 10)
                            )
                            .map((a, i) => (
                              <tr key={i}>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {a.PV_YEAR ? (
                                    <a
                                      className="d-flex"
                                      style={{
                                        textUnderlineOffset: '0.125rem',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        setPropValModalTaxYear(a.PV_YEAR);
                                        setShowPropValModal(true);
                                      }}
                                    >
                                      {`${a.PV_YEAR}`}
                                    </a>
                                  ) : (
                                    '-'
                                  )}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {a.RE_TOTAL
                                    ? noCentsFormat(`${a.RE_TOTAL}`)
                                    : '-'}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {a.IM_TOTAL
                                    ? noCentsFormat(`${a.IM_TOTAL}`)
                                    : '-'}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: 'bottom',
                                    textAlign: 'right',
                                  }}
                                >
                                  {a.GB_TOTAL
                                    ? noCentsFormat(`${a.GB_TOTAL}`)
                                    : '-'}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                    verticalAlign: 'bottom',
                                  }}
                                >
                                  {a.TOTASMT
                                    ? noCentsFormat(`${a.TOTASMT}`)
                                    : '-'}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              )}
            </Jumbotron>
          </Row>
          <Row noGutters className="pt-3 px-3">
            <Jumbotron className="w-100 h-100 d-flex flex-column shadow-sm bg-white p-3 mb-0">
              <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Tax History</h5>
              </div>
              <hr className="w-100" />
              {!propTaxValues && (
                <Row noGutters className="h-100">
                  <h5 className="mx-auto text-danger d-flex align-items-center justify-content-center">
                    {`No related tax values found.`}
                  </h5>
                </Row>
              )}
              {propTaxValues && (
                <Row noGutters>
                  <Col
                    xs={12}
                    sm={6}
                    className={
                      Object.values(propTaxValues).length > 1
                        ? `mb-0${mobileView ? ' px-0' : ' pr-2 pl-0'}`
                        : 'd-none'
                    }
                    style={{
                      maxHeight: '30vh',
                      height: mobileView
                        ? '30vh'
                        : `${30 * Object.values(propTaxValues).length + 30}px`,
                    }}
                  >
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={Object.values(propTaxValues)}
                        width="100%"
                        height="100%"
                        minWidth="250"
                        minHeight="250"
                        style={{
                          minHeight: '250px',
                          minWidth: '250px',
                        }}
                        margin={
                          mobileView
                            ? { left: 0 }
                            : Math.max(
                                ...Object.values(propTaxValues).map(t =>
                                  parseInt(t.ASMT_TAXES, 10)
                                )
                              ) >= 1000000
                            ? { left: 65 }
                            : Math.max(
                                ...Object.values(propTaxValues).map(t =>
                                  parseInt(t.ASMT_TAXES, 10)
                                )
                              ) >= 10000
                            ? { left: 45 }
                            : { left: 25 }
                        }
                      >
                        <defs>
                          <linearGradient
                            id="taxHistoryGradient"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="rgb(25, 135, 84)"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor="rgb(25, 135, 84)"
                              stopOpacity={0.05}
                            />
                          </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="2 2" />
                        <XAxis dataKey="TAX_YEAR" />
                        {!mobileView && (
                          <YAxis tickFormatter={v => withCentsFormat(`${v}`)} />
                        )}
                        <Tooltip
                          formatter={(v, n) => [
                            withCentsFormat(`${v}`),
                            'Taxes',
                          ]}
                        />
                        <Area
                          type="monotone"
                          dataKey="ASMT_TAXES"
                          stroke="rgba(25, 135, 84, 1)"
                          fill="url(#taxHistoryGradient)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </Col>
                  <Col
                    xs={12}
                    sm={{
                      span: Object.values(propTaxValues).length > 1 ? 6 : 8,
                      offset: Object.values(propTaxValues).length > 1 ? 0 : 2,
                    }}
                    className={`mb-0${
                      mobileView || Object.values(propTaxValues).length === 1
                        ? ' px-0'
                        : ' pr-0 pl-2'
                    }`}
                  >
                    <div
                      className="rounded"
                      style={{
                        maxHeight: '30vh',
                        overflow: 'auto',
                      }}
                    >
                      <Table
                        striped
                        hover
                        size="sm"
                        className="w-100 mb-0"
                        style={{
                          whiteSpace: 'nowrap',
                          fontSize: '1rem',
                          lineHeight: '1.15rem',
                          fontFamily: 'Calibri, sans-serif',
                        }}
                      >
                        <thead className="stickyTop bg-white">
                          <tr>
                            <th
                              style={{ fontWeight: '400' }}
                              className="border-top-0 border-bottom-0"
                            >
                              Year
                            </th>
                            <th
                              style={{ fontWeight: '400', textAlign: 'right' }}
                              className="border-top-0 border-bottom-0"
                            >
                              General Taxes
                            </th>
                            <th
                              style={{ fontWeight: '400', textAlign: 'right' }}
                              className="border-top-0 border-bottom-0"
                            >
                              Adjustments
                            </th>
                            <th
                              style={{ fontWeight: '400', textAlign: 'right' }}
                              className="border-top-0 border-bottom-0"
                            >
                              Net Taxes
                            </th>
                            <th
                              style={{ fontWeight: '400', textAlign: 'right' }}
                              className="border-top-0 border-bottom-0"
                            >
                              Fees
                            </th>
                            <th
                              style={{ fontWeight: '400', textAlign: 'right' }}
                              className="border-top-0 border-bottom-0"
                            >
                              Payments
                            </th>
                            <th
                              style={{ fontWeight: '400', textAlign: 'right' }}
                              className="border-top-0 border-bottom-0"
                            >
                              Tax Balance*
                            </th>
                            <th
                              style={{ fontWeight: '400', textAlign: 'right' }}
                              className="border-top-0 border-bottom-0"
                            >
                              Balance Due
                            </th>
                            <th
                              style={{ fontWeight: '400' }}
                              className="border-top-0 border-bottom-0"
                            >
                              Tax District
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.values(propTaxValues)
                            .sort(
                              (a, b) =>
                                parseInt(b.TAX_YEAR, 10) -
                                parseInt(a.TAX_YEAR, 10)
                            )
                            .map((t, i) => (
                              <tr key={i}>
                                <td style={{ verticalAlign: 'middle' }}>
                                  {t.TAX_YEAR ? (
                                    <a
                                      className="d-flex"
                                      style={{
                                        textUnderlineOffset: '0.125rem',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        setTaxDetailModalTaxYear(t.TAX_YEAR);
                                        setShowTaxDetailModal(true);
                                      }}
                                    >
                                      {`${t.TAX_YEAR}`}
                                    </a>
                                  ) : (
                                    '-'
                                  )}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {t.ASMT_TAXES >= 0
                                    ? withCentsFormat(`${t.ASMT_TAXES}`)
                                    : '-'}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  {t.ADJUSTMENTS >= 0
                                    ? withCentsFormat(`${t.ADJUSTMENTS}`)
                                    : '-'}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                  }}
                                >
                                  {t.NET_TAXES >= 0
                                    ? withCentsFormat(`${t.NET_TAXES}`)
                                    : '-'}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                  }}
                                >
                                  {t.FEES >= 0
                                    ? withCentsFormat(`${t.FEES}`)
                                    : '-'}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                  }}
                                >
                                  {t.TOTAL_PAYMENTS >= 0 ? (
                                    <a
                                      className="d-flex"
                                      style={{
                                        textUnderlineOffset: '0.125rem',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        dispatch(
                                          setTaxPaymentsSerial(curProp.serial)
                                        );
                                        dispatch(
                                          setTaxPaymentsTaxyear(t.TAX_YEAR)
                                        );
                                        setShowTaxPaymentsModal(true);
                                      }}
                                    >
                                      {withCentsFormat(`${t.TOTAL_PAYMENTS}`)}
                                    </a>
                                  ) : (
                                    '-'
                                  )}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                  }}
                                >
                                  {t.TAX_BALANCE >= 0
                                    ? withCentsFormat(`${t.TAX_BALANCE}`)
                                    : '-'}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                  }}
                                >
                                  {t.BALANCE >= 0
                                    ? withCentsFormat(`${t.BALANCE}`)
                                    : '-'}
                                </td>
                                <td>{t.TAXDIST || '-'}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              )}
            </Jumbotron>
          </Row>
          <Row noGutters className="mt-3 mx-3">
            <Jumbotron className="w-100 h-100 d-flex flex-column shadow-sm bg-white p-3 mb-0">
              <div className="w-100 d-flex justify-content-between align-items-center">
                <h5 className="mb-0">
                  Tax{mobileView ? ' Dist' : ' Distribution'}
                </h5>
                <div className="d-flex align-items-center">
                  <span
                    className="py-1 px-2 rounded-left border-top border-left border-bottom border-primary"
                    style={{
                      backgroundColor: '#e9ecef',
                      lineHeight: '1.15rem',
                      fontSize: '1rem',
                    }}
                  >
                    {mobileView ? '' : 'Tax '}Year
                  </span>
                  <Dropdown align="end">
                    <Dropdown.Toggle
                      className={`py-1 pl-3 pr-2${
                        partialReload ? ' bg-success' : ''
                      }`}
                      style={{
                        lineHeight: '1.15rem',
                        fontSize: '1rem',
                        fontWeight: '100',
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',
                      }}
                    >
                      {propTaxyear}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{ maxHeight: '25vh', overflow: 'auto' }}
                    >
                      {propTaxValues &&
                        Object.values(propTaxValues)
                          .sort(
                            (a, b) =>
                              parseInt(b.TAX_YEAR, 10) -
                              parseInt(a.TAX_YEAR, 10)
                          )
                          .map((t, i) => (
                            <Dropdown.Item
                              key={i}
                              onClick={() => {
                                if (propTaxyear !== t.TAX_YEAR) {
                                  setPartialReload(true);
                                  dispatch(
                                    setTaxyear({
                                      taxyear: t.TAX_YEAR,
                                    })
                                  );
                                }
                              }}
                            >
                              {t.TAX_YEAR}
                            </Dropdown.Item>
                          ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <hr className="w-100" />
              {((!valTaxDist && curPropStatus === 'succeeded') ||
                curPropStatus === 'failed') && (
                <Row noGutters className="h-100">
                  <h5 className="mx-auto text-danger d-flex align-items-center justify-content-center">
                    {`No related tax distribution information found.`}
                  </h5>
                </Row>
              )}
              {valTaxDist && (
                <>
                  <PropertyTaxDistributionChart
                    valTaxDist={[
                      ...valTaxDist.filter(
                        t =>
                          t.PREV_TAX !== 0 &&
                          t.CUR_CERT_TAX !== 0 &&
                          t.CUR_TAX !== 0
                      ),
                    ]}
                  />
                </>
              )}
            </Jumbotron>
          </Row>
          <Row noGutters className="p-3">
            <Jumbotron className="w-100 h-100 d-flex flex-column shadow-sm bg-white p-3 mb-0">
              <div className="m-0 p-0 d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Documents</h5>
              </div>
              <hr className="w-100" />
              {!propDocuments && (
                <Row noGutters className="h-100">
                  <h5 className="mx-auto text-danger d-flex align-items-center justify-content-center">
                    {`No related documents found.`}
                  </h5>
                </Row>
              )}
              {propDocuments && (
                <div
                  className="rounded"
                  style={{
                    maxHeight: '30vh',
                    overflow: 'auto',
                  }}
                >
                  <Table
                    striped
                    hover
                    size="sm"
                    className="w-100 mb-0"
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: '1rem',
                      lineHeight: '1.15rem',
                      fontFamily: 'Calibri, sans-serif',
                    }}
                  >
                    <thead className="stickyTop bg-white">
                      <tr>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Entry Number
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Instrument Date
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Recorded Date
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Type (KOI)
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Party1 (Grantor)
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Party2 (Grantee)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {propDocuments.map((d, i) => (
                        <tr key={i}>
                          <td>
                            {d.ENTRY_NO && d.ENTRY_YR ? (
                              <a
                                className="d-flex"
                                style={{
                                  textUnderlineOffset: '0.125rem',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setDocEntryNumber(
                                    `${d.ENTRY_NO}-${d.ENTRY_YR}`
                                  );
                                  setShowDocumentModal(true);
                                }}
                              >
                                {`${d.ENTRY_NO}-${d.ENTRY_YR}`}
                              </a>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>
                            {d.INST_DATE && Date.parse(d.INST_DATE)
                              ? new Date(d.INST_DATE).toLocaleDateString()
                              : '-'}
                          </td>
                          <td>
                            {d.RJ_DATE && Date.parse(d.RJ_DATE)
                              ? new Date(d.RJ_DATE).toLocaleDateString()
                              : '-'}
                          </td>
                          <td>{d.KOI || '-'}</td>
                          <td>{d.GRANTOR || '-'}</td>
                          <td>{d.GRANTEE || '-'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </Jumbotron>
          </Row>

          {/* Property Images Modal */}
          <Modal
            show={showCurPropImages}
            onHide={() => setShowCurPropImages(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Property Images</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PropertyImagesCarousel
                curPropImageIndex={curPropImageIndex}
                setCurPropImageIndex={setCurPropImageIndex}
                setShowCurPropImages={setShowCurPropImages}
              />
            </Modal.Body>
          </Modal>
          {/* Serial History Modal */}
          <Modal
            show={showSerialHistoryModal}
            onHide={() => setShowSerialHistoryModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Serial History</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-light pt-0 rounded-bottom">
              {serialHistoryParents && (
                <Jumbotron className="w-100 shadow-sm bg-white p-3 mb-0 mt-3">
                  <h5 className="mb-0">
                    Parents of: {serialHistoryParents[0].SERIAL_FORMATTED} Life:{' '}
                    {serialHistoryParents[0].SERIAL_LIFE}
                  </h5>
                  <hr />
                  <Table
                    responsive
                    hover
                    size="sm"
                    className="w-100 mb-0"
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: '1rem',
                      lineHeight: '1.15rem',
                      fontFamily: 'Calibri, sans-serif',
                    }}
                  >
                    <thead className="stickyTop bg-white">
                      <tr>
                        <th
                          style={{ fontWeight: '800' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Entry
                        </th>
                        <th
                          style={{ fontWeight: '800' }}
                          className="border-top-0 border-bottom-0"
                        >
                          KOI
                        </th>
                        <th
                          style={{ fontWeight: '800' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Serial
                        </th>
                        <th
                          style={{ fontWeight: '800' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Life
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {serialHistoryParents.map((p, i) => (
                        <tr key={i}>
                          <td>
                            {p.ENTRY_ID_FORMATTED ? (
                              <a
                                className="d-flex"
                                style={{
                                  textUnderlineOffset: '0.125rem',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  dispatch(setCurrentID({ currentID: null }));
                                  dispatch(
                                    setSearchDidRun({ searchDidRun: false })
                                  );
                                  setShowSerialHistoryModal(false);
                                  history.push(
                                    `/documents/document/0?entry=${p.ENTRYNO}&year=${p.ENTRYYEAR}&currentID=${p.ENTRY_ID_FORMATTED}`
                                  );
                                }}
                              >
                                {p.ENTRY_ID_FORMATTED}
                              </a>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>{p.KOI || '-'}</td>
                          <td>
                            {_normalize(p.TIE_SERIAL_NUMBER) !== 0 ? (
                              <a
                                className="d-flex"
                                style={{
                                  textUnderlineOffset: '0.125rem',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  dispatch(setCurrentID({ currentID: null }));
                                  dispatch(
                                    setSearchDidRun({ searchDidRun: false })
                                  );
                                  setShowSerialHistoryModal(false);
                                  history.push(
                                    `/properties/serial/0?serial=${p.TIE_SERIAL_NUMBER}&currentID=${p.TIE_SERIAL_NUMBER}`
                                  );
                                }}
                              >
                                {p.TIE_SERIAL_FORMATTED}
                              </a>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>
                            {p.TIE_LIFE || '-'} {p.TIE_SH_FLAGS || ''}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Jumbotron>
              )}
              {serialHistoryChildren && (
                <Jumbotron className="w-100 shadow-sm bg-white p-3 mb-0 mt-3">
                  <h5 className="mb-0">
                    Children of: {serialHistoryChildren[0].SERIAL_FORMATTED}{' '}
                    Life: {serialHistoryChildren[0].SERIAL_LIFE}
                  </h5>
                  <hr />
                  <Table
                    responsive
                    hover
                    size="sm"
                    className="w-100 mb-0"
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: '1rem',
                      lineHeight: '1.15rem',
                      fontFamily: 'Calibri, sans-serif',
                    }}
                  >
                    <thead className="stickyTop bg-white">
                      <tr>
                        <th
                          style={{ fontWeight: '800' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Entry
                        </th>
                        <th
                          style={{ fontWeight: '800' }}
                          className="border-top-0 border-bottom-0"
                        >
                          KOI
                        </th>
                        <th
                          style={{ fontWeight: '800' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Serial
                        </th>
                        <th
                          style={{ fontWeight: '800' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Life
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {serialHistoryChildren.map((p, i) => (
                        <tr key={i}>
                          <td>
                            {p.ENTRY_ID_FORMATTED ? (
                              <a
                                className="d-flex"
                                style={{
                                  textUnderlineOffset: '0.125rem',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  dispatch(setCurrentID({ currentID: null }));
                                  dispatch(
                                    setSearchDidRun({ searchDidRun: false })
                                  );
                                  setShowSerialHistoryModal(false);
                                  history.push(
                                    `/documents/document/0?entry=${p.ENTRYNO}&year=${p.ENTRYYEAR}&currentID=${p.ENTRY_ID_FORMATTED}`
                                  );
                                }}
                              >
                                {p.ENTRY_ID_FORMATTED}
                              </a>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>{p.KOI || '-'}</td>
                          <td>
                            {_normalize(p.TIE_SERIAL_NUMBER) !== 0 ? (
                              <a
                                className="d-flex"
                                style={{
                                  textUnderlineOffset: '0.125rem',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  dispatch(setCurrentID({ currentID: null }));
                                  dispatch(
                                    setSearchDidRun({ searchDidRun: false })
                                  );
                                  setShowSerialHistoryModal(false);
                                  history.push(
                                    `/properties/serial/0?serial=${p.TIE_SERIAL_NUMBER}&currentID=${p.TIE_SERIAL_NUMBER}`
                                  );
                                }}
                              >
                                {p.TIE_SERIAL_FORMATTED}
                              </a>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>
                            {p.TIE_LIFE || '-'} {p.TIE_SH_FLAGS || ''}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Jumbotron>
              )}
            </Modal.Body>
          </Modal>
          {/* Owner Names Modal */}
          <Modal
            show={showOwnerNameModal}
            onHide={() => setShowOwnerNameModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Owner Names</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {propOwners &&
                propOwners.map((o, i) => (
                  <Row noGutters className="w-100" key={i}>
                    <Col
                      xs={5}
                      sm={3}
                      className={`border-right p-1 w-100${
                        o.YEARSVALID.search('[.]{3}') !== -1
                          ? ' text-success'
                          : o.YEARSVALID.search('NV') !== -1
                          ? ' text-danger'
                          : ''
                      }`}
                    >
                      {o.YEARSVALID || '-'}
                    </Col>
                    <Col xs={7} sm={9} className="pl-2 py-1 pr-1">
                      {o.OWNERNAME ? (
                        <a
                          className="d-flex"
                          style={{
                            textUnderlineOffset: '0.125rem',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            dispatch(setCurrentID({ currentID: null }));
                            dispatch(setSearchDidRun({ searchDidRun: false }));
                            setShowOwnerNameModal(false);
                            history.push(
                              `/properties/name/0?name=${o.OWNERNAME}`
                            );
                          }}
                        >
                          {o.OWNERNAME}
                        </a>
                      ) : (
                        '-'
                      )}
                    </Col>
                  </Row>
                ))}
            </Modal.Body>
          </Modal>
          <PropertyValuationModal
            show={showPropValModal}
            setShow={setShowPropValModal}
            serial={`${parseInt(curProp.serial, 10)}`}
            taxyear={`${propValModalTaxYear}`}
          />
          <TaxDetailModal
            show={showTaxDetailModal}
            setShow={setShowTaxDetailModal}
            serial={`${parseInt(curProp.serial, 10)}`}
            taxyear={`${taxDetailModalTaxYear}`}
          />
          <TaxPaymentsModal
            show={showTaxPaymentsModal}
            setShow={setShowTaxPaymentsModal}
          />
          <DocumentModal
            show={showDocumentModal}
            setShow={setShowDocumentModal}
            entryNumber={docEntryNumber}
          />
        </>
      )}
    </>
  );
};

export default PropertyDetailView;
