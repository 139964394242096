import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import defaultSearches from './defaultSearchesSlice';
import search from './searchSlice';
import sidebar from '../sidebar/sidebarSlice';
import windowState from './windowStateSlice';
import propertyImages from './propertyImages/propertyImagesSlice';
import developmentDetailViewMap from '../detailView/developmentDetailView/developmentDetailViewMap/developmentDetailViewMapSlice';
import developmentDetailView from '../detailView/developmentDetailView/developmentDetailViewSlice';
import documentDetailView from '../detailView/documentDetailView/documentDetailViewSlice';
import lastAbstractModal from './searchForm/lastAbstractInfo/lastAbstractInfoSlice';
import propertyDetailView from '../detailView/propertyDetailView/propertyDetailViewSlice';
import propertyDetailViewMap from '../detailView/propertyDetailView/propertyDetailViewMap/propertyDetailViewMapSlice';
import documentNameDetailView from '../detailView/documentNameDetailView/documentNameDetailViewSlice';
import delinquentTaxDetailView from '../detailView/delinquentTaxDetailView/delinquentTaxDetailViewSlice';
import searchForm from './searchForm/searchFormSlice';
import propertyValuationModal from '../detailView/propertyDetailView/propertyValuationModal/propertyValuationModalSlice';
import taxDetail from './taxDetail/taxDetailSlice';
import detailView from '../detailView/detailViewSlice';
import feedbackModal from '../pageComponents/feedbackModal/feedbackModalSlice';
import taxPaymentsDetailView from '../detailView/taxPaymentsDetailView/taxPaymentsDetailViewSlice';

export const history = createBrowserHistory();

// "state" is returned as the default module export
export default configureStore({
  reducer: {
    root: null,
    router: connectRouter(history),
    defaultSearches,
    search,
    sidebar,
    windowState,
    propertyImages,
    developmentDetailView,
    developmentDetailViewMap,
    documentDetailView,
    lastAbstractModal,
    propertyDetailView,
    propertyDetailViewMap,
    documentNameDetailView,
    delinquentTaxDetailView,
    searchForm,
    propertyValuationModal,
    taxDetail,
    detailView,
    feedbackModal,
    taxPaymentsDetailView,
  },
  middleware: () => getDefaultMiddleware().concat(routerMiddleware(history)),
});
