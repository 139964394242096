import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Table from 'react-bootstrap/Table';

import { Bank2 } from 'react-bootstrap-icons';

import TaxDetailModal from '../../app/taxDetail/TaxDetailModal';

import {
  fetchTaxPaymentsList,
  setTaxPaymentsStatus,
  selectAllTaxPaymentsListIDs,
  setTaxyear,
  selectTaxPaymentsListById,
} from './taxPaymentsDetailViewSlice';

import { withCentsFormat } from '../../../utilities/currency';

const TaxPaymentsDetailView = () => {
  const serial = useSelector(state => state.taxPaymentsDetailView.serial);
  const taxyear = useSelector(state => state.taxPaymentsDetailView.taxyear);
  const allTaxPaymentsListIds = useSelector(selectAllTaxPaymentsListIDs);
  const dispatch = useDispatch();
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const curTaxPaymentsList = useSelector(state =>
    selectTaxPaymentsListById(state, `${serial}`)
  );
  const taxPaymentsStatus = useSelector(
    state => state.taxPaymentsDetailView.status
  );
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const [showTaxDetailModal, setShowTaxDetailModal] = useState(false);

  // update current document
  useEffect(() => {
    if (!!serial && !!taxyear && !allTaxPaymentsListIds.includes(`${serial}`)) {
      dispatch(
        fetchTaxPaymentsList({
          url: baseApiUrl,
          serial: serial,
        })
      );
    } else if (!serial || !taxyear) dispatch(setTaxPaymentsStatus('failed'));
  }, [serial, taxyear]);

  return (
    <>
      {taxPaymentsStatus === 'loading' && (
        <Row
          noGutters
          className={
            sidebarExpanded
              ? 'w-100 h-100 p-1 overflow-auto bg-light'
              : 'd-none'
          }
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '20rem', width: '20rem' }}
          />
        </Row>
      )}
      {((taxPaymentsStatus === 'succeeded' && !curTaxPaymentsList) ||
        taxPaymentsStatus === 'failed') && (
        <Row
          noGutters
          className="h-100 d-flex flex-column align-items-center justify-content-center p-2"
          style={{ backgroundColor: 'rgba(217, 190, 80, 0.1)' }}
        >
          <Bank2
            height={mobileView ? '4rem' : '8rem'}
            width={mobileView ? '4rem' : '8rem'}
            style={{ color: 'rgba(78, 128, 166, 1)' }}
          />
          <h5 className="text-center mt-4" style={{ fontSize: '1.5rem' }}>
            Could not find tax payments information.
          </h5>
          <h5 className="text-center mt-2" style={{ fontSize: '1.5rem' }}>
            Please try another search.
          </h5>
        </Row>
      )}
      {taxPaymentsStatus === 'succeeded' && curTaxPaymentsList && (
        <>
          <Row noGutters className={`h-100${mobileView ? ' p-1' : ' p-3'}`}>
            <Jumbotron
              className={`h-100 w-100 shadow-sm bg-white p-2 mb-0 d-flex flex-column flex-grow-1`}
            >
              <h5 className={`mt-2 mb-0${mobileView ? ' text-center' : ''}`}>
                Tax Payments for Serial/Parcel: {mobileView && <br />}
                <span className="text-primary" style={{ fontWeight: '400' }}>
                  {curTaxPaymentsList && curTaxPaymentsList.serial_formatted
                    ? curTaxPaymentsList.serial_formatted
                    : serial}
                </span>
              </h5>
              <hr className="w-100" />
              {(!curTaxPaymentsList ||
                curTaxPaymentsList.taxPaymentsList.length === 0) && (
                <Row noGutters className="h-100">
                  <h5 className="mx-auto text-danger d-flex align-items-center justify-content-center">
                    {`No related documents found.`}
                  </h5>
                </Row>
              )}
              {curTaxPaymentsList &&
                curTaxPaymentsList.taxPaymentsList.length > 0 && (
                  <div className={`h-100 overflow-auto`}>
                    <Table
                      striped
                      hover
                      size="sm"
                      className="w-100 mb-0 shadow-sm"
                      style={{
                        whiteSpace: 'nowrap',
                        fontSize: '1rem',
                        lineHeight: '1.15rem',
                        fontFamily: 'Calibri, sans-serif',
                        maxHeight: '100%',
                      }}
                    >
                      <thead className="bg-white stickyTop">
                        <tr>
                          <th
                            style={{ fontWeight: '400' }}
                            className="border-top-0 border-bottom-0"
                          >
                            Year
                          </th>
                          <th
                            style={{ fontWeight: '400' }}
                            className="border-top-0 border-bottom-0"
                          >
                            Date
                          </th>
                          <th
                            style={{ fontWeight: '400' }}
                            className="border-top-0 border-bottom-0"
                          >
                            Time
                          </th>
                          <th
                            style={{ fontWeight: '400' }}
                            className="border-top-0 border-bottom-0 text-right"
                          >
                            Taxes Paid
                          </th>
                          <th
                            style={{ fontWeight: '400' }}
                            className="border-top-0 border-bottom-0 text-right"
                          >
                            Penalties
                          </th>
                          <th
                            style={{ fontWeight: '400' }}
                            className="border-top-0 border-bottom-0 text-right"
                          >
                            Interest
                          </th>
                          <th
                            style={{ fontWeight: '400' }}
                            className="border-top-0 border-bottom-0 text-right"
                          >
                            Fees
                          </th>
                          <th
                            style={{ fontWeight: '400' }}
                            className="border-top-0 border-bottom-0 text-center"
                          >
                            Pay Number
                          </th>
                          <th
                            style={{ fontWeight: '400' }}
                            className="border-top-0 border-bottom-0"
                          >
                            Pay Reference
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {curTaxPaymentsList.taxPaymentsList
                          .slice()
                          .sort((a, b) => a.PAY_NUMBER - b.PAY_NUMBER)
                          .sort((a, b) => b.TAX_YEAR - a.TAX_YEAR)
                          .map((p, i) => (
                            <tr
                              key={i}
                              style={{
                                lineHeight: '1.375rem',
                                backgroundColor:
                                  p.TAX_YEAR === taxyear
                                    ? '#FFFFD0'
                                    : 'transparent',
                              }}
                            >
                              <td
                                style={{
                                  lineHeight: '0.75rem',
                                  paddingTop: '0.75rem',
                                  paddingBottom: '0.25rem',
                                }}
                              >
                                {p.TAX_YEAR ? (
                                  <a
                                    className="d-flex"
                                    style={{
                                      textUnderlineOffset: '0.125rem',
                                      textDecoration: 'underline',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      dispatch(setTaxyear(p.TAX_YEAR));
                                      // setTaxDetailModalTaxYear(p.TAX_YEAR);
                                      setShowTaxDetailModal(true);
                                    }}
                                  >
                                    {`${p.TAX_YEAR}`}
                                  </a>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td className="py-2">
                                {p.PAY_DATE && Date.parse(p.PAY_DATE)
                                  ? new Date(p.PAY_DATE).toLocaleDateString()
                                  : '-'}
                              </td>
                              <td className="py-2">
                                {p.PAY_TIME ? p.PAY_TIME.toUpperCase() : '-'}
                              </td>
                              <td className="py-2 text-right">
                                {p.AMOUNT !== null
                                  ? withCentsFormat(`${p.AMOUNT}`)
                                  : '-'}
                              </td>
                              <td className="py-2 text-right">
                                {p.PENALTY !== null
                                  ? withCentsFormat(`${p.PENALTY}`)
                                  : '-'}
                              </td>
                              <td className="py-2 text-right">
                                {p.INTEREST !== null
                                  ? withCentsFormat(`${p.INTEREST}`)
                                  : '-'}
                              </td>
                              <td className="py-2 text-right">
                                {p.FEES !== null
                                  ? withCentsFormat(`${p.FEES}`)
                                  : '-'}
                              </td>
                              <td className="py-2 text-center">
                                {p.PAY_NUMBER !== null ? p.PAY_NUMBER : '-'}
                              </td>
                              <td className="py-2">
                                {p.PAY_REFERENCE ? p.PAY_REFERENCE : '-'}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                )}
            </Jumbotron>
          </Row>
          <TaxDetailModal
            show={showTaxDetailModal}
            setShow={setShowTaxDetailModal}
            serial={serial}
            taxyear={taxyear}
          />
        </>
      )}
    </>
  );
};

export default TaxPaymentsDetailView;
