import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { setSearchActive, setSearchStatus } from '../../../app/searchSlice';
import { setSidebarExpanded } from '../../sidebarSlice';
import { selectDefaultMainSearch } from '../../../app/defaultSearchesSlice';
import { setCurrentID } from '../../../app/searchSlice';
import { Search } from 'react-bootstrap-icons';

import { queryToString } from '../../../../utilities/url';

const DocumentSearchList = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const mainSearchInfo = useSelector(selectDefaultMainSearch(mainSearch));
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const [currentActiveKey, setCurrentActiveKey] = useState(null);
  const [searchResultsListOverflowed, setSearchResultsListOverflowed] =
    useState(false);
  const dispatch = useDispatch();
  const currentID = useSelector(state => state.search.currentID);
  const history = useHistory();
  const router = useSelector(state => state.router);

  useEffect(() => {
    const updateSearchResultsListOverflowed = () => {
      const searchResultsList = document.getElementById('searchResultsList');
      if (
        searchResultsList &&
        searchResultsList.getBoundingClientRect().height <
          searchResultsList.scrollHeight
      )
        setSearchResultsListOverflowed(true);
      else setSearchResultsListOverflowed(false);
    };
    window.addEventListener('resize', updateSearchResultsListOverflowed);
    return () =>
      window.removeEventListener('resize', updateSearchResultsListOverflowed);
  }, [searchResultsListOverflowed, searchResults]);

  const searchStatus = useSelector(state => state.search.status);
  // load
  const rawResults = useSelector(state => state.search.searchResults);
  const [searchResults, setSearchResults] = useState(
    rawResults.slice().filter(r => r && r.INFO && r.INFO.length > 0)
  );

  // update currentID when page loads
  useEffect(() => {
    // console.log('searchResults:', searchResults);
    // console.log('searchStatus:', searchStatus);
    if (
      searchStatus === 'succeeded' &&
      searchResults.length > 0 &&
      !currentID &&
      searchResults[0].INFO &&
      searchResults[0].INFO.length > 0
    ) {
      dispatch(
        setCurrentID({
          currentID: `${searchResults[0].INFO[0].RJ_ENTRY_NO}-${searchResults[0].INFO[0].RJ_ENTRY_YR}`,
        })
      );
    }
  }, [searchResults]);

  return (
    <>
      {/* show search loading */}
      {searchStatus === 'loading' && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '10rem', width: '10rem' }}
          />
        </Row>
      )}
      {/* show no result message */}
      {searchStatus === 'failed' ||
        (searchStatus === 'succeeded' && searchResults.length === 0 && (
          <Row
            noGutters
            id="sidebarResults"
            className={
              sidebarExpanded
                ? 'w-100 h-100 d-flex flex-column flex-grow-1'
                : 'd-none'
            }
            style={{
              backgroundColor: 'rgba(101, 167, 219, 0.25)',
            }}
          >
            <h5
              className="mx-auto w-75 d-flex flex-column h-100 align-items-center"
              style={{
                marginTop: '25%',
              }}
            >
              <Search
                height={mobileView ? '4rem' : '8rem'}
                width={mobileView ? '4rem' : '8rem'}
                style={{ color: 'rgba(101, 167, 219, 1)' }}
              />
              <p className="mb-1 mt-4 text-center">{`No ${mainSearchInfo.titleSingle.toLowerCase()} results found.`}</p>
              <p className="mb-0 mt-2 text-center">{`Please update your filter parameters or try another search.`}</p>
            </h5>
          </Row>
        ))}
      {/* show search results list */}
      {searchStatus === 'succeeded' && searchResults.length > 0 && (
        <>
          <Row
            className={
              sidebarExpanded
                ? 'w-100 px-0 py-2 border-top border-bottom '
                : 'd-none'
            }
            style={{
              background: '#e9ecef',
              fontWeight: '600',
              fontFamily: 'Calibri, sans-serif',
            }}
            noGutters
          >
            <Col
              xs={4}
              sm={3}
              className="pl-1 pr-0 d-flex flex-column align-items-center"
            >
              <Row noGutters className="pl-2 ml-2 w-100">
                Entry No
              </Row>
              <Row noGutters className="pl-2 ml-2 w-100">
                Kind of Inst
              </Row>
            </Col>
            <Col
              xs={3}
              sm={2}
              className="d-flex flex-column align-items-center"
            >
              <Row noGutters className="w-100">
                Rec Date
              </Row>
              <Row noGutters className="w-100">
                Inst Date
              </Row>
            </Col>
            <Col xs={5} sm={7} className="d-flex align-items-center">
              Recording Party
            </Col>
          </Row>
          <div
            id="searchResultsList"
            className={
              sidebarExpanded
                ? 'w-100 overflow-auto d-flex flex-column flex-grow-1'
                : 'd-none'
            }
            style={{
              fontWeight: '100',
              fontFamily: 'Calibri, sans-serif',
            }}
          >
            {searchResults
              .map((result, index) => {
                if (!result.INFO || !result.INFO.length > 0) return null;
                return (
                  <Card
                    key={index}
                    className="w-100 rounded-0 border-left-0 border-right-0"
                    style={{ marginTop: '-1px' }}
                  >
                    <Card.Header className="w-100 px-1 d-flex flex-row border-bottom-0 shadow-sm">
                      <Col
                        xs={4}
                        sm={3}
                        className="px-0 d-flex flex-column align-items-center"
                      >
                        <Row noGutters className="pl-2 ml-2 w-100">
                          <a
                            className="d-flex"
                            style={{
                              textUnderlineOffset: '0.125rem',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              marginBottom: '0.55rem',
                              lineHeight: '1.15rem',
                            }}
                            onClick={() => {
                              if (mobileView)
                                dispatch(
                                  setSidebarExpanded({ sidebarExpanded: false })
                                );
                              setCurrentActiveKey(
                                index !== currentActiveKey ? index : null
                              );
                              if (
                                currentID !==
                                `${result.INFO[0].RJ_ENTRY_NO}-${result.INFO[0].RJ_ENTRY_YR}`
                              ) {
                                dispatch(
                                  setCurrentID({
                                    currentID: `${result.INFO[0].RJ_ENTRY_NO}-${result.INFO[0].RJ_ENTRY_YR}`,
                                  })
                                );
                                const query = queryToString({
                                  query: router.location.query,
                                  currentID: `${result.INFO[0].RJ_ENTRY_NO}-${result.INFO[0].RJ_ENTRY_YR}`,
                                });
                                history.push(
                                  `${router.location.pathname}?${query}`
                                );
                              }
                            }}
                          >
                            {result.INFO &&
                            result.INFO[0].RJ_ENTRY_NO &&
                            result.INFO[0].RJ_ENTRY_YR
                              ? `${result.INFO[0].RJ_ENTRY_NO}-${result.INFO[0].RJ_ENTRY_YR}`
                              : `-`}
                          </a>
                        </Row>
                        <Row
                          noGutters
                          className="pl-2 ml-2 w-100"
                          style={{ lineHeight: '1.15rem' }}
                        >
                          {result.INFO && result.INFO[0].KOI
                            ? `${result.INFO[0].KOI}`
                            : `-`}
                        </Row>
                      </Col>
                      <Col
                        xs={3}
                        sm={2}
                        className="pl-0 pr-1 d-flex flex-column align-items-center"
                      >
                        <Row noGutters className="w-100">
                          {result.INFO && result.INFO[0].RJ_DATE
                            ? new Date(
                                result.INFO[0].RJ_DATE
                              ).toLocaleDateString()
                            : '-'}
                        </Row>
                        <Row noGutters className="w-100">
                          {result.INFO && result.INFO[0].INST_DATE
                            ? new Date(
                                result.INFO[0].INST_DATE
                              ).toLocaleDateString()
                            : '-'}
                        </Row>
                      </Col>
                      <Col
                        xs={5}
                        sm={7}
                        className="px-0 d-flex align-items-center"
                      >
                        <Row noGutters className="w-100">
                          <span
                            className={
                              currentActiveKey === index ? '' : 'text-truncate'
                            }
                          >
                            {result.INFO && result.INFO[0].RECORDEDBY}
                          </span>
                        </Row>
                      </Col>
                    </Card.Header>
                  </Card>
                );
              })
              .filter(r => r !== null)}
          </div>
        </>
      )}
    </>
  );
};

export default DocumentSearchList;
