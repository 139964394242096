import React from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { DropdownSubmenu, NavDropdownMenu } from 'react-bootstrap-submenu';

import Logo from '../../img/ucg-wht';

// SiteHeader returns the navbar component that provides links to other Utah County sites
export default function SiteHeader() {
  return (
    <Navbar
      collapseOnSelect
      expand="md"
      id="site-header"
      bg="dark"
      variant="dark"
      sticky="top"
      // className="w-100"
    >
      <Navbar.Brand href="/">
        <img alt="Logo" src={Logo} />
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Nav>
          <NavDropdownMenu title="I Want To..." alignRight>
            <DropdownSubmenu title="Apply For">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ClerkAud/Equalization.html"
              >
                Appeal Property Value
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ComDev/BusinessLic/index.asp"
              >
                Beer and Alcohol Licensing
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ComDev/BuildingInsp/index.asp"
              >
                Building Permit
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ComDev/BusinessLic/index.asp"
              >
                Business License
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Pers/EmploymentCenter.asp"
              >
                County Job
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://ssl.utahcounty.gov/dept/clerkaud/elections/WorkerApp.html"
              >
                Election Pollworker Application
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/environmental-health/annual-permits-and-certifications/"
              >
                Food Handler Permit
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://housinguc.org/"
              >
                Housing Assistance
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ClerkAud/Marriage.html"
              >
                Marriage License
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ClerkAud/Passports.html"
              >
                Passport Renewal
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Assess/resdec.html"
              >
                Primary Residence Exemption
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Buy">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/health-promotion/request-a-service/"
              >
                Radon Kits
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.publicsurplus.com/sms/utahco,ut/list/current?orgid=50608"
              >
                Utah County Surplus Property
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Pay">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://secure.utahcounty.gov/taxes.asp"
              >
                Property Taxes
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/JustCrt/PO.html"
              >
                Justice Court Fines/Fees
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Find">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://utahcounty.maps.arcgis.com/apps/SimpleViewer/index.html?appid=09839449da424ad89c15d282c20ca432"
              >
                My Polling Location
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Parks/Index.asp"
              >
                A County Park
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/CountyBldgMaps.asp"
              >
                County Offices
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/OnlineServices/maps/index.asp"
              >
                County Maps
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/HealthEnvirAir/VehicleEmissions/IMStations/IMStationSearch.asp"
              >
                Local Emissions Testing Facilities
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="File">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/apps/WebLink/Dept/CLERKAUD/2018BOEAppealApplication.pdf"
              >
                BOE Valuation Appeal Form
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://secure.utahcounty.gov/pptax/"
              >
                Personal Property Online Tax Statement
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/FireMarshal/burn.asp"
              >
                Burn Notification
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Learn About">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ClerkAud/Elections"
              >
                Elections / Voter Information
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/CountyInfo/CountyHistory.html"
              >
                County History
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/CountyInfo/HistoricCountyCourthouse.html"
              >
                County Historic Courthouse
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Residents/Homework.asp"
              >
                Homework Help
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/NUCWCD/index.html"
              >
                North Utah County Water Conservancy District
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://api.utahcounty.gov/sheriff/administration/radkids"
              >
                radKIDS
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/PubWrks/Projects/index.asp"
              >
                Public Works Projects
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Read">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Commish/Meetings.asp"
              >
                Commission Meeting Agendas &amp; Minutes
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/News/NewsRoomL.asp"
              >
                News
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/News/Subscribe.asp"
              >
                County Newsletters
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Register">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/TaxSale/index.html"
              >
                Tax Sale Bidder
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ClerkAud/Elections/VoterRegistration.html"
              >
                To Vote
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Report">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ClerkAud/InternalAudit.html"
              >
                Government Waste, Fraud, or Abuse
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://api.utahcounty.gov/sheriff/media/tip"
              >
                A Crime / Tip A Cop
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/HealthEnvirAir/VehicleEmissions/index.asp"
              >
                Vehicle Emission Violation
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Request">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Treas/DuplicateNotice/index.asp"
              >
                Property Tax Notice
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Treas/PrepayCoupon/index.asp"
              >
                Prepay Coupons
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Parks/Index.asp"
              >
                Park Reservations
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/mosquito-abatement/"
              >
                Spraying for Mosquitoes
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/birth-death-certificates/"
              >
                Birth/Death Certificates
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Treas/AddressChange.html"
              >
                Update Mailing Address for Tax Notices
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Search">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Record/LandRecords/index.asp"
              >
                Original Land Records Site
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://api.utahcounty.gov/sheriff/corrections/inmateSearch"
              >
                Jail Inmates
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Take">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept2/Health/Environmental Health/Food_Service/food_handler.asp"
              >
                Food Handler Classes
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/JustCrt/TrafficSchool.html"
              >
                Traffic School
              </NavDropdown.Item>
            </DropdownSubmenu>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.utahcounty.gov/ContactUs/Index.asp"
            >
              Contact The County
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.utahcounty.gov/OnlineServices/index.asp"
            >
              Online Services
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.utahcounty.gov/Residents/index.asp#Volunteer"
            >
              Volunteer
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/UtahCountyGov"
            >
              Follow Utah County on Twitter
            </NavDropdown.Item>
          </NavDropdownMenu>
          <NavDropdownMenu title="Government" alignRight>
            <DropdownSubmenu title="Department Directory">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/AgInsp/index.html"
              >
                Agriculture Inspection
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Assess/index.asp"
              >
                Assessor
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Atty/index.asp"
              >
                Attorney
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Commish/index.asp"
              >
                Commission
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/CJC/index.asp"
              >
                Children's Justice Center
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ClerkAud/index.asp"
              >
                Clerk/Auditor
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ComDev/index.asp"
              >
                Community Development
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://aDDAPT.utahcounty.gov"
              >
                Drug and Alcohol Prevention and Treatment (aDDAPT)
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://extension.usu.edu/utah/"
              >
                Extension Services
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/FireMarshal/"
              >
                Fire Marshal
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://housinguc.org/"
              >
                Housing
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Pers/index.asp"
              >
                Human Resources
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/IS/index.asp"
              >
                Information Systems
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/JustCrt/index.asp"
              >
                Justice Court
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/PubWrks/Index.asp"
              >
                Public Works
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Record/index.asp"
              >
                Recorder
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://api.utahcounty.gov/sheriff/"
              >
                Sheriff
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Surveyor/index.asp"
              >
                Surveyor
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Treas/index.asp"
              >
                Treasurer
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://utahcounty4h.org/"
              >
                Utah County 4-H
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcountyfire.org/"
              >
                Utah County Wildland Fire
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Health Department">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/administration/"
              >
                Administration
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/birth-death-certificates/"
              >
                Birth/Death Certificates (Vital Records)
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/immunizations/"
              >
                Community Health (Nursing)
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/emergency-preparedness/"
              >
                Emergency Preparedness
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/environmental-health/"
              >
                Environmental Health
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/health-promotion/"
              >
                Health Promotions
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/mosquito-abatement/"
              >
                Mosquito Abatement
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/senior-volunteers/"
              >
                Senior Services
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/wic/"
              >
                Women, Infants, Children (WIC)
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Miscellaneous">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/Commish/index.asp"
              >
                County Commission
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/CountyInfo/ElectedOfficials.html"
              >
                Elected Officials
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/CountyInfo/CountyGovernment.html"
              >
                What We Do For You
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Ordinances/index.asp"
              >
                County Codes
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/policy.asp"
              >
                County Policies
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ComDev/Planning/index.asp"
              >
                Land Use Ordinance
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/CountyInfo/CountyHistory.html"
              >
                County History
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/CountyInfo/HistoricCountyCourthouse.html"
              >
                Historic County Courthouse
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/CountyInfo/HistoricTree.html"
              >
                Historic Tree
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/CountyInfo/CountyFamousPeople.html"
              >
                Famous People
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/CountyInfo/CountyHolidays.html"
              >
                County Holidays
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Transparency/index.html"
              >
                Transparency
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/CountyInfo/CountyQuickFacts.html"
              >
                Quick Facts About Utah County
              </NavDropdown.Item>
            </DropdownSubmenu>
          </NavDropdownMenu>
          <NavDropdown title="Visitors" alignRight>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.utahcounty.gov/Parks/Index.asp"
            >
              County Parks
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.utahvalley.com/"
            >
              Convention and Visitors Bureau
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.utahcountyart.com/"
            >
              County Art Gallery
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.utahcounty.gov/CountyInfo/HistoricCountyCourthouse.html"
            >
              Historic County Courthouse
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.utahcounty.gov/LocalInfo/Cities.asp"
            >
              Utah County Cities
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdownMenu title="Residents" alignRight>
            <DropdownSubmenu title="Children/Youth">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://utahcounty4h.org/"
              >
                4-H
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/childrens-health/"
              >
                Children's Health Services
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/CJC/index.asp"
              >
                Children's Justice Center (CJC)
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Residents/Homework.html"
              >
                Homework Help
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/immunizations/"
              >
                Immunizations
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/school-health/"
              >
                School Health Information
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://addapt.utahcounty.gov/Prevention"
              >
                Drug and Alcohol Abuse Prevention
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://aDDAPT.utahcounty.gov"
              >
                Drug and Alcohol Abuse Treatment
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Fees">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/apps/WebLink/Dept/COMMISH/UtahCountyFeeScheduleUpdate03132019.pdf"
              >
                County Fees
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/"
              >
                Health Department Fees
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Parks/Index.asp"
              >
                Park Fees
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Documents/Permits">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/birth-death-certificates/"
              >
                Birth/Death Certificates
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ComDev/BusinessLic/index.asp"
              >
                Business Licenses
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ClerkAud/Marriage.asp"
              >
                Marriage Licenses
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ClerkAud/Passports.html"
              >
                Passports
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Residents/forms.asp"
              >
                County Forms
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Recreation">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcountyfair.org/"
              >
                County Fair
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Parks/Index.asp"
              >
                County Parks
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.utahvalley.com/"
              >
                Visitors Center
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://stateparks.utah.gov/"
              >
                Utah State Parks
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Families">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://aDDAPT.utahcounty.gov"
              >
                Adult Drug and Alcohol Abuse Treatment
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://bookmobiles.utah.gov/utah/"
              >
                Bookmobile
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/clinical-services/"
              >
                Family Health Services
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/wic/"
              >
                WIC
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/womens-health/"
              >
                Women's Cancer Screening
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/clinical-services/cancer-screening/#mammogram"
              >
                Breast/Cervical Screening
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://addapt.utahcounty.gov/Promise"
              >
                Women's Drug and Alcohol Abuse Treatment
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Senior Services">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/senior-volunteers/become-a-foster-grandparent/"
              >
                Foster Grandparents
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/senior-volunteers/become-a-senior-companion/"
              >
                Senior Companion
              </NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Miscellaneous">
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://ssl.utahcounty.gov/dept/clerkaud/elections/Index.html"
              >
                Voter / Election Information
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ClerkAud/Marriage.asp"
              >
                Marriage Licenses
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/ClerkAud/Passports.html"
              >
                Passports
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://health.utahcounty.gov/environmental-health/annual-permits-and-certifications/"
              >
                Food Handler Permits
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/property/index.asp"
              >
                Property Information
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="https://api.utahcounty.gov/sheriff/media/tip"
              >
                Tip A Cop
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Dept/HealthEnvirAir/index.asp"
              >
                Vehicle Emissions / Inspections
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.utahcounty.gov/Residents/index.asp#Volunteer"
              >
                Volunteer Opportunities
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                rel="noopener noreferrer"
                href="http://addapt.utahcounty.gov/medicaid"
              >
                Utah County Medicaid
              </NavDropdown.Item>
            </DropdownSubmenu>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.utahcounty.gov/OnlineServices/index.asp"
            >
              Online Services
            </NavDropdown.Item>
          </NavDropdownMenu>
          <NavDropdown title="Employment" alignRight>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.utahcounty.gov/Dept/Pers/EmploymentCenter.asp"
            >
              Available Jobs
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.utahcounty.gov/Dept/Pers/EmploymentCenter.asp"
            >
              Application
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.utahcounty.gov/Dept/Pers/JobDescriptions.asp"
            >
              Job Descriptions
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.utahcounty.gov/Dept/Pers/FAQ.asp"
            >
              FAQs
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Contact Us" alignRight>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.utahcounty.gov/ContactUs/Index.asp"
            >
              Utah County Contact Form
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:utahcnty@utahcounty.gov"
            >
              Email : utahcnty@utahcounty.gov
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="tel:8018518000"
            >
              Phone : 801-851-8000
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.utahcounty.gov/CountyInfo/CountyHolidays.html"
            >
              County Holidays
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
