import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Table from 'react-bootstrap/Table';

import {
  fetchPropertyValue,
  selectAllPropValIDs,
  selectPropValById,
} from './propertyValuationModalSlice';

import { noCentsFormat } from '../../../../utilities/currency';

import { BoxArrowUpRight } from 'react-bootstrap-icons';

const PropertyValuationModal = ({ serial, taxyear, show, setShow }) => {
  const allPropValIds = useSelector(selectAllPropValIDs);
  const dispatch = useDispatch();
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const curPropVal = useSelector(state =>
    selectPropValById(state, `${taxyear}${serial}`)
  );
  const propValStatus = useSelector(
    state => state.propertyValuationModal.status
  );

  // update current document
  useEffect(() => {
    if (!!serial && !!taxyear && !allPropValIds.includes(`${taxyear}${serial}`))
      dispatch(
        fetchPropertyValue({
          url: baseApiUrl,
          serial: serial,
          taxyear: taxyear,
        })
      );
  }, [serial, taxyear]);

  return (
    <Modal show={show} onHide={() => setShow(false)} className="w-100">
      <Modal.Header closeButton>
        <Modal.Title>Real Property Values</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          lineHeight: '1.15rem',
          fontSize: '1rem',
          marginBottom: '0',
        }}
        className="bg-light rounded-bottom"
      >
        {propValStatus === 'loading' && (
          <Row noGutters>
            <Spinner
              animation="border"
              variant="info"
              className="d-flex align-self-center m-auto"
              style={{ height: '10rem', width: '10rem' }}
            />
          </Row>
        )}
        {propValStatus === 'failed' ||
          (propValStatus === 'succeeded' && !curPropVal && (
            <Row noGutters>
              <h5 className="mx-auto my-5 text-danger">
                <p className="text-center">{`Could not find property valuation information for serial: ${serial} in tax year: ${taxyear}.`}</p>
              </h5>
            </Row>
          ))}
        {propValStatus === 'succeeded' && curPropVal && (
          <>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2 w-100"
                style={{ fontWeight: '500' }}
              >
                Serial Number
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curPropVal.SERIAL_FORMATTED
                  ? curPropVal.SERIAL_FORMATTED
                  : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Tax Year
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curPropVal.PV_YEAR ? curPropVal.PV_YEAR : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Tax District
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curPropVal.TAX_DIST
                  ? `${curPropVal.TAX_DIST}`.padStart(3, '0')
                  : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Owner
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curPropVal.OWNERNAME ? curPropVal.OWNERNAME : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Property Type
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curPropVal.PROP_TYPE_DESC ? curPropVal.PROP_TYPE_DESC : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Valuation Date
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curPropVal.PV_DATE && Date.parse(curPropVal.PV_DATE)
                  ? `${new Date(curPropVal.PV_DATE).toLocaleDateString()}`
                  : '-'}
              </Col>
            </Row>

            <Jumbotron className="w-100 shadow-sm bg-white pt-1 pb-2 px-2 mt-3 mb-0">
              <Table
                striped
                hover
                responsive
                size="sm"
                className="w-100 mb-0"
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: '1rem',
                  lineHeight: '1.15rem',
                  fontFamily: 'Calibri, sans-serif',
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{ fontWeight: '800' }}
                      className="border-top-0 border-bottom-0"
                    >
                      Real Estate
                    </th>
                    <th
                      style={{ fontWeight: '800', textAlign: 'right' }}
                      className="border-top-0 border-bottom-0"
                    >
                      Taxable
                    </th>
                    <th
                      style={{ fontWeight: '800', textAlign: 'right' }}
                      className="border-top-0 border-bottom-0"
                    >
                      Market
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'center' }}>Residential</td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.RE_TAX_RES !== null
                        ? noCentsFormat(`${curPropVal.RE_TAX_RES}`)
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.RE_RES !== null
                        ? noCentsFormat(`${curPropVal.RE_RES}`)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>Agricultural</td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.RE_TAX_AGR !== null
                        ? noCentsFormat(`${curPropVal.RE_TAX_AGR}`)
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.RE_AGR !== null
                        ? noCentsFormat(`${curPropVal.RE_AGR}`)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>Commercial</td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.RE_TAX_COM !== null
                        ? noCentsFormat(`${curPropVal.RE_TAX_COM}`)
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.RE_COM !== null
                        ? noCentsFormat(`${curPropVal.RE_COM}`)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: '400', textAlign: 'right' }}>
                      Totals
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.RE_TAX_TOT !== null
                        ? noCentsFormat(`${curPropVal.RE_TAX_TOT}`)
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.RE_TOT !== null
                        ? noCentsFormat(`${curPropVal.RE_TOT}`)
                        : '-'}
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th
                      style={{ fontWeight: '800' }}
                      className="border-top-0 border-bottom-0"
                    >
                      Improvements
                    </th>
                    <th
                      style={{ fontWeight: '400', textAlign: 'right' }}
                      className="border-top-0 border-bottom-0"
                    ></th>
                    <th
                      style={{ fontWeight: '400', textAlign: 'right' }}
                      className="border-top-0 border-bottom-0"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'center' }}>Residential</td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.IM_TAX_RES !== null
                        ? noCentsFormat(`${curPropVal.IM_TAX_RES}`)
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.IM_RES !== null
                        ? noCentsFormat(`${curPropVal.IM_RES}`)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>Agricultural</td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.IM_TAX_AGR !== null
                        ? noCentsFormat(`${curPropVal.IM_TAX_AGR}`)
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.IM_AGR !== null
                        ? noCentsFormat(`${curPropVal.IM_AGR}`)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>Commercial</td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.IM_TAX_COM !== null
                        ? noCentsFormat(`${curPropVal.IM_TAX_COM}`)
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.IM_COM !== null
                        ? noCentsFormat(`${curPropVal.IM_COM}`)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: '400', textAlign: 'right' }}>
                      Totals
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.IM_TAX_TOT !== null
                        ? noCentsFormat(`${curPropVal.IM_TAX_TOT}`)
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.IM_TOT !== null
                        ? noCentsFormat(`${curPropVal.IM_TOT}`)
                        : '-'}
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th
                      style={{ fontWeight: '800' }}
                      className="border-top-0 border-bottom-0"
                    >
                      Greenbelt as of
                    </th>
                    <th
                      style={{ fontWeight: '400', textAlign: 'right' }}
                      className="border-top-0 border-bottom-0"
                    ></th>
                    <th
                      style={{ fontWeight: '400', textAlign: 'right' }}
                      className="border-top-0 border-bottom-0"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'center' }}>Real Estate</td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.GB_LAND !== null
                        ? noCentsFormat(`${curPropVal.GB_LAND}`)
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right' }}></td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>Home Site</td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.GB_TAX_HOMESITE !== null
                        ? noCentsFormat(`${curPropVal.GB_TAX_HOMESITE}`)
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.GB_HOMESITE !== null
                        ? noCentsFormat(`${curPropVal.GB_HOMESITE}`)
                        : '-'}{' '}
                      *
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: '400', textAlign: 'right' }}>
                      Totals
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.GB_TOT !== null
                        ? noCentsFormat(`${curPropVal.GB_TOT}`)
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right' }}></td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th
                      style={{
                        fontWeight: '400',
                        minHeight: '28.09px',
                        height: '28.09px',
                      }}
                      className="border-top-0 border-bottom-0"
                    >
                      {' '}
                    </th>
                    <th
                      style={{ fontWeight: '400', textAlign: 'right' }}
                      className="border-top-0 border-bottom-0"
                    ></th>
                    <th
                      style={{ fontWeight: '400', textAlign: 'right' }}
                      className="border-top-0 border-bottom-0"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'left' }}>Total Real Property</td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.RP_TAX_TOT !== null
                        ? noCentsFormat(`${curPropVal.RP_TAX_TOT}`)
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.RP_TOT !== null
                        ? noCentsFormat(`${curPropVal.RP_TOT}`)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>
                      Attached Personal Property
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.APP_VALUE !== null
                        ? noCentsFormat(`${curPropVal.APP_VALUE}`)
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right' }}></td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: '800', textAlign: 'left' }}>
                      Total Valuation
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {curPropVal.TOT_TAX_VAL !== null
                        ? noCentsFormat(`${curPropVal.TOT_TAX_VAL}`)
                        : '-'}
                    </td>
                    <td style={{ textAlign: 'right' }}></td>
                  </tr>
                </tbody>
              </Table>
              <p
                style={{
                  fontStyle: 'italic',
                  fontFamily: 'Calibri, sans-serif',
                  fontSize: '0.85rem',
                }}
                className="mb-0 mt-2"
              >
                * Note: This value is not included in the total.
              </p>
            </Jumbotron>
            <Row noGutters className="h-100 w-100 d-flex align-items-center">
              <Button
                className={'w-100 mt-3'}
                onClick={() => {
                  let valLink;
                  if (taxyear >= 2020)
                    valLink =
                      'https://www.utahcounty.gov/Dept/Treas/DuplicateNotices.asp';
                  else
                    valLink = `https://www.utahcounty.gov/Dept/Treas/DuplicateNoticeConfirm.asp?serialform-serial=${serial}&serialform-year=${taxyear}&serialform-type=VAL&serialform-submit=submit`;

                  window.open(valLink, '_blank', 'noopener,noreferrer');
                }}
              >
                <BoxArrowUpRight className="mr-2 mb-1" />
                Duplicate Valuation Notice
              </Button>
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PropertyValuationModal;
