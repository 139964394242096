import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import SearchForm from '../app/searchForm/SearchForm';
import SiteFooter from './SiteFooter';

// Home component returns homepage interface
const HomePage = () => (
  <>
    <div
      id="homepageContainer"
      className="d-flex flex-column flex-grow-1 p-0 overflow-auto"
    >
      <div
        id="homepageCover"
        className="d-flex flex-column justify-content-start pt-0 overflow-auto"
      >
        <Row
          className="w-100 pt-0 text-center d-flex justify-content-center"
          noGutters
        >
          <Col xs={10} md={12} className="pt-3 pt-md-5">
            <h1 id="homeTitle">Utah County Land Records (Beta)</h1>
          </Col>
          <Col
            xs={11}
            md={8}
            lg={7}
            className="pt-3 pt-md-5 px-0 px-md-2 mx-0 w-100"
          >
            <SearchForm />
          </Col>
        </Row>
      </div>
    </div>
    <SiteFooter style={{ position: 'sticky', bottom: '0' }} />
  </>
);

export default HomePage;
