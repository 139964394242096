import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectPhotosBySerial } from './propertyImagesSlice';
import DefaultPropertyImage from './DefaultPropertyImage';

const PropertyImage = ({ photoData, width = '5.5rem', height = '5.5rem' }) => (
  <div
    className="my-auto"
    style={{
      maxWidth: width,
      maxHeight: height,
      width: width,
      height: '100%',
      position: 'relative',
      fontFamily: 'Poppins, "Gill Sans MT", Calibri, sans-serif',
    }}
  >
    <img
      className="rounded mw-100 w-100 mh-100 h-100 shadow-sm"
      style={{ objectFit: 'cover' }}
      src={`${photoData}`}
      alt="Property Image"
    />
  </div>
);

export default PropertyImage;
