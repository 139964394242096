import { createSlice } from '@reduxjs/toolkit';
import { getFormDateFormat } from '../../utilities/date';

const initialState = {
  properties: {
    title: 'Properties',
    titleSingle: 'Property',
    subSearches: {
      address: {
        title: 'Address Search',
        description: 'Search property records by property location address',
        inputDescriptions: ['Search by property location address'],
        inputFields: [
          {
            house: {
              type: 'textbox',
              title: 'House Number',
              emptyCheck: true,
              mobileWidth: 6,
              desktopWidth: 3,
              value: '',
              hint: 'Example: 151',
              validators: [
                {
                  type: 'number',
                  min: 1,
                  max: 1000000,
                  message:
                    'House number must be a number between 0 and 1,000,000.',
                },
              ],
            },
            houseDir: {
              type: 'dropdown',
              title: 'House Direction',
              required: false,
              mobileWidth: 6,
              desktopWidth: 3,
              optionsEndpoint: null,
              options: [
                { title: '-- All --', value: '' },
                { title: 'North', value: 'N' },
                { title: 'East', value: 'E' },
                { title: 'South', value: 'S' },
                { title: 'West', value: 'W' },
              ],
              value: '',
              hint: 'Example: South',
            },
            street: {
              type: 'textbox',
              title: 'Street Name',
              mobileWidth: 6,
              desktopWidth: 3,
              value: '',
              hint: 'Example: University',
              validators: [
                {
                  type: 'length',
                  min: 2,
                  message: 'Street name must be at least two characters.',
                },
              ],
            },
            streetType: {
              type: 'dropdown',
              title: 'Street Type',
              required: false,
              mobileWidth: 6,
              desktopWidth: 3,
              optionsEndpoint: null,
              options: [
                { title: '-- All --', value: '' },
                { title: 'Drive', value: 'DR' },
                { title: 'Lane', value: 'LN' },
                { title: 'Road', value: 'RD' },
                { title: 'Street', value: 'ST' },
                { title: 'Way', value: 'W%Y' },
                { title: 'Circle', value: 'CIR' },
                { title: 'Avenue', value: 'AV%' },
                { title: 'Court', value: 'CT' },
                { title: 'Boulevard', value: 'BLVD' },
                { title: 'Place', value: 'PL' },
                { title: 'Parkway', value: 'PKWY' },
                { title: 'Loop', value: 'LOOP' },
                { title: 'Cove', value: 'CV' },
                { title: 'Row', value: 'ROW' },
                { title: 'Bend', value: 'BND' },
                // { title: 'Highway', value: 'HWY' },
                // { title: 'Alley', value: 'ALY' },
                // { title: 'Trail', value: 'TRL' },
                // { title: 'Ridge', value: 'RDG' },
                // { title: 'View', value: 'VW' },
                // { title: 'Hill', value: 'HL' },
                // { title: 'Point', value: 'PT' },
                // { title: 'Crest', value: 'CRST' },
                // { title: 'Manor', value: 'MNR' },
                // { title: 'Mountain', value: 'MTN' },
                // { title: 'Crossing', value: 'XING' },
                // { title: 'Run', value: 'RUN' },
                // { title: 'Park', value: 'PARK' },
                // { title: 'Station', value: 'STA' },
                // { title: 'Bridge', value: 'BRG' },
                // { title: 'Canyon', value: 'CANY' },
                // { title: 'Spur', value: 'SPUR' },
                // { title: 'Mount', value: 'MT' },
              ].sort((a, b) => a.title.localeCompare(b.title)),
              value: '',
              hint: 'Example: Avenue',
            },
            location: {
              type: 'dropdown',
              title: 'City/Location',
              required: false,
              mobileWidth: 12,
              desktopWidth: 7,
              dynamicOptions: 'allCities',
              options: [],
              value: '',
              hint: 'Example: Provo',
            },
            current: {
              type: 'dropdown',
              title: 'Current Records Filter',
              reqired: false,
              mobileWidth: 12,
              desktopWidth: 5,
              optionsEndpoint: null,
              options: [
                { title: '-- All --', value: '0' },
                { title: 'Current Records Only', value: '1' },
              ],
              value: '',
              hint: 'Example: Current Records Only',
            },
          },
        ],
        inputApiEndpoints: ['address'],
        sortOptions: {
          options: {
            address: {
              key: 'address',
              type: 'text',
              title: 'Address',
              apiName: 'ADDRESS',
            },
            serial: {
              key: 'serial',
              type: 'number',
              title: 'Serial/Parcel',
              apiName: 'SERIAL',
            },
            owner: {
              key: 'owner',
              type: 'text',
              title: 'Owner Name',
              apiName: 'OWNERNAME',
            },
            legalDesc: {
              key: 'legalDesc',
              type: 'text',
              title: 'Legal Description',
              apiName: 'LEGAL_DESC',
            },
            serialLife: {
              key: 'serialLife',
              type: 'number',
              title: 'Serial Life',
              apiName: 'SERIAL_LIFE',
            },
          },
          selection: '',
          order: 'ASC',
        },
      },
      name: {
        title: 'Name Search',
        description: 'Search property records by owner name',
        inputDescriptions: ['Search for real property records by owner name'],
        inputFields: [
          {
            lastName: {
              type: 'textbox',
              title: 'Last/Entity Name',
              namePart: true,
              mobileWidth: 12,
              desktopWidth: 5,
              value: '',
              hint: 'Example: Smith or Utah County',
              validators: [
                {
                  type: 'required',
                  message: 'Last/Entity Name required.',
                },
                {
                  type: 'length',
                  min: 2,
                  message: 'Last/Entity Name must be at least two characters.',
                },
              ],
            },
            firstName: {
              type: 'textbox',
              title: 'First Name',
              required: false,
              namePart: true,
              mobileWidth: 12,
              desktopWidth: 5,
              value: '',
              hint: 'Example: John',
              validators: [
                {
                  type: 'length',
                  min: 2,
                  message: 'First Name must be at least two characters.',
                },
              ],
            },
            valid: {
              type: 'dropdown',
              title: 'Current Records Filter',
              reqired: false,
              mobileWidth: 12,
              desktopWidth: 2,
              optionsEndpoint: null,
              options: [
                { title: '-- All --', value: '0' },
                { title: 'Current Only', value: '1' },
                { title: 'Never Valid Only', value: '-1' },
              ],
              value: '',
              hint: 'Example: Current Only',
            },
          },
        ],
        inputApiEndpoints: ['namesearch'],
        sortOptions: {
          options: {
            owner: {
              key: 'owner',
              type: 'text',
              title: 'Owner Name',
              apiName: 'OWNERNAME',
            },
            serial: {
              key: 'serial',
              type: 'number',
              title: 'Serial/Parcel',
              apiName: 'SERIAL',
            },
            address: {
              key: 'address',
              type: 'text',
              title: 'Address',
              apiName: 'ADDRESS',
            },
            legalDesc: {
              key: 'legalDesc',
              type: 'text',
              title: 'Legal Description',
              apiName: 'LEGAL_DESC',
            },
            yearsValid: {
              key: 'yearsValid',
              type: 'number',
              title: 'Years Valid',
              apiName: 'YEARSVALID',
            },
          },
          selection: 'owner',
          order: 'ASC',
        },
      },
      serial: {
        title: 'Serial Number Search',
        description:
          'Search property records by Utah County parcel or serial number',
        inputDescriptions: [
          'Search for real property records by serial/parcel number',
        ],
        inputFields: [
          {
            serial: {
              type: 'textbox',
              title: 'Serial/Parcel',
              mobileWidth: 12,
              desktopWidth: 12,
              value: '',
              hint: 'Examples: 570490001 OR 57:049:0001 OR 57 49 1',
              validators: [
                {
                  type: 'required',
                  message: 'Serial/parcel number required.',
                },
                {
                  type: 'serial',
                  message: 'Invalid serial/parcel number.',
                },
              ],
            },
            // changed this endpoint to only return 1, so a filter isn't required
            // valid: {
            //   type: 'dropdown',
            //   title: 'Current Records Filter',
            //   reqired: false,
            //   mobileWidth: 12,
            //   desktopWidth: 3,
            //   optionsEndpoint: null,
            //   options: [
            //     { title: '-- All --', value: '0' },
            //     { title: 'Current Records Only', value: '1' },
            //   ],
            //   value: '',
            //   hint: 'Example: Current Records Only',
            // },
          },
        ],
        inputApiEndpoints: ['serialsearch'],
      },
      development: {
        title: 'Development Search',
        description:
          'Search property parcel records by subdivision or development name',
        inputDescriptions: ['Search by relevant development information'],
        inputFields: [
          {
            name: {
              type: 'textbox',
              title: 'Development Name',
              required: false,
              emptyCheck: true,
              mobileWidth: 12,
              desktopWidth: 12,
              value: '',
              hint: 'Example: Utah County Health',
              validators: [
                {
                  type: 'length',
                  min: 2,
                  message: 'Development name must be at least two characters.',
                },
              ],
            },
            map_from: {
              type: 'textbox',
              title: 'Map Start',
              required: false,
              mobileWidth: 6,
              desktopWidth: 4,
              value: '',
              hint: 'Example: 9000',
              validators: [
                {
                  type: 'number',
                  min: 0,
                  max: 1000000,
                  message:
                    'Map start must be a number between 0 and 1,000,000.',
                },
                {
                  type: 'dependentRequired',
                  dependentKey: 'map_to',
                  message: 'Required because value exists for Map Start.',
                },
              ],
            },
            map_to: {
              type: 'textbox',
              title: 'Map End',
              required: false,
              mobileWidth: 6,
              desktopWidth: 4,
              value: '',
              hint: 'Example: 12000',
              validators: [
                {
                  type: 'number',
                  min: 0,
                  max: 1000000,
                  message: 'Map end must be a number between 0 and 1,000,000.',
                },
              ],
            },
            year: {
              type: 'textbox',
              title: 'Year',
              required: false,
              mobileWidth: 5,
              desktopWidth: 4,
              value: '',
              hint: 'Example: 2020',
              validators: [
                {
                  type: 'number',
                  min: 1800,
                  max: new Date().getFullYear(),
                  message: `Year must be between 1800 and ${new Date().getFullYear()}.`,
                },
              ],
            },
            type: {
              type: 'dropdown',
              title: 'Type',
              required: false,
              mobileWidth: 7,
              desktopWidth: 6,
              optionsEndpoint: null,
              options: [
                { title: '-- All --', value: '' },
                { title: 'Subdivision', value: 'S' },
                { title: 'Condominium', value: 'C' },
                { title: 'Annexation', value: 'A' },
                { title: 'Other', value: 'O' },
              ],
              value: '',
              hint: 'Example: Subdivision',
            },
            city: {
              type: 'dropdown',
              title: 'City/Location',
              required: false,
              mobileWidth: 12,
              desktopWidth: 6,
              dynamicOptions: 'allCities',
              options: [],
              value: '',
              hint: 'Example: Payson',
            },
            section: {
              type: 'textbox',
              title: 'Section',
              required: false,
              mobileWidth: 6,
              desktopWidth: 3,
              value: '',
              hint: 'Example: 6',
              validators: [
                {
                  type: 'number',
                  min: 1,
                  max: 99,
                  message: 'Section must be a number between 1 and 99.',
                },
              ],
            },
            township: {
              type: 'textbox',
              title: 'Township',
              required: false,
              mobileWidth: 6,
              desktopWidth: 3,
              value: '',
              hint: 'Example: 8',
              validators: [
                {
                  type: 'number',
                  min: 1,
                  max: 99,
                  message: 'Township must be a number between 1 and 99.',
                },
              ],
            },
            range: {
              type: 'textbox',
              title: 'Range',
              required: false,
              mobileWidth: 6,
              desktopWidth: 3,
              value: '',
              hint: 'Example: 3',
              validators: [
                {
                  type: 'number',
                  min: 1,
                  max: 99,
                  message: 'Range must be a number between 1 and 99.',
                },
              ],
            },
            range_dir: {
              type: 'dropdown',
              title: 'Range Direction',
              required: false,
              mobileWidth: 6,
              desktopWidth: 3,
              optionsEndpoint: null,
              options: [
                { title: '-- All --', value: '' },
                { title: 'East', value: 'E' },
                { title: 'West', value: 'W' },
              ],
              value: '',
              hint: 'Example: East',
            },
          },
        ],
        inputApiEndpoints: ['development'],
        sortOptions: {
          options: {
            map: {
              key: 'map',
              type: 'number',
              title: 'Map Number',
              apiName: 'NUMB',
            },
            city: {
              key: 'city',
              type: 'text',
              title: 'City',
              apiName: 'CITYNAME',
            },
            name: {
              key: 'name',
              type: 'text',
              title: 'Development Name',
              apiName: 'NAME',
            },
            book: {
              key: 'book',
              type: 'number',
              title: 'Book',
              apiName: 'BOOK',
            },
            page: {
              key: 'page',
              type: 'number',
              title: 'Page',
              apiName: 'PAGE',
            },
            type: {
              key: 'type',
              type: 'text',
              title: 'Development Type',
              apiName: 'TYPENAME',
            },
            section: {
              key: 'section',
              type: 'number',
              title: 'Section',
              apiName: 'SECTION',
            },
            township: {
              key: 'township',
              type: 'number',
              title: 'Township',
              apiName: 'TOWNSHIP',
            },
            range: {
              key: 'range',
              type: 'number',
              title: 'Range',
              apiName: 'RANGE',
            },
          },
          selection: '',
          order: 'ASC',
        },
        apiID: 'ID',
      },
    },
  },
  taxes: {
    title: 'Taxes',
    titleSingle: 'Tax',
    subSearches: {
      delinquentTax: {
        title: 'Delinquent Tax Search',
        description: 'Search delinquent tax records by owner name or serial',
        inputDescriptions: [
          'Search for delinquent tax records by owner name. Include a year to narrow your search or leave the year field blank to search all years',
          'Search for delinquent tax records by serial/parcel number. Include a year to narrow your search or leave the year field blank to search all years',
        ],
        inputFields: [
          {
            lastName: {
              type: 'textbox',
              title: 'Last/Entity Name',
              namePart: true,
              mobileWidth: 12,
              desktopWidth: 5,
              value: '',
              hint: 'Example: Smith or Example Trust LLC',
              validators: [
                {
                  type: 'required',
                  message: 'Last/Entity Name required.',
                },
                {
                  type: 'length',
                  min: 2,
                  message: 'Name must be at least two characters.',
                },
              ],
            },
            firstName: {
              type: 'textbox',
              title: 'First Name',
              required: false,
              namePart: true,
              mobileWidth: 12,
              desktopWidth: 5,
              value: '',
              hint: 'Example: John',
              validators: [
                {
                  type: 'length',
                  min: 2,
                  message: 'First Name must be at least two characters.',
                },
              ],
            },
            year: {
              type: 'textbox',
              title: 'Year',
              required: false,
              mobileWidth: 12,
              desktopWidth: 2,
              value: '',
              hint: `Example: ${new Date().getFullYear()}`,
              validators: [
                {
                  type: 'number',
                  min: 1800,
                  max: new Date().getFullYear(),
                  message: `Year must be between 1800 and ${new Date().getFullYear()}.`,
                },
              ],
            },
          },
          {
            serial: {
              type: 'textbox',
              title: 'Serial/parcel Number',
              mobileWidth: 12,
              desktopWidth: 9,
              value: '',
              hint: 'Examples: 570490001 OR 57:049:0001 OR 57 49 1',
              validators: [
                {
                  type: 'required',
                  message: 'Serial/parcel number required.',
                },
                {
                  type: 'serial',
                  message: 'Invalid serial/parcel number.',
                },
              ],
            },
            year: {
              type: 'textbox',
              title: 'Year',
              required: false,
              mobileWidth: 12,
              desktopWidth: 3,
              value: '',
              hint: `Example: ${new Date().getFullYear()}`,
              validators: [
                {
                  type: 'number',
                  min: 1800,
                  max: new Date().getFullYear(),
                  message: `Year must be between 1800 and ${new Date().getFullYear()}.`,
                },
              ],
            },
          },
        ],
        inputApiEndpoints: ['delinquenttax', 'delinquenttaxserial'],
        sortOptions: {
          options: {
            name: {
              key: 'name',
              type: 'text',
              title: 'Name',
              apiName: 'name',
            },
            serial: {
              key: 'serial',
              type: 'number',
              title: 'Serial/Parcel',
              apiName: 'serial',
            },
            balanceDue: {
              key: 'balanceDue',
              type: 'number',
              title: 'Balance Due',
              apiName: 'balanceDue',
            },
          },
          selection: '',
          order: 'ASC',
        },
      },
      taxHistory: {
        title: 'Tax History Search',
        description: 'Search for property tax history by serial/parcel number',
        inputDescriptions: ['Search by serial/parcel number'],
        inputFields: [
          {
            serial: {
              type: 'textbox',
              title: 'Serial/parcel Number',
              mobileWidth: 12,
              desktopWidth: 12,
              value: '',
              hint: 'Examples: 570490001 OR 57:049:0001 OR 57 49 1',
              validators: [
                {
                  type: 'required',
                  message: 'Serial/parcel number required.',
                },
                {
                  type: 'serial',
                  message: 'Invalid serial/parcel number.',
                },
              ],
            },
          },
        ],
        inputApiEndpoints: ['taxsummary'],
        sortOptions: {
          options: {
            taxYear: {
              key: 'taxYear',
              type: 'number',
              title: 'Tax Year',
              apiName: 'TAX_YEAR',
            },
            generalTaxes: {
              key: 'generalTaxes',
              type: 'number',
              title: 'General Taxes',
              apiName: 'ASMT_TAXES',
            },
            taxBalance: {
              key: 'taxBalance',
              type: 'number',
              title: 'Tax Balance',
              apiName: 'TAX_BALANCE',
            },
          },
          selection: '',
          order: 'ASC',
        },
      },
      taxPayments: {
        title: 'Tax Payments Search',
        description: 'Search for property tax payments by serial/parcel number',
        inputDescriptions: ['Search by serial/parcel number'],
        inputFields: [
          {
            serial: {
              type: 'textbox',
              title: 'Serial/parcel Number',
              mobileWidth: 12,
              desktopWidth: 12,
              value: '',
              hint: 'Examples: 570490001 OR 57:049:0001 OR 57 49 1',
              validators: [
                {
                  type: 'required',
                  message: 'Serial/parcel number required.',
                },
                {
                  type: 'serial',
                  message: 'Invalid serial/parcel number.',
                },
              ],
            },
          },
        ],
        inputApiEndpoints: ['taxpayments'],
        sortOptions: {
          options: {
            taxYear: {
              key: 'taxYear',
              type: 'number',
              title: 'Tax Year',
              apiName: 'TAX_YEAR',
            },
            payDate: {
              key: 'payDate',
              type: 'date',
              title: 'Pay Date',
              apiName: 'PAY_DATE',
            },
            totalPayment: {
              key: 'totalPayment',
              type: 'number',
              title: 'Total Payment',
              apiName: 'TOTAL_PAYMENTS',
            },
          },
          selection: '',
          order: 'ASC',
        },
      },
    },
  },
  documents: {
    title: 'Documents',
    titleSingle: 'Document',
    subSearches: {
      name: {
        title: 'Documents by Name',
        description: 'Search recorded documents by name',
        inputDescriptions: [
          `Search by party name. The KOI Group field is optional. If used, it will narrow the search by the KOI (Kind of Instrument) Group, or group of documents that are similar in type and description. For example, documents for mortgates all exist in the "Mortgage" KOI Group.`,
        ],
        inputFields: [
          {
            lastName: {
              type: 'textbox',
              title: 'Last/Entity Name',
              namePart: true,
              value: '',
              mobileWidth: 12,
              desktopWidth: 4,
              hint: 'Example: Smith or Utah County',
              validators: [
                {
                  type: 'required',
                  message: 'Last/Entity Name required.',
                },
                {
                  type: 'length',
                  min: 2,
                  message: 'Last/Entity Name must be at least two characters.',
                },
              ],
            },
            firstName: {
              type: 'textbox',
              title: 'First Name',
              namePart: true,
              value: '',
              mobileWidth: 12,
              desktopWidth: 4,
              hint: 'Example: John',
              validators: [
                {
                  type: 'length',
                  min: 2,
                  message: 'First Name must be at least two characters.',
                },
              ],
            },
            koigroup: {
              type: 'dropdown',
              title: 'KOI Code Group',
              required: false,
              mobileWidth: 12,
              desktopWidth: 4,
              dynamicOptions: 'allKoiCodeGroups',
              options: [],
              value: '',
              hint: 'Example: Federal Tax Liens',
            },
          },
        ],
        inputApiEndpoints: ['partyname'],
        sortOptions: {
          options: {
            name: {
              key: 'name',
              type: 'text',
              title: 'Name',
              apiName: 'NAME',
            },
            docCount: {
              key: 'docCount',
              type: 'number',
              title: 'Document Count',
              apiName: 'NAMECOUNT',
            },
          },
          selection: '',
          order: 'ASC',
        },
      },
      abstract: {
        title: 'Abstract Information',
        description: 'Display documents recorded for a property',
        inputDescriptions: ['Search by serial or parcel number'],
        inputFields: [
          {
            serial: {
              type: 'textbox',
              title: 'Serial/Parcel',
              value: '',
              hint: 'Examples: 570490001 OR 57:049:0001 OR 57 49 1',
              validators: [
                {
                  type: 'required',
                  message: 'Serial/parcel number required.',
                },
                {
                  type: 'serial',
                  message: 'Invalid serial/parcel number.',
                },
              ],
            },
          },
        ],
        inputApiEndpoints: ['abstract'],
        sortOptions: {
          options: {
            entry: {
              key: 'entry',
              type: 'number',
              title: 'Entry Number',
              apiName: 'ENTRY_NO',
            },
            koi: {
              key: 'koi',
              type: 'text',
              title: 'Kind of Instrument (KOI)',
              apiName: 'KOI',
            },
            grantor: {
              key: 'grantor',
              type: 'text',
              title: 'Grantor',
              apiName: 'GRANTOR',
            },
            grantee: {
              key: 'grantee',
              type: 'text',
              title: 'Grantee',
              apiName: 'GRANTEE',
            },
            book: {
              key: 'book',
              type: 'number',
              title: 'Book',
              apiName: 'RJ_BOOK',
            },
            page: {
              key: 'page',
              type: 'number',
              title: 'Page',
              apiName: 'RJ_PAGE',
            },
            instDate: {
              key: 'instDate',
              type: 'date',
              title: 'Instrument Date',
              apiName: 'INST_DATE',
            },
            recDate: {
              key: 'recDate',
              type: 'date',
              title: 'Recording Date',
              apiName: 'RJ_DATE',
            },
            consideration: {
              key: 'consideration',
              type: 'number',
              title: 'Consideration',
              apiName: 'CONSIDERATION',
            },
          },
          selection: '',
          order: 'ASC',
        },
      },
      interimAbstract: {
        title: 'Document Description Search (Interim Abstract)',
        description:
          'Search recorded documents by abbreviated legal description or location',
        inputDescriptions: [
          'Search by section, township, and range',
          'Search by subdivision, plat, and lot',
          'Search by city, plat, block, and lot',
        ],
        inputFields: [
          {
            section: {
              type: 'textbox',
              title: 'Section',
              mobileWidth: 6,
              desktopWidth: 3,
              value: '',
              hint: 'Example: 6',
              validators: [
                {
                  type: 'required',
                  message: 'Section required.',
                },
                {
                  type: 'number',
                  min: 1,
                  max: 99,
                  message: 'Section must be a number between 1 and 99.',
                },
              ],
            },
            township: {
              type: 'textbox',
              title: 'Township',
              mobileWidth: 6,
              desktopWidth: 3,
              value: '',
              hint: 'Example: 8',
              validators: [
                {
                  type: 'number',
                  min: 1,
                  max: 99,
                  message: 'Township must be a number between 1 and 99.',
                },
              ],
            },
            range: {
              type: 'textbox',
              title: 'Range',
              mobileWidth: 6,
              desktopWidth: 3,
              value: '',
              hint: 'Example: 3',
              validators: [
                {
                  type: 'number',
                  min: 1,
                  max: 99,
                  message: 'Range must be a number between 1 and 99.',
                },
              ],
            },
            range_dir: {
              type: 'dropdown',
              title: 'Range Direction',
              required: false,
              mobileWidth: 6,
              desktopWidth: 3,
              optionsEndpoint: null,
              options: [
                { title: '-- All --', value: '' },
                { title: 'East', value: 'E' },
                { title: 'West', value: 'W' },
              ],
              value: '',
              hint: 'Example: East',
            },
          },
          {
            name: {
              type: 'textbox',
              title: 'Subdivision',
              mobileWidth: 12,
              desktopWidth: 6,
              value: '',
              hint: 'Example: Utah',
              validators: [
                {
                  type: 'required',
                  message: 'Subdivision required.',
                },
                {
                  type: 'length',
                  min: 2,
                  message: 'Subdivision must be at least two characters.',
                },
              ],
            },
            plat: {
              type: 'textbox',
              title: 'Plat',
              mobileWidth: 6,
              desktopWidth: 3,
              value: '',
              hint: 'Example: B',
            },
            lot: {
              type: 'textbox',
              title: 'Lot',
              mobileWidth: 6,
              desktopWidth: 3,
              value: '',
              hint: 'Example: 120',
            },
          },
          {
            name: {
              type: 'textbox', // could make this a dropdown?
              title: 'City',
              mobileWidth: 12,
              desktopWidth: 6,
              value: '',
              hint: 'Example: Orem',
              validators: [
                {
                  type: 'required',
                  message: 'City required.',
                },
                {
                  type: 'length',
                  min: 2,
                  message: 'City must be at least two characters.',
                },
              ],
            },
            plat: {
              type: 'textbox',
              title: 'Plat',
              mobileWidth: 4,
              desktopWidth: 2,
              value: '',
              hint: 'Example: 2A',
            },
            block: {
              type: 'textbox',
              title: 'Block',
              mobileWidth: 4,
              desktopWidth: 2,
              value: '',
              hint: 'Example: C',
            },
            lot: {
              type: 'textbox',
              title: 'Lot',
              mobileWidth: 4,
              desktopWidth: 2,
              value: '',
              hint: 'Example: 6',
            },
          },
        ],
        inputApiEndpoints: ['docdesc', 'docdescsub', 'docdescsub'],
        sortOptions: {
          options: {
            entry: {
              key: 'entry',
              type: 'number',
              title: 'Entry Number',
              apiName: 'ENTRYNUM',
            },
            entryYear: {
              key: 'entryYear',
              type: 'number',
              title: 'Entry Year',
              apiName: 'EYEAR',
            },
            koi: {
              key: 'koi',
              type: 'text',
              title: 'Kind of Instrument (KOI)',
              apiName: 'KOI',
            },
            legalDesc: {
              key: 'legalDesc',
              type: 'text',
              title: 'Legal Description',
              apiName: 'LDESC',
            },
            recDate: {
              key: 'recDate',
              type: 'date',
              title: 'Recording Date',
              apiName: 'RJ_DATE',
            },
            grantor: {
              key: 'grantor',
              type: 'text',
              title: 'Grantor',
              apiName: 'P1',
            },
            grantee: {
              key: 'grantee',
              type: 'text',
              title: 'Grantee',
              apiName: 'P2',
            },
          },
          selection: '',
          order: 'ASC',
        },
      },
      lastAbstract: {
        title: 'Last Abstract Information',
        description: 'Display the location of the document abstract process',
      },
      crossReference: {
        title: 'Document/Parcel Cross-Reference Search',
        description:
          'Search recorded documents by document entry number or serial/parcel number',
        inputDescriptions: [
          'Search by entry and year to find the related property',
          'Search by serial to find related documents',
        ],
        inputFields: [
          {
            entry: {
              type: 'textbox',
              title: 'Entry',
              mobileWidth: 6,
              desktopWidth: 6,
              value: '',
              hint: 'Example: 96523',
              validators: [
                {
                  type: 'required',
                  message: 'Entry number required.',
                },
                {
                  type: 'number',
                  min: 0,
                  max: 1000000,
                  message: 'Entry must be a number between 0 and 1,000,000.',
                },
              ],
            },
            year: {
              type: 'textbox',
              title: 'Year',
              mobileWidth: 6,
              desktopWidth: 6,
              value: '',
              hint: 'Example: 2002',
              validators: [
                {
                  type: 'required',
                  message: 'Year required.',
                },
                {
                  type: 'number',
                  min: 1800,
                  max: new Date().getFullYear(),
                  message: `Year must be between 1800 and ${new Date().getFullYear()}.`,
                },
              ],
            },
          },
          {
            serial: {
              type: 'textbox',
              title: 'Serial/Parcel',
              mobileWidth: 12,
              desktopWidth: 12,
              value: '',
              hint: 'Examples: 570490001 OR 57:049:0001 OR 57 49 1',
              validators: [
                {
                  type: 'required',
                  message: 'Serial/parcel number required.',
                },
                {
                  type: 'serial',
                  message: 'Invalid serial/parcel number.',
                },
              ],
            },
          },
        ],
        inputApiEndpoints: ['serialbyentry', 'entrybyserial'],
        sortOptions: {
          options: {
            serial: {
              key: 'serial',
              type: 'number',
              title: 'Serial/Parcel Number',
              apiName: 'SERIAL_FORMATTED',
            },
            entry: {
              key: 'entry',
              type: 'number',
              title: 'Entry Number',
              apiName: 'ID',
            },
            koi: {
              key: 'koi',
              type: 'text',
              title: 'Kind of Instrument (KOI)',
              apiName: 'KOI',
            },
            grantor: {
              key: 'grantor',
              type: 'text',
              title: 'Grantor',
              apiName: 'PARTY1NAME',
            },
            grantee: {
              key: 'grantee',
              type: 'text',
              title: 'Grantee',
              apiName: 'PARTY2NAME',
            },
            book: {
              key: 'book',
              type: 'number',
              title: 'Book',
              apiName: 'RJ_BOOK',
            },
          },
          selection: '',
          order: 'ASC',
        },
      },
      document: {
        title: 'Documents',
        description:
          'Display recorded document by entry number or book and page',
        inputDescriptions: [
          'Search by entry number and year',
          'Search by book and page',
        ],
        inputFields: [
          {
            entry: {
              type: 'textbox',
              title: 'Entry',
              mobileWidth: 6,
              desktopWidth: 6,
              value: '',
              hint: 'Example: 96523',
              validators: [
                {
                  type: 'required',
                  message: 'Entry number required.',
                },
                {
                  type: 'number',
                  min: 0,
                  max: 1000000,
                  message: 'Entry must be a number between 0 and 1,000,000.',
                },
              ],
            },
            year: {
              type: 'textbox',
              title: 'Year',
              mobileWidth: 6,
              desktopWidth: 6,
              value: '',
              hint: 'Example: 2002',
              validators: [
                {
                  type: 'required',
                  message: 'Year required.',
                },
                {
                  type: 'number',
                  min: 1800,
                  max: new Date().getFullYear(),
                  message: `Year must be between 1800 and ${new Date().getFullYear()}.`,
                },
              ],
            },
          },
          {
            book: {
              type: 'textbox',
              title: 'Book',
              mobileWidth: 6,
              desktopWidth: 6,
              value: '',
              hint: 'Example: 2910',
              validators: [
                {
                  type: 'required',
                  message: 'Book required.',
                },
                {
                  type: 'number',
                  min: 0,
                  max: 100000,
                  message: 'Book must be a number between 0 and 100,000.',
                },
              ],
            },
            page: {
              type: 'textbox',
              title: 'Page',
              mobileWidth: 6,
              desktopWidth: 6,
              value: '',
              hint: 'Example: 100',
              validators: [
                {
                  type: 'required',
                  message: 'Page required.',
                },
                {
                  type: 'number',
                  min: 0,
                  max: 100000,
                  message: 'Page must be a number between 0 and 100,000.',
                },
              ],
            },
          },
        ],
        inputApiEndpoints: ['document', 'documentbybookpage'],
        resultListType: 'list', // single value
        // entry-year | koi
        // grantor
        // grantee
        detailViewType: 'single', // document
      },
      documentRecording: {
        title: 'Document Recording Search',
        description: `Search Recorder's Office document recordings by entry number or recording date`,
        inputDescriptions: [
          'Search by entry number and year',
          'Search by date range',
        ],
        inputFields: [
          {
            entry: {
              type: 'textbox',
              title: 'Entry',
              mobileWidth: 6,
              desktopWidth: 6,
              value: '',
              hint: 'Example: 96523',
              validators: [
                {
                  type: 'required',
                  message: 'Entry number required.',
                },
                {
                  type: 'number',
                  min: 0,
                  max: 1000000,
                  message: 'Entry must be a number between 0 and 1,000,000.',
                },
              ],
            },
            year: {
              type: 'textbox',
              title: 'Year',
              mobileWidth: 6,
              desktopWidth: 6,
              value: '',
              hint: 'Example: 2002',
              validators: [
                {
                  type: 'required',
                  message: 'Year required.',
                },
                {
                  type: 'number',
                  min: 1800,
                  max: new Date().getFullYear(),
                  message: `Year must be between 1800 and ${new Date().getFullYear()}.`,
                },
              ],
            },
          },
          {
            startdate: {
              type: 'date',
              title: 'Start Date',
              mobileWidth: 12,
              desktopWidth: 6,
              value: getFormDateFormat(new Date()),
              hint: `Example: ${new Date().toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}`,
              validators: [
                {
                  type: 'required',
                  message: 'Start date required.',
                },
                {
                  type: 'dateValid',
                  message: 'Invalid start date.',
                },
              ],
            },
            enddate: {
              type: 'date',
              title: 'End Date',
              required: false,
              mobileWidth: 12,
              desktopWidth: 6,
              value: getFormDateFormat(new Date()),
              hint: `Example: ${new Date().toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}`,
              validators: [
                {
                  type: 'dateValid',
                  message: 'Invalid end date.',
                },
                {
                  type: 'dateCompare',
                  min: 'startdate',
                  message: 'End date must be greater or equal to start date.',
                },
              ],
            },
          },
        ],
        inputApiEndpoints: ['recordings', 'recordingsdate'],
        sortOptions: {
          options: {
            entry: {
              key: 'entry',
              type: 'number',
              title: 'Entry Number',
              apiName: 'ID',
            },
            fees: {
              key: 'fees',
              type: 'number',
              title: 'Fees',
              apiName: 'RJ_FEES',
            },
            recDate: {
              key: 'recDate',
              type: 'date',
              title: 'Recording Date',
              apiName: 'RJ_DATE',
            },
            recordedBy: {
              key: 'recordedBy',
              type: 'text',
              title: 'Recorded By',
              apiName: 'RECORDEDBY',
            },
            pages: {
              key: 'pages',
              type: 'number',
              title: 'Pages',
              apiName: 'RJ_PAGES',
            },
          },
          selection: '',
          order: 'ASC',
        },
      },

      koi: {
        title: 'Kind of Instrument (KOI) Search',
        description:
          'Search recorded documents by document type (KOI - Kind of Instrument)',
        // KOI Code > dropdown contains KOIGroup>Koi code/description | beginning rec date
        inputDescriptions: [
          `Kind of Instrument (KOI) codes describe documents by type and purpose. For example, "D TR" is a DEED OF TRUST document used in the context of mortgages. In this case, "D TR" is the KOI Code and "Mortgage" is the more general KOI Code Group.\nUse the KOI Code Group dropdown to help narrow your search, but only the KOI Code is required to start searching for documents. Using the Beginning Recording Date narrow your search.`,
        ],
        inputFields: [
          {
            koigroup: {
              type: 'dropdown',
              title: 'KOI Code Group',
              mobileWidth: 12,
              desktopWidth: 3,
              dynamicOptions: 'allKoiCodeGroups',
              options: [],
              value: '',
              hint: 'Example: Judgment Liens',
            },
            koi: {
              type: 'dropdown',
              title: 'KOI Code',
              mobileWidth: 12,
              desktopWidth: 6,
              dynamicOptions: 'koiCodes',
              options: [],
              value: '',
              hint: 'Example: A ABSTJ (JUDGMENT ABSTRACT OF AMENDED)',
              validators: [
                {
                  type: 'required',
                  message: 'KOI Code required.',
                },
              ],
            },
            recordingDate: {
              type: 'date',
              title: 'Beginning Recording Date',
              reqired: false,
              mobileWidth: 12,
              desktopWidth: 3,
              value: '',
              hint: `Example: ${new Date().toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}`,
              validators: [
                {
                  type: 'dateValid',
                  message: 'Invalid beginning recording date.',
                },
              ],
            },
          },
        ],
        inputApiEndpoints: ['dockoi'], // api needs work on handling this
        sortOptions: {
          options: {
            entry: {
              key: 'entry',
              type: 'number',
              title: 'Entry Number',
              apiName: 'ID',
            },
            recDate: {
              key: 'recDate',
              type: 'date',
              title: 'Recording Date',
              apiName: 'RJ_DATE',
            },
            grantor: {
              key: 'grantor',
              type: 'text',
              title: 'Grantor',
              apiName: 'PARTY1NAME',
            },
            grantee: {
              key: 'grantee',
              type: 'text',
              title: 'Grantee',
              apiName: 'PARTY2NAME',
            },
          },
          selection: '',
          order: 'ASC',
        },
      },
      mining: {
        title: 'Mining Documents Search',
        description: 'Search and display documents pertaining to mining',
        inputType: 'singleTextBox', // related keywords
        inputDescriptions: ['Search for documents by related keywords'],
        inputFields: [
          {
            keywords: {
              type: 'textbox',
              title: 'Keywords',
              mobileWidth: 12,
              desktopWidth: 12,
              value: '',
              hint: 'Example: Red Cross',
              validators: [
                {
                  type: 'required',
                  message: 'Keywords required.',
                },
                {
                  type: 'length',
                  min: 3,
                  message: 'Keywords must be at least three characters.',
                },
              ],
            },
          },
        ],
        inputApiEndpoints: ['mining'],
        sortOptions: {
          options: {
            entry: {
              key: 'entry',
              type: 'number',
              title: 'Entry Number',
              apiName: 'RECORD_ID',
            },
            koi: {
              key: 'koi',
              type: 'text',
              title: 'Kind of Instrument (KOI)',
              apiName: 'KOI',
            },
            recDate: {
              key: 'recDate',
              type: 'date',
              title: 'Recording Date',
              apiName: 'RJ_DATE',
            },
          },
          selection: '',
          order: 'ASC',
        },
      },
      waterRights: {
        title: 'Water Rights Documents Search',
        description: 'Search and display documents pertaining to water rights',
        inputType: 'multiTextBoxX2',
        // prefix | suffix
        inputDescriptions: [
          'Search by the prefix and suffix of the water right number',
          'Search by the document recording year',
        ],
        inputFields: [
          {
            prefix: {
              type: 'textbox',
              title: 'Prefix',
              mobileWidth: 12,
              desktopWidth: 6,
              value: '',
              hint: 'Example: 55',
              validators: [
                {
                  type: 'required',
                  message: 'Prefix required.',
                },
                {
                  type: 'number',
                  min: 0,
                  max: 1000000,
                  message: 'Prefix must be a number between 0 and 1,000,000.',
                },
              ],
            },
            suffix: {
              type: 'textbox',
              title: 'Suffix',
              mobileWidth: 12,
              desktopWidth: 6,
              value: '',
              hint: 'Example: 3150',
              validators: [
                {
                  type: 'required',
                  message: 'Suffix required.',
                },
                {
                  type: 'number',
                  min: 0,
                  max: 1000000,
                  message: 'Suffix must be a number between 0 and 1,000,000.',
                },
              ],
            },
          },
          {
            year: {
              type: 'textbox',
              title: 'Year',
              mobileWidth: 12,
              desktopWidth: 12,
              value: '',
              hint: `Example: ${new Date().getFullYear()}`,
              validators: [
                { type: 'required', message: 'Year required.' },
                {
                  type: 'number',
                  min: 1800,
                  max: new Date().getFullYear(),
                  message: `Year must be between 1800 and ${new Date().getFullYear()}.`,
                },
              ],
            },
          },
        ],
        inputApiEndpoints: ['waterrights', 'waterrightsyear'],
        sortOptions: {
          options: {
            entry: {
              key: 'entry',
              type: 'number',
              title: 'Entry Number',
              apiName: 'RJ_ID',
            },
            koi: {
              key: 'koi',
              type: 'text',
              title: 'Kind of Instrument (KOI)',
              apiName: 'KOI',
            },
            legalDesc: {
              key: 'legalDesc',
              type: 'text',
              title: 'Legal Description',
              apiName: 'DESCR',
            },
            recDate: {
              key: 'recDate',
              type: 'date',
              title: 'Recording Date',
              apiName: 'RJ_DATE',
            },
            grantor: {
              key: 'grantor',
              type: 'text',
              title: 'Grantor',
              apiName: 'PARTY1NAME',
            },
            grantee: {
              key: 'grantee',
              type: 'text',
              title: 'Grantee',
              apiName: 'PARTY2NAME',
            },
          },
          selection: '',
          order: 'ASC',
        },
      },
    },
  },
  maps: {
    title: 'Maps',
    titleSingle: 'Map',
    subSearches: {
      gis: {
        title: 'Maps Home Page',
        description:
          'Opens Utah County Geographic Information Systems (GIS) home page in a new tab',
      },
      parcel: {
        title: 'Parcel Map',
        description: 'Opens Utah County Parcel Map in a new tab',
      },
      propNotification: {
        title: 'Property Notification Map',
        description: 'Opens Utah County Property Notification Map in a new tab',
      },
      appLinks: {
        title: 'Mobile Apps',
        description:
          'Get the Utah County Parcel Map application on iOS or Android in a new tab',
      },
    },
  },
};

// subSearch dropdown will not show title, but labels will
// appear to help the user know which search is currently selected

export const slice = createSlice({
  name: 'app/defaultSearches',
  initialState: initialState,
  reducers: {},
});

// export selectors
export const selectAllDefaultSearches = state => state.defaultSearches;
export const selectDefaultMainSearch = mainSearch => state => {
  if (mainSearch in state.defaultSearches)
    return state.defaultSearches[mainSearch];
  console.error(`Main search ${mainSearch} does not exist.`);
};
export const selectDefaultSubSearch = (mainSearch, subSearch) => state => {
  if (
    mainSearch in state.defaultSearches &&
    subSearch in state.defaultSearches[mainSearch].subSearches
  )
    return state.defaultSearches[mainSearch].subSearches[subSearch];
  console.error(
    `Subsearch ${subSearch} of main search ${mainSearch} does not exist.`
  );
};
export const selectFirstDefaultSubSearch = mainSearch => state => {
  if (mainSearch in state.defaultSearches)
    return Object.values(state.defaultSearches[mainSearch].subSearches)[0];
  console.error(`Main search ${mainSearch} does not exist.`);
};

export const selectCurrentSortOptions = (mainSearch, subSearch) => state =>
  state.defaultSearches[mainSearch].subSearches[subSearch].sortOptions;

// export slice
export default slice.reducer;
