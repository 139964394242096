import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  error: null,
  status: 'notSent',
};

// sendFeedback provides the redux interface to create an async action that
// uses the local app's server's /sendfeedback endpoint to send the form payload
export const sendFeedback = createAsyncThunk(
  'app/feedbackModal',
  async email => {
    if (
      !email ||
      !email.feedbackType ||
      !email.feedbackEmail ||
      !email.feedbackComments
    ) {
      return Error(
        'Feedback not sent. Missing a required field: Type, Email, or Comments.'
      );
    }
    let response;
    try {
      response = await axios.post('/sendfeedback', email);
    } catch (err) {
      console.error(err);
      return err;
    }
    return response.data;
  }
);

export const slice = createSlice({
  name: 'app/feedbackModal',
  initialState,
  reducers: {
    updateFeedbackModalStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: {
    [sendFeedback.pending]: (state, action) => {
      state.status = 'sending';
    },
    [sendFeedback.fulfilled]: (state, action) => {
      state.status = 'succeeded';
    },
    [sendFeedback.rejected]: (state, action) => {
      state.status = 'failed';
    },
  },
});

export const { updateFeedbackModalStatus } = slice.actions;
// export slice
export default slice.reducer;
