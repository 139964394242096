import React from 'react';

import Alert from 'react-bootstrap/Alert';
import { useState } from 'react';


function RedirectAlert() {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert variant="info" onClose={() => setShow(false)} dismissible>
        Prefer the old Land-Records website? {' '}
        <Alert.Link href="https://www.utahcounty.gov/LandRecords/Index.asp">
          Link available here or footer of this site.
        </Alert.Link>{' '}
      </Alert>
    )
  }
  else {
    return (
      <div></div>
    )
  }
};

export default RedirectAlert;
