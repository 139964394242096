import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const initialState = {
  status: 'loading',
  error: null,
  lastAbstract: null,
};

export const fetchLastAbstractInfo = createAsyncThunk(
  'app/lastAbstractModal/fetchLastAbstractInfo',
  async ({ url }) => {
    let lastAbstract;
    try {
      lastAbstract = (
        await axios({
          url: `${url}/lastabstractinfo`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data[0];
    } catch (err) {
      console.error(err);
      return;
    }
    return lastAbstract;
  }
);

export const slice = createSlice({
  name: 'app/lastAbstractModal',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchLastAbstractInfo.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchLastAbstractInfo.fulfilled]: (state, action) => {
      state.lastAbstract = action.payload;
      state.status = 'succeeded';
    },
    [fetchLastAbstractInfo.rejected]: (state, action) => {
      state.error = action.error.message;
      state.status = 'failed';
    },
  },
});

export default slice.reducer;
