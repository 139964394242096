import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'axios';

const docNameAdapter = createEntityAdapter({
  selectId: e => `${e.nameID}`,
});

const initialState = docNameAdapter.getInitialState({
  currentName: null,
  status: 'loading',
  error: null,
});

export const fetchDocNameList = createAsyncThunk(
  'app/documentNameDetailView/fetchDocNameList',
  async ({ url, nameID }) => {
    let docNameList;
    try {
      docNameList = (
        await axios({
          url: `${url}/partydetail?nameID=${nameID}`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data;
    } catch (err) {
      console.error(err);
      return;
    }
    return { nameID, docNameList };
  }
);

export const slice = createSlice({
  name: 'app/documentNameDetailView',
  initialState: initialState,
  reducers: {
    setCurrentName: (state, action) => {
      state.currentName = action.payload.currentName;
    },
    emptyDocNameList: docNameAdapter.removeAll,
  },
  extraReducers: {
    [fetchDocNameList.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchDocNameList.fulfilled]: (state, action) => {
      docNameAdapter.upsertOne(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchDocNameList.rejected]: (state, action) => {
      state.error = action.error.message;
      state.status = 'failed';
    },
  },
});

export const { setCurrentName, emptyDocNameList } = slice.actions;

export const {
  selectEntities: selectAllDocNameLists,
  selectById: selectDocNameListById,
  selectIds: selectAllDocNameListIDs,
} = docNameAdapter.getSelectors(state => state.documentNameDetailView);

export default slice.reducer;
