import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'axios';

const documentAdapter = createEntityAdapter({
  selectId: e =>
    e && e.INFO && e.INFO.length > 0
      ? `${e.INFO[0].RJ_ENTRY_NO}-${e.INFO[0].RJ_ENTRY_YR}`
      : null,
});

const initialState = documentAdapter.getInitialState({
  status: 'loading',
  error: null,
});

export const fetchDocumentInfo = createAsyncThunk(
  'app/documentDetailView/fetchDocumentDetailViewInfo',
  async ({ url, entryNumber }) => {
    const entryData = entryNumber.split('-');
    if (entryData.length !== 2 || entryData.includes(null, 'null')) {
      console.error('Invalid entry number.');
      state.status = 'failed';
      return;
    }
    let doc;
    try {
      doc = (
        await axios({
          url: `${url}/document?entry=${entryNumber.split('-')[0]}&year=${
            entryNumber.split('-')[1]
          }`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data[0];
    } catch (err) {
      console.error(err);
      return;
    }
    return doc;
  }
);

export const slice = createSlice({
  name: 'app/documentDetailView',
  initialState: initialState,
  reducers: {
    emptyDocuments: documentAdapter.removeAll,
  },
  extraReducers: {
    [fetchDocumentInfo.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchDocumentInfo.fulfilled]: (state, action) => {
      documentAdapter.upsertOne(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchDocumentInfo.rejected]: (state, action) => {
      state.error = action.error.message;
      state.status = 'failed';
    },
  },
});

export const { emptyDocuments } = slice.actions;

export const {
  selectEntities: selectAllDocuments,
  selectById: selectDocumentById,
  selectIds: selectAllDocumentIDs,
} = documentAdapter.getSelectors(state => state.documentDetailView);

export default slice.reducer;
