import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import {
  Plus,
  X,
  BoxArrowUpRight,
  Telephone,
  Window,
  Mailbox,
  Envelope,
  Bank2,
} from 'react-bootstrap-icons';

import {
  fetchTaxPayoffList,
  selectAllTaxPayoffLists,
  selectTaxPayoffListById,
  selectAllTaxPayoffListIDs,
} from './delinquentTaxDetailViewSlice';

import { withCentsFormat } from '../../../utilities/currency';
import { useHistory, useLocation } from 'react-router-dom';
import { setCurrentID, setSearchDidRun } from '../../app/searchSlice';
import { queryToString } from '../../../utilities/url';
import { getFormDateFormat, getOffsetDate } from '../../../utilities/date';

const DelinquentTaxDetailView = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const detailViewStatus = useSelector(
    state => state.delinquentTaxDetailView.status
  );
  const searchStatus = useSelector(state => state.search.status);
  const dispatch = useDispatch();
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const currentID = useSelector(state => state.search.currentID);
  const curTaxPayoffList = useSelector(state =>
    selectTaxPayoffListById(state, currentID)
  );
  const allDocNameListIDs = useSelector(selectAllTaxPayoffListIDs);
  const history = useHistory();
  const router = useSelector(state => state.router);
  const currentName = useSelector(
    state => state.delinquentTaxDetailView.currentName
  );
  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleDateString()
  );

  const [payoffDateValue, setPayoffDateValue] = useState(
    new Date().toLocaleDateString()
  );
  const [partialReload, setPartialReload] = useState(false);
  const [viewStatus, setViewStatus] = useState('loading');

  useEffect(() => {
    if (currentID && mainSearch === 'taxes') {
      const _currentDate = new Date().toLocaleDateString();
      setCurrentDate(_currentDate);
      setPayoffDateValue(_currentDate);
      dispatch(
        fetchTaxPayoffList({
          url: baseApiUrl,
          serial: currentID,
          date: _currentDate,
        })
      );
      const query = queryToString({
        query: router.location.query,
        currentID: currentID,
      });
      if (!router.location.query.currentID)
        history.replace(`${router.location.pathname}?${query}`);
    }
  }, [currentID]);

  useEffect(() => {
    if (currentID)
      dispatch(
        fetchTaxPayoffList({
          url: baseApiUrl,
          serial: currentID,
          date: new Date(currentDate).toLocaleDateString(),
        })
      );
  }, [currentDate]);

  useEffect(() => {
    setPartialReload(false);
  }, [curTaxPayoffList]);

  useEffect(() => {
    if (detailViewStatus === 'loading') setViewStatus('loading');
    if (
      detailViewStatus === 'failed' ||
      (detailViewStatus === 'succeeded' && !curTaxPayoffList) ||
      (searchStatus === 'succeeded' && !currentID)
    )
      setViewStatus('failed');
    if (detailViewStatus === 'succeeded' && curTaxPayoffList)
      setViewStatus('succeeded');
  }, [currentID, detailViewStatus, searchStatus, curTaxPayoffList]);

  return (
    <>
      {viewStatus === 'loading' && !partialReload && (
        <Row
          noGutters
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '20rem', width: '20rem' }}
          />
        </Row>
      )}
      {viewStatus === 'failed' && (
        <Row
          noGutters
          className="h-100 d-flex flex-column align-items-center justify-content-center p-2"
          style={{ backgroundColor: 'rgba(217, 190, 80, 0.1)' }}
        >
          <Bank2
            height={mobileView ? '4rem' : '8rem'}
            width={mobileView ? '4rem' : '8rem'}
            style={{ color: 'rgba(78, 128, 166, 1)' }}
          />
          <h5 className="text-center mt-4" style={{ fontSize: '1.5rem' }}>
            Could not find delinquent tax information.
          </h5>
          <h5 className="text-center mt-2" style={{ fontSize: '1.5rem' }}>
            Please try another search.
          </h5>
        </Row>
      )}
      {viewStatus === 'succeeded' && curTaxPayoffList && (
        <>
          <Row noGutters className="w-100 pt-3 px-3">
            <Jumbotron className="w-100 shadow-sm bg-white p-2 mb-3 d-flex flex-column">
              <div
                className={`w-100 d-flex justify-content-between align-items-center${
                  mobileView ? ' flex-column' : ''
                }`}
              >
                <h5 className={mobileView ? 'w-100 mb-0' : 'my-1'}>
                  Utah County Treasurer Delinquent Tax Summary for{' '}
                  <span className="text-primary" style={{ fontWeight: '400' }}>
                    {curTaxPayoffList.serialFormatted}
                  </span>
                </h5>
                <div
                  className={`${
                    mobileView
                      ? 'w-100'
                      : 'flex-grow-1 d-flex justify-content-end align-items-center'
                  }`}
                >
                  <Form
                    inline
                    onSubmit={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (currentDate !== payoffDateValue) {
                        setPartialReload(true);
                        setCurrentDate(payoffDateValue);
                      }
                    }}
                    onKeyUp={e => {
                      if (e.key === 13) {
                        e.preventDefault();
                        e.stopPropagation();
                        if (currentDate !== payoffDateValue) {
                          setPartialReload(true);
                          setCurrentDate(payoffDateValue);
                        }
                      }
                    }}
                  >
                    <Form.Label
                      className={`${
                        mobileView
                          ? 'mt-3 mb-1'
                          : 'p-2 rounded-left border-top border-left border-bottom border-primary'
                      }`}
                      style={{
                        backgroundColor: mobileView ? undefined : '#e9ecef',
                        lineHeight: mobileView ? undefined : '1.15rem',
                        fontSize: mobileView ? undefined : '1rem',
                      }}
                    >
                      Payoff Date
                    </Form.Label>
                    <Form.Control
                      type="date"
                      defaultValue={getFormDateFormat(currentDate)}
                      // value={getFormDateFormat(currentDate)}
                      className={`${
                        mobileView ? '' : 'p-2 rounded-0 border border-primary'
                      }`}
                      style={{
                        maxHeight: '36px',
                        lineHeight: mobileView ? undefined : '1.15rem',
                        fontSize: mobileView ? undefined : '1rem',
                      }}
                      onChange={e => setPayoffDateValue(e.target.value)}
                    />
                    <Button
                      type="submit"
                      className={
                        mobileView
                          ? `w-100 mt-3${
                              partialReload || currentDate !== payoffDateValue
                                ? ' bg-success'
                                : ''
                            }`
                          : `p-2 rounded-right border-left-0 border-top border-right border-bottom border-primary${
                              partialReload || currentDate !== payoffDateValue
                                ? ' bg-success'
                                : ''
                            }`
                      }
                      style={{
                        maxHeight: '36px',
                        fontWeight: '100',
                        lineHeight: mobileView ? undefined : '1.15rem',
                        fontSize: mobileView ? undefined : '1rem',
                        borderTopLeftRadius: mobileView ? undefined : '0',
                        borderBottomLeftRadius: mobileView ? undefined : '0',
                      }}
                    >
                      Calculate Payoff
                    </Button>
                  </Form>
                </div>
              </div>
              <hr className="w-100" />
              {(!curTaxPayoffList ||
                curTaxPayoffList.taxPayoffList.length === 0) && (
                <Row noGutters className="h-100">
                  <h5 className="mx-auto text-success d-flex align-items-center justify-content-center">
                    {`Balance has been PAID.`}
                  </h5>
                </Row>
              )}
              {curTaxPayoffList && curTaxPayoffList.taxPayoffList.length > 0 && (
                <div className="overflow-auto">
                  <Table
                    striped
                    hover
                    size="sm"
                    className="w-100 mb-0 shadow-sm"
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: '1rem',
                      lineHeight: '1.15rem',
                      fontFamily: 'Calibri, sans-serif',
                      maxHeight: '100%',
                    }}
                  >
                    <thead className="stickyTop bg-white">
                      <tr>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Year
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Tax Balance
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Penalty
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Fees
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Interest
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {curTaxPayoffList.taxPayoffList.map((t, i) => (
                        <tr key={i}>
                          <td className="py-2">{t.TAX_YEAR || '-'}</td>
                          <td className="py-2">
                            {!isNaN(parseFloat(`${t.TAX_BALANCE}`))
                              ? withCentsFormat(`${t.TAX_BALANCE}`)
                              : '-'}
                          </td>
                          <td className="py-2">
                            {!isNaN(parseFloat(`${t.PENALTY}`))
                              ? withCentsFormat(`${t.PENALTY}`)
                              : '-'}
                          </td>
                          <td className="py-2">
                            {!isNaN(parseFloat(`${t.FEES}`))
                              ? withCentsFormat(`${t.FEES}`)
                              : '-'}
                          </td>
                          <td className="py-2">
                            {!isNaN(parseFloat(`${t.INTEREST}`))
                              ? withCentsFormat(`${t.INTEREST}`)
                              : '-'}
                          </td>
                          <td className="py-2">
                            {!isNaN(parseFloat(`${t.TOTAL}`))
                              ? withCentsFormat(`${t.TOTAL}`)
                              : '-'}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td
                          style={{ fontWeight: '400' }}
                          className="py-2 border-top border-dark"
                        >
                          TOTAL
                        </td>
                        <td
                          style={{ fontWeight: '400' }}
                          className="py-2 border-top border-dark"
                        >
                          {!isNaN(
                            parseFloat(
                              `${curTaxPayoffList.taxPayoffList
                                .map(t => t.TAX_BALANCE)
                                .reduce((sum, t) => sum + t, 0)}`
                            )
                          )
                            ? withCentsFormat(
                                `${curTaxPayoffList.taxPayoffList
                                  .map(t => t.TAX_BALANCE)
                                  .reduce((sum, t) => sum + t, 0)}`
                              )
                            : '-'}
                        </td>
                        <td
                          style={{ fontWeight: '400' }}
                          className="py-2 border-top border-dark"
                        >
                          {!isNaN(
                            parseFloat(
                              `${curTaxPayoffList.taxPayoffList
                                .map(t => t.PENALTY)
                                .reduce((sum, t) => sum + t, 0)}`
                            )
                          )
                            ? withCentsFormat(
                                `${curTaxPayoffList.taxPayoffList
                                  .map(t => t.PENALTY)
                                  .reduce((sum, t) => sum + t, 0)}`
                              )
                            : '-'}
                        </td>
                        <td
                          style={{ fontWeight: '400' }}
                          className="py-2 border-top border-dark"
                        >
                          {!isNaN(
                            parseFloat(
                              `${curTaxPayoffList.taxPayoffList
                                .map(t => t.FEES)
                                .reduce((sum, t) => sum + t, 0)}`
                            )
                          )
                            ? withCentsFormat(
                                `${curTaxPayoffList.taxPayoffList
                                  .map(t => t.FEES)
                                  .reduce((sum, t) => sum + t, 0)}`
                              )
                            : '-'}
                        </td>
                        <td
                          style={{ fontWeight: '400' }}
                          className="py-2 border-top border-dark"
                        >
                          {!isNaN(
                            parseFloat(
                              `${curTaxPayoffList.taxPayoffList
                                .map(t => t.INTEREST)
                                .reduce((sum, t) => sum + t, 0)}`
                            )
                          )
                            ? withCentsFormat(
                                `${curTaxPayoffList.taxPayoffList
                                  .map(t => t.INTEREST)
                                  .reduce((sum, t) => sum + t, 0)}`
                              )
                            : '-'}
                        </td>
                        <td
                          style={{ fontWeight: '400' }}
                          className="py-2 border-top border-dark"
                        >
                          {!isNaN(
                            parseFloat(
                              `${curTaxPayoffList.taxPayoffList
                                .map(t => t.TOTAL)
                                .reduce((sum, t) => sum + t, 0)}`
                            )
                          )
                            ? withCentsFormat(
                                `${curTaxPayoffList.taxPayoffList
                                  .map(t => t.TOTAL)
                                  .reduce((sum, t) => sum + t, 0)}`
                              )
                            : '-'}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
            </Jumbotron>
          </Row>
          <Row noGutters className="w-100 px-3">
            <Jumbotron
              className="w-100 shadow-sm bg-white p-2 mb-3 d-flex flex-column"
              // style={{ borderLeft: 'solid 0.25rem #dc3545' }}
            >
              <h5 className="mt-2 mb-0">Payoff Amount</h5>
              <hr className="w-100" />
              <h6>
                <span className="mr-2">
                  Total amount if paid as of {mobileView && <br />}
                  <span className="mr-2" style={{ fontWeight: '600' }}>
                    {getOffsetDate(currentDate).toLocaleDateString()}
                  </span>
                  . . . . . . . . . . . .
                </span>
                <span style={{ fontWeight: '600' }}>
                  {!isNaN(
                    parseFloat(
                      `${curTaxPayoffList.taxPayoffList
                        .map(t => t.TOTAL)
                        .reduce((sum, t) => sum + t, 0)}`
                    )
                  )
                    ? withCentsFormat(
                        `${curTaxPayoffList.taxPayoffList
                          .map(t => t.TOTAL)
                          .reduce((sum, t) => sum + t, 0)}`
                      )
                    : '-'}
                </span>
              </h6>
              <h6 className="text-danger mb-1">
                TOTAL AMOUNT IS SUBJECT TO CHANGE AS INTEREST IS COMPUTED DAILY.
                PAYMENTS MUST BE RECEIVED IN THE TREASURER'S OFFICE ON THE SAME
                DAY AS THE CALCULATED PAYOFF DATE.
              </h6>
            </Jumbotron>
          </Row>
          <Row noGutters className="w-100 px-3">
            <Jumbotron
              className="h-100 w-100 shadow-sm bg-white p-2 mb-3 d-flex flex-column"
              // style={{ borderLeft: 'solid 0.25rem #45c3d6' }}
            >
              <h5 className="mt-2 mb-0">
                Utah Code{mobileView && <br />} Annotated 59-2-1343
              </h5>
              <hr className="w-100" />
              <p>
                All taxes, penalties, fees and interest must be paid in full. If
                all such items are not paid by March 15 following the lapse of
                four years from the date when the property tax became
                delinquent, the property will be subject to sale at the annual
                Utah County Tax Sale. Tax Sale properties will incur an
                administration fee of $165.
              </p>
              <Col xs={12} sm={{ span: 4, offset: 4 }} className={`px-0`}>
                <Button
                  className="w-100"
                  variant="info"
                  style={{ color: 'white', fontWeight: '100' }}
                  onClick={() =>
                    window.open(
                      `https://le.utah.gov/xcode/Title59/Chapter2/59-2-S1343.html?v=C59-2-S1343_1800010118000101`,
                      '_blank',
                      'noopener,noreferrer'
                    )
                  }
                >
                  <BoxArrowUpRight className="mr-2 mb-1" />
                  Utah Code{mobileView && <br />} Annotated 59-2-1343
                </Button>
              </Col>
            </Jumbotron>
          </Row>
          <Row noGutters className="w-100 p-3">
            <Jumbotron
              className="h-100 w-100 shadow-sm bg-white p-2 mb-0 d-flex flex-column"
              // style={{ borderLeft: 'solid 0.25rem #45c3d6' }}
            >
              <h5 className="mt-2 mb-0">Contact the Utah County Treasurer</h5>
              <hr className="w-100" />
              <p>
                The Treasurer has no authority to waive taxes, penalties, fees
                or interest. Verify the parcel description for accuracy; this
                office is not responsible if payments are made on the wrong
                property.
              </p>
              <Row noGutters className="w-100">
                <Col xs={12} sm={3} className={mobileView ? '' : 'p-1'}>
                  <div className="h-100 d-flex flex-column p-3 border rounded shadow-sm">
                    <h6 className="mb-0 text-center">
                      <Mailbox className="mr-2" />
                      Write Us
                    </h6>
                    <hr className="w-100" />
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        fontFamily: 'Calibri, sans-serif',
                        fontSize: '1.15rem',
                        lineHeight: '1.33rem',
                      }}
                    >
                      KIM T. JACKSON
                      <br />
                      Treasurer, Utah County
                      <br />
                      100 East Center Street Ste 1200
                      <br />
                      Provo, UT 84606-3159
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={3} className={mobileView ? 'mt-2' : 'p-1'}>
                  <div className="h-100 d-flex flex-column p-3 border rounded shadow-sm">
                    <h6 className="mb-0 text-center">
                      <Telephone className="mr-2" />
                      Call Us
                    </h6>
                    <hr className="w-100" />
                    <div
                      className="d-flex flex-column align-items-center justify-content-around"
                      style={{ lineHeight: '2.5rem' }}
                    >
                      <a
                        className="d-flex"
                        style={{
                          textUnderlineOffset: '0.125rem',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        href="tel:8018518255"
                      >
                        (801) 851-8255
                      </a>
                      <a
                        className="d-flex"
                        style={{
                          textUnderlineOffset: '0.125rem',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        href="tel:8018519264"
                      >
                        (801) 851-8264
                      </a>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={3} className={mobileView ? 'mt-2' : 'p-1'}>
                  <div className="h-100 d-flex flex-column p-3 border rounded shadow-sm">
                    <h6 className="mb-0 text-center">
                      <Envelope className="mr-2" />
                      Send Feedback
                    </h6>
                    <hr className="w-100" />
                    <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                      <Button
                        className={`w-100`}
                        variant="primary"
                        style={{ color: 'white', fontWeight: '100' }}
                        onClick={() =>
                          window.open(
                            `https://www.utahcounty.gov/contactus/index.asp`,
                            '_blank',
                            'noopener,noreferrer'
                          )
                        }
                      >
                        <BoxArrowUpRight className="mr-2 mb-1" />
                        Contact Form
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={3} className={mobileView ? 'mt-2' : 'p-1'}>
                  <div className="h-100 d-flex flex-column p-3 border rounded shadow-sm">
                    <h6 className="mb-0 text-center">
                      <Window className="mr-2" />
                      View Website
                    </h6>
                    <hr className="w-100" />
                    <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                      <Button
                        className={`w-100`}
                        variant="primary"
                        style={{ color: 'white', fontWeight: '100' }}
                        onClick={() =>
                          window.open(
                            `https://www.utahcounty.gov/Dept/Treas/index.asp`,
                            '_blank',
                            'noopener,noreferrer'
                          )
                        }
                      >
                        <BoxArrowUpRight className="mr-2 mb-1" />
                        Treasurer Website
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Jumbotron>
          </Row>
        </>
      )}
    </>
  );
};

export default DelinquentTaxDetailView;
