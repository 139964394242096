import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ArrowCounterclockwise, Check2Circle } from 'react-bootstrap-icons';
import { setFilterActive, setFilterOptions } from '../../sidebarSlice';

import { updateInput } from '../../../../utilities/filterSort';

export default function DevelopmentFilterForm() {
  const defaultValues = {
    map: { type: 'textbox', apiName: 'NUMB', value: '' },
    city: { type: 'textbox', apiName: 'CITYNAME', value: '' },
    name: { type: 'textbox', apiName: 'NAME', value: '' },
    book: { type: 'textbox', apiName: 'BOOK', value: '' },
    page: { type: 'textbox', apiName: 'PAGE', value: '' },
    type: { type: 'checkbox', apiName: 'TYPENAME', value: [] },
    section: { type: 'textbox', apiName: 'SECTION', value: '' },
    township: { type: 'textbox', apiName: 'TOWNSHIP', value: '' },
    range: { type: 'textbox', apiName: 'RANGE', value: '' },
    rangeDir: { type: 'textbox', apiName: 'RANGE_DIR', value: '' },
  };
  const dispatch = useDispatch();
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const searchResults = useSelector(state => state.search.searchResults);
  const [values, setValues] = useState(filterOptions || { ...defaultValues });

  return (
    <>
      <Row
        noGutters
        className="rounded border border-primary bg-light w-100 mb-3 px-3 pt-3 pb-0 shadow-sm"
      >
        <Form
          className="w-100"
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(setFilterOptions(values));
            dispatch(setFilterActive({ filterActive: false }));
          }}
        >
          <Form.Row>
            <Form.Group as={Col} className="d-flex justify-content-end">
              <Button
                className="ml-auto mr-2"
                onClick={e => {
                  dispatch(setFilterOptions({ ...defaultValues }));
                  dispatch(setFilterActive({ filterActive: false }));
                }}
              >
                <ArrowCounterclockwise /> reset
              </Button>
              <Button type="submit">
                <Check2Circle /> apply
              </Button>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={12} sm={6}>
              <Form.Label>Map Number Contains</Form.Label>
              <Form.Control
                id="filter-textbox-map"
                placeholder="Enter map number"
                value={values.map.value}
                onChange={e => updateInput(e, values, setValues)}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={6}>
              <Form.Label>Development Name Contains</Form.Label>
              <Form.Control
                id="filter-textbox-name"
                placeholder="Enter development name"
                value={values.name.value}
                onChange={e => updateInput(e, values, setValues)}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={12} sm={6}>
              <Form.Label>Book Contains</Form.Label>
              <Form.Control
                id="filter-textbox-book"
                placeholder="Enter book number"
                value={values.book.value}
                onChange={e => updateInput(e, values, setValues)}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={6}>
              <Form.Label>Page Contains</Form.Label>
              <Form.Control
                id="filter-textbox-page"
                placeholder="Enter page number"
                value={values.page.value}
                onChange={e => updateInput(e, values, setValues)}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>City</Form.Label>
              <Form.Control
                as="select"
                id="filter-textbox-city"
                value={values.city.value}
                onChange={e => updateInput(e, values, setValues)}
                style={{ maxHeight: '25vh', overflow: 'auto' }}
              >
                {searchResults &&
                  searchResults.length > 0 &&
                  [
                    '-- Select a city --',
                    ...new Set(
                      searchResults
                        .slice()
                        .map(r => r.CITYNAME || '')
                        .filter(r => r !== '')
                    ),
                  ]
                    .sort((a, b) => a.localeCompare(b))
                    .map((c, i) => (
                      <option
                        key={i}
                        value={c === '-- Select a city --' ? '' : c}
                      >
                        {c}
                      </option>
                    ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Development Type</Form.Label>
              <div
                className="w-100 d-flex flex-column justify-content-around bg-white rounded py-2 px-3"
                style={{ border: '1px solid #ced4da' }}
                autoComplete="off"
              >
                {searchResults &&
                  searchResults.length > 0 &&
                  [
                    ...new Set(
                      searchResults
                        .slice()
                        .map(r => r.TYPENAME || '')
                        .filter(r => r !== '')
                    ),
                  ]
                    .sort((a, b) => a.localeCompare(b))
                    .map((n, i) => (
                      <Form.Check
                        id={`filter-checkbox-type-${i}`}
                        key={i}
                        label={n}
                        type="checkbox"
                        name={n}
                        checked={values.type.value.includes(n)}
                        onChange={e => updateInput(e, values, setValues)}
                      />
                    ))}
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={12} sm={6}>
              <Form.Label>Section</Form.Label>
              <Form.Control
                as="select"
                id="filter-textbox-section"
                value={values.section.value}
                onChange={e => updateInput(e, values, setValues)}
                style={{ maxHeight: '25vh', overflow: 'auto' }}
              >
                {searchResults &&
                  searchResults.length > 0 &&
                  [
                    '-- Select a section --',
                    ...new Set(
                      searchResults
                        .slice()
                        .map(r => r.SECTION || '')
                        .filter(r => r !== '')
                    ),
                  ]
                    .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
                    .map((c, i) => (
                      <option
                        key={i}
                        value={c === '-- Select a section --' ? '' : c}
                      >
                        {c}
                      </option>
                    ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={6}>
              <Form.Label>Township</Form.Label>
              <Form.Control
                as="select"
                id="filter-textbox-township"
                value={values.township.value}
                onChange={e => updateInput(e, values, setValues)}
                style={{ maxHeight: '25vh', overflow: 'auto' }}
              >
                {searchResults &&
                  searchResults.length > 0 &&
                  [
                    '-- Select a township --',
                    ...new Set(
                      searchResults
                        .slice()
                        .map(r => r.TOWNSHIP || '')
                        .filter(r => r !== '')
                    ),
                  ]
                    .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
                    .map((c, i) => (
                      <option
                        key={i}
                        value={c === '-- Select a township --' ? '' : c}
                      >
                        {c}
                      </option>
                    ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={12} sm={6}>
              <Form.Label>Range</Form.Label>
              <Form.Control
                as="select"
                id="filter-textbox-range"
                value={values.range.value}
                onChange={e => updateInput(e, values, setValues)}
                style={{ maxHeight: '25vh', overflow: 'auto' }}
              >
                {searchResults &&
                  searchResults.length > 0 &&
                  [
                    '-- Select a range --',
                    ...new Set(
                      searchResults
                        .slice()
                        .map(r => r.RANGE || '')
                        .filter(r => r !== '')
                    ),
                  ]
                    .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
                    .map((c, i) => (
                      <option
                        key={i}
                        value={c === '-- Select a range --' ? '' : c}
                      >
                        {c}
                      </option>
                    ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={6}>
              <Form.Label>Range Direction</Form.Label>
              <Form.Control
                as="select"
                id="filter-textbox-rangeDir"
                value={values.rangeDir.value}
                onChange={e => updateInput(e, values, setValues)}
                style={{ maxHeight: '25vh', overflow: 'auto' }}
              >
                {searchResults &&
                  searchResults.length > 0 &&
                  [
                    '-- Select a direction --',
                    ...new Set(
                      searchResults
                        .slice()
                        .map(r => r.RANGE_DIR || '')
                        .filter(r => r !== '')
                    ),
                  ]
                    .sort((a, b) => a.localeCompare(b))
                    .map((c, i) => (
                      <option
                        key={i}
                        value={c === '-- Select a direction --' ? '' : c}
                      >
                        {c}
                      </option>
                    ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </Form>
      </Row>
    </>
  );
}
