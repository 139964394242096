import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ArrowCounterclockwise, Check2Circle } from 'react-bootstrap-icons';
import { setFilterActive, setFilterOptions } from '../../sidebarSlice';
import { updateInput } from '../../../../utilities/filterSort';

export default function DocumentNameFilterForm() {
  const defaultValues = {
    name: { type: 'textbox', apiName: 'NAME', value: '' },
    party: {
      type: 'multiApiTextbox',
      value: 'none',
      items: {
        party1: {
          apiName: 'PARTY1FLAG',
          value: null,
        },
        party2: {
          apiName: 'PARTY2FLAG',
          value: null,
        },
      },
      itemMap: {
        none: { party1: null, party2: null },
        both: { party1: 'X', party2: 'X' },
        party1: { party1: 'X', party2: null },
        party2: { party1: null, party2: 'X' },
      },
    },
  };

  const dispatch = useDispatch();
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const [values, setValues] = useState(filterOptions || { ...defaultValues });

  return (
    <>
      <Row
        noGutters
        className="rounded border border-primary bg-light w-100 mb-3 p-3 shadow-sm"
      >
        <Form
          className="w-100"
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(setFilterOptions(values));
            dispatch(setFilterActive({ filterActive: false }));
          }}
        >
          <Form.Row>
            <Form.Group as={Col} className="w-100 d-flex">
              <Button
                className="ml-auto mr-2"
                onClick={e => {
                  dispatch(setFilterOptions({ ...defaultValues }));
                  dispatch(setFilterActive({ filterActive: false }));
                }}
              >
                <ArrowCounterclockwise /> reset
              </Button>
              <Button type="submit">
                <Check2Circle /> apply
              </Button>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Party Type</Form.Label>
              <Form.Control
                as="select"
                id="filter-multiApiTextbox-party"
                value={values.party.value}
                onChange={e => updateInput(e, values, setValues)}
                style={{ maxHeight: '25vh', overflow: 'auto' }}
              >
                <option value="none">-- ALL Party Types --</option>
                <option value="party1">ONLY Party 1</option>
                <option value="party2">ONLY Party 2</option>
                <option value="both">BOTH Party 1 &#38; Party 2</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="mb-0">
              <Form.Label>Name Contains</Form.Label>
              <Form.Control
                id="filter-textbox-name"
                placeholder="Enter name"
                value={values.name.value}
                onChange={e => updateInput(e, values, setValues)}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </Row>
    </>
  );
}
