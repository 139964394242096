import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  searchFormValues: null,
  allCities: [],
  allKoiCodes: {},
  allKoiCodeGroups: [],
  cityStatus: 'loading',
  koiCodeGroupStatus: 'loading',
  koiCodesStatus: 'loading',
  error: null,
  activeSearchFormGroup: 0,
};

export const fetchCityNames = createAsyncThunk(
  'app/searchForm/fetchCityNames',
  async baseUrl => {
    let cities;
    try {
      cities = (
        await axios({
          url: `${baseUrl}/citycodes`,
          headers: { apikey: process.env.APIKEY },
        })
      ).data.data;
      return [
        { title: '-- All --', value: '' },
        ...cities
          .map(c => ({
            title: c.CITY,
            value: c.CITY,
          }))
          .sort((a, b) => a.title.localeCompare(b.title)),
      ];
    } catch (err) {
      console.error(err);
      return err;
    }
  }
);

export const fetchKoiCodeGroups = createAsyncThunk(
  'app/searchForm/fetchKoiCodeGroups',
  async baseUrl => {
    let koiGroups, koiCodes;
    try {
      koiGroups = (
        await axios({
          url: `${baseUrl}/koigroups`,
          headers: { apikey: process.env.APIKEY },
        })
      ).data.data;
      koiCodes = (
        await axios({
          url: `${baseUrl}/koi`,
          headers: { apikey: process.env.APIKEY },
        })
      ).data.data;
      return [
        {
          title: '-- All --',
          value: '',
          kois: [
            { title: '-- All --', value: '', groupRef: '' },
            ...koiCodes
              .map(k => ({
                title: `${k.KOI} (${k.DESCR})`,
                value: k.KOI,
                groupRef: k.GROUP_,
              }))
              .sort((a, b) => a.title.localeCompare(b.title)),
          ],
        },
        ...koiGroups
          .map(c => ({
            title: c.DESCRIPTION,
            value: c.GROUP_NUMBER,
            kois: [
              { title: '-- All --', value: '', groupRef: c.GROUP_NUMBER },
              ...koiCodes
                .filter(k => k.GROUP_ === c.GROUP_NUMBER)
                .map(k => ({
                  title: `${k.KOI} (${k.DESCR})`,
                  value: k.KOI,
                  groupRef: k.GROUP_,
                }))
                .sort((a, b) => a.title.localeCompare(b.title)),
            ],
          }))
          .sort((a, b) => a.title.localeCompare(b.title)),
      ];
    } catch (err) {
      console.error(err);
      return err;
    }
  }
);

export const fetchKoiCodes = createAsyncThunk(
  'app/searchForm/fetchKoiCodes',
  async baseUrl => {
    let koiCodes;
    try {
      koiCodes = (
        await axios({
          url: `${baseUrl}/koi`,
          headers: { apikey: process.env.APIKEY },
        })
      ).data.data.reduce((codes, code) => {
        if (!codes[code.GROUP_]) codes[code.GROUP_] = [];
        codes[code.GROUP_].push({
          title: `${code.KOI} (${code.DESCR})`,
          value: code.KOI,
          group: code.GROUP_,
        });
        return codes;
      }, {});
      Object.keys(koiCodes).forEach(key =>
        koiCodes[key].sort((a, b) => a.title.localeCompare(b.title))
      );
      return koiCodes;
    } catch (err) {
      console.error(err);
      return err;
    }
  }
);

export const slice = createSlice({
  name: 'app/searchFormSlice',
  initialState,
  reducers: {
    setSearchFormValues: (state, action) => {
      state.searchFormValues = action.payload;
    },
    setActiveSearchFormGroup: (state, action) => {
      state.activeSearchFormGroup = action.payload;
    },
  },
  extraReducers: {
    [fetchCityNames.pending]: (state, action) => {
      state.cityStatus = 'loading';
    },
    [fetchCityNames.fulfilled]: (state, action) => {
      state.allCities = action.payload;
      state.cityStatus = 'succeeded';
    },
    [fetchCityNames.rejected]: (state, action) => {
      state.cityStatus = 'failed';
      state.error = action.error.message;
    },
    [fetchKoiCodeGroups.pending]: (state, action) => {
      state.koiCodeGroupStatus = 'loading';
    },
    [fetchKoiCodeGroups.fulfilled]: (state, action) => {
      state.allKoiCodeGroups = action.payload;
      state.koiCodeGroupStatus = 'succeeded';
    },
    [fetchKoiCodeGroups.rejected]: (state, action) => {
      state.koiCodeGroupStatus = 'failed';
      state.error = action.error.message;
    },
    [fetchKoiCodes.pending]: (state, action) => {
      state.koiCodesStatus = 'loading';
    },
    [fetchKoiCodes.fulfilled]: (state, action) => {
      state.allKoiCodes = action.payload;
      state.koiCodesStatus = 'succeeded';
    },
    [fetchKoiCodes.rejected]: (state, action) => {
      state.koiCodesStatus = 'failed';
      state.error = action.error.message;
    },
  },
});
// export actions
export const { setSearchFormValues, setActiveSearchFormGroup } = slice.actions;
// export slice
export default slice.reducer;
