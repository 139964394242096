import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'axios';

const propertyAdapter = createEntityAdapter({
  selectId: e => `${e.serial}`,
});

const initialState = propertyAdapter.getInitialState({
  status: 'loading',
  error: null,
  taxyear: `${new Date().getFullYear()}`,
});

export const fetchPropertyInfo = createAsyncThunk(
  'app/propertyDetailView/fetchPropertyInfo',
  async ({ url, serial, taxyear }) => {
    let propInfo = { serial: serial };
    try {
      propInfo.property = (
        await axios({
          url: `${url}/property?serial=${serial}`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data[0];
      propInfo.appraisalinfo = (
        await axios({
          url: `${url}/appraisalinfo?serial=${serial}`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data[0];
      propInfo.propertyvalues = (
        await axios({
          url: `${url}/propertyvalues?serial=${serial}&taxyear=${taxyear}`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data[0];
      propInfo.valuation = (
        await axios({
          url: `${url}/valuation?serial=${serial}&taxyear=${taxyear}`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data[0];
      propInfo.propertytaxyear = (
        await axios({
          url: `${url}/propertytaxyear?serial=${serial}&taxyear=${taxyear}`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data[0];
      propInfo.serialhistory = (
        await axios({
          url: `${url}/serialhistory?serial=${serial}`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data[0];
    } catch (err) {
      // console.log('fetchPropertyInfo error...');
      console.error(err);
      state.status = 'failed';
      return;
    }
    return propInfo;
  }
);

export const slice = createSlice({
  name: 'app/propertyDetailView',
  initialState: initialState,
  reducers: {
    emptyProperties: propertyAdapter.removeAll,
    setTaxyear: (state, action) => {
      state.taxyear = action.payload.taxyear;
    },
    setPropertyDetailViewStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: {
    [fetchPropertyInfo.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchPropertyInfo.fulfilled]: (state, action) => {
      propertyAdapter.upsertOne(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchPropertyInfo.rejected]: (state, action) => {
      state.error = action.error.message;
      state.status = 'failed';
    },
  },
});

export const { emptyProperties, setTaxyear, setPropertyDetailViewStatus } =
  slice.actions;

export const {
  selectEntities: selectAllProperties,
  selectById: selectPropertyById,
  selectIds: selectAllPropertyIDs,
} = propertyAdapter.getSelectors(state => state.propertyDetailView);

export default slice.reducer;
