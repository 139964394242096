import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'axios';

const developmentAdapter = createEntityAdapter({
  selectId: e => (e && e.ID ? `${e.ID}` : null),
});

const initialState = developmentAdapter.getInitialState({
  status: 'loading',
  error: null,
});

export const fetchDevelopmentInfo = createAsyncThunk(
  'app/developmentDetailView/fetchDevelopmentDetailViewInfo',
  async ({ url, id }) => {
    // get detail info
    let development;
    try {
      development = (
        await axios({
          url: `${url}/developmentdetail?developmentID=${id}`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data[0];
      // get serials in subdivision with bookpage endpoint
      if (development.BOOK && development.PAGE)
        development.serials = (
          await axios({
            url: `${url}/bookpage?book=${development.BOOK}&page=${development.PAGE}`,
            headers: {
              apikey: process.env.APIKEY,
            },
          })
        ).data.data;
      else development.serials = [];
    } catch (err) {
      console.error(err);
      return;
    }
    return development;
  }
);

export const slice = createSlice({
  name: 'app/developmentDetailView',
  initialState: initialState,
  reducers: {
    emptyDevelopments: developmentAdapter.removeAll,
  },
  extraReducers: {
    [fetchDevelopmentInfo.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchDevelopmentInfo.fulfilled]: (state, action) => {
      developmentAdapter.upsertOne(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchDevelopmentInfo.rejected]: (state, action) => {
      state.error = action.error.message;
      state.status = 'failed';
    },
  },
});

export const { emptyDevelopments } = slice.actions;

export const {
  selectEntities: selectAllDevelopments,
  selectById: selectDevelopmentById,
  selectIds: selectAllDevelopmentIDs,
} = developmentAdapter.getSelectors(state => state.developmentDetailView);

export default slice.reducer;
