import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/esm/AccordionToggle';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

import { ChevronDown, ChevronUp, Search } from 'react-bootstrap-icons';
import { withCentsFormat } from '../../../../utilities/currency';
import { selectDefaultMainSearch } from '../../../app/defaultSearchesSlice';
import { setCurrentID, setSearchDidRun } from '../../../app/searchSlice';
import { setSidebarExpanded } from '../../sidebarSlice';

import { queryToString } from '../../../../utilities/url';
import { filterResults, sortResults } from '../../../../utilities/filterSort';
import { _normalize } from '../../../../utilities/searchForm';

const DocumentAbstractSearchList = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const mainSearchInfo = useSelector(selectDefaultMainSearch(mainSearch));
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const [currentActiveKey, setCurrentActiveKey] = useState(null);
  const [headerExpanded, setHeaderExpanded] = useState(false);
  const searchStatus = useSelector(state => state.search.status);
  const currentID = useSelector(state => state.search.currentID);
  const dispatch = useDispatch();
  const mobileView = useSelector(state => state.windowState.mobileView);
  const history = useHistory();
  const router = useSelector(state => state.router);
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const sortOptions = useSelector(state => state.sidebar.sortOptions);

  // load
  const rawResults = useSelector(state => state.search.searchResults);
  const [searchResults, setSearchResults] = useState(
    rawResults[0] && rawResults[0].ABSTRACTS
      ? rawResults[0].ABSTRACTS.slice()
      : []
  );

  useEffect(() => {
    let tempResults = [...searchResults];
    // filter
    tempResults = [...filterResults(filterOptions, tempResults)];
    // sort
    tempResults = [...sortResults(sortOptions, tempResults)];
    // update list with filtered/sorted results
    setSearchResults([...tempResults]);
  }, [filterOptions, sortOptions]);

  // update currentID when page loads
  useEffect(() => {
    if (
      searchStatus === 'succeeded' &&
      searchResults.length > 0 &&
      !currentID
    ) {
      dispatch(
        setCurrentID({
          currentID: `${searchResults[0].ENTRY_NO}-${searchResults[0].ENTRY_YR}`,
        })
      );
    }
  }, [searchResults]);

  return (
    <>
      {/* show search loading */}
      {searchStatus === 'loading' && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '10rem', width: '10rem' }}
          />
        </Row>
      )}
      {/* show no result message */}
      {searchStatus === 'succeeded' && searchResults.length === 0 && (
        <Row
          noGutters
          id="sidebarResults"
          className={
            sidebarExpanded
              ? 'w-100 h-100 d-flex flex-column flex-grow-1'
              : 'd-none'
          }
          style={{
            backgroundColor: 'rgba(101, 167, 219, 0.25)',
          }}
        >
          <h5
            className="mx-auto w-75 d-flex flex-column h-100 align-items-center"
            style={{
              marginTop: '25%',
            }}
          >
            <Search
              height={mobileView ? '4rem' : '8rem'}
              width={mobileView ? '4rem' : '8rem'}
              style={{ color: 'rgba(101, 167, 219, 1)' }}
            />
            <p className="mb-1 mt-4 text-center">{`No document results found.`}</p>
            <p className="mb-0 mt-2 text-center">{`Please update your filter parameters or try another search.`}</p>
          </h5>
        </Row>
      )}
      {/* show search results list */}
      {searchStatus === 'succeeded' && searchResults.length > 0 && (
        <>
          {/* title */}
          <Row
            className={
              sidebarExpanded ? `w-100 p-0 border-top border-bottom` : 'd-none'
            }
            style={{
              background: '#e9ecef',
              fontWeight: '600',
              fontFamily: 'Calibri, sans-serif',
            }}
            noGutters
          >
            <Accordion
              className={sidebarExpanded ? `w-100` : 'd-none'}
              style={{
                fontWeight: '100',
                fontFamily: 'Calibri, sans-serif',
                marginBottom: '-2px',
              }}
            >
              <Card className="rounded-0 border-left-0 border-right-0">
                <Card.Header
                  className="d-flex p-2"
                  style={{
                    background: '#e9ecef',
                    fontWeight: '600',
                    fontSize: '1.15rem',
                    lineHeight: '1.25rem',
                  }}
                >
                  <Col
                    xs={10}
                    sm={11}
                    className="pl-4 pr-2 d-flex align-items-center justify-content-center text-center"
                  >
                    Abstract Information for Serial #{' '}
                    {rawResults[0].INFO[0].SERIAL_FORMATTED}
                  </Col>
                  <Col
                    xs={2}
                    sm={1}
                    className="px-0 d-flex align-items-center justify-content-center"
                  >
                    <Accordion.Toggle
                      as="a"
                      className="rounded"
                      eventKey="0"
                      onClick={() => {
                        setHeaderExpanded(!headerExpanded);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {headerExpanded ? <ChevronUp /> : <ChevronDown />}
                    </Accordion.Toggle>
                  </Col>
                </Card.Header>
                <Accordion.Collapse className="p-2" eventKey="0">
                  <Table
                    size="sm"
                    className="w-100 mb-0"
                    style={{ lineHeight: '1.15rem' }}
                  >
                    <tbody className="h-100 overflow-auto">
                      <tr className="w-100">
                        <th className="border-top-0 border-right">
                          Owner Name
                        </th>
                        <td className="border-top-0 pl-3">
                          {rawResults[0].CURRENT_INFO[0].OWNERNAME || '-'}
                        </td>
                      </tr>
                      <tr className="w-100">
                        <th className="border-top-0 border-right">
                          Property Address
                        </th>
                        <td className="border-top-0 pl-3">
                          {rawResults[0].INFO[0].ADDRESS || '-'}
                        </td>
                      </tr>
                      <tr className="w-100">
                        <th className="border-top-0 border-right">
                          Mailing Address
                        </th>
                        <td className="border-top-0 pl-3">
                          {rawResults[0].CURRENT_INFO[0].STREET &&
                          rawResults[0].CURRENT_INFO[0].CITY_STATE
                            ? `${rawResults[0].CURRENT_INFO[0].STREET} ${rawResults[0].CURRENT_INFO[0].CITY_STATE}`
                            : '-'}
                        </td>
                      </tr>
                      <tr className="w-100">
                        <th className="border-top-0 border-right">
                          Tax Legal Description
                        </th>
                        <td className="border-top-0 pl-3">
                          {rawResults[0].CURRENT_INFO[0].LEGAL_DESCRIPTION ||
                            '-'}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Row>
          <Row
            className={
              sidebarExpanded
                ? 'w-100 px-0 py-2 border-top border-bottom '
                : 'd-none'
            }
            style={{
              background: '#e9ecef',
              fontWeight: '600',
              fontFamily: 'Calibri, sans-serif',
              marginBottom: '2px',
            }}
            noGutters
          >
            <Col xs={4} sm={3} className="pl-3 pr-0">
              Entry No
            </Col>
            <Col xs={8} sm={9} className="px-0">
              Grantor
            </Col>
            <Col xs={4} sm={3} className="pl-3 pr-0">
              Kind of Inst
            </Col>
            <Col xs={8} sm={9} className="px-0">
              ..Grantee
            </Col>
          </Row>
          <Accordion
            className={sidebarExpanded ? 'w-100 overflow-auto' : 'd-none'}
            style={{
              fontWeight: '100',
              fontFamily: 'Calibri, sans-serif',
            }}
          >
            {searchResults.map((result, index) => {
              return (
                <Card
                  key={index}
                  className="w-100 rounded-0 border-left-0 border-right-0"
                  style={{ marginTop: '-1px' }}
                >
                  <Card.Header className="w-100 px-1 d-flex flex-row border-bottom-0 shadow-sm">
                    <Col
                      xs={4}
                      sm={3}
                      className="px-0 d-flex flex-column align-items-center"
                    >
                      <Row noGutters className="w-100 text-left">
                        <Accordion.Toggle
                          as="a"
                          onClick={() => {
                            if (mobileView)
                              dispatch(
                                setSidebarExpanded({ sidebarExpanded: false })
                              );
                            setCurrentActiveKey(
                              index !== currentActiveKey ? index : null
                            );
                            if (
                              currentID !==
                              `${searchResults[index].ENTRY_NO}-${searchResults[index].ENTRY_YR}`
                            ) {
                              dispatch(
                                setCurrentID({
                                  currentID: `${searchResults[index].ENTRY_NO}-${searchResults[index].ENTRY_YR}`,
                                })
                              );
                              const query = queryToString({
                                query: router.location.query,
                                currentID: `${searchResults[index].ENTRY_NO}-${searchResults[index].ENTRY_YR}`,
                              });
                              history.push(
                                `${router.location.pathname}?${query}`
                              );
                            }
                          }}
                          eventKey={`${index}`}
                          className="py-0 px-2 ml-1 border-0 d-flex text-left"
                          style={{
                            textUnderlineOffset: '0.125rem',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          {result.ENTRY_NO && result.ENTRY_YR
                            ? `${result.ENTRY_NO}-${result.ENTRY_YR}`
                            : '-'}
                        </Accordion.Toggle>
                      </Row>
                      <Row noGutters className="pl-2 ml-2 w-100">
                        {result.KOI || '-'}
                      </Row>
                    </Col>
                    <Col
                      xs={7}
                      sm={8}
                      className="pl-0 pr-1 d-flex flex-column align-items-center"
                    >
                      <Row noGutters className="w-100">
                        <span
                          className={
                            currentActiveKey === index ? '' : 'text-truncate'
                          }
                          style={{
                            lineHeight: '1.15rem',
                            marginBottom: '0.5rem',
                          }}
                        >
                          {result.GRANTOR || '-'}
                        </span>
                      </Row>
                      <Row noGutters className="w-100">
                        <span
                          className={
                            currentActiveKey === index ? '' : 'text-truncate'
                          }
                          style={{ lineHeight: '1.15rem' }}
                        >
                          {result.GRANTEE ? `..${result.GRANTEE}` : '-'}
                        </span>
                      </Row>
                    </Col>
                    <Col
                      xs={1}
                      sm={1}
                      className="px-0 d-flex align-items-center justify-content-center"
                    >
                      <Accordion.Toggle
                        as="a"
                        onClick={() =>
                          setCurrentActiveKey(
                            index !== currentActiveKey ? index : null
                          )
                        }
                        className="py-0 px-1"
                        eventKey={`${index}`}
                        style={{ cursor: 'pointer' }}
                      >
                        {currentActiveKey === index ? (
                          <ChevronUp />
                        ) : (
                          <ChevronDown />
                        )}
                      </Accordion.Toggle>
                    </Col>
                  </Card.Header>
                  <Accordion.Collapse className="px-3" eventKey={`${index}`}>
                    <div className="d-flex flex-column w-100">
                      <Row noGutters className="w-100">
                        <Table responsive size="sm" className="w-100 my-2">
                          <tbody>
                            <tr>
                              <th className="border-top-0 py-0 pl-0 pr-2 border-right">
                                Book/Page
                              </th>
                              <td className="border-top-0 pt-0 pr-0 pb-0 pl-3">
                                {result.RJ_BOOK && result.RJ_PAGE
                                  ? `${result.RJ_BOOK}-${result.RJ_PAGE}`
                                  : '-'}
                              </td>
                            </tr>
                            <tr>
                              <th className="border-top-0 py-0 pl-0 pr-2 border-right">
                                Inst Date
                              </th>
                              <td className="border-top-0 pt-0 pr-0 pb-0 pl-3">
                                {Date.parse(result.INST_DATE)
                                  ? new Date(
                                      result.INST_DATE
                                    ).toLocaleDateString()
                                  : '-'}
                              </td>
                            </tr>
                            <tr>
                              <th className="border-top-0 py-0 pl-0 pr-2 border-right">
                                Rec Date
                              </th>
                              <td className="border-top-0 pt-0 pr-0 pb-0 pl-3">
                                {Date.parse(result.RJ_DATE) && result.RJ_TIME
                                  ? `${new Date(
                                      result.RJ_DATE
                                    ).toLocaleDateString()} ${result.RJ_TIME.toUpperCase()}`
                                  : '-'}
                              </td>
                            </tr>
                            <tr>
                              <th className="border-top-0 py-0 pl-0 pr-2 border-right">
                                Tie Entry No
                              </th>
                              <td className="border-top-0 pt-0 pr-0 pb-0 pl-3">
                                {result.PREV_ENTRY && result.PREV_YEAR ? (
                                  <a
                                    style={{
                                      textUnderlineOffset: '0.125rem',
                                      textDecoration: 'underline',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      dispatch(
                                        setCurrentID({ currentID: null })
                                      );
                                      dispatch(
                                        setSearchDidRun({ searchDidRun: false })
                                      );
                                      history.push(
                                        `/documents/document/0?entry=${result.PREV_ENTRY}&year=${result.PREV_YEAR}`
                                      );
                                    }}
                                  >
                                    {`${result.PREV_ENTRY}-${result.PREV_YEAR}`}
                                  </a>
                                ) : (
                                  '-'
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th className="border-top-0 py-0 pl-0 pr-2 border-right">
                                Consideration
                              </th>
                              <td className="border-top-0 pt-0 pr-0 pb-0 pl-3">
                                {isNaN(parseInt(result.CONSIDERATION, 10))
                                  ? '-'
                                  : withCentsFormat(`${result.CONSIDERATION}`)}
                              </td>
                            </tr>
                            <tr>
                              <th className="border-top-0 py-0 pl-0 pr-2 border-right">
                                Satisfaction
                              </th>
                              <td className="border-top-0 pt-0 pr-0 pb-0 pl-3">
                                {!result.FIRST_RELEASE && '-'}
                                {result.FIRST_RELEASE &&
                                  result.FIRST_RELEASE.split(
                                    ' '
                                  )[1].toUpperCase() === 'BOOK' && (
                                    <a
                                      style={{
                                        textUnderlineOffset: '0.125rem',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        dispatch(
                                          setCurrentID({ currentID: null })
                                        );
                                        dispatch(
                                          setSearchDidRun({
                                            searchDidRun: false,
                                          })
                                        );
                                        history.push(
                                          `/documents/document/1?book=${
                                            result.FIRST_RELEASE.split(' ')[2]
                                          }&page=${
                                            result.FIRST_RELEASE.split(' ')[4]
                                          }`
                                        );
                                      }}
                                    >
                                      {result.FIRST_RELEASE}
                                    </a>
                                  )}
                                {result.FIRST_RELEASE &&
                                  result.FIRST_RELEASE.split(
                                    ' '
                                  )[1].toUpperCase() === 'ENTRY' && (
                                    <a
                                      style={{
                                        textUnderlineOffset: '0.125rem',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        dispatch(
                                          setCurrentID({ currentID: null })
                                        );
                                        dispatch(
                                          setSearchDidRun({
                                            searchDidRun: false,
                                          })
                                        );
                                        history.push(
                                          `/documents/document/0?entry=${
                                            result.FIRST_RELEASE.split(' ')[2]
                                          }&year=${
                                            result.FIRST_RELEASE.split(' ')[4]
                                          }`
                                        );
                                      }}
                                    >
                                      {result.FIRST_RELEASE}
                                    </a>
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <th className="border-top-0 py-0 pl-0 pr-2 border-right">
                                Comments
                              </th>
                              <td className="border-top-0 pt-0 pr-0 pb-0 pl-3">
                                {(!result.REASON || result.REASON === ' ') &&
                                  (!result.REMAINDER ||
                                    result.REMAINDER === ' ') &&
                                  result.FROM_COUNT === 0 &&
                                  result.TO_COUNT === 0 &&
                                  '-'}
                                {result.REASON && result.REASON !== ' ' ? (
                                  <>
                                    {result.REASON}
                                    <br />
                                  </>
                                ) : (
                                  ''
                                )}
                                {result.REMAINDER &&
                                result.REMAINDER !== ' ' ? (
                                  <>
                                    {result.REMAINDER}
                                    <br />
                                  </>
                                ) : (
                                  ''
                                )}
                                {result.FROM_COUNT > 0 &&
                                _normalize(result.FROM_NO) !== 0 ? (
                                  <>
                                    From:{' '}
                                    <a
                                      style={{
                                        textUnderlineOffset: '0.125rem',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        dispatch(
                                          setCurrentID({ currentID: null })
                                        );
                                        dispatch(
                                          setSearchDidRun({
                                            searchDidRun: false,
                                          })
                                        );
                                        history.push(
                                          `/properties/serial/0?serial=${result.FROM_NO}`
                                        );
                                      }}
                                    >
                                      {result.FROM_NO}
                                    </a>
                                    {result.FROM_COUNT > 1 ? ' (more)' : ''}
                                  </>
                                ) : (
                                  ''
                                )}
                                {result.TO_COUNT > 0 &&
                                _normalize(result.TO_NO) !== 0 ? (
                                  <>
                                    To:{' '}
                                    <a
                                      style={{
                                        textUnderlineOffset: '0.125rem',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        dispatch(
                                          setCurrentID({ currentID: null })
                                        );
                                        dispatch(
                                          setSearchDidRun({
                                            searchDidRun: false,
                                          })
                                        );
                                        history.push(
                                          `/properties/serial/0?serial=${result.TO_NO}`
                                        );
                                      }}
                                    >
                                      {result.TO_NO}
                                    </a>
                                    {result.TO_COUNT > 1 ? ' (more)' : ''}
                                  </>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                    </div>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
        </>
      )}
    </>
  );
};

export default DocumentAbstractSearchList;
