import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'axios';

const taxPaymentsAdapter = createEntityAdapter({
  selectId: e => e.serial,
});

const initialState = taxPaymentsAdapter.getInitialState({
  serial: null,
  taxyear: null,
  status: 'loading',
  error: null,
});

export const fetchTaxPaymentsList = createAsyncThunk(
  'app/taxPaymentsDetailView/fetchTaxPaymentsList',
  async ({ url, serial }) => {
    let taxPaymentsList;
    try {
      taxPaymentsList = (
        await axios({
          url: `${url}/taxpayments?serial=${serial}`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data;
    } catch (err) {
      console.error(err);
      return;
    }
    return {
      serial: `${serial}`,
      serial_formatted:
        taxPaymentsList && taxPaymentsList.length > 0
          ? taxPaymentsList[0].PAYMENT_SERIAL
          : `${serial}`,
      taxPaymentsList,
    };
  }
);

export const slice = createSlice({
  name: 'app/taxPaymentsDetailView',
  initialState: initialState,
  reducers: {
    setSerial: (state, action) => {
      state.serial = action.payload;
    },
    setTaxyear: (state, action) => {
      state.taxyear = action.payload;
    },
    setTaxPaymentsStatus: (state, action) => {
      state.status = action.payload;
    },
    emptyTaxPaymentsLists: taxPaymentsAdapter.removeAll,
  },
  extraReducers: {
    [fetchTaxPaymentsList.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchTaxPaymentsList.fulfilled]: (state, action) => {
      taxPaymentsAdapter.upsertOne(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchTaxPaymentsList.rejected]: (state, action) => {
      state.error = action.error.message;
      state.status = 'failed';
    },
  },
});

export const {
  setSerial,
  setTaxyear,
  setTaxPaymentsStatus,
  emptyTaxPaymentsLists,
} = slice.actions;

export const {
  selectEntities: selectAllTaxPaymentsLists,
  selectById: selectTaxPaymentsListById,
  selectIds: selectAllTaxPaymentsListIDs,
} = taxPaymentsAdapter.getSelectors(state => state.taxPaymentsDetailView);

export default slice.reducer;
