import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'axios';

export const slice = createSlice({
  name: 'app/propertyDetailViewMap',
  initialState: { status: 'loading' },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
  },
  extraReducers: {},
});

export const { setStatus } = slice.actions;

export default slice.reducer;
