import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import {
  Filter,
  SortDownAlt,
  ArrowBarLeft,
  ArrowBarUp,
  ArrowBarDown,
  ArrowBarRight,
  X,
  MenuButtonWideFill,
} from 'react-bootstrap-icons';

import { setSearchActive } from '../app/searchSlice';
import {
  setSidebarExpanded,
  setFilterActive,
  setSortActive,
} from './sidebarSlice';

import SearchForm from '../app/searchForm/SearchForm';
import DevelopmentSearchList from './searchLists/developmentsSearchLists/DevelopmentSearchList';
import DocumentAbstractSearchList from './searchLists/documentsSearchLists/DocumentAbstractSearchList';
import DocumentNameSearchList from './searchLists/documentsSearchLists/DocumentNameSearchList';
import DocumentCrossReferenceSearchList from './searchLists/documentsSearchLists/DocumentCrossReferenceSearchList';
import DocumentRecordingSearchList from './searchLists/documentsSearchLists/DocumentRecordingSearchList';
import DocumentInterimAbstractSearchList from './searchLists/documentsSearchLists/DocumentInterimAbstractSearchList';
import DocumentSearchList from './searchLists/documentsSearchLists/DocumentSearchList';
import DocumentKoiSearchList from './searchLists/documentsSearchLists/DocumentKoiSearchList';
import DocumentMiningSearchList from './searchLists/documentsSearchLists/DocumentMiningSearchList';
import DocumentWaterRightsSearchList from './searchLists/documentsSearchLists/DocumentWaterRightsSearchList';
import PropertyAddressSearchList from './searchLists/propertiesSearchLists/PropertyAddressSearchList';
import PropertyNameSearchList from './searchLists/propertiesSearchLists/PropertyNameSearchList';
import PropertySerialSearchList from './searchLists/propertiesSearchLists/PropertySerialSearchList';
import DelinquentTaxSearchList from './searchLists/taxesSearchLists/DelinquentTaxSearchList';
import TaxHistorySearchList from './searchLists/taxesSearchLists/TaxHistorySearchList';
import TaxPaymentsSearchList from './searchLists/taxesSearchLists/TaxPaymentsSearchList';

import DevelopmentFilterForm from './filterForms/developmentsFilterForms/DevelopmentFilterForm';
import DocumentAbstractFilterForm from './filterForms/documentsFilterForms/DocumentAbstractFilterForm';
import DocumentCrossReferenceFilterForm from './filterForms/documentsFilterForms/DocumentCrossReferenceFilterForm';
import DocumentInterimAbstractFilterForm from './filterForms/documentsFilterForms/DocumentInterimAbstractFilterForm';
import DocumentRecordingFilterForm from './filterForms/documentsFilterForms/DocumentRecordingFilterForm';
import DocumentKoiFilterForm from './filterForms/documentsFilterForms/DocumentKoiFilterForm';
import DocumentNameFilterForm from './filterForms/documentsFilterForms/DocumentNameFilterForm';
import DocumentMiningFilterForm from './filterForms/documentsFilterForms/DocumentMiningFilterForm';
import DocumentWaterRightsFilterForm from './filterForms/documentsFilterForms/DocumentWaterRightsFilterForm';
import PropertyAddressFilterForm from './filterForms/propertiesFilterForms/PropertyAddressFilterForm';
import PropertyNameFilterForm from './filterForms/propertiesFilterForms/PropertyNameFilterForm';
import DelinquentTaxFilterForm from './filterForms/taxesFilterForms/DelinquentTaxFilterForm';
import TaxHistoryDetailFilterForm from './filterForms/taxesFilterForms/TaxHistoryDetailFilterForm';
import TaxPaymentsFilterForm from './filterForms/taxesFilterForms/TaxPaymentsFilterForm';

import SortForm from './sortForms/SortForm';

const Sidebar = () => {
  const history = useHistory();
  const mainSearch = useSelector(state => state.search.mainSearch);
  const subSearch = useSelector(state => state.search.subSearch);
  const dispatch = useDispatch();
  const mobileView = useSelector(state => state.windowState.mobileView);
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const filterActive = useSelector(state => state.sidebar.filterActive);
  const sortActive = useSelector(state => state.sidebar.sortActive);
  const searchActive = useSelector(state => state.search.searchActive);
  const searchStatus = useSelector(state => state.search.status);
  const rawSearchResults = useSelector(state => state.search.searchResults);
  const [searchList, setSearchList] = useState(null);
  const [filterForm, setFilterForm] = useState(null);
  const [sortForm, setSortForm] = useState(null);

  useEffect(() => {
    switch (mainSearch) {
      case 'documents':
        switch (subSearch) {
          case 'abstract':
            setSearchList(<DocumentAbstractSearchList />);
            setFilterForm(<DocumentAbstractFilterForm />);
            break;
          case 'crossReference':
            setSearchList(<DocumentCrossReferenceSearchList />);
            setFilterForm(<DocumentCrossReferenceFilterForm />);
            break;
          case 'document':
            setSearchList(<DocumentSearchList />);
            // no filter or sort
            setFilterForm(null);
            break;
          case 'documentRecording':
            setSearchList(<DocumentRecordingSearchList />);
            setFilterForm(<DocumentRecordingFilterForm />);
            break;
          case 'interimAbstract':
            setSearchList(<DocumentInterimAbstractSearchList />);
            setFilterForm(<DocumentInterimAbstractFilterForm />);
            break;
          case 'lastAbstract':
            setSearchList(null);
            setFilterForm(null);
            break;
          case 'koi':
            setSearchList(<DocumentKoiSearchList />);
            setFilterForm(<DocumentKoiFilterForm />);
            break;
          case 'name':
            setSearchList(<DocumentNameSearchList />);
            setFilterForm(<DocumentNameFilterForm />);
            break;
          case 'mining':
            setSearchList(
              <DocumentMiningSearchList rawSearchResults={rawSearchResults} />
            );
            setFilterForm(<DocumentMiningFilterForm />);
            break;
          case 'waterRights':
            setSearchList(<DocumentWaterRightsSearchList />);
            setFilterForm(<DocumentWaterRightsFilterForm />);
            break;
          default:
            console.log(`not implemented for ${subSearch}`);
            setSearchList(null);
        }
        break;
      case 'properties':
        switch (subSearch) {
          case 'address':
            setSearchList(<PropertyAddressSearchList />);
            setFilterForm(<PropertyAddressFilterForm />);
            break;
          case 'name':
            setSearchList(<PropertyNameSearchList />);
            setFilterForm(<PropertyNameFilterForm />);
            break;
          case 'serial':
            setSearchList(<PropertySerialSearchList />);
            setFilterForm(null);
            break;
          case 'development':
            setSearchList(<DevelopmentSearchList />);
            setFilterForm(<DevelopmentFilterForm />);
            break;
          default:
            console.log(`not implemented for ${subSearch}`);
            setSearchList(null);
        }
        break;
      case 'taxes':
        switch (subSearch) {
          case 'delinquentTax':
            setSearchList(<DelinquentTaxSearchList />);
            setFilterForm(<DelinquentTaxFilterForm />);
            break;
          case 'taxHistory':
            setSearchList(<TaxHistorySearchList />);
            setFilterForm(<TaxHistoryDetailFilterForm />);
            break;
          case 'taxPayments':
            setSearchList(<TaxPaymentsSearchList />);
            setFilterForm(<TaxPaymentsFilterForm />);
            break;
          default:
            console.log(`not implemented for ${subSearch}`);
            setSearchList(null);
        }
        break;
      default:
        setSearchList(null);
        setFilterForm(null);
        setSortForm(null);
        history.push('/');
    }
  }, [mainSearch, subSearch]);

  return (
    <>
      {/* standard results pane */}
      {searchList &&
        !searchActive &&
        !filterActive &&
        !sortActive &&
        (!mobileView || (mobileView && sidebarExpanded)) && (
          <Col
            id="sidebar"
            xs={12}
            md={!sidebarExpanded && !mobileView ? { span: 1 } : 4}
            className={`mh-100 d-flex flex-column p-0${
              !mobileView ? ' h-100' : ''
            }${!sidebarExpanded && !mobileView ? ' darkBG' : ' bg-light'}`}
            style={{
              zIndex: '1',
              boxShadow: '1px 0 10px lightgray',
            }}
          >
            <Row
              noGutters
              id="sidebarMenu"
              className={`mh-100 py-2 px-1 justify-content-between${
                !sidebarExpanded && !mobileView ? ' flex-column' : ' darkBG'
              }`}
              style={{ position: 'sticky', top: 0 }}
            >
              <Col className={`px-1 ${sidebarExpanded ? 'd-flex' : 'd-none'}`}>
                <Button
                  id="sidebarFilterBtn"
                  variant="outline-dark"
                  className="w-100"
                  onClick={() =>
                    dispatch(setFilterActive({ filterActive: true }))
                  }
                  disabled={['document', 'serial'].includes(subSearch)}
                >
                  <Filter width="1.5rem" height="1.5rem" />
                  {mobileView && <br />}
                  <span className={mobileView ? 'pl-0' : 'pl-2'}>filter</span>
                </Button>
              </Col>
              <Col className={`px-1 ${sidebarExpanded ? 'd-flex' : 'd-none'}`}>
                <Button
                  id="sidebarSortBtn"
                  variant="outline-dark"
                  className="w-100"
                  onClick={() => dispatch(setSortActive({ sortActive: true }))}
                  disabled={['document', 'serial'].includes(subSearch)}
                >
                  <SortDownAlt width="1.5rem" height="1.5rem" />
                  {mobileView && <br />}
                  <span className={mobileView ? 'pl-0' : 'pl-2'}>sort</span>
                </Button>
              </Col>

              <Col
                className={`px-1`}
                md={!sidebarExpanded && !mobileView ? { order: 2 } : null}
              >
                <Button
                  id="sidebarSearchBtn"
                  variant="outline-dark"
                  className={`w-100`}
                  onClick={() => {
                    dispatch(setSearchActive({ searchActive: true }));
                  }}
                >
                  <MenuButtonWideFill width="1.5rem" height="1.5rem" />
                  {mobileView && <br />}
                  <span className={mobileView ? `pl-0` : `pl-2`}>search</span>
                </Button>
              </Col>
              <Col
                className={
                  !sidebarExpanded && !mobileView ? 'pb-2 px-1' : `px-1`
                }
                md={!sidebarExpanded && !mobileView ? { order: 1 } : null}
              >
                <Button
                  id="sidebarCollapseBtn"
                  variant="outline-dark"
                  className={`w-100`}
                  onClick={() =>
                    dispatch(
                      setSidebarExpanded({ sidebarExpanded: !sidebarExpanded })
                    )
                  }
                >
                  {mobileView && sidebarExpanded && (
                    <ArrowBarUp width="1.5rem" height="1.5rem" />
                  )}
                  {mobileView && !sidebarExpanded && (
                    <ArrowBarDown width="1.5rem" height="1.5rem" />
                  )}
                  {!mobileView && sidebarExpanded && (
                    <ArrowBarLeft width="1.5rem" height="1.5rem" />
                  )}
                  {!mobileView && !sidebarExpanded && (
                    <ArrowBarRight width="1.5rem" height="1.5rem" />
                  )}
                  {mobileView && <br />}
                  <span className={mobileView ? `pl-0` : `pl-2`}>
                    {sidebarExpanded && !mobileView && `collapse`}
                    {!sidebarExpanded && !mobileView && `expand`}
                    {sidebarExpanded && mobileView && `details`}
                    {!sidebarExpanded && mobileView && `results`}
                  </span>
                </Button>
              </Col>
            </Row>
            {/* show search loading */}
            {searchStatus === 'loading' && !searchList && sidebarExpanded && (
              <Row
                noGutters
                id="sidebarResults"
                className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
              >
                <Spinner
                  animation="border"
                  variant="info"
                  className="d-flex align-self-center m-auto"
                  style={{ height: '10rem', width: '10rem' }}
                />
              </Row>
            )}
            {/* show search results list */}
            {searchStatus === 'succeeded' &&
              searchList !== null &&
              sidebarExpanded &&
              searchList}
          </Col>
        )}
      {/* show all search options */}
      {searchActive && (
        <Col
          xs={12}
          md={4}
          className={`w-100 h-100 d-flex flex-column overflow-auto flex-grow-1 m-0 pt-3 px-3 pb-0 darkBG ${
            !mobileView ? 'border-right border-primary' : ''
          }`}
        >
          <Row noGutters className="w-100 justify-content-between mb-3">
            <span
              className="bg-light mb-0 d-flex flex-grow-1 justify-content-center align-items-center border-top border-left border-bottom rounded-left border-primary h5"
              style={{
                color: `#645ca1`,
                textShadow: `-0.085rem 0.085rem rgba(0, 0, 0, 0.25)`,
                fontSize: `1.5rem`,
              }}
            >
              Search Menu
            </span>
            <Button
              variant="outline-dark"
              onClick={() => {
                dispatch(setSearchActive({ searchActive: false }));
              }}
              style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
            >
              <X width="1.5rem" height="1.5rem" />
            </Button>
          </Row>
          <SearchForm />
        </Col>
      )}
      {filterActive && (
        <Col
          xs={12}
          md={4}
          className={`w-100 h-100 d-flex flex-column overflow-auto flex-grow-1 m-0 pt-3 px-3 pb-0 darkBG ${
            !mobileView ? 'border-right border-primary' : ''
          }`}
        >
          <Row noGutters className="w-100 justify-content-between mb-3">
            <span
              className="bg-light mb-0 d-flex flex-grow-1 justify-content-center align-items-center border-top border-left border-bottom rounded-left border-primary h5"
              style={{
                color: `#645ca1`,
                textShadow: `-0.085rem 0.085rem rgba(0, 0, 0, 0.25)`,
                fontSize: `1.5rem`,
              }}
            >
              Filter Results
            </span>
            <Button
              variant="outline-dark"
              onClick={() => {
                dispatch(setFilterActive({ filterActive: false }));
              }}
              style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
            >
              <X width="1.5rem" height="1.5rem" />
            </Button>
          </Row>
          {filterForm}
        </Col>
      )}
      {sortActive && (
        <Col
          xs={12}
          md={4}
          className={`w-100 h-100 d-flex flex-column overflow-auto flex-grow-1 m-0 pt-3 px-3 pb-0 darkBG ${
            !mobileView ? 'border-right border-primary' : ''
          }`}
        >
          <Row noGutters className="w-100 justify-content-between mb-3">
            <span
              className="bg-light mb-0 d-flex flex-grow-1 justify-content-center align-items-center border-top border-left border-bottom rounded-left border-primary h5"
              style={{
                color: `#645ca1`,
                textShadow: `-0.085rem 0.085rem rgba(0, 0, 0, 0.25)`,
                fontSize: `1.5rem`,
              }}
            >
              Sort Results
            </span>
            <Button
              variant="outline-dark"
              onClick={() => {
                dispatch(setSortActive({ sortActive: false }));
              }}
              style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
            >
              <X width="1.5rem" height="1.5rem" />
            </Button>
          </Row>
          <SortForm />
        </Col>
      )}
    </>
  );
};

export default Sidebar;
