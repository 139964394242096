import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import { BoxArrowUpRight, Bank2 } from 'react-bootstrap-icons';

import {
  fetchTaxDetail,
  selectAllTaxDetailIDs,
  selectTaxDetailById,
  setTaxDetailStatus,
} from '../../app/taxDetail/taxDetailSlice';

import { withCentsFormat } from '../../../utilities/currency';
import { setCurrentID, setSearchDidRun } from '../../app/searchSlice';

const TaxHistoryDetailView = () => {
  const serial = useSelector(state => state.taxDetail.serial);
  const taxyear = useSelector(state => state.taxDetail.taxyear);
  const allTaxDetailIds = useSelector(selectAllTaxDetailIDs);
  const history = useHistory();
  const dispatch = useDispatch();
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const curTaxDetail = useSelector(state =>
    selectTaxDetailById(state, `${taxyear}${serial}`)
  );
  const taxDetailStatus = useSelector(state => state.taxDetail.status);
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);

  // update current document
  useEffect(() => {
    if (
      !!serial &&
      !!taxyear &&
      !allTaxDetailIds.includes(`${taxyear}${serial}`)
    )
      dispatch(
        fetchTaxDetail({
          url: baseApiUrl,
          serial: serial,
          taxyear: taxyear,
        })
      );
    else if (!serial || !taxyear) dispatch(setTaxDetailStatus('failed'));
  }, [serial, taxyear]);

  return (
    <>
      {taxDetailStatus === 'loading' && (
        <Row
          noGutters
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '20rem', width: '20rem' }}
          />
        </Row>
      )}
      {((taxDetailStatus === 'succeeded' && !curTaxDetail) ||
        taxDetailStatus === 'failed') && (
        <Row
          noGutters
          className="h-100 d-flex flex-column align-items-center justify-content-center p-2"
          style={{ backgroundColor: 'rgba(217, 190, 80, 0.1)' }}
        >
          <Bank2
            height={mobileView ? '4rem' : '8rem'}
            width={mobileView ? '4rem' : '8rem'}
            style={{ color: 'rgba(78, 128, 166, 1)' }}
          />
          <h5 className="text-center mt-4" style={{ fontSize: '1.5rem' }}>
            Could not find tax detail information.
          </h5>
          <h5 className="text-center mt-2" style={{ fontSize: '1.5rem' }}>
            Please try another search.
          </h5>
        </Row>
      )}
      {taxDetailStatus === 'succeeded' && curTaxDetail && (
        <div className="w-100 p-3">
          <Jumbotron className="w-100 shadow-sm bg-white p-3 mb-0">
            <h5 className="mb-0">Tax History Information</h5>
            <hr />
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2 w-100"
                style={{ fontWeight: '500' }}
              >
                Serial Number
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curTaxDetail.INFO &&
                curTaxDetail.INFO.length > 0 &&
                curTaxDetail.INFO[0].SERIAL_FORMATTED
                  ? curTaxDetail.INFO[0].SERIAL_FORMATTED
                  : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Tax Year
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curTaxDetail.INFO &&
                curTaxDetail.INFO.length > 0 &&
                curTaxDetail.INFO[0].TAX_YEAR
                  ? curTaxDetail.INFO[0].TAX_YEAR
                  : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Tax District
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curTaxDetail.OWNER_PARCEL_INFO &&
                curTaxDetail.OWNER_PARCEL_INFO.length > 0 &&
                curTaxDetail.OWNER_PARCEL_INFO[0].DISTDESC
                  ? curTaxDetail.OWNER_PARCEL_INFO[0].DISTDESC
                  : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Owner
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curTaxDetail.OWNER_PARCEL_INFO &&
                curTaxDetail.OWNER_PARCEL_INFO.length > 0 &&
                curTaxDetail.OWNER_PARCEL_INFO[0].OWNER
                  ? curTaxDetail.OWNER_PARCEL_INFO[0].OWNER
                  : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Mailing Address
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curTaxDetail.OWNER_PARCEL_INFO &&
                curTaxDetail.OWNER_PARCEL_INFO.length > 0 &&
                curTaxDetail.OWNER_PARCEL_INFO[0].MAILADDR
                  ? curTaxDetail.OWNER_PARCEL_INFO[0].MAILADDR
                  : '-'}
              </Col>
            </Row>
            <Row noGutters className="w-100">
              <Col
                xs={6}
                sm={5}
                className="border-right p-2"
                style={{ fontWeight: '500' }}
              >
                Mortgage Company
              </Col>
              <Col xs={6} sm={7} className="p-2">
                {curTaxDetail.INFO &&
                curTaxDetail.INFO.length > 0 &&
                curTaxDetail.INFO[0].MORTCONAME
                  ? curTaxDetail.INFO[0].MORTCONAME
                  : '-'}
              </Col>
            </Row>
          </Jumbotron>

          <Jumbotron className="w-100 shadow-sm bg-white p-3 mt-3 mb-0">
            <h5 className="mb-0">Tax Detail</h5>
            <hr />
            <Table
              hover
              responsive
              size="sm"
              className="mb-0 mx-auto"
              style={{
                whiteSpace: 'nowrap',
                fontSize: '1rem',
                lineHeight: '1.15rem',
                fontFamily: 'Calibri, sans-serif',
                maxWidth: mobileView ? '100%' : '50%',
              }}
            >
              <tbody>
                <tr>
                  <td style={{ textAlign: 'right' }} className="border-top-0">
                    General Taxes:
                  </td>
                  <td style={{ textAlign: 'right' }} className="border-top-0">
                    {curTaxDetail.INFO &&
                    curTaxDetail.INFO.length > 0 &&
                    curTaxDetail.INFO[0].ASMT_TAXES !== null
                      ? withCentsFormat(`${curTaxDetail.INFO[0].ASMT_TAXES}`)
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'right' }}>Adjustments:</td>
                  <td style={{ textAlign: 'right' }}>
                    {curTaxDetail.INFO &&
                    curTaxDetail.INFO.length > 0 &&
                    curTaxDetail.INFO[0].ADJUSTMENTS !== null
                      ? withCentsFormat(`${curTaxDetail.INFO[0].ADJUSTMENTS}`)
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'right' }}>Net Taxes:</td>
                  <td style={{ textAlign: 'right' }}>
                    {curTaxDetail.INFO &&
                    curTaxDetail.INFO.length > 0 &&
                    curTaxDetail.INFO[0].NETTAXES !== null
                      ? withCentsFormat(`${curTaxDetail.INFO[0].NETTAXES}`)
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'right' }}>Penalty:</td>
                  <td style={{ textAlign: 'right' }}>
                    {curTaxDetail.INFO &&
                    curTaxDetail.INFO.length > 0 &&
                    curTaxDetail.INFO[0].PENALTY !== null
                      ? withCentsFormat(`${curTaxDetail.INFO[0].PENALTY}`)
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'right' }}>Fees:</td>
                  <td style={{ textAlign: 'right' }}>
                    {curTaxDetail.INFO &&
                    curTaxDetail.INFO.length > 0 &&
                    curTaxDetail.INFO[0].FEES !== null
                      ? withCentsFormat(`${curTaxDetail.INFO[0].FEES}`)
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: 'right',
                      borderBottom: '1px solid black',
                    }}
                  >
                    Payments:
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      borderBottom: '1px solid black',
                    }}
                  >
                    {curTaxDetail.INFO &&
                    curTaxDetail.INFO.length > 0 &&
                    curTaxDetail.INFO[0].TOTALPYMTS !== null
                      ? withCentsFormat(`${curTaxDetail.INFO[0].TOTALPYMTS}`)
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'right' }}>Tax Balance:</td>
                  <td style={{ textAlign: 'right' }}>
                    {curTaxDetail.INFO &&
                    curTaxDetail.INFO.length > 0 &&
                    curTaxDetail.INFO[0].BALANCEDUE !== null
                      ? withCentsFormat(`${curTaxDetail.INFO[0].BALANCEDUE}`)
                      : '-'}
                  </td>
                </tr>
              </tbody>
            </Table>
            {curTaxDetail.DELINQUENT_DESCR &&
              curTaxDetail.DELINQUENT_DESCR.length > 0 &&
              curTaxDetail.DELINQUENT_DESCR[0].DELINQ_STATUS !== ' ' && (
                <p
                  style={{
                    fontStyle: 'italic',
                    fontFamily: 'Calibri, sans-serif',
                    fontSize: '0.85rem',
                  }}
                  className="mb-0 pt-2 pb-1 border-top"
                >
                  Delinquent Status:{' '}
                  {curTaxDetail.DELINQUENT_DESCR[0].DELINQ_STATUS}
                </p>
              )}
          </Jumbotron>
          <Row noGutters className="w-100 d-flex align-items-center">
            <Button
              className={`mx-auto mt-3${mobileView ? ' w-100' : ' w-50'}`}
              onClick={() => {
                let txLink;
                if (taxyear >= 2020)
                  txLink =
                    'https://www.utahcounty.gov/Dept/Treas/DuplicateNotices.asp';
                else
                  txLink = `https://www.utahcounty.gov/Dept/Treas/DuplicateNoticeConfirm.asp?serialform-serial=${serial}&serialform-year=${taxyear}&serialform-type=TAX&serialform-submit=submit`;

                window.open(txLink, '_blank', 'noopener,noreferrer');
              }}
            >
              <BoxArrowUpRight className="mr-2 mb-1" />
              Duplicate Tax Notice
            </Button>
          </Row>
          <Jumbotron className="w-100 shadow-sm bg-white p-3 mb-0 mt-3">
            <h5 className="mb-0">Payments</h5>
            <hr />
            {!curTaxDetail.TAX_PAYMENTS ||
              (curTaxDetail.TAX_PAYMENTS.length === 0 && (
                <Row noGutters className="h-100">
                  <h5
                    className="mx-auto d-flex align-items-center justify-content-center"
                    style={{
                      fontFamily: 'Calibri, sans-serif',
                      fontSize: '1rem',
                      fontWeight: '100',
                    }}
                  >
                    {'- None -'}
                  </h5>
                </Row>
              ))}
            {curTaxDetail.TAX_PAYMENTS && curTaxDetail.TAX_PAYMENTS.length > 0 && (
              <Table
                responsive
                hover
                size="sm"
                className="w-100 mb-0"
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: '1rem',
                  lineHeight: '1.15rem',
                  fontFamily: 'Calibri, sans-serif',
                }}
              >
                <thead className="stickyTop bg-white">
                  <tr>
                    <th
                      style={{ fontWeight: '800' }}
                      className="border-top-0 border-bottom-0"
                    >
                      Date
                    </th>
                    <th
                      style={{ fontWeight: '800' }}
                      className="border-top-0 border-bottom-0"
                    >
                      Amount
                    </th>
                    <th
                      style={{ fontWeight: '800' }}
                      className="border-top-0 border-bottom-0"
                    >
                      Reference
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {curTaxDetail.TAX_PAYMENTS.map((p, i) => (
                    <tr key={i}>
                      <td>
                        {Date.parse(p.PAY_DATE)
                          ? new Date(p.PAY_DATE).toLocaleDateString()
                          : p.PAY_DATE}{' '}
                        {`${p.PAY_TIME || '-'}`.toUpperCase()}
                      </td>
                      <td>{withCentsFormat(`${p.AMOUNT}`)}</td>
                      <td>{p.PAY_REFERENCE || '-'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Jumbotron>
          {curTaxDetail.TAX_ADJUSTMENTS &&
            curTaxDetail.TAX_ADJUSTMENTS.length > 0 && (
              <Jumbotron className="w-100 shadow-sm bg-white p-3 mb-0 mt-3">
                <h5 className="mb-0">Adjustments</h5>
                <hr />
                <Table
                  responsive
                  hover
                  size="sm"
                  className="w-100 mb-0"
                  style={{
                    whiteSpace: 'nowrap',
                    fontSize: '1rem',
                    lineHeight: '1.15rem',
                    fontFamily: 'Calibri, sans-serif',
                  }}
                >
                  <thead className="stickyTop bg-white">
                    <tr>
                      <th
                        style={{ fontWeight: '800' }}
                        className="border-top-0 border-bottom-0"
                      >
                        Date
                      </th>
                      <th
                        style={{ fontWeight: '800' }}
                        className="border-top-0 border-bottom-0"
                      >
                        Amount
                      </th>
                      <th
                        style={{ fontWeight: '800' }}
                        className="border-top-0 border-bottom-0"
                      >
                        Posted
                      </th>
                      <th
                        style={{ fontWeight: '800' }}
                        className="border-top-0 border-bottom-0"
                      >
                        Description
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {curTaxDetail.TAX_ADJUSTMENTS.map((p, i) => (
                      <tr key={i}>
                        <td>
                          {Date.parse(p.ADJ_DATE)
                            ? `${new Date(
                                p.ADJ_DATE
                              ).toLocaleDateString()} ${new Date(
                                p.ADJ_DATE
                              ).toLocaleTimeString()}`
                            : '-'}
                        </td>
                        <td>{withCentsFormat(`${p.ADJ_AMOUNT}`)}</td>
                        <td>{withCentsFormat(`${p.POSTED_AMOUNT}`)}</td>
                        <td>{p.ADJ_DESC || '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Jumbotron>
            )}
          {curTaxDetail.DELINQUENT_INFO &&
            curTaxDetail.DELINQUENT_INFO.length > 0 && (
              <Jumbotron className="w-100 shadow-sm bg-white p-3 mb-0 mt-3">
                <h5 className="mb-0">Delinquent Tax Information</h5>
                <hr />
                <Table
                  responsive
                  hover
                  size="sm"
                  className="w-100 mb-0"
                  style={{
                    whiteSpace: 'nowrap',
                    fontSize: '1rem',
                    lineHeight: '1.15rem',
                    fontFamily: 'Calibri, sans-serif',
                  }}
                >
                  <thead className="stickyTop bg-white">
                    <tr>
                      <th
                        style={{ fontWeight: '800' }}
                        className="border-top-0 border-bottom-0"
                      >
                        Year
                      </th>
                      <th
                        style={{ fontWeight: '800' }}
                        className="border-top-0 border-bottom-0"
                      >
                        Flag
                      </th>
                      <th
                        style={{ fontWeight: '800' }}
                        className="border-top-0 border-bottom-0"
                      >
                        Link
                      </th>
                      <th
                        style={{ fontWeight: '800' }}
                        className="border-top-0 border-bottom-0"
                      >
                        Amount
                      </th>
                      <th
                        style={{ fontWeight: '800' }}
                        className="border-top-0 border-bottom-0"
                      >
                        I.D.
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {curTaxDetail.DELINQUENT_INFO.map((p, i) => (
                      <tr key={i}>
                        <td>{p.TAX_YEAR || '-'}</td>
                        <td>{p.MY_FLAG || '-'}</td>
                        <td>
                          <a
                            className="d-flex"
                            style={{
                              textUnderlineOffset: '0.125rem',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              dispatch(setCurrentID({ currentID: null }));
                              dispatch(
                                setSearchDidRun({ searchDidRun: false })
                              );
                              history.push(
                                `/taxes/delinquentTax/1?serial=${
                                  curTaxDetail.INFO.length > 0 &&
                                  curTaxDetail.INFO[0].SERIAL
                                    ? curTaxDetail.INFO[0].SERIAL
                                    : ''
                                }&currentID=${
                                  curTaxDetail.INFO.length > 0 &&
                                  curTaxDetail.INFO[0].SERIAL
                                    ? curTaxDetail.INFO[0].SERIAL
                                    : ''
                                }`
                              );
                            }}
                          >
                            Click for Payoff
                          </a>
                        </td>
                        <td>{withCentsFormat(`${p.TAX_BALANCE}`)}</td>
                        <td>{p.MY_INFO || '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Jumbotron>
            )}
        </div>
      )}
    </>
  );
};

export default TaxHistoryDetailView;
