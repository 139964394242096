import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'axios';

import DefaultPropertyImage from './DefaultPropertyImage';
import PropertyImage from './PropertyImage';

// entity adapter / normalization to improve performance
const propertyImageAdapter = createEntityAdapter({
  selectId: e => e.serial,
});

const initialState = propertyImageAdapter.getInitialState({
  status: 'loading',
  error: null,
});

export const fetchAllPropertyImagesData = createAsyncThunk(
  'app/propertyImages/fetchAllPropertyImagesData',
  async ({ url, serial }) => {
    let photoInfoList;
    try {
      photoInfoList = (
        await axios({
          url: `${url}/photosbyserial?serial=${serial}`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data;
    } catch (err) {
      // provide a default image
      return; // { serial: serial, photos: [<DefaultPropertyImage />] };
    }
    // in case the array does not exist or is empty
    if (!photoList || !photoList.length) return; // { serial: serial, photos: [<DefaultPropertyImage />] };
    return photoInfoList;
  }
);

export const fetchPropertyImageData = createAsyncThunk(
  'app/propertyImages/fetchPropertyImageData',
  async ({ url, dir1, dir2, pic_no }) => {
    let photoData;
    try {
      photoData = (
        await axios({
          url: `${url}/photocompressed?dir1=${dir1}&dir2=${dir2}&pic_no=${pic_no}&scaleWidth=464`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data[0].PHOTO;
    } catch (err) {
      // provide a default image
      return <DefaultPropertyImage />;
    }
    return photoData;
  }
);

export const fetchPropertyImages = createAsyncThunk(
  'app/propertyImages/fetchPropertyImages',
  async ({ url, serial }) => {
    let photoList;
    try {
      photoList = (
        await axios({
          url: `${url}/photosbyserial?serial=${serial}`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data;
    } catch (err) {
      // return empty list
      return { serial: serial, photos: [], photosLength: 0 };
    }

    // in case the array does not exist or is empty
    if (!photoList || !photoList.length)
      return { serial: serial, photos: [], photosLength: 0 };

    let photos = [];
    for (let p of photoList) {
      let photoData;
      try {
        photoData = (
          await axios({
            url: `${url}/photocompressed?dir1=${p.DIR1}&dir2=${p.DIR2}&pic_no=${p.PIC_NO}&scaleWidth=464`,
            headers: {
              apikey: process.env.APIKEY,
            },
          })
        ).data.data[0].PHOTO;
      } catch (err) {
        // do nothing
      }
      if (photoData) photos.push(photoData);
    }
    return { serial: serial, photos: photos, photosLength: photoList.length };
  }
);

export const slice = createSlice({
  name: 'app/propertyImages',
  initialState: initialState,
  reducers: {
    emptyPropertyImages: propertyImageAdapter.removeAll,
  },
  extraReducers: {
    [fetchPropertyImages.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchPropertyImages.fulfilled]: (state, action) => {
      propertyImageAdapter.upsertOne(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchPropertyImages.rejected]: (state, action) => {
      state.error = action.error.message;
      state.status = 'failed';
    },
  },
});

export const { emptyPropertyImages } = slice.actions;

export const {
  selectById: selectPropertyImagesBySerial,
  selectIds: selectAllPropertyImageIds,
  selectEntities: selectAllPropertyImageEntities,
  selectAll: selectAllPropertyImages,
} = propertyImageAdapter.getSelectors(state => state.propertyImages);

export default slice.reducer;
