import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { Search } from 'react-bootstrap-icons';

import DefaultPropertyImage from '../../../app/propertyImages/DefaultPropertyImage';
import PropertyImage from '../../../app/propertyImages/PropertyImage';

import {
  selectAllPropertyImageEntities,
  selectAllPropertyImageIds,
  fetchPropertyImages,
} from '../../../app/propertyImages/propertyImagesSlice';
import { selectDefaultMainSearch } from '../../../app/defaultSearchesSlice';
import { setCurrentID } from '../../../app/searchSlice';
import { setSidebarExpanded } from '../../sidebarSlice';
import { queryToString } from '../../../../utilities/url';
import { setTaxyear } from '../../../detailView/propertyDetailView/propertyDetailViewSlice';

const PropertySerialSearchList = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const mainSearchInfo = useSelector(selectDefaultMainSearch(mainSearch));
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const searchStatus = useSelector(state => state.search.status);
  const allPropertyImageEntities = useSelector(selectAllPropertyImageEntities);
  const allPropertyImageIds = useSelector(selectAllPropertyImageIds);
  const dispatch = useDispatch();
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const currentID = useSelector(state => state.search.currentID);
  const router = useSelector(state => state.router);
  const history = useHistory();

  // load
  const rawResults = useSelector(state => state.search.searchResults);
  const [searchResults, setSearchResults] = useState(rawResults.slice());

  // load images for search results
  useEffect(() => {
    searchResults.forEach(r => {
      if (allPropertyImageIds.indexOf(r.SERIAL) === -1)
        dispatch(fetchPropertyImages({ url: baseApiUrl, serial: r.SERIAL }));
    });
  }, []);

  // update currentID when page loads
  useEffect(() => {
    if (
      searchStatus === 'succeeded' &&
      searchResults.length > 0 &&
      !currentID
    ) {
      const yv =
        searchResults[0].YEARSVALID.slice(-3) === '...' ||
        !searchResults[0].YEARSVALID
          ? [`${new Date().getFullYear()}`]
          : searchResults[0].YEARSVALID.split('-');
      let yearsvalid;
      if (yv.length === 2) yearsvalid = yv[1];
      else yearsvalid = yv[0].slice(0, 4);
      dispatch(setTaxyear({ taxyear: yearsvalid }));
      dispatch(
        setCurrentID({
          currentID: searchResults[0].SERIAL,
        })
      );
    }
  });

  return (
    <>
      {/* show search loading */}
      {searchStatus === 'loading' && !searchResults && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '10rem', width: '10rem' }}
          />
        </Row>
      )}
      {/* show no result message */}
      {searchStatus === 'succeeded' &&
        searchResults &&
        searchResults.length === 0 && (
          <Row
            noGutters
            id="sidebarResults"
            className={
              sidebarExpanded
                ? 'w-100 h-100 d-flex flex-column flex-grow-1'
                : 'd-none'
            }
            style={{
              backgroundColor: 'rgba(101, 167, 219, 0.25)',
            }}
          >
            <h5
              className="mx-auto w-75 d-flex flex-column h-100 align-items-center"
              style={{
                marginTop: '25%',
              }}
            >
              <Search
                height={mobileView ? '4rem' : '8rem'}
                width={mobileView ? '4rem' : '8rem'}
                style={{ color: 'rgba(101, 167, 219, 1)' }}
              />
              <p className="mb-1 mt-4 text-center">{`No ${mainSearchInfo.titleSingle.toLowerCase()} results found.`}</p>
              <p className="mb-0 mt-2 text-center">{`Please update your filter parameters or try another search.`}</p>
            </h5>
          </Row>
        )}
      {/* show search results list */}
      {searchStatus === 'succeeded' &&
        searchResults &&
        searchResults.length > 0 && (
          <>
            <div
              id="searchResultsList"
              className={
                sidebarExpanded
                  ? 'w-100 d-flex flex-column overflow-auto border-bottom shadow-sm'
                  : 'd-none'
              }
              style={{
                fontWeight: '100',
                fontFamily: 'Calibri, sans-serif',
                background: '#FFF',
                position: 'relative',
              }}
            >
              {searchResults.map((result, index) => {
                // load images for result
                return (
                  <Row
                    noGutters
                    key={index}
                    className={`w-100 rounded-0 border-top border-bottom ${
                      mobileView ? 'pl-0 py-2 pr-2' : 'py-3 px-2'
                    }`}
                    style={{
                      background: 'rgba(0, 0, 0, 0.03)',
                    }}
                  >
                    <Col
                      xs={4}
                      sm={2}
                      className="d-flex flex-column align-items-center"
                    >
                      {!allPropertyImageEntities[result.SERIAL] && (
                        <DefaultPropertyImage loading={true} />
                      )}
                      {allPropertyImageEntities[result.SERIAL] &&
                        allPropertyImageEntities[result.SERIAL].photos
                          .length === 0 && (
                          <DefaultPropertyImage loading={false} />
                        )}
                      {allPropertyImageEntities[result.SERIAL] &&
                        allPropertyImageEntities[result.SERIAL].photos.length >
                          0 && (
                          <PropertyImage
                            photoData={
                              allPropertyImageEntities[result.SERIAL].photos[0]
                            }
                          />
                        )}
                    </Col>
                    <Col xs={8} sm={10}>
                      <Row
                        noGutters
                        className={`w-100 pl-1 mb-1 ${
                          mobileView ? 'd-flex' : 'd-none'
                        }`}
                      >
                        <a
                          className="text-truncate"
                          style={{
                            lineHeight: '1.25rem',
                            textUnderlineOffset: '0.125rem',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (mobileView)
                              dispatch(
                                setSidebarExpanded({ sidebarExpanded: false })
                              );
                            if (currentID !== result.SERIAL)
                              dispatch(
                                setCurrentID({
                                  currentID: result.SERIAL,
                                })
                              );
                            const query = queryToString({
                              query: router.location.query,
                              currentID: `${result.SERIAL}`,
                            });
                            history.push(
                              `${router.location.pathname}?${query}`
                            );
                          }}
                        >
                          {result.SERIAL_FORMATTED
                            ? result.SERIAL_FORMATTED
                            : '-'}
                        </a>
                      </Row>
                      <Row
                        noGutters
                        className={`w-100${mobileView ? ' pl-1' : ' pl-2'}`}
                      >
                        <Col
                          xs={4}
                          sm={3}
                          className="pl-0 pr-1 d-flex flex-column align-items-center text-truncate"
                        >
                          <Row
                            noGutters
                            className={`w-100 border-right pb-1 ${
                              mobileView ? 'd-none' : 'd-flex'
                            }`}
                            style={{
                              fontWeight: '400',
                              fontSize: '1.15rem',
                              lineHeight: '1.5rem',
                            }}
                          >
                            <span className="text-truncate">Serial/Parcel</span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100 border-right"
                            style={{
                              fontWeight: '400',
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">Address</span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100 border-right"
                            style={{
                              fontWeight: '400',
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">Owner Name</span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100 border-right"
                            style={{
                              fontWeight: '400',
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              Legal Description
                            </span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100 border-right"
                            style={{
                              fontWeight: '400',
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">Years Valid</span>
                          </Row>
                        </Col>
                        <Col className="px-1 d-flex flex-column align-items-center text-truncate">
                          <Row
                            noGutters
                            className={`w-100 pb-1 ${
                              mobileView ? 'd-none' : 'd-flex'
                            }`}
                          >
                            <a
                              className="text-truncate"
                              style={{
                                fontWeight: '400',
                                fontSize: '1.15rem',
                                lineHeight: '1.5rem',
                                textUnderlineOffset: '0.125rem',
                                textDecoration: result.SERIAL_FORMATTED
                                  ? 'underline'
                                  : 'none',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                if (mobileView)
                                  dispatch(
                                    setSidebarExpanded({
                                      sidebarExpanded: false,
                                    })
                                  );
                                if (currentID !== result.SERIAL)
                                  dispatch(
                                    setCurrentID({
                                      currentID: result.SERIAL,
                                    })
                                  );
                                const query = queryToString({
                                  query: router.location.query,
                                  currentID: `${result.SERIAL}`,
                                });
                                history.push(
                                  `${router.location.pathname}?${query}`
                                );
                              }}
                            >
                              {result.SERIAL_FORMATTED
                                ? result.SERIAL_FORMATTED
                                : '-'}
                            </a>
                          </Row>
                          <Row
                            noGutters
                            className="w-100"
                            style={{
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              {result.ADDRESS ? `${result.ADDRESS}` : '-'}
                            </span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100"
                            style={{
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              {result.OWNERNAME ? `${result.OWNERNAME}` : `-`}
                            </span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100"
                            style={{
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              {result.LEGAL_DESC ? `${result.LEGAL_DESC}` : `-`}
                            </span>
                          </Row>
                          <Row
                            noGutters
                            className="w-100"
                            style={{
                              fontSize: '0.9rem',
                              lineHeight: '1.15rem',
                            }}
                          >
                            <span className="text-truncate">
                              {result.YEARSVALID ? `${result.YEARSVALID}` : `-`}
                            </span>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </>
        )}
    </>
  );
};

export default PropertySerialSearchList;
