import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import { BoxArrowUpRight, Building } from 'react-bootstrap-icons';

import DevelopmentDetailViewMap from './developmentDetailViewMap/DevelopmentDetailViewMap';
import DocumentModal from '../modalViews/DocumentModal';

import {
  fetchDevelopmentInfo,
  selectAllDevelopments,
  selectDevelopmentById,
  selectAllDevelopmentIDs,
} from './developmentDetailViewSlice';
import { useHistory, useLocation } from 'react-router-dom';
import { setCurrentID, setSearchDidRun } from '../../app/searchSlice';
import { queryToString } from '../../../utilities/url';
import { _normalize } from '../../../utilities/searchForm';

const DevelopmentDetailView = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const subSearch = useSelector(state => state.search.subSearch);
  const [mapExpanded, setMapExpanded] = useState(false);
  const [viewStatus, setViewStatus] = useState('loading');
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const detailViewStatus = useSelector(
    state => state.developmentDetailView.status
  );
  const searchStatus = useSelector(state => state.search.status);
  const dispatch = useDispatch();
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const currentID = useSelector(state => state.search.currentID);
  const curDev = useSelector(state => selectDevelopmentById(state, currentID));
  const allDevIDs = useSelector(selectAllDevelopmentIDs);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const searchHistoryIndex = useSelector(
    state => state.search.searchHistoryIndex
  );
  const router = useSelector(state => state.router);

  // update current development every time current ID changes
  useEffect(() => {
    if (
      currentID &&
      mainSearch === 'properties' &&
      subSearch === 'development'
    ) {
      if (!allDevIDs.includes(currentID))
        dispatch(fetchDevelopmentInfo({ url: baseApiUrl, id: currentID }));
      const query = queryToString({
        query: router.location.query,
        currentID: currentID,
      });
      if (!router.location.query.currentID)
        history.replace(`${router.location.pathname}?${query}`);
    }
  }, [currentID]);

  useEffect(() => {
    if (detailViewStatus === 'loading') setViewStatus('loading');
    if (
      detailViewStatus === 'failed' ||
      (detailViewStatus === 'succeeded' && !curDev) ||
      (searchStatus === 'succeeded' && !currentID)
    )
      setViewStatus('failed');
    if (detailViewStatus === 'succeeded' && curDev) setViewStatus('succeeded');
  }, [currentID, detailViewStatus, searchStatus, curDev]);

  return (
    <>
      {viewStatus === 'loading' && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '20rem', width: '20rem' }}
          />
        </Row>
      )}
      {viewStatus === 'failed' && (
        <Row
          noGutters
          className="h-100 d-flex flex-column align-items-center justify-content-center p-2"
          style={{ backgroundColor: 'rgba(217, 190, 80, 0.1)' }}
        >
          <Building
            height={mobileView ? '4rem' : '8rem'}
            width={mobileView ? '4rem' : '8rem'}
            style={{ color: 'rgba(78, 128, 166, 1)' }}
          />
          <h5 className="text-center mt-4" style={{ fontSize: '1.5rem' }}>
            Could not find development information.
          </h5>
          <h5 className="text-center mt-2" style={{ fontSize: '1.5rem' }}>
            Please try another search.
          </h5>
        </Row>
      )}
      {viewStatus === 'succeeded' && curDev && (
        <>
          <Row noGutters className="d-flex flex-column p-3">
            <Jumbotron className="w-100 shadow-sm bg-white p-2 mb-3">
              <Container className="p-2" fluid>
                <Row noGutters>
                  <Col
                    className="px-0 d-flex justify-content-center align-items-center"
                    xs={{ span: 12, order: 2 }}
                    sm={{ span: 4, order: 1 }}
                  >
                    <DevelopmentDetailViewMap
                      serialList={curDev.serials.map(d => d.SERIAL)}
                    />
                  </Col>
                  <Col
                    className={`${
                      mobileView ? 'p2' : 'py-2 pl-4 pr-2'
                    } flex-grow-1 d-flex flex-column justify-content-center align-items-center`}
                    xs={{ span: 12, order: 1 }}
                    sm={{ span: 8, order: 2 }}
                  >
                    <Table
                      size="sm"
                      style={{
                        lineHeight: '1.25rem',
                        fontSize: '1.15rem',
                        width: mobileView
                          ? '100%'
                          : sidebarExpanded
                          ? '85%'
                          : '66%',
                      }}
                    >
                      <tbody>
                        <tr className="mx-auto">
                          <th
                            className="border-top-0 border-right pr-3"
                            style={{
                              fontWeight: '500',
                              width: mobileView ? '25%' : '10%',
                            }}
                          >
                            Number
                          </th>
                          <td className="border-top-0 pl-3">{`${
                            curDev.NUMB || '-'
                          }`}</td>
                        </tr>
                        <tr className="mx-auto">
                          <th
                            className="border-top-0 border-right pr-3"
                            style={{
                              fontWeight: '500',
                              width: mobileView ? '25%' : '10%',
                            }}
                          >
                            Name
                          </th>
                          <td className="border-top-0 pl-3">{`
                          ${curDev.NAME || '-'}`}</td>
                        </tr>
                        <tr className="mx-auto">
                          <th
                            className="border-top-0 border-right pr-3"
                            style={{
                              fontWeight: '500',
                              width: mobileView ? '25%' : '10%',
                            }}
                          >
                            Type
                          </th>
                          <td className="border-top-0 pl-3">
                            {`${curDev.TYPENAME || '-'}`}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Row
                      noGutters
                      className="mb-3"
                      style={{
                        width: mobileView
                          ? '100%'
                          : sidebarExpanded
                          ? '85%'
                          : '66%',
                      }}
                    >
                      <Button
                        className="w-100"
                        onClick={() =>
                          window.open(
                            `http://bmiweb.utahcounty.gov/BmiWeb/?database=Mapfilings&page=document&MAP_NO=${curDev.NUMB}`,
                            '_blank',
                            'noopener,noreferrer'
                          )
                        }
                      >
                        <BoxArrowUpRight className="mr-2 mb-1" />
                        Recorded Development Images
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Jumbotron>
            <Jumbotron className="w-100 shadow-sm bg-white p-2 mb-3">
              <Container className="p-2" fluid>
                <h5>Development Detail</h5>
                <hr />
                <Row
                  noGutters
                  className="h-100 w-100 d-flex align-items-center"
                >
                  <Col
                    xs={12}
                    sm={4}
                    style={{
                      lineHeight: '1.15rem',
                      fontSize: '1rem',
                      marginBottom: '0',
                    }}
                  >
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={4}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Entry Number
                      </Col>
                      <Col xs={6} sm={8} className="p-2">
                        <a
                          className="d-flex"
                          style={{
                            textUnderlineOffset: '0.125rem',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => setShowDocumentModal(true)}
                        >
                          {curDev.ENTRYNO && curDev.ENTRYYR
                            ? `${curDev.ENTRYNO}-${curDev.ENTRYYR}`
                            : `-`}
                        </a>
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={4}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Recording Date
                      </Col>
                      <Col xs={6} sm={8} className="p-2">
                        {curDev.RECDATE && Date.parse(curDev.RECDATE)
                          ? new Date(curDev.RECDATE).toLocaleDateString()
                          : '-'}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={4}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Instrument Date
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {curDev.INST_DATE && Date.parse(curDev.INST_DATE)
                          ? new Date(curDev.INST_DATE).toLocaleDateString()
                          : '-'}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    style={{
                      lineHeight: '1.15rem',
                      fontSize: '1rem',
                      marginBottom: '0',
                    }}
                  >
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={4}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Book/Page
                      </Col>
                      <Col xs={6} sm={8} className="p-2">
                        {curDev.BOOK && curDev.PAGE
                          ? `${curDev.BOOK}-${curDev.PAGE}`
                          : `-`}
                      </Col>
                    </Row>

                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={4}
                        className="border-right p-2 w-100"
                        style={{ fontWeight: '500' }}
                      >
                        Tax District
                      </Col>
                      <Col xs={6} sm={8} className="p-2">
                        {curDev.TAX_DIST || `-`}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={4}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Arm
                      </Col>
                      <Col xs={6} sm={8} className="p-2">
                        {curDev.ARM ? curDev.ARM.trim() : '-'}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    style={{
                      lineHeight: '1.15rem',
                      fontSize: '1rem',
                      marginBottom: '0',
                    }}
                  >
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={4}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        City
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {curDev.CITY || '-'}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={4}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Location
                      </Col>
                      <Col xs={6} sm={8} className="p-2">
                        <span style={{ fontWeight: '400' }}>Section:</span>{' '}
                        {curDev.SECTION || '-'}
                        <br />
                        <span style={{ fontWeight: '400' }}>
                          Township:
                        </span>{' '}
                        {curDev.TOWNSHIP || '-'}S<br />
                        <span style={{ fontWeight: '400' }}>Range:</span>{' '}
                        {curDev.RANGE_ || '-'}
                        {curDev.RANGE_DIR || '-'}
                      </Col>
                    </Row>
                    <Row noGutters className="w-100">
                      <Col
                        xs={6}
                        sm={4}
                        className="border-right p-2"
                        style={{ fontWeight: '500' }}
                      >
                        Description
                      </Col>
                      <Col xs={6} sm={7} className="p-2">
                        {curDev.DESCR || `-`}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Jumbotron>
            <Jumbotron className="w-100 shadow-sm bg-white p-2 mb-0">
              <Container className="p-2" fluid>
                <h5>Parcels in Development</h5>
                <hr />
                <div
                  className="rounded"
                  style={{
                    maxHeight: '30vh',
                    overflow: 'auto',
                  }}
                >
                  <Table
                    striped
                    hover
                    size="sm"
                    className="w-100 mb-0"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <thead className="stickyTop bg-white">
                      <tr>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Serial Number
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Owner Name
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Address
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Acres
                        </th>
                        <th
                          style={{ fontWeight: '400' }}
                          className="border-top-0 border-bottom-0"
                        >
                          Years Valid
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {curDev.serials.map((s, index) => (
                        <tr key={index}>
                          <td>
                            {_normalize(s.SERIAL_FORMATTED) !== 0 ? (
                              <a
                                className="d-flex"
                                style={{
                                  textUnderlineOffset: '0.125rem',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  dispatch(setCurrentID({ currentID: null }));
                                  dispatch(
                                    setSearchDidRun({ searchDidRun: false })
                                  );
                                  history.push(
                                    `/properties/serial/0?serial=${s.SERIAL}`
                                  );
                                }}
                              >
                                {s.SERIAL_FORMATTED}
                              </a>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>{s.OWNERNAME || `-`}</td>
                          <td>{s.ADDRESS || `-`}</td>
                          <td>{parseFloat(s.ACREAGE) || `-`}</td>
                          <td>{s.YEARSVALID || `-`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Container>
            </Jumbotron>
          </Row>
          <DocumentModal
            show={showDocumentModal}
            setShow={setShowDocumentModal}
            entryNumber={`${curDev.ENTRYNO}-${curDev.ENTRYYR}`}
          />
        </>
      )}
      {detailViewStatus === 'succeeded' && mapExpanded && <></>}
    </>
  );
};

export default DevelopmentDetailView;
