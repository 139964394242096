import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from 'react-bootstrap/Spinner';
import { setStatus } from './developmentDetailViewMapSlice';

// note: come back and implement book page with esri map and UC layers API using bookPage param

const DevelopmentDetailViewMap = ({ serialList = [50220004], bookPage }) => {
  const mapStatus = useSelector(state => state.developmentDetailViewMap.status);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const disptach = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      disptach(setStatus({ status: 'succeeded' }));
    }, 200);
  });

  return (
    <>
      {mapStatus === 'loading' && (
        <div
          className="h-100 w-100"
          style={{ minHeight: mobileView ? '300px' : '400px' }}
        >
          <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
            <Spinner
              animation="border"
              variant="info"
              className="d-flex align-self-center m-auto"
              style={{ height: '10rem', width: '10rem' }}
            />
          </div>
        </div>
      )}
      {mapStatus === 'succeeded' && (
        <div
          className={`h-100 w-100`}
          style={{ minHeight: mobileView ? '300px' : '400px' }}
        >
          <div
            id="mapView"
            className="w-100 h-100 border rounded shadow-sm d-flex align-items-center justify-content-center"
            dangerouslySetInnerHTML={{
              __html: `<iframe class="h-100 w-100" src="https://maps.utahcounty.gov/ParcelLocationMap/ParcelLocationMap.html?mParcelToSearch=${serialList[0]}" />`,
            }}
          ></div>
        </div>
      )}
    </>
  );
};

export default DevelopmentDetailViewMap;

// const embeddedMapURL = `<iframe id="embeddedMap" ${
//   ctx.mapExpanded
//     ? 'class="h-100 w-100"'
//     : 'class="h-100 w-100 border rounded shadow-sm"'
// }
// 	src="https://maps.utahcounty.gov/ParcelLocationMap/ParcelLocationMap.html?mParcelToSearch=${
//     propertyInfo.propertyAddress ? propertyInfo.serialNo : '50220004'
//   }"></iframe>`;

// const embededAerialMapURL = `<iframe id="embeddedAerialMap" ${ctx.mapExpanded ? 'class="h-100 w-100"' : 'class="h-100 w-100 border rounded shadow-sm"'}
// src="http://maps.utahcounty.gov/PictometryIPA/PictometryIPA.html?mParcelToSearch=${propertyInfo.propertyAddress ? propertyInfo.serialNo : '50220004'}"></iframe>`;

//   function init()
// {
// 	map = new esri.Map("map", {logo:false});

// 	LargeScale_Layer = new esri.layers.ArcGISTiledMapServiceLayer("https://maps.utahcounty.gov/arcgis/rest/services/Basemaps/UC_TopoBasemap/MapServer");
// 	map.addLayer(LargeScale_Layer);

// 	ParcelLive_Layer = new esri.layers.ArcGISDynamicMapServiceLayer("https://maps.utahcounty.gov/arcgis/rest/services/Parcels/TaxParcels/MapServer");
// 	map.addLayer(ParcelLive_Layer);

// 	LargeScale_Layer.show();
// 	ParcelLive_Layer.show();
// 	QueryParcel();
// }

// function QueryParcel()
// {
// 		//First Query Task represents
// 	var queryTask = new esri.tasks.QueryTask("https://maps.utahcounty.gov/arcgis/rest/services/Parcels/ParcelsHistorical/MapServer/2");
// 	var query = new esri.tasks.Query();

// 	     //build query filter
// 	query.returnGeometry = true;
// 	query.outFields = ["SERIAL"];

// 	if (gSerialNo.length == 4){
// 	    query.where = "CAST(SERIAL as VARCHAR(9)) LIKE '" + gSerialNo + "%' AND SERIAL < 100000000"; //new format for file GDB, using the CAST to convert the long integer to a string for the 'LIKE' sql command
// 	}
// 	else {
// 		query.where = "CAST(SERIAL as VARCHAR(9)) LIKE '" + gSerialNo + "%' AND SERIAL >= 100000000";
// 	}

// 	//alert("query: " + query.where);

// 		//Execute task and call showResults on completion
// 	 queryTask.execute(query, function(fset) {
// 		 if (fset.features.length >= 1) {
// 			 showFeatures(fset.features);
// 		 } else if (fset.features.length !== 0) {
// 			  alert ("Parcels for Subdivision " + gSerialNo + " not found in GIS.");
// 		 } else{
// 			 alert ("Parcels for Subdivision " + gSerialNo + " not found in GIS.");}
// 	 });
// }

// //passed in an array of features
// function showFeatures(features)
// {
// 	map.graphics.clear();
// 	var newMapExtent = features[0].geometry.getExtent();

// 	for (var i=0; i < features.length; i++)             //8-6-10 modified to highlight 'ALSO' parcels
// 	{
// 		var fExtent = features[i].geometry.getExtent();
// 		newMapExtent = newMapExtent.union(fExtent);

// 		var symbol = new esri.symbol.SimpleFillSymbol(esri.symbol.SimpleFillSymbol.STYLE_NULL, new esri.symbol.SimpleLineSymbol(esri.symbol.SimpleLineSymbol.STYLE_SOLID, new dojo.Color([255, 0, 0]), 2), new dojo.Color([255, 255, 0, 0.5]));
// 		features[i].setSymbol(symbol);
// 		map.graphics.add(features[i]);
// 	}
// 	map.setExtent(newMapExtent.expand(1.3));

// 	var centerPt = newMapExtent.getCenter();
// 	map.centerAt(centerPt);
// }

// function resizeMap()
// {
//   console.info("resizeMap fired");
//   map.resize();
// }
