import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ArrowCounterclockwise, Check2Circle } from 'react-bootstrap-icons';
import { setFilterActive, setFilterOptions } from '../../sidebarSlice';
import { updateInput } from '../../../../utilities/filterSort';
import { setSearchResultsScrollTop } from '../../../app/searchSlice';

export default function PropertyAddressFilterForm() {
  const defaultValues = {
    city: { type: 'textbox', apiName: 'ADDRESS', value: '' },
    address: { type: 'textbox', apiName: 'ADDRESS', value: '' },
    ownername: { type: 'textbox', apiName: 'OWNERNAME', value: '' },
    serial: { type: 'textbox', apiName: 'SERIAL_FORMATTED', value: '' },
    legalDesc: { type: 'textbox', apiName: 'LEGAL_DESC', value: '' },
    serialLife: { type: 'textbox', apiName: 'SERIAL_LIFE', value: '' },
  };

  const dispatch = useDispatch();
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const searchResults = useSelector(state => state.search.searchResults);
  const [values, setValues] = useState(filterOptions || { ...defaultValues });

  return (
    <>
      <Row
        noGutters
        className="rounded border border-primary bg-light w-100 mb-3 p-3 shadow-sm"
      >
        <Form
          className="w-100"
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(setFilterOptions(values));
            dispatch(setFilterActive({ filterActive: false }));
            dispatch(setSearchResultsScrollTop({ searchResultsScrollTop: 0 }));
          }}
        >
          <Form.Row>
            <Form.Group as={Col} className="w-100 d-flex">
              <Button
                className="ml-auto mr-2"
                onClick={e => {
                  dispatch(setFilterOptions({ ...defaultValues }));
                  dispatch(setFilterActive({ filterActive: false }));
                }}
              >
                <ArrowCounterclockwise /> reset
              </Button>
              <Button type="submit">
                <Check2Circle /> apply
              </Button>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>City</Form.Label>
              <Form.Control
                as="select"
                id="filter-textbox-city"
                value={values.city.value}
                onChange={e => updateInput(e, values, setValues)}
                style={{ maxHeight: '25vh', overflow: 'auto' }}
              >
                {searchResults &&
                  searchResults.length > 0 &&
                  [
                    '-- Select a city --',
                    ...new Set(
                      searchResults
                        .slice()
                        .map(
                          r =>
                            `${r[values.city.apiName]
                              .split(' - ')
                              .slice(-1)[0]
                              .trim()}`
                        )
                    ),
                  ]
                    .sort((a, b) => a.localeCompare(b))
                    .map((c, i) => (
                      <option
                        key={i}
                        value={c === '-- Select a city --' ? '' : c}
                      >
                        {c}
                      </option>
                    ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Serial Life</Form.Label>
              <Form.Control
                as="select"
                id="filter-textbox-serialLife"
                value={values.serialLife.value}
                onChange={e => updateInput(e, values, setValues)}
                style={{ maxHeight: '25vh', overflow: 'auto' }}
              >
                <option value="">-- All Records --</option>
                <option value="\.\.\.">Current Records Only</option>
                <option value="NV">Never Valid Records Only</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Address Contains</Form.Label>
              <Form.Control
                id="filter-textbox-address"
                placeholder="Enter address"
                value={values.address.value}
                onChange={e => updateInput(e, values, setValues)}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Owner Name Contains</Form.Label>
              <Form.Control
                id="filter-textbox-ownername"
                placeholder="Enter owner name"
                value={values.ownername.value}
                onChange={e => updateInput(e, values, setValues)}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Serial/Parcel Contains</Form.Label>
              <Form.Control
                id="filter-textbox-serial"
                placeholder="Enter serial/parcel number"
                value={values.serial.value}
                onChange={e => updateInput(e, values, setValues)}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="mb-0">
              <Form.Label>Legal Description Contains</Form.Label>
              <Form.Control
                id="filter-textbox-legalDesc"
                placeholder="Enter legal description"
                value={values.legalDesc.value}
                onChange={e => updateInput(e, values, setValues)}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </Row>
    </>
  );
}
