import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { Search } from 'react-bootstrap-icons';

import { setSidebarExpanded } from '../../sidebarSlice';
import { selectDefaultMainSearch } from '../../../app/defaultSearchesSlice';
import { setCurrentID } from '../../../app/searchSlice';

import { queryToString } from '../../../../utilities/url';
import { filterResults, sortResults } from '../../../../utilities/filterSort';

const DocumentMiningSearchList = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const mainSearchInfo = useSelector(selectDefaultMainSearch(mainSearch));
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const dispatch = useDispatch();
  const currentID = useSelector(state => state.search.currentID);
  const history = useHistory();
  const router = useSelector(state => state.router);
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const sortOptions = useSelector(state => state.sidebar.sortOptions);

  const searchStatus = useSelector(state => state.search.status);
  // load
  const rawResults = useSelector(state => state.search.searchResults);
  const [searchResults, setSearchResults] = useState(rawResults.slice());

  useEffect(() => {
    let tempResults = [...searchResults];
    // filter
    tempResults = [...filterResults(filterOptions, tempResults)];
    // sort
    tempResults = [...sortResults(sortOptions, tempResults)];
    // update list with filtered/sorted results
    setSearchResults([...tempResults]);
  }, [filterOptions, sortOptions]);

  // update currentID when page loads
  useEffect(() => {
    if (
      searchStatus === 'succeeded' &&
      searchResults.length > 0 &&
      !currentID
    ) {
      dispatch(
        setCurrentID({
          currentID: `${searchResults[0].ENTRY}-${searchResults[0].YEAR}`,
        })
      );
    }
  }, [searchResults]);
  return (
    <>
      {/* show search loading */}
      {searchStatus === 'loading' && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '10rem', width: '10rem' }}
          />
        </Row>
      )}
      {/* show search results list */}
      {searchStatus === 'succeeded' && searchResults.length > 0 && (
        <>
          <div
            id="searchResultsList"
            className={
              sidebarExpanded
                ? 'w-100 overflow-auto d-flex flex-column flex-grow-1'
                : 'd-none'
            }
            style={{
              fontWeight: '100',
              fontFamily: 'Calibri, sans-serif',
            }}
          >
            {searchResults.map((result, index) => {
              return (
                <Card
                  key={index}
                  className="w-100 rounded-0 border-left-0 border-right-0 border-bottom shadow-sm"
                  style={{ marginTop: '-1px' }}
                >
                  <Card.Header className="w-100 px-1 d-flex flex-row border-bottom-0">
                    <Col className="px-0 d-flex flex-column align-items-center">
                      <Table
                        responsive
                        size="sm"
                        className="w-100 mb-0"
                        style={{ lineHeight: '1.15rem', fontSize: '0.9rem' }}
                      >
                        <tbody>
                          <tr>
                            <td className="border-top-0 pt-0 pb-0 px-3">
                              <a
                                className="d-flex mb-2"
                                style={{
                                  textUnderlineOffset: '0.125rem',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  lineHeight: '1.15rem',
                                  fontSize: '0.95rem',
                                  fontFamily:
                                    '"Gill Sans MT", Calibri, sans-serif',
                                }}
                                onClick={() => {
                                  if (mobileView)
                                    dispatch(
                                      setSidebarExpanded({
                                        sidebarExpanded: false,
                                      })
                                    );
                                  if (
                                    currentID !==
                                    `${result.ENTRY}-${result.YEAR}`
                                  ) {
                                    dispatch(
                                      setCurrentID({
                                        currentID: `${result.ENTRY}-${result.YEAR}`,
                                      })
                                    );
                                    const query = queryToString({
                                      query: router.location.query,
                                      currentID: `${result.ENTRY}-${result.YEAR}`,
                                    });
                                    history.push(
                                      `${router.location.pathname}?${query}`
                                    );
                                  }
                                }}
                              >
                                {result.RECORD_DESC
                                  ? `${result.RECORD_DESC}`
                                  : `-`}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td className="border-top-0 pt-0 pb-2 px-3">
                              {result.DESCR ? `${result.DESCR}` : `-`}
                            </td>
                          </tr>
                          <tr>
                            <td className="border-top-0 pt-0 pb-0 px-3">
                              {result.LEGAL_DESCR
                                ? `${result.LEGAL_DESCR}`
                                : `-`}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Card.Header>
                </Card>
              );
            })}
          </div>
        </>
      )}
      {/* show no result message */}
      {searchStatus === 'succeeded' && searchResults.length === 0 && (
        <Row
          noGutters
          id="sidebarResults"
          className={
            sidebarExpanded
              ? 'w-100 h-100 d-flex flex-column flex-grow-1'
              : 'd-none'
          }
          style={{
            backgroundColor: 'rgba(101, 167, 219, 0.25)',
          }}
        >
          <h5
            className="mx-auto w-75 d-flex flex-column h-100 align-items-center"
            style={{
              marginTop: '25%',
            }}
          >
            <Search
              height={mobileView ? '4rem' : '8rem'}
              width={mobileView ? '4rem' : '8rem'}
              style={{ color: 'rgba(101, 167, 219, 1)' }}
            />
            <p className="mb-1 mt-4 text-center">{`No ${mainSearchInfo.titleSingle.toLowerCase()} results found.`}</p>
            <p className="mb-0 mt-2 text-center">{`Please update your filter parameters or try another search.`}</p>
          </h5>
        </Row>
      )}
    </>
  );
};

export default DocumentMiningSearchList;
