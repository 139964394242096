import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ArrowCounterclockwise, Check2Circle } from 'react-bootstrap-icons';
import { setFilterActive, setFilterOptions } from '../../sidebarSlice';
import { updateInput } from '../../../../utilities/filterSort';

export default function TaxHistoryDetailFilterForm() {
  const defaultValues = {
    taxYear: { type: 'checkbox', apiName: 'TAX_YEAR', value: [] },
  };

  const dispatch = useDispatch();
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const searchResults = useSelector(state => state.search.searchResults);
  const [values, setValues] = useState(filterOptions || { ...defaultValues });

  return (
    <>
      <Row
        noGutters
        className="rounded border border-primary bg-light w-100 mb-3 p-3 shadow-sm"
      >
        <Form
          className="w-100"
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(setFilterOptions(values));
            dispatch(setFilterActive({ filterActive: false }));
          }}
        >
          <Form.Row>
            <Form.Group as={Col} className="w-100 d-flex">
              <Button
                className="ml-auto mr-2"
                onClick={e => {
                  dispatch(setFilterOptions({ ...defaultValues }));
                  dispatch(setFilterActive({ filterActive: false }));
                }}
              >
                <ArrowCounterclockwise /> reset
              </Button>
              <Button type="submit">
                <Check2Circle /> apply
              </Button>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Tax Year</Form.Label>
              <div
                className="w-100 d-flex flex-column bg-white rounded py-2 px-3"
                style={{
                  border: '1px solid #ced4da',
                  maxHeight: '15vh',
                  minHeight: '38px',
                  overflow: 'auto',
                }}
              >
                {searchResults &&
                  searchResults.length > 0 &&
                  [
                    ...new Set(
                      searchResults.slice().map(r => r[values.taxYear.apiName])
                    ),
                  ]
                    .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
                    .map((n, i) => (
                      <Form.Check
                        id={`filter-checkbox-taxYear-${i}`}
                        key={i}
                        label={`${n}`}
                        type="checkbox"
                        name={`${n}`}
                        checked={values.taxYear.value.includes(`${n}`)}
                        onChange={e => updateInput(e, values, setValues)}
                      />
                    ))}
              </div>
            </Form.Group>
          </Form.Row>
        </Form>
      </Row>
    </>
  );
}
