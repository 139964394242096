import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

import { ChevronDown, ChevronUp, Search } from 'react-bootstrap-icons';

import { setCurrentID } from '../../../app/searchSlice';
import { setSidebarExpanded } from '../../sidebarSlice';
import {
  selectDefaultMainSearch,
  selectDefaultSubSearch,
} from '../../../app/defaultSearchesSlice';

import { queryToString } from '../../../../utilities/url';
import { filterResults, sortResults } from '../../../../utilities/filterSort';

const DevelopmentSearchList = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const mainSearchInfo = useSelector(selectDefaultMainSearch(mainSearch));
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const [currentActiveKey, setCurrentActiveKey] = useState(null);
  const currentID = useSelector(state => state.search.currentID);
  const dispatch = useDispatch();
  const searchStatus = useSelector(state => state.search.status);
  const history = useHistory();
  const router = useSelector(state => state.router);
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const sortOptions = useSelector(state => state.sidebar.sortOptions);

  // load
  const rawResults = useSelector(state => state.search.searchResults);
  const [searchResults, setSearchResults] = useState(rawResults.slice());

  useEffect(() => {
    let tempResults = [...searchResults];
    // filter
    tempResults = [...filterResults(filterOptions, tempResults)];
    // sort
    tempResults = [...sortResults(sortOptions, tempResults)];
    // update list with filtered/sorted results
    setSearchResults([...tempResults]);
  }, [filterOptions, sortOptions]);

  // update currentID when page loads
  useEffect(() => {
    if (searchStatus === 'succeeded' && searchResults.length && !currentID) {
      dispatch(setCurrentID({ currentID: `${searchResults[0].ID}` }));
    }
  }, [searchResults]);

  return (
    <>
      {/* show search loading */}
      {searchStatus === 'loading' && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '10rem', width: '10rem' }}
          />
        </Row>
      )}
      {/* show search results list */}
      {searchStatus === 'succeeded' && searchResults.length > 0 && (
        <>
          <Row
            className={
              sidebarExpanded
                ? 'w-100 px-2 py-2 border-top border-bottom'
                : 'd-none'
            }
            style={{
              background: '#e9ecef',
              fontWeight: '600',
              fontFamily: 'Calibri, sans-serif',
              marginBottom: mobileView ? '2.25px' : '2px',
            }}
            noGutters
          >
            <Col xs={3} sm={2} className="pl-1 pr-0">
              <Row noGutters className="w-100">
                Map
              </Row>
              <Row noGutters className="w-100">
                Type
              </Row>
            </Col>
            <Col xs={7} sm={9} className="pl-1 pr-0">
              <Row noGutters className="w-100">
                Development Name
              </Row>
              <Row noGutters className="w-100">
                City
              </Row>
            </Col>
            <Col xs={2} sm={1}></Col>
          </Row>
          <Accordion
            className={
              sidebarExpanded
                ? 'w-100 h-100 flex-grow-1 overflow-auto'
                : 'd-none'
            }
            style={{
              fontWeight: '100',
              fontFamily: 'Calibri, sans-serif',
            }}
          >
            {searchResults.map((result, index) => {
              return (
                <Card
                  key={index}
                  className="rounded-0 border-left-0 border-right-0 border-bottom shadow-sm"
                  style={{ marginTop: '-1px', lineHeight: '1.15rem' }}
                >
                  <Card.Header className="d-flex px-1 border-bottom-0">
                    <Col
                      xs={3}
                      sm={2}
                      className="pl-2 pr-0 d-flex flex-column align-items-center"
                    >
                      <Row noGutters className="w-100 mb-2">
                        <Accordion.Toggle
                          as="a"
                          className="d-flex"
                          style={{
                            textUnderlineOffset: '0.125rem',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          eventKey={`${index}`}
                          onClick={() => {
                            if (mobileView)
                              dispatch(
                                setSidebarExpanded({ sidebarExpanded: false })
                              );
                            setCurrentActiveKey(
                              currentActiveKey === index ? null : index
                            );
                            if (currentID !== `${searchResults[index].ID}`) {
                              dispatch(
                                setCurrentID({
                                  currentID: `${searchResults[index].ID}`,
                                })
                              );
                              const query = queryToString({
                                query: router.location.query,
                                currentID: `${searchResults[index].ID}`,
                              });
                              history.push(
                                `${router.location.pathname}?${query}`
                              );
                            }
                          }}
                        >
                          {result.NUMB}
                        </Accordion.Toggle>
                      </Row>
                      <Row noGutters className="w-100">
                        <span
                          className={
                            currentActiveKey === index
                              ? 'text-break'
                              : 'text-truncate'
                          }
                        >
                          {result.TYPENAME || ''}
                        </span>
                      </Row>
                    </Col>
                    <Col
                      xs={7}
                      sm={9}
                      className="pl-2 pr-0 d-flex flex-column align-items-center"
                    >
                      <Row noGutters className="w-100 mb-2">
                        <span
                          className={
                            currentActiveKey === index ? '' : 'text-truncate'
                          }
                        >
                          {result.NAME || ''}
                        </span>
                      </Row>
                      <Row noGutters className="w-100">
                        {result.CITYNAME || '-'}
                      </Row>
                    </Col>
                    <Col
                      xs={2}
                      sm={1}
                      className="px-0 d-flex justify-content-end align-items-center"
                    >
                      <Accordion.Toggle
                        as="a"
                        onClick={() =>
                          setCurrentActiveKey(
                            currentActiveKey === index ? null : index
                          )
                        }
                        className="py-0 px-2"
                        eventKey={`${index}`}
                        style={{ cursor: 'pointer' }}
                      >
                        {currentActiveKey === index ? (
                          <ChevronUp />
                        ) : (
                          <ChevronDown />
                        )}
                      </Accordion.Toggle>
                    </Col>
                  </Card.Header>
                  <Accordion.Collapse className="pl-2" eventKey={`${index}`}>
                    <div className="d-flex flex-column w-100">
                      <Row noGutters className="w-100">
                        <Col
                          xs={3}
                          sm={2}
                          className="border-right pb-2 px-2 pt-3 w-100"
                          style={{ fontWeight: '500' }}
                        >
                          Book/{window.screen.width < 356 ? <br /> : ''}Page
                        </Col>
                        <Col xs={8} sm={9} className="pb-2 px-2 pt-3">
                          {result.BOOK && result.PAGE
                            ? `${result.BOOK}-${result.PAGE}`
                            : '-'}
                        </Col>
                        <Col xs={2} sm={1}></Col>
                      </Row>
                      <Row noGutters className="w-100">
                        <Col
                          xs={3}
                          sm={2}
                          className="border-right pb-2 px-2 pb-3"
                          style={{ fontWeight: '500' }}
                        >
                          Location
                        </Col>
                        <Col xs={8} sm={9} className="pb-2 px-2 pb-3">
                          <span style={{ fontWeight: '400' }}>Section:</span>{' '}
                          {result.SECTION || '-'}
                          {mobileView ? <br /> : ' '}
                          <span style={{ fontWeight: '400' }}>
                            Township:
                          </span>{' '}
                          {result.TOWNSHIP || '-'}S{mobileView ? <br /> : ' '}
                          <span style={{ fontWeight: '400' }}>Range:</span>{' '}
                          {result.RANGE || '-'}
                          {result.RANGE_DIR || '-'}
                        </Col>
                      </Row>
                    </div>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
        </>
      )}
      {/* show no result message */}
      {(searchStatus === 'failed' ||
        (searchStatus === 'succeeded' && searchResults.length === 0)) && (
        <Row
          noGutters
          id="sidebarResults"
          className={
            sidebarExpanded
              ? 'w-100 h-100 d-flex flex-column flex-grow-1'
              : 'd-none'
          }
          style={{
            backgroundColor: 'rgba(101, 167, 219, 0.25)',
          }}
        >
          <h5
            className="mx-auto w-75 d-flex flex-column h-100 align-items-center"
            style={{
              marginTop: '25%',
            }}
          >
            <Search
              height={mobileView ? '4rem' : '8rem'}
              width={mobileView ? '4rem' : '8rem'}
              style={{ color: 'rgba(101, 167, 219, 1)' }}
            />
            <p className="mb-1 mt-4 text-center">{`No development results found.`}</p>
            <p className="mb-0 mt-2 text-center">{`Please update your filter parameters or try another search.`}</p>
          </h5>
        </Row>
      )}
    </>
  );
};

export default DevelopmentSearchList;
