import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { withCentsFormat } from '../../../../utilities/currency';

import { Search } from 'react-bootstrap-icons';

import { setSidebarExpanded, setSidebarLoading } from '../../sidebarSlice';
import { setDetailViewLoading } from '../../../detailView/detailViewSlice';
import {
  selectDefaultMainSearch,
  selectDefaultSubSearch,
} from '../../../app/defaultSearchesSlice';
import {
  setCurrentID,
  fetchResults,
  setSearchResultsScrollTop,
} from '../../../app/searchSlice';

import { queryToString, toApiQuery } from '../../../../utilities/url';
import { filterResults, sortResults } from '../../../../utilities/filterSort';

const DocumentRecordingSearchList = () => {
  const mainSearch = useSelector(state => state.search.mainSearch);
  const subSearch = useSelector(state => state.search.subSearch);
  const subSearchIndex = useSelector(state => state.search.subSearchIndex);
  const mainSearchInfo = useSelector(selectDefaultMainSearch(mainSearch));
  const sidebarExpanded = useSelector(state => state.sidebar.sidebarExpanded);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const [currentActiveKey, setCurrentActiveKey] = useState(null);
  const searchResultsList = () => document.getElementById('searchResultsList');
  const [searchResultsListOverflowed, setSearchResultsListOverflowed] =
    useState(
      !mobileView &&
        searchResultsList() &&
        searchResultsList().getBoundingClientRect().height + 1 <
          searchResultsList().scrollHeight
    );
  const dispatch = useDispatch();
  const currentID = useSelector(state => state.search.currentID);
  const history = useHistory();
  const router = useSelector(state => state.router);
  const filterOptions = useSelector(state => state.sidebar.filterOptions);
  const sortOptions = useSelector(state => state.sidebar.sortOptions);
  const lastSearchResult = useSelector(state => state.search.lastSearchResult);
  const searchResultsScrollTop = useSelector(
    state => state.search.searchResultsScrollTop
  );
  const baseApiUrl = useSelector(state => state.search.baseApiUrl);
  const subSearchInfo = useSelector(
    selectDefaultSubSearch(mainSearch, subSearch)
  );
  const [sortedFilteredFlag, setSortedFilteredFlag] = useState(false);

  const getSearchResultList = () =>
    document.getElementById('searchResultsList');
  const loadMoreSearchResults = e => {
    // initiate UI search
    dispatch(setSidebarLoading(true));
    dispatch(setDetailViewLoading(true));
    // save scroll top
    if (getSearchResultList())
      dispatch(
        setSearchResultsScrollTop({
          searchResultsScrollTop: getSearchResultList().scrollTop,
        })
      );
    // dispatch search with last
    dispatch(
      fetchResults({
        url: baseApiUrl,
        endpoint: subSearchInfo.inputApiEndpoints[subSearchIndex],
        queryString: `${toApiQuery(
          router.location.query
        )}&last=${lastSearchResult}`,
      })
    );
  };
  const checkScroll = () =>
    lastSearchResult &&
    getSearchResultList() &&
    getSearchResultList().scrollTop > 0 &&
    getSearchResultList().scrollHeight - getSearchResultList().scrollTop <=
      getSearchResultList().getBoundingClientRect().height;
  const checkScrollHandler = e => {
    if (checkScroll()) loadMoreSearchResults();
  };
  // check for resultList height === scrollHeight && lastResult !== null
  const checkResultsHeight = () =>
    lastSearchResult &&
    getSearchResultList() &&
    getSearchResultList().getBoundingClientRect().height >=
      getSearchResultList().scrollHeight;
  const checkResultsHeightHandler = e => {
    if (checkResultsHeight()) loadMoreSearchResults();
  };

  const searchStatus = useSelector(state => state.search.status);
  // load
  const rawResults = useSelector(state => state.search.searchResults);
  const [searchResults, setSearchResults] = useState(null);
  const [newSearchResultsLoaded, setNewSearchResultsLoaded] = useState(false);

  useEffect(() => {
    setSearchResults([...rawResults]);
    setNewSearchResultsLoaded(true);
  }, [rawResults]);

  useEffect(() => {
    if (newSearchResultsLoaded && searchResults) {
      let tempResults = [...searchResults];
      // filter
      tempResults = [...filterResults(filterOptions, tempResults)];
      // sort
      tempResults = [...sortResults(sortOptions, tempResults)];
      // update list with filtered/sorted results

      setSortedFilteredFlag(!sortedFilteredFlag);
      setNewSearchResultsLoaded(false);
      setSearchResults([...tempResults]);
    }
  }, [searchResults, filterOptions, sortOptions]);

  useEffect(() => {
    setSearchResultsListOverflowed(
      !mobileView &&
        searchResultsList() &&
        searchResultsList().getBoundingClientRect().height + 1 <
          searchResultsList().scrollHeight
    );
  }, [sortedFilteredFlag, mobileView]);

  // search for additional results when space left in search results
  // window or until user reaches end of currently available search results
  useEffect(() => {
    // every time searchResults are updated, also check the results height
    checkResultsHeightHandler();
    // subscribe window scroll event
    if (getSearchResultList())
      getSearchResultList().addEventListener('scroll', checkScrollHandler);
    return () => {
      if (getSearchResultList())
        getSearchResultList().removeEventListener('scroll', checkScrollHandler);
    };
  }, [sortedFilteredFlag]);

  // update currentID when page loads
  useEffect(() => {
    if (
      searchStatus === 'succeeded' &&
      searchResults &&
      searchResults.length > 0 &&
      !currentID
    ) {
      dispatch(
        setCurrentID({
          currentID: `${searchResults[0].RJ_ENTRY_NO}-${searchResults[0].RJ_ENTRY_YR}`,
        })
      );
    }
  }, [sortedFilteredFlag]);

  // update search results scroll top after lazy load for better UX
  useEffect(() => {
    if (searchStatus === 'succeeded' && getSearchResultList()) {
      getSearchResultList().scrollTop = searchResultsScrollTop;
    }
  }, [sortedFilteredFlag]);

  return (
    <>
      {/* show search loading */}
      {searchStatus === 'loading' && (
        <Row
          noGutters
          id="sidebarResults"
          className={sidebarExpanded ? 'w-100 h-100 bg-light' : 'd-none'}
        >
          <Spinner
            animation="border"
            variant="info"
            className="d-flex align-self-center m-auto"
            style={{ height: '10rem', width: '10rem' }}
          />
        </Row>
      )}
      {/* show search results list */}
      {searchStatus === 'succeeded' &&
        searchResults &&
        searchResults.length > 0 && (
          <>
            <Row
              className={
                sidebarExpanded
                  ? 'w-100 px-0 py-2 border-top border-bottom'
                  : 'd-none'
              }
              style={{
                background: '#e9ecef',
                fontWeight: '600',
                fontFamily: 'Calibri, sans-serif',
              }}
              noGutters
            >
              <Col
                xs={4}
                sm={3}
                className="pl-1 pr-0 d-flex flex-column align-items-center"
              >
                <Row noGutters className="pl-2 ml-2 w-100">
                  Entry No
                </Row>
                <Row noGutters className="pl-2 ml-2 w-100">
                  Fees
                </Row>
              </Col>
              <Col
                xs={6}
                sm={8}
                className="d-flex flex-column align-items-center"
              >
                <Row noGutters className="w-100">
                  Recorded By
                </Row>
                <Row noGutters className="w-100">
                  Recorded Date
                </Row>
              </Col>
              <Col
                xs={2}
                sm={1}
                className="d-flex align-items-center justify-content-center"
                style={{
                  marginLeft:
                    !mobileView && searchResultsListOverflowed ? '-1rem' : '0',
                }}
              >
                Pages
              </Col>
            </Row>
            <div
              id="searchResultsList"
              className={
                sidebarExpanded
                  ? 'w-100 overflow-auto d-flex flex-column flex-grow-1'
                  : 'd-none'
              }
              style={{
                fontWeight: '100',
                fontFamily: 'Calibri, sans-serif',
              }}
            >
              {searchResults.map((result, index) => {
                return (
                  <Card
                    key={index}
                    className="w-100 rounded-0 border-left-0 border-right-0"
                    style={{ marginTop: '-1px' }}
                  >
                    <Card.Header className="w-100 px-1 d-flex flex-row border-bottom-0 shadow-sm">
                      <Col
                        xs={4}
                        sm={3}
                        className="px-0 d-flex flex-column align-items-center"
                      >
                        <Row noGutters className="pl-2 ml-2 w-100">
                          <a
                            className="d-flex"
                            style={{
                              textUnderlineOffset: '0.125rem',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              lineHeight: '1.15rem',
                              marginBottom: '0.55rem',
                            }}
                            onClick={() => {
                              if (mobileView)
                                dispatch(
                                  setSidebarExpanded({ sidebarExpanded: false })
                                );
                              setCurrentActiveKey(
                                index !== currentActiveKey ? index : null
                              );
                              if (
                                currentID !==
                                `${result.RJ_ENTRY_NO}-${result.RJ_ENTRY_YR}`
                              ) {
                                dispatch(
                                  setCurrentID({
                                    currentID: `${result.RJ_ENTRY_NO}-${result.RJ_ENTRY_YR}`,
                                  })
                                );
                                const query = queryToString({
                                  query: router.location.query,
                                  currentID: `${result.RJ_ENTRY_NO}-${result.RJ_ENTRY_YR}`,
                                });
                                history.push(
                                  `${router.location.pathname}?${query}`
                                );
                              }
                            }}
                          >
                            {result.RJ_ENTRY_NO && result.RJ_ENTRY_YR
                              ? `${result.RJ_ENTRY_NO}-${result.RJ_ENTRY_YR}`
                              : `-`}
                          </a>
                        </Row>
                        <Row
                          noGutters
                          className="pl-2 ml-2 w-100"
                          style={{ lineHeight: '1.15rem' }}
                        >
                          <span
                            className={
                              currentActiveKey === index ? '' : 'text-truncate'
                            }
                          >
                            {isNaN(parseInt(result.RJ_FEES, 10))
                              ? `-`
                              : withCentsFormat(`${result.RJ_FEES}`)}
                          </span>
                        </Row>
                      </Col>
                      <Col
                        xs={6}
                        sm={8}
                        className="pl-0 pr-1 d-flex flex-column align-items-center"
                      >
                        <Row noGutters className="w-100">
                          <span
                            className={
                              currentActiveKey === index ? '' : 'text-truncate'
                            }
                          >
                            {result.RECORDEDBY ? `${result.RECORDEDBY}` : `-`}
                          </span>
                        </Row>
                        <Row noGutters className="w-100">
                          {result.RJ_DATE
                            ? `${new Date(
                                result.RJ_DATE
                              ).toLocaleDateString()} ${new Date(
                                result.RJ_DATE
                              ).toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: '2-digit',
                              })}`
                            : '-'}
                        </Row>
                      </Col>
                      <Col
                        xs={2}
                        sm={1}
                        className="pl-2 pr-0 d-flex align-items-center justify-content-center"
                      >
                        {result.RJ_PAGES ? `${result.RJ_PAGES}` : `-`}
                      </Col>
                    </Card.Header>
                  </Card>
                );
              })}
            </div>
          </>
        )}
      {/* show no result message */}
      {((searchStatus === 'succeeded' &&
        searchResults &&
        searchResults.length === 0) ||
        searchStatus === 'failed') && (
        <Row
          noGutters
          id="sidebarResults"
          className={
            sidebarExpanded
              ? 'w-100 h-100 d-flex flex-column flex-grow-1'
              : 'd-none'
          }
          style={{
            backgroundColor: 'rgba(101, 167, 219, 0.25)',
          }}
        >
          <h5
            className="mx-auto w-75 d-flex flex-column h-100 align-items-center"
            style={{
              marginTop: '25%',
            }}
          >
            <Search
              height={mobileView ? '4rem' : '8rem'}
              width={mobileView ? '4rem' : '8rem'}
              style={{ color: 'rgba(101, 167, 219, 1)' }}
            />
            <p className="mb-1 mt-4 text-center">{`No ${mainSearchInfo.titleSingle.toLowerCase()} results found.`}</p>
            <p className="mb-0 mt-2 text-center">{`Please update your filter parameters or try another search.`}</p>
          </h5>
        </Row>
      )}
    </>
  );
};

export default DocumentRecordingSearchList;
