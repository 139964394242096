// core external dependencies
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// external component dependencies
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import NavLink from 'react-bootstrap/NavLink';
import Row from 'react-bootstrap/esm/Row';

// internal dependencies
import { sendFeedback, updateFeedbackModalStatus } from './feedbackModalSlice';

// FeedbackModal returns a modal component that provides an interface for users
// to send feedback
export default function FeedbackModal() {
  const dispatch = useDispatch();
  const status = useSelector(state => state.feedbackModal.status);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const [show, setShow] = useState(false);
  const initialFormValues = {
    feedbackType: '',
    feedbackEmail: '',
    feedbackName: '',
    feedbackComments: '',
  };
  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const updateForm = ({ target: { id, value } }) =>
    setFormValues({ ...formValues, [id]: value });

  useEffect(() => {
    if (show) {
      setFormValues({ ...initialFormValues });
      dispatch(updateFeedbackModalStatus('notSent'));
    }
  }, [show]);

  return (
    <>
      {/* This link will be rendered if included in view */}
      <NavLink
        className="py-1 px-0"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setShow(true);
        }}
      >
        Found a Problem? Send Feedback
      </NavLink>
      {/* The modal component is only visible when show === true */}
      <Modal className="" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Land Records Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light rounded-bottom">
          {/* Error screen */}
          {status === 'failed' && (
            <>
              <Row noGutters className="w-100 d-flex justify-content-center">
                <h5 className="text-danger mt-4 mb-5">
                  Sorry, we weren't able to process your feedback. Please try
                  again.
                </h5>
              </Row>
              <Row noGutters className="w-100 d-flex justify-content-around">
                <Button
                  className={mobileView ? 'w-100 mb-3' : ''}
                  onClick={() => {
                    dispatch(updateFeedbackModalStatus('notSent'));
                  }}
                >
                  Try Again
                </Button>
                <Button
                  className={mobileView ? 'w-100' : ''}
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>
              </Row>
            </>
          )}
          {/* Successfully sent message */}
          {status === 'succeeded' && (
            <>
              <Row noGutters className="w-100 d-flex justify-content-center">
                <h5 className="text-primary mt-4 mb-5">
                  Thank you for your feedback!
                </h5>
              </Row>
              <Row noGutters className="w-100 d-flex justify-content-around">
                <Button
                  className={mobileView ? 'w-100 mb-3' : ''}
                  onClick={() => {
                    setFormValues({ ...initialFormValues });
                    dispatch(updateFeedbackModalStatus('notSent'));
                  }}
                >
                  Send More Feedback
                </Button>
                <Button
                  className={mobileView ? 'w-100' : ''}
                  onClick={() => setShow(false)}
                >
                  Close
                </Button>
              </Row>
            </>
          )}
          {/* display form when status === 'notSent' */}
          {status === 'notSent' && (
            <>
              <div className="w-100 d-flex justify-content-center">
                <div style={{ fontSize: '1rem' }}>
                  <div>Please let us know :</div>
                  <div className="ml-3 d-flex w-100">
                    <b>&#8226;</b>
                    <div className="d-inline-block w-100 ml-2">
                      <b className="w-100">what is not working as expected</b>{' '}
                      or
                    </div>
                  </div>
                  <div className="ml-3 d-flex w-100">
                    <b>&#8226;</b>
                    <div className="d-inline-block w-100 ml-2">
                      <b className="w-100">how we did something well</b> or
                    </div>
                  </div>
                  <div className="ml-3 d-flex w-100">
                    <b>&#8226;</b>
                    <div className="d-inline-block w-100 ml-2">
                      <b className="w-100">where we can make improvements</b>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(sendFeedback(formValues));
                }}
              >
                <Form.Group>
                  <Form.Label>Feedback Type</Form.Label>
                  <Form.Control
                    as="select"
                    id="feedbackType"
                    value={formValues.feedbackType}
                    onChange={updateForm}
                    required
                  >
                    <option value="">-- Choose One --</option>
                    <option value="🐛 Bug">Report Issue</option>
                    <option value="👏 Praise">Send Praise</option>
                    <option value="🧪 Improvement">Suggest Improvement</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Your Email</Form.Label>
                  <Form.Control
                    type="email"
                    id="feedbackEmail"
                    value={formValues.feedbackEmail}
                    onChange={updateForm}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Your Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="feedbackName"
                    value={formValues.feedbackName}
                    onChange={updateForm}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    required
                    id="feedbackComments"
                    value={formValues.feedbackComments}
                    onChange={updateForm}
                    placeholder="Tell us about it ..."
                  />
                </Form.Group>
                <Form.Group className="d-flex justify-content-end">
                  <Button type="submit">submit</Button>
                </Form.Group>
              </Form>
              <hr />
              <p
                className="mb-1"
                style={{ fontSize: '1.1rem', textAlign: 'center' }}
              >
                <b>We appreciate your time.</b>
              </p>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
