import { getOffsetDate } from './date';

const updateInput = (e, values, setValues) => {
  const [_, type, key, item] = e.target.id.split('-');
  switch (type) {
    case 'textbox':
      setValues({
        ...values,
        [key]: { ...values[key], value: e.target.value },
      });
      break;
    case 'checkbox':
      if (e.target.checked)
        setValues({
          ...values,
          [key]: {
            ...values[key],
            value: [...new Set([...values[key].value, e.target.name])],
          },
        });
      else {
        const s = new Set(values[key].value);
        s.delete(e.target.name);
        setValues({
          ...values,
          [key]: { ...values[key], value: [...s] },
        });
      }
      break;
    case 'date':
      setValues({
        ...values,
        [key]: {
          ...values[key],
          value: { ...values[key].value, [item]: e.target.value },
        },
      });
      break;
    case 'multiApiTextbox':
      let valuesCopy = {
        ...values,
        [key]: { ...values[key], value: e.target.value },
      };
      Object.entries(valuesCopy[key].itemMap[e.target.value]).forEach(
        ([_key, _value]) => {
          valuesCopy = {
            ...valuesCopy,
            [key]: {
              ...valuesCopy[key],
              items: {
                ...valuesCopy[key].items,
                [_key]: {
                  ...valuesCopy[key].items[_key],
                  value: _value,
                },
              },
            },
          };
        }
      );
      setValues({ ...valuesCopy });
      break;
    default:
      console.log('unregistered filter type...');
  }
};

const filterResults = (_filterOptions, _searchResults) => {
  let filteredResults = [..._searchResults];
  if (_filterOptions) {
    Object.entries(_filterOptions).forEach(([key, filter]) => {
      switch (filter.type) {
        case 'textbox':
          if (filter.value !== '')
            filteredResults = filteredResults.filter(
              r =>
                `${r[filter.apiName]}`.search(
                  new RegExp(filter.value, 'gi')
                ) !== -1
            );
          break;
        case 'checkbox':
          if (filter.value.length > 0)
            filteredResults = filteredResults.filter(r =>
              filter.value.includes(`${r[filter.apiName]}`)
            );
          break;
        case 'date':
          if (filter.value.startDate !== null)
            filteredResults = filteredResults.filter(
              r =>
                getOffsetDate(filter.value.startDate).getTime() <=
                new Date(r[filter.apiName]).getTime()
            );
          if (filter.value.endDate !== null)
            filteredResults = filteredResults.filter(
              r =>
                getOffsetDate(filter.value.endDate).getTime() >=
                new Date(r[filter.apiName]).getTime()
            );
          break;
        case 'multiApiTextbox':
          filteredResults = filteredResults.filter(r => {
            if (Object.values(filter.items).every(item => item.value === null))
              return true;
            else
              return Object.values(filter.items).every(
                item => r[item.apiName] === item.value
              );
          });
          break;
        default:
          console.log(`invalid filter type "${filter.type}" in "${key}"`);
      }
    });
  }
  return filteredResults;
};

const sortResults = (_sortOptions, _searchResults) => {
  let sortedResults = [..._searchResults];
  if (_sortOptions && _sortOptions.selection !== '') {
    const sorter = _sortOptions.options[_sortOptions.selection];
    const api = sorter.apiName;
    const checkOrder = (_a, _b) =>
      _sortOptions.order === 'DESC' ? [_b, _a] : [_a, _b];
    switch (sorter.type) {
      case 'text':
        sortedResults = sortedResults.sort((cur, next) => {
          const [a, b] = checkOrder(cur, next);
          return `${a[api]}`.localeCompare(`${b[api]}`);
        });
        break;
      case 'number':
        sortedResults = sortedResults.sort((cur, next) => {
          const [a, b] = checkOrder(cur, next);
          return parseFloat(a[api]) - parseFloat(b[api]);
        });
        break;
      case 'date':
        sortedResults = sortedResults.sort((cur, next) => {
          const [a, b] = checkOrder(cur, next);
          if (isNaN(Date.parse(a[api])) || isNaN(Date.parse(b[api]))) return 0;
          return new Date(a[api]).getTime() - new Date(b[api]).getTime();
        });
        break;
      default:
        console.log(`invalid sort type: ${sorter.type}`);
    }
  }
  return sortedResults;
};

export { updateInput, filterResults, sortResults };
