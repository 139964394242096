import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'axios';

const propValAdapter = createEntityAdapter({
  selectId: e =>
    e && e.PV_YEAR && e.SERIAL ? `${e.PV_YEAR}${parseInt(e.SERIAL, 10)}` : null,
});

const initialState = propValAdapter.getInitialState({
  status: 'loading',
  error: null,
});

export const fetchPropertyValue = createAsyncThunk(
  'app/propertyValuationModal/fetchPropertyValue',
  async ({ url, serial, taxyear }) => {
    let propVal;
    try {
      propVal = (
        await axios({
          url: `${url}/propertyvalues?serial=${serial}&taxyear=${taxyear}`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data[0];
    } catch (err) {
      console.error(err);
      state.status = 'failed';
      return;
    }
    return propVal;
  }
);

export const slice = createSlice({
  name: 'app/propertyValuationModal',
  initialState: initialState,
  reducers: {
    emptyPropVals: propValAdapter.removeAll,
  },
  extraReducers: {
    [fetchPropertyValue.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchPropertyValue.fulfilled]: (state, action) => {
      propValAdapter.upsertOne(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchPropertyValue.rejected]: (state, action) => {
      state.error = action.error.message;
      state.status = 'failed';
    },
  },
});

export const { emptyPropVals } = slice.actions;

export const {
  selectEntities: selectAllPropVals,
  selectById: selectPropValById,
  selectIds: selectAllPropValIDs,
} = propValAdapter.getSelectors(state => state.propertyValuationModal);

export default slice.reducer;
