const queryToString = ({ query, currentID }) =>
  Object.entries(
    !currentID ? { ...query } : { ...query, currentID: currentID }
  ).reduce(
    (outStr, [key, value]) =>
      `${outStr}${outStr.length > 0 ? '&' : ''}${key}=${value}`,
    ''
  );

const toApiQuery = query =>
  Object.entries(query)
    .filter(kv => !['currentID'].includes(kv[0]))
    .reduce(
      (outStr, [key, value]) =>
        `${outStr}${outStr.length > 0 ? '&' : ''}${key}=${value}`,
      ''
    );

const toCleanApiQuery = query =>
  Object.entries(query)
    .filter(kv => !['currentID', 'last'].includes(kv[0]))
    .reduce(
      (outStr, [key, value]) =>
        `${outStr}${outStr.length > 0 ? '&' : ''}${key}=${value}`,
      ''
    );

export { queryToString, toApiQuery, toCleanApiQuery };
