const getFormDateFormat = inDate => {
  if (!inDate || !Date.parse(inDate)) return null;
  const d = new Date(inDate);
  d.setHours(d.getHours() + 8);
  return `${d.getFullYear()}-${(d.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
};

const getOffsetDate = inDate => {
  if (!inDate || !Date.parse(inDate)) return null;
  return new Date(new Date(inDate).setHours(new Date(inDate).getHours() + 8));
};

export { getFormDateFormat, getOffsetDate };
