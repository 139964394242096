import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import SiteFooter from './SiteFooter';

export default function NotFoundPage() {
  return (
    <>
      <div id="notFoundPageContainer" className=" p-0">
        <div
          id="notFoundPageCover"
          className="d-flex flex-column justify-content-start pt-3 pt-md-5"
        >
          <Row
            className="w-100 pt-3 pt-md-5 text-center d-flex justify-content-center"
            noGutters
          >
            <Col xs={10} md={12} className="pt-md-5">
              <h1 className="notFoundTitle">Utah County Land Records</h1>
            </Col>
            <Col xs={10} md={12} className="pt-3 pt-md-5">
              <h2 className="notFoundTitle">404 - Page Not Found</h2>
            </Col>
            <Col xs={10} md={12} className="pt-4 pt-md-5">
              <Link
                to="/"
                title="Return Home"
                className="notFoundBtn btn btn-outline-dark p-3"
              >
                Return Home
              </Link>
            </Col>
          </Row>
        </div>
      </div>
      <SiteFooter style={{ position: 'sticky', bottom: '0' }} />
    </>
  );
}
