import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'axios';

const taxPayoffAdapter = createEntityAdapter({
  selectId: e => `${e.serial}`,
});

const initialState = taxPayoffAdapter.getInitialState({
  currentName: null,
  status: 'idle',
  error: null,
});

export const fetchTaxPayoffList = createAsyncThunk(
  'app/delinquentTaxDetailView/fetchPayoffInfo',
  async ({ url, serial, date }) => {
    let taxPayoffList;
    try {
      taxPayoffList = (
        await axios({
          url: `${url}/taxpayoff?serial=${serial}&date=${date}`,
          headers: {
            apikey: process.env.APIKEY,
          },
        })
      ).data.data;
    } catch (err) {
      console.error(err);
      return;
    }
    return {
      serial,
      serialFormatted:
        taxPayoffList.length > 0 ? taxPayoffList[0].SERIAL_FORMATTED : serial,
      taxPayoffList,
    };
  }
);

export const slice = createSlice({
  name: 'app/delinquentTaxDetailView',
  initialState: initialState,
  reducers: {
    setCurrentName: (state, action) => {
      state.currentName = action.payload.currentName;
    },
    emptyTaxPayoffLists: taxPayoffAdapter.removeAll,
  },
  extraReducers: {
    [fetchTaxPayoffList.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchTaxPayoffList.fulfilled]: (state, action) => {
      taxPayoffAdapter.upsertOne(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchTaxPayoffList.rejected]: (state, action) => {
      state.error = action.error.message;
      state.status = 'failed';
    },
  },
});

export const { setCurrentName, emptyTaxPayoffLists } = slice.actions;

export const {
  selectEntities: selectAllTaxPayoffLists,
  selectById: selectTaxPayoffListById,
  selectIds: selectAllTaxPayoffListIDs,
} = taxPayoffAdapter.getSelectors(state => state.delinquentTaxDetailView);

export default slice.reducer;
