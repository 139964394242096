// external imports
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
// import custom style
import './App.scss';
// import custom components
import SiteHeader from './components/pageComponents/SiteHeader';
import NotFoundPage from './components/pageComponents/NotFoundPage';
import HomePage from './components/pageComponents/HomePage';
import SearchPage from './components/pageComponents/SearchPage';
import ErrorBoundary from './components/pageComponents/ErrorBoundary';

// app store imports
import { setMobileView } from './components/app/windowStateSlice';
import {
  fetchCityNames,
  fetchKoiCodeGroups,
} from './components/app/searchForm/searchFormSlice';
import RedirectAlert from './components/pageComponents/RedirectAlert';

// App returns the application component
const App = () => {
  const { listen } = useHistory();
  const router = useSelector(state => state.router);
  const mobileView = useSelector(state => state.windowState.mobileView);
  const dispatch = useDispatch();
  const baseUrl = useSelector(state => state.search.baseApiUrl);
  const trackingID = process.env.GA_TRACKING_ID;
  window.dataLayer = window.dataLayer || [];
  const gtag = function () {
    window.dataLayer.push(arguments);
  };
  const currentPath = router.location.pathname;
  const [lastPath, setLastPath] = useState(null);
  const allSearches = useSelector(state => state.defaultSearches);

  // load dynamic search form lists
  useEffect(() => {
    // city names
    dispatch(fetchCityNames(baseUrl));
    // koi codes
    dispatch(fetchKoiCodeGroups(baseUrl));
  }, []);

  // attach event listener to update mobileView on window resize
  useEffect(() => {
    const updateWindowScreen = () => {
      const currentScreenWidthIsMobile = window.screen.width < 768;
      if (currentScreenWidthIsMobile !== mobileView)
        dispatch(setMobileView({ mobileView: currentScreenWidthIsMobile }));
    };
    window.addEventListener('resize', updateWindowScreen);
    return () => window.removeEventListener('resize', updateWindowScreen);
  });

  // update document title google analytics
  useEffect(() => {
    if (currentPath === '/') {
      document.title = 'Utah County Land Records | Home';
    } else if (currentPath === '/notFound') {
      document.title = 'Utah County Land Records | Not Found';
    } else {
      const [_main, _sub] = currentPath.split('/').filter(p => p !== '');
      if (
        Object.keys(allSearches).includes(_main) &&
        Object.keys(allSearches[_main].subSearches).includes(_sub)
      ) {
        document.title = `Utah County Land Records | ${allSearches[_main].title} | ${allSearches[_main].subSearches[_sub].title}`;
      } else document.title = 'Utah County Land Records';
    }
  }, [currentPath]);

  return (
    <>
      <ErrorBoundary>
        <RedirectAlert />
        <SiteHeader />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route
            exact
            push={false}
            path="/:mSearch/:sSearch/:sSearchIndex"
            component={SearchPage}
          />
          <Route exact path="/notFound" component={NotFoundPage} />
          <Route path="*">
            <Redirect to="/notFound" />
          </Route>
        </Switch>
      </ErrorBoundary>
    </>
  );
};

export default App;
