import React from 'react';
import { Redirect } from 'react-router-dom';

import { Search, FileEarmarkXFill } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.errorType = props.errorType;
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      switch (this.errorType) {
        case 'sidebar':
          return (
            <Col
              md={4}
              sm={12}
              className={'d-flex flex-column flex-grow-1 p-3'}
              style={{
                backgroundColor: 'rgba(101, 167, 219, 0.25)',
              }}
            >
              <div className="mx-auto w-75 d-flex flex-column h-100 justify-content-center align-items-center">
                <Search
                  height={'5rem'}
                  width={'5rem'}
                  style={{ color: 'rgba(101, 167, 219, 1)' }}
                />
                <h5 className="mb-1 mt-4 text-center">
                  We encountered an error and could not load your search
                  results.
                </h5>
                <h5 className="mb-0 mt-2 text-center">
                  Please try another search.
                </h5>
                <Button
                  className="mt-3"
                  onClick={() => window.location.replace('/')}
                >
                  Return Home
                </Button>
              </div>
            </Col>
          );
        case 'detailView':
          return (
            <Col
              md={8}
              sm={12}
              className="h-100 d-flex flex-column align-items-center justify-content-center p-2"
              style={{ backgroundColor: 'rgba(217, 190, 80, 0.1)' }}
            >
              <FileEarmarkXFill
                height={'5rem'}
                width={'5rem'}
                style={{ color: 'rgba(78, 128, 166, 1)' }}
              />
              <h5 className="text-center mt-4" style={{ fontSize: '1.5rem' }}>
                Could not find detail view information.
              </h5>
              <h5 className="text-center mt-2" style={{ fontSize: '1.5rem' }}>
                Please try another search.
              </h5>
              <Button
                className="mt-3"
                onClick={() => window.location.replace('/')}
              >
                Return Home
              </Button>
            </Col>
          );
        default:
          return <Redirect to="/" />;
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
